<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, Ref, ref, watch } from 'vue';
import VocabList from '@/components/didactics/VocabList.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { nextTick } from 'vue';
import { debounce } from 'lodash';
import { VocabMode } from '@/helper';
import VocabTestModal from '@/components/didactics/vocab/VocabTestModal.vue';
import { v4 as uuidv4 } from 'uuid';
import MakeSentencesModal from '@/components/didactics/vocab/MakeSentencesModal.vue';
import { getApiClient } from '@/apiclient/client';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const authStore = useAuthStore();
const alertStore = useAlertStore();
const courseStore = useCourseStore();
const courseInteractionStore = useCourseInteractionStore();

const emit = defineEmits(['viewFullHeightOn', 'viewFullHeightOff', 'generateAndAddMoreVocab', 'scrollBy']);
const container = ref(null);
const vocabListRefs = ref([] as Ref<HTMLElement | null>[]); // user's personal lists
const mainVocabListRef = ref(null); // list of vocab items in content item
const vocabTestRef = ref(null);
const makeSentencesRef = ref(null);
const computedMaxHeight = ref('220px');

const isDraggingTo = ref([] as boolean[]);
const isDragging = ref(false);
const isDraggingItemId = ref('');
const practiceMode = ref(false);
const isSubmitting = ref(false);
const showingInset = ref(false);
const showingModal = ref(false);
const insetHasBeenAutoOpened = ref(false);
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const itemInteractionState = ref({});
const fetchCompleted = ref(false);
const testFinished = ref(false);

const exerciseCompleted = computed(() => {
  return itemInteractionState.value?.completed_at != null;
});

const props = defineProps({
  contentItem: {
    type: Object,
    required: true,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  fixedMaximization: {
    type: Boolean,
    default: false,
  },
  isMaximized: {
    type: Boolean,
    default: false,
  },
  showNative: {
    type: Boolean,
    default: false,
  },
  allowEditing: {
    type: Boolean,
    default: false,
  },
  vocabListLoading: {
    type: Boolean,
    default: false,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
});

watch(
  () => props.contentItem.vocab_list.vocab_items,
  async () => {
    await nextTick();
    console.log('vocab items changed');
    if (props.contentItem.vocab_list.vocab_items.length === 0) return;
    computeMaxHeight();
  },
);

watch(
  () => props.isMaximized,
  async () => {
    await nextTick();
    console.log('isMaximized changed');
    computeMaxHeight();
  },
);

const vocabWithoutAudio = computed(() => {
  return props.contentItem.vocab_list.vocab_items.some((vocabItem) => !vocabItem.audio_url);
});

// const adjustMaxHeights = () => {
//   if (!container.value) {
//     return;
//   }
//   let maxHeight = window.innerHeight - props.outerHeaderHeight;
//   console.log('maxHeight', maxHeight);
//   container.value.style.setProperty('--max-height', `${maxHeight}px`);
// };
//
// const debouncedAdjustMaxHeight = debounce(adjustMaxHeights, 200);

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  if (!props.fixedMaximization) {
    document.addEventListener('click', handleClickOutside);
  }
  isDraggingTo.value = new Array(authStore.user.vocab_lists.length).fill(false);

  authStore.user.vocab_lists.forEach(() => {
    vocabListRefs.value.push(ref(null));
  });

  await nextTick();
  console.log('VocabExercise mounted');
  computeMaxHeight();
  await fetchInteractionState();
});

watch(
  () => courseInteractionFetchCompleted.value,
  async () => {
    await nextTick();
    await fetchInteractionState();
  },
  { immediate: true },
);

onBeforeUnmount(async () => {
  if (!props.fixedMaximization) {
    document.removeEventListener('click', handleClickOutside);
  }

  if (props.allowEditing && vocabWithoutAudio.value) {
    console.log(
      'Teacher finished editing list ',
      props.contentItem.vocab_list.id,
      ' => sending for audio generation ' +
        '(and extraction of new vocab for generated-from-context list, if any) or fetching audio (if already known)',
    );
    (await getApiClient()).vocabs
      .completeAndSyncVocabListAndGeneratedFromContextVocabList(props.contentItem.vocab_list.id)
      .then(() => {})
      .catch((error) => {
        console.error('Error completing vocab items and adding to generated-from-context list', error);
        throw Error('Error completing vocab items and adding to generated-from-context list');
      });
  }
});

const handleClickOutside = (event: Event) => {
  if (props.fixedMaximization) return;
  if (showingModal.value) return; // otherwise ruins the DOM, leaving the backdrop open forever
  console.log('click outside');
  if (props.isMaximized && !!container.value && !container.value.contains(event.target)) {
    emit('viewFullHeightOff');
  }
};

const handleClickInside = () => {
  if (props.fixedMaximization) return;
  if (!props.isMaximized) {
    emit('viewFullHeightOn');
  }
};

const computeMaxHeight = () => {
  console.log('computeMaxHeight');
  if (!container.value) {
    computedMaxHeight.value = '150px';
    return;
  }
  // TODO add extra height for each personal vocab list appearing in top window inset
  if (!!mainVocabListRef.value?.getFullScrollHeight()) {
    computedMaxHeight.value = `${mainVocabListRef.value.getFullScrollHeight() + 200}px`;
    return;
  }
  if (!!props.contentItem?.vocab_list?.vocab_items?.length && !!authStore.user?.vocab_lists?.length) {
    computedMaxHeight.value = `${
      props.contentItem.vocab_list.vocab_items.length * 35 + authStore.user.vocab_lists.length * 50 + 100
    }px`;
    return;
  }
  console.warn('fullHeight: no vocab items or vocab lists. Fallback to 800 px.');
  console.warn('test vocab items', props.contentItem?.vocab_list?.vocab_items?.length);
  console.warn('personal vocab lists', authStore.user?.vocab_lists?.length);
  computedMaxHeight.value = `800px`;
};

const handleIsDraggingVocabItem = (isDraggingNow: boolean, vocabItemId: string) => {
  isDragging.value = isDraggingNow;
  isDraggingItemId.value = vocabItemId;
  if (isDraggingNow) {
    if (!showingInset.value) insetHasBeenAutoOpened.value = true;
    showingInset.value = true;
  } else {
    if (insetHasBeenAutoOpened.value) {
      setTimeout(() => {
        showingInset.value = false;
        insetHasBeenAutoOpened.value = false;
      }, 200);
    }
  }
};

const handleDragOver = (event: Event, index: number) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'copy';
  isDraggingTo.value[index] = true;
};

const handleDragLeave = (event: Event, index: number) => {
  isDraggingTo.value[index] = false;
};

const handleDrop = async (index: number) => {
  console.log('dropped', isDraggingItemId.value, ' to ', index);
  let vocabListId = authStore.user.vocab_lists[index].id;
  await authStore.copyVocabItemToPersonalVocabList(vocabListId, isDraggingItemId.value, true, true);
  isDragging.value = false;
  isDraggingTo.value[index] = false;
  isDraggingItemId.value = '';
};

const addVocabItem = async () => {
  isSubmitting.value = true;
  await courseStore
    .addVocabItemToContentItemWithVocabList(props.contentItem.section_id, props.contentItem.id, {
      term: 'Begriff',
      explanation: 'Erläuterung',
    })
    .then(() => {
      alertStore.success('Vokabel erfolgreich hinzugefügt');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht hinzugefügt werden', 'Fehler', error);
      console.error(error);
      isSubmitting.value = false;
      throw error;
    });
  isSubmitting.value = false;
};

const deleteVocabItem = async (vocabItemId: string) => {
  isSubmitting.value = true;
  await courseStore
    .deleteVocabItemFromContentItemWithVocabList(props.contentItem.section_id, props.contentItem.id, vocabItemId)
    .then(() => {
      alertStore.success('Vokabel erfolgreich gelöscht');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht gelöscht werden', 'Fehler', error);
      console.error(error);
      isSubmitting.value = false;
      throw error;
    });
  isSubmitting.value = false;
};

const updateItem = async (vocabItemId: string, columnName: string, newValue: any) => {
  let updateObject = {};
  updateObject[columnName] = newValue;

  await courseStore
    .updateVocabItemInContentItemWithVocabList(props.sectionId, props.contentItem.id, vocabItemId, updateObject)
    .then(() => {
      console.log('Vokabel erfolgreich aktualisiert');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht aktualisiert werden', 'Fehler', error);
      console.error(error);
      throw error;
    });
};

const handleDroppedAtPosition = (position) => {
  for (let i = 0; i < vocabListRefs.value.length; i++) {
    const navItemContainer = vocabListRefs.value[i]?.value?.[0];
    if (!navItemContainer) {
      continue;
    }

    const rect = navItemContainer.getBoundingClientRect();

    if (position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom) {
      handleDrop(i);
    }
  }
};

const handleCloseModal = async (finished: boolean) => {
  await new Promise((resolve) => setTimeout(resolve, 200));
  if (finished) {
    testFinished.value = true;
    // if user doesnt do, set to completed after some secs
    setTimeout(() => {
      markComplete();
    }, 8000);
  }
  showingModal.value = false; // reenables minimize by click outside shortly after, not directly
};

watch(
  () => props.isMaximized,
  () => {
    if (!props.isMaximized) {
      showingInset.value = false;
    }
  },
);

watch(
  () => props.vocabListLoading,
  () => {
    mainVocabListRef.value?.scrollToBottom();
  },
);

const fetchInteractionState = async () => {
  let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(props.contentItem.id);
  console.log('interactionState', interactionState, '.');
  // create local copy - we want to defer updates to wait for animations etc
  if (!!interactionState && interactionState !== {}) {
    fetchCompleted.value = true;
    itemInteractionState.value = { ...interactionState };
  }
};

const markComplete = async () => {
  let examinedVocabItems = props.contentItem.vocab_list.vocab_items;
  let usersWortschatzkisteId = authStore.user.vocab_lists[0].id;

  let errorWhenAdding = false;

  for (const vocab of examinedVocabItems) {
    await (await getApiClient()).vocabLists
      .copyVocabToPersonalVocabList(usersWortschatzkisteId, vocab.id)
      .catch((error) => {
        alertStore.error('Failed to add vocab item to Wortschatzkiste', 'Error', error);
        errorWhenAdding = true;
      });
  }

  if (!errorWhenAdding) {
    alertStore.success(
      'Toll, ' + examinedVocabItems.length + ' neue Begriffe wurden in deine Wortschatzkiste übernommen!',
    );
  }

  courseInteractionStore
    .setSectionContentItemCompletedIfNotAlready(props.contentItem.id, props.sectionIndex, props.pageIndex)
    .then(async (response) => {
      if (!response) return;
      console.log(response);
      response.notifications?.forEach((notification) => {
        alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
      });
      itemInteractionState.value = response.section_content_item_interaction;
      await authStore.fetchUserXp();
    });
};

const takeTest = async () => {
  showingModal.value = true;
  if (Math.random() < 0.0) {
    await makeSentencesRef.value.takeTest();
  } else {
    await vocabTestRef.value.takeTest();
  }
};
</script>

<template>
  <div
    ref="container"
    class="w-full pt-0.5 px-2 overflow-auto transition-1000 transition-all ease-in-out relative flex-col flex"
    :style="{
      height: props.isMaximized ? computedMaxHeight : '200px',
    }"
    @click.prevent="
      (event) => {
        handleClickInside();
        event.stopPropagation();
      }
    "
  >
    <h2 class="text font-semibold text-center pt-8 md:pt-2">Neue Vokabeln</h2>

    <!-- vocab lists card -->
    <div
      v-show="!props.allowEditing"
      class="z-20 text-sm flex-col top-1 right-1 transition-all transform duration-300 ease-in-out overflow-hidden absolute divide-y divide-gray-500 bg-white rounded-md text-gray-800 dark:text-neutral-200"
      :class="{
        'hd-[200px] md:h-[300px] p-4 mb-2 border border-gray-200 ': showingInset && props.isMaximized,
        'h-[0px] max-h-[0px]': !showingInset || !props.isMaximized,
      }"
    >
      <div class="pt-4 pb-1 md:pb-4 pl-2 mr:pr-8">
        <a class="hidden md:block hover:text-blue-600 hover:underline cursor-pointer" href="/vocab-dashboard"
          >Your personal vocab lists</a
        >
        <a class="block md:hidden hover:text-blue-600 hover:underline cursor-pointer" href="/vocab-dashboard"
          >Vocab lists</a
        >
        <div class="text-gray-400 rounded-md dark:text-neutral-600">Drag vocabs here to add</div>
      </div>
      <div class="pt-1 md:pt-4 pb-0 flex-col flex md:gap-y-2">
        <div v-for="(personalVocabList, index) in authStore.user.vocab_lists">
          <div
            :ref="vocabListRefs[index]"
            class="inline-flex items-center w-full md:p-1 rounded-lg"
            @dragover.prevent="handleDragOver($event, index)"
            @dragleave.prevent="handleDragLeave($event, index)"
            @drop.prevent="handleDrop(index)"
            :class="{
              'bg-gray-200 dark:bg-neutral-700': isDraggingTo[index],
              'bg-transparent dark:bg-neutral-800 border-dashed': !isDraggingTo[index],
              'text-blue-600': isDragging,
            }"
          >
            <span
              v-if="index === 0"
              translate="no"
              class="material-symbols-outlined notranslate items-center flex pr-2 text-xl"
            >
              join
            </span>
            <span
              translate="no"
              class="material-symbols-outlined notranslate items-center flex pr-2 text-xl"
              :style="{ fontVariationSettings: '\'FILL\' 1' }"
              v-else-if="index === 1"
            >
              star
            </span>
            <span v-else translate="no" class="material-symbols-outlined notranslate items-center flex pr-2 text-xl">
              list
            </span>
            {{ personalVocabList.name }}
            <div class="pl-4 material-symbols-outlined relative" v-show="isDragging">
              content_copy
              <span
                translate="no"
                class="material-symbols-outlined notranslate absolute top-[-13px] end-[-6px] text-lg font-medium"
                >add</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="props.isMaximized"
      class="absolute top-1 right-1 z-30 my-1 mx-1 md:mx-2 flex items-center text-sm cursor-pointer bg-white rounded-md text-gray-500 hover:text-gray-400 font-medium dark:text-gray-400 dark:hover:text-gray-500"
      @click.prevent="showingInset = !showingInset"
    >
      <span class="md:pr-2 select-none" v-show="!showingInset">Meine Vokabellisten</span>
      <span
        translate="no"
        class="material-symbols-outlined notranslate transition-all transform duration-300 ease-in-out select-none"
        :style="{
          transform: showingInset ? 'rotate(180deg)' : 'rotate(0deg)',
        }"
        >expand_more</span
      >
      <span translate="no" class="material-symbols-outlined notranslate select-none">list</span>
    </div>
    <!-- end vocab lists card -->

    <div class="w-full h-full flex-col flex">
      <div class="px-2 inline-flex items-center text-center text-blue-600 gap-x-4 py-2" v-show="props.allowEditing">
        <span
          translate="no"
          class="material-symbols-outlined notranslate text-2xl select-none"
          :class="{ 'pb-32': props.isMaximized }"
          >info</span
        >
        <div class="w-full items-start flex-col">
          <h3 class="text-xs md:text-sh text-justify">
            Ergänze und überarbeite die Vokabelliste.
            <span v-show="props.isMaximized">Der User kann diese Vokabeln</span>
          </h3>
          <ul
            v-show="props.isMaximized"
            class="text-xs md:text-sh text-start pt-2 pb-2"
            style="list-style-type: disc; padding-left: 20px"
          >
            <li>Lesen und lernen,</li>
            <li>In einem Aufdeckspiel üben und</li>
            <li>In seine eigene Vokabelliste übernehmen</li>
          </ul>
          <span v-show="props.isMaximized" class="text-xs md:text-sh text-start flex pb-2">
            Dies alles wird automatisch aus deiner Vokabelliste erzeugt.
          </span>
          <span v-show="props.isMaximized" class="text-xs md:text-sh text-start flex pb-8">
            Außerdem werden aus allen Begriffen deiner Vokabelliste Audio-Dateien generiert, so dass deine Studenten die
            Aussprache hören können.
          </span>
        </div>
      </div>
      <div
        v-show="props.isMaximized && !props.allowEditing"
        class="group inline-flex w-full h-fit pt-2 pb-4 justify-center items-center gap-x-6"
      >
        <div v-if="!testFinished && !exerciseCompleted" @click="takeTest">
          <ProgressButton text="Üben" />
        </div>
        <div class="group" v-else-if="testFinished && !exerciseCompleted" @click="markComplete">
          <div class="block group-hover:hidden">
            <ProgressButton text="Vokabeln speichern" color="teal" icon="circle" iconSize="text-2xl" />
          </div>
          <div class="hidden group-hover:block">
            <ProgressButton
              text="Vokabeln speichern"
              color="teal"
              icon="check_circle"
              iconSize="text-2xl"
              :hovered="true"
            />
          </div>
        </div>
        <div v-else>
          <div class="block group-hover:hidden">
            <button
              type="button"
              class="group select-none py-1.5 px-3 text-xs md:text-sm inline-flex items-center gap-x-0.5 md:gap-x-2 font-medium rounded-lg shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:text-white text-teal-500 bg-teal-200/10 border-gray-200 border-2 cursor-default dark:border-teal-700 dark:bg-teal-900 '"
            >
              <span translate="no" class="material-symbols-outlined notranslate"> task_alt </span>
              {{ 'Fertig' }}
            </button>
          </div>
          <div class="hidden group-hover:block">
            <ProgressButton text="Nochmal üben" color="blue" icon="exercise" iconSize="text-2xl" @click="takeTest" />
          </div>
        </div>
      </div>

      <!-- TODO add usage examples! -->
      <div>
        <VocabList
          ref="mainVocabListRef"
          :key="props.isMaximized && practiceMode"
          :show-searchbar="false"
          :vocab-list="props.contentItem.vocab_list.vocab_items"
          :view-fullscreen="false"
          :show-column-selected="false"
          :show-column-context-links="false"
          :show-column-usage-examples="true"
          :show-column-translations="props.showNative"
          :show-column-tags="false"
          :show-column-remarks="props.isMaximized"
          :show-column-results-at-tests="false"
          :show-column-is-favourite="false"
          :allow-editing="props.allowEditing"
          :allow-editing-term="props.allowEditing"
          :hide-random-but-one-column="practiceMode ? ['term', 'explanation'] : null"
          :disable-scroll="!props.isMaximized"
          @isDragging="
            (isDragging, vocabItemId) => {
              handleIsDraggingVocabItem(isDragging, vocabItemId);
            }
          "
          @updatedCell="
            (vocabItemId, columnName, newValue) => {
              updateItem(vocabItemId, columnName, newValue);
            }
          "
          @droppedAtPosition="handleDroppedAtPosition"
          @scrollBy="(distance) => emit('scrollBy', distance)"
          @deleteItem="(vocabItemId) => deleteVocabItem(vocabItemId)"
        />
      </div>
      <div class="p-4 pt-8 gap-x-6 w-full flex items-center justify-center" v-show="allowEditing && props.isMaximized">
        <ProgressButton
          @click="addVocabItem"
          text="Vokabel hinzufügen"
          :show-progress="isSubmitting"
          :disabled="isSubmitting || vocabListLoading"
          icon="add"
          iconSize="text-2xl"
        />
        <ProgressButton
          @click="emit('generateAndAddMoreVocab')"
          text="Fünf weitere generieren"
          :show-progress="vocabListLoading"
          :disabled="isSubmitting || vocabListLoading"
          icon="ai_sparkle"
          iconSize="text-2xl"
        />
      </div>
    </div>
  </div>

  <VocabTestModal
    ref="vocabTestRef"
    :overlayId="`vocab-test-modal-${uuidv4()}`"
    :vocabItems="props.contentItem.vocab_list.vocab_items || []"
    :showSolutions="false"
    :mode="VocabMode.EXPLAIN"
    :nVocabs="3"
    :goThroughAll="true"
    @closed="handleCloseModal"
  />
  <MakeSentencesModal
    ref="makeSentencesRef"
    :overlayId="`make-sentences-modal-${uuidv4()}`"
    :vocabItems="props.contentItem.vocab_list.vocab_items || []"
    :showSolutions="false"
    :nVocabs="5"
    :goThroughAll="true"
    @closed="handleCloseModal"
  />
</template>

<style scoped></style>
