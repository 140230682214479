/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_profile_image_users__user_id__profile_image__post } from '../models/Body_upload_profile_image_users__user_id__profile_image__post';
import type { CaseInteractionDetailed } from '../models/CaseInteractionDetailed';
import type { CaseListOverview } from '../models/CaseListOverview';
import type { CourseInteractionDetailed } from '../models/CourseInteractionDetailed';
import type { FollowCreate } from '../models/FollowCreate';
import type { FollowWithFollowedUser } from '../models/FollowWithFollowedUser';
import type { FollowWithFollowingUser } from '../models/FollowWithFollowingUser';
import type { ImageUrls } from '../models/ImageUrls';
import type { Organization } from '../models/Organization';
import type { StarredCaseCreate } from '../models/StarredCaseCreate';
import type { StarredCaseDetails } from '../models/StarredCaseDetails';
import type { StarredCaseList } from '../models/StarredCaseList';
import type { UserBlockCreate } from '../models/UserBlockCreate';
import type { UserBlockDetails } from '../models/UserBlockDetails';
import type { UserDetails } from '../models/UserDetails';
import type { UserDetailsUpdate } from '../models/UserDetailsUpdate';
import type { VocabTestStats } from '../models/VocabTestStats';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Current User
   * Get the current user details by token.
   * @returns UserDetails Successful Response
   * @throws ApiError
   */
  public getCurrentUser(): CancelablePromise<UserDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/me',
    });
  }

  /**
   * Get Current User Xp
   * Get the current user's XP points.
   * @returns number Successful Response
   * @throws ApiError
   */
  public getCurrentUserXp(): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/my-xp',
    });
  }

  /**
   * Get Current User Vocab Test Stats
   * @returns VocabTestStats Successful Response
   * @throws ApiError
   */
  public getCurrentUserVocabTestStats(): CancelablePromise<VocabTestStats> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/my-vocab-stats',
    });
  }

  /**
   * Get User By Id
   * Get a user by their ID.
   *
   * Raises:
   * UserNotFoundError: If the user is not found.
   * @param userId
   * @returns UserDetails Successful Response
   * @throws ApiError
   */
  public getUserById(userId: string): CancelablePromise<UserDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update User
   * @param userId
   * @param requestBody
   * @returns UserDetails Successful Response
   * @throws ApiError
   */
  public updateUser(userId: string, requestBody: UserDetailsUpdate): CancelablePromise<UserDetails> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/users/{user_id}',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Upload Profile Image
   * @param userId
   * @param formData
   * @returns ImageUrls Successful Response
   * @throws ApiError
   */
  public uploadProfileImage(
    userId: string,
    formData: Body_upload_profile_image_users__user_id__profile_image__post,
  ): CancelablePromise<ImageUrls> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/{user_id}/profile-image/',
      path: {
        user_id: userId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Users Following User
   * @param userId
   * @returns FollowWithFollowingUser Successful Response
   * @throws ApiError
   */
  public listUsersFollowingUser(userId: string): CancelablePromise<Array<FollowWithFollowingUser>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}/followed-by',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Users Followed By User
   * @param userId
   * @returns FollowWithFollowedUser Successful Response
   * @throws ApiError
   */
  public listUsersFollowedByUser(userId: string): CancelablePromise<Array<FollowWithFollowedUser>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}/following',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add User Followed By User
   * @param userId
   * @param requestBody
   * @returns FollowWithFollowedUser Successful Response
   * @throws ApiError
   */
  public addUserFollowedByUser(userId: string, requestBody: FollowCreate): CancelablePromise<FollowWithFollowedUser> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/{user_id}/following',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Starred Case To User
   * @param userId
   * @param requestBody
   * @returns StarredCaseDetails Successful Response
   * @throws ApiError
   */
  public addStarredCaseToUser(userId: string, requestBody: StarredCaseCreate): CancelablePromise<StarredCaseDetails> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/{user_id}/starred-cases',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Starred Cases Of User
   * @param userId
   * @returns StarredCaseDetails Successful Response
   * @throws ApiError
   */
  public listStarredCasesOfUser(userId: string): CancelablePromise<Array<StarredCaseDetails>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}/starred-cases',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Starred Case Lists Of User
   * @param userId
   * @returns StarredCaseList Successful Response
   * @throws ApiError
   */
  public listStarredCaseListsOfUser(userId: string): CancelablePromise<Array<StarredCaseList>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}/starred-case-lists',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Starred Case List For User
   * @param userId
   * @param requestBody
   * @returns StarredCaseDetails Successful Response
   * @throws ApiError
   */
  public addStarredCaseListForUser(
    userId: string,
    requestBody: StarredCaseCreate,
  ): CancelablePromise<StarredCaseDetails> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/{user_id}/starred-case-lists',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List User Blocks Of User
   * @param userId
   * @returns UserBlockDetails Successful Response
   * @throws ApiError
   */
  public listUserBlocksOfUser(userId: string): CancelablePromise<Array<UserBlockDetails>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}/blocking',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add User Block For User
   * @param userId
   * @param requestBody
   * @returns UserBlockDetails Successful Response
   * @throws ApiError
   */
  public addUserBlockForUser(userId: string, requestBody: UserBlockCreate): CancelablePromise<UserBlockDetails> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/users/{user_id}/blocking',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Case Lists Of User
   * @param userId
   * @returns CaseListOverview Successful Response
   * @throws ApiError
   */
  public listCaseListsOfUser(userId: string): CancelablePromise<Array<CaseListOverview>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}/case-lists',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Case Interactions Of User
   * @param userId
   * @param solved
   * @returns CaseInteractionDetailed Successful Response
   * @throws ApiError
   */
  public listCaseInteractionsOfUser(
    userId: string,
    solved?: boolean | null,
  ): CancelablePromise<Array<CaseInteractionDetailed>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}/case-interactions',
      path: {
        user_id: userId,
      },
      query: {
        solved: solved,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Course Interactions Of User
   * @param userId
   * @returns CourseInteractionDetailed Successful Response
   * @throws ApiError
   */
  public listCourseInteractionsOfUser(userId: string): CancelablePromise<Array<CourseInteractionDetailed>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}/course-interactions',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * List Managed Organizations Of User
   * @param userId
   * @returns Organization Successful Response
   * @throws ApiError
   */
  public listManagedOrganizationsOfUser(userId: string): CancelablePromise<Array<Organization>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/{user_id}/managed-organizations',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
