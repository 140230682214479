<script setup>
import MainHeader from '@/components/headers/MainHeader.vue';
import { ref, onMounted, onBeforeUnmount, computed, nextTick } from 'vue';
import { debounce } from 'lodash';
import { useRoute, useRouter } from 'vue-router';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import { VocabMode } from '@/helper';
import VocabTestModal from '@/components/didactics/vocab/VocabTestModal.vue';
import MakeSentencesModal from '@/components/didactics/vocab/MakeSentencesModal.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores';

const authStore = useAuthStore();
const { vocabLists } = storeToRefs(authStore);

const outerHeader = ref(null);
const outerHeaderHeight = ref(0);
const mobileInteractionsFooter = ref(null);
const mobileInteractionsFooterHeight = ref(0);
const vocabTestRef = ref(null);
const makeSentencesRef = ref(null);
const route = useRoute();
const routerKey = ref('initial-router-key');
const modalInstanceKey = ref(0);

const transitionDirection = ref(''); // Tracks the transition direction (e.g., 'slide-left' or 'slide-right')

const handleTransitionDirection = (direction) => {
  console.log('received transition: ' + direction);
  transitionDirection.value = direction;
};

const adjustHeight = async () => {
  await nextTick();
  if (outerHeader.value) {
    const newHeight = outerHeader.value.offsetHeight;
    console.log('New header height:', newHeight); // Debug log
    if (newHeight < 10) {
      // Sanity check for unreasonably small heights
      console.warn('Suspiciously small header height detected');
      return; // Don't update with invalid height
    }
    outerHeaderHeight.value = newHeight;
  }
  if (mobileInteractionsFooter.value) {
    mobileInteractionsFooterHeight.value = mobileInteractionsFooter.value.offsetHeight;
  }
};

const outerObjectsHeight = computed(() => {
  return outerHeaderHeight.value + mobileInteractionsFooterHeight.value;
});

const debouncedAdjustHeight = debounce(adjustHeight, 200);

onMounted(async () => {
  window.addEventListener('resize', debouncedAdjustHeight);

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  // Initial height calculation with retry mechanism
  let attempts = 0;
  const maxAttempts = 5;

  const tryAdjustHeight = async () => {
    await adjustHeight();
    if (outerHeaderHeight.value < 10 && attempts < maxAttempts) {
      attempts++;
      console.log(`Retrying height calculation, attempt ${attempts}`);
      setTimeout(tryAdjustHeight, 100);
    }
  };

  await tryAdjustHeight();
  await authStore.fetchUserVocabLists();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${outerObjectsHeight.value}px)`,
  };
});

const computedStyleMainPlusHeader = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh)`,
  };
});

const takeDailyQuickTest = async () => {
  console.log('LoggedInRoot: takeDailyQuickTest');
  modalInstanceKey.value++; // Increment the key to force recreation
  await new Promise((resolve) => setTimeout(resolve, 50));
  // randomly select one of the two modal types
  if (Math.random() > 0.7) {
    vocabTestRef.value.takeTest();
    return;
  }
  makeSentencesRef.value.takeTest();
};

const shouldCache = (route) => {};

const changeRouterKey = (key) => {
  console.log('changeRouterKey: ', key);

  if (key === 'keep-alive') {
    return routerKey.value;
  }
  routerKey.value = key;
  return routerKey.value;
};
</script>

<template>
  <div class="flex flex-col w-screen overflow-hidden" :style="computedStyleMainPlusHeader">
    <header
      ref="outerHeader"
      class="flex-none bg-white sticky top-0 md:justify-start md:flex-nowrap z-50 w-full text-sm py-3 md:py-0 dark:bg-neutral-900"
    >
      <MainHeader
        :showRootLink="true"
        :showCoursesDropdown="true"
        :showPatientCaseDropdown="true"
        :showChallengesAndEventsLink="true"
        :showCommunityLink="true"
        :showAboutUsLink="false"
        :showQuizDropdown="true"
        :showSignInOrEditProfile="true"
        @takeDailyQuickTest="takeDailyQuickTest"
      />
    </header>
    <main :style="computedStyleMain" class="flex-grow w-full overflow-hidden" ref="mainContent">
      <router-view v-slot="{ Component, route }" :key="changeRouterKey(route.meta.forceNewRouterKey ?? 'keep-alive')">
        <transition :name="transitionDirection" mode="out-in">
          <component
            :key="route.fullPath"
            :is="Component"
            :outer-header-height="outerObjectsHeight"
            @transition-direction="handleTransitionDirection"
            @takeDailyQuickTest="takeDailyQuickTest"
          />
        </transition>
      </router-view>
    </main>
  </div>

  <VocabTestModal
    :key="`${(vocabLists[0]?.vocab_items || []).length}-${modalInstanceKey}`"
    ref="vocabTestRef"
    overlayId="vocab-test-modal-quicktest"
    :vocabItems="vocabLists[0]?.vocab_items || []"
    :showSolutions="false"
    :mode="VocabMode.EXPLAIN"
    :nVocabs="5"
  />
  <!-- key: update at least if vocab added/removed -->

  <MakeSentencesModal
    :key="`${(vocabLists[0]?.vocab_items || []).length}-${modalInstanceKey}`"
    ref="makeSentencesRef"
    overlayId="make-sentences-modal-quicktest"
    :vocabItems="vocabLists[0]?.vocab_items || []"
    :showSolutions="false"
    :nVocabs="3"
  />
</template>

<style scoped>
/* Slide Left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.5s ease;
  position: absolute;
  top: 64;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-left-enter-from {
  transform: translateX(50%);
}

.slide-left-enter-to {
  transform: translateX(0%);
}

.slide-left-leave-from {
  transform: translateX(0%);
}

.slide-left-leave-to {
  transform: translateX(-50%);
}

/* Slide Right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s ease;
  position: absolute;
  top: 64;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-right-enter-from {
  transform: translateX(-50%);
}

.slide-right-enter-to {
  transform: translateX(0%);
}

.slide-right-leave-from {
  transform: translateX(0%);
}

.slide-right-leave-to {
  transform: translateX(50%);
}
</style>
