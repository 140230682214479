<script setup lang="ts">
import CardPair from '@/components/didactics/pair_of_terms/CardPair.vue';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { PairOfTermsItemPair, PairOfTermsItemType } from '@/helper/typing';
import { v4 as uuidv4 } from 'uuid';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { is } from '@vee-validate/rules';
import { Ref } from 'vue';
import Card from '@/components/didactics/pair_of_terms/Card.vue';

const courseInteractionStore = useCourseInteractionStore();
const authStore = useAuthStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const emit = defineEmits(['viewFullHeightOn', 'viewFullHeightOff', 'scrollBy']);
const container = ref(null);
const pairContainerRefs = ref([] as Ref<HTMLElement | null>[]);
const courseStore = useCourseStore();
const alertStore = useAlertStore();
const fetchCompleted = ref(false);
const itemInteractionState = ref({});
const showGrandmaJoke = ref(false);
const grandmaThumbnail = ref(null);

import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import PairOfTermsCardMediaUpload from '@/components/didactics/pair_of_terms/ExerciseMediaUploadCard.vue';
import { onBeforeRouteLeave } from 'vue-router';

const { t } = useI18n();

const props = defineProps({
  allowEdit: {
    type: Boolean,
    default: false,
  },
  isMaximized: {
    type: Boolean,
    default: false,
  },
  fixedMaximization: {
    type: Boolean,
    default: false,
  },
  contentItem: {
    type: Object,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  // index: {
  //   type: Number,
  //   required: true,
  // },
  sectionIndex: {
    type: Number,
    required: true,
  },
});

const truePairs = ref([] as PairOfTermsItemPair[]);
const currentData = ref([] as PairOfTermsItemPair[]);

const prompt = ref('');

const pairwiseResults = ref<boolean[]>([]);
const showResults = ref(false);
const isLoading = ref(false);
const isSubmitting = ref(false);
const unsavedChanges = ref(false);
const isEditing = ref(props.allowEdit);
const maximizedHeight = ref(500);
const isSavingChanges = ref(false);
const currentlyDraggingItem1Id = ref<string | null>(null);
const currentlyDraggingItem2Id = ref<string | null>(null);
const currentlyDraggingOver = ref([] as boolean[]);

const unfilledPairs = computed(() => {
  return currentData.value.some((pair) => {
    return (
      (pair.pair_of_terms_items[0].content === '' && pair.pair_of_terms_items[0].media_item_id === null) ||
      (pair.pair_of_terms_items[1].content === '' && pair.pair_of_terms_items[1].media_item_id === null)
    );
  });
});

const unfilledInstructions = computed(() => {
  return prompt.value === '';
});

const unfilledData = computed(() => {
  return unfilledPairs.value || unfilledInstructions.value;
});

// const trySolve = () => {
//   pairwiseResults.value = currentData.value.map((pair) => {
//     return truePairs.value.some(
//       (truePair) =>
//         pair.pair_of_terms_items[0].id === truePair.pair_of_terms_items[0].id &&
//         pair.pair_of_terms_items[1].id === truePair.pair_of_terms_items[1].id,
//     );
//   });
//   showResults.value = !showResults.value;
// };

const trySolve = async () => {
  pairwiseResults.value = currentData.value.map((pair) => {
    return truePairs.value.some((truePair) => {
      const [pairItem1, pairItem2] = pair.pair_of_terms_items;
      const [truePairItem1, truePairItem2] = truePair.pair_of_terms_items;

      // Check if media item ID is not null, then match by IDs
      let isMatch = false;
      console.log(pairItem1.media_item_id, pairItem2.media_item_id);
      if (pairItem1.media_item_id || pairItem2.media_item_id) {
        isMatch =
          (pairItem1.id === truePairItem1.id && pairItem2.id === truePairItem2.id) ||
          (pairItem1.id === truePairItem2.id && pairItem2.id === truePairItem1.id);
      } else {
        // Content-only items: Check if the contents match for both items in the pair, regardless of IDs
        isMatch =
          (pairItem1.content === truePairItem1.content && pairItem2.content === truePairItem2.content) ||
          (pairItem1.content === truePairItem2.content && pairItem2.content === truePairItem1.content);
      }

      return isMatch;
    });
  });

  showResults.value = !showResults.value;

  if (!showResults.value) {
    return;
  }
  // if finished and all true, send completed
  if (pairwiseResults.value.every((result) => result)) {
    await new Promise((resolve) => setTimeout(resolve, 700));
    courseInteractionStore
      .setSectionContentItemCompletedIfNotAlready(props.contentItem.id, props.sectionIndex, props.pageIndex)
      .then(async (response) => {
        console.log(response);
        response.notifications?.forEach((notification) => {
          alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
        });
        itemInteractionState.value = response.section_content_item_interaction;
        await authStore.fetchUserXp();
      });
  } else if (pairwiseResults.value.every((result) => !result)) {
    await new Promise((resolve) => setTimeout(resolve, 400));
    await alertStore.playErrorSound(true);
    await new Promise((resolve) => setTimeout(resolve, 100));
    showGrandmaJoke.value = true;
  } else {
    await new Promise((resolve) => setTimeout(resolve, 300));
    await alertStore.playErrorSound();
  }
};

const reopenExercise = async () => {
  courseInteractionStore.reopenSectionContentItem(props.contentItem.id).then((response) => {
    if (!response) return; // nothing has happened as the item was already started
    itemInteractionState.value = response;
  });
  showResults.value = false;
  shuffle();
};

const handleItem2Dragging = (isDragging: boolean, index: number, item2Id: string) => {
  if (isDragging) {
    currentlyDraggingItem2Id.value = item2Id;
  } else {
    currentlyDraggingItem2Id.value = null;
  }
};

const handleItem1Dragging = (isDragging: boolean, index: number, item1Id: string) => {
  if (isDragging) {
    currentlyDraggingItem1Id.value = item1Id;
  } else {
    currentlyDraggingItem1Id.value = null;
  }
};

const handleDragOver = (event: Event, index: number) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
  currentlyDraggingOver.value.splice(index, 1, true);
};

const handleDragLeave = (event: Event, index: number) => {
  currentlyDraggingOver.value.splice(index, 1, false);
};

const handleDrop = (event: Event, index: number) => {
  showResults.value = false;
  showGrandmaJoke.value = false;

  courseInteractionStore.startSectionContentItemIfNotAlready(props.contentItem.id).then((response) => {
    if (!response) return; // nothing has happened as the item was already started
    itemInteractionState.value = response.section_content_item_interaction;
  });

  if (currentlyDraggingItem1Id.value == null && currentlyDraggingItem2Id.value == null) {
    currentlyDraggingOver.value[index] = false;
    return;
  }

  if (currentlyDraggingItem1Id.value != null) {
    // Find the index of the item1 currently being dragged
    const draggingItem1Index = currentData.value.findIndex(
      (pair) => pair.pair_of_terms_items[0].id === currentlyDraggingItem1Id.value,
    );

    // Swap item1 of dragging index with item1 of target index
    if (draggingItem1Index !== -1) {
      const temp = currentData.value[index].pair_of_terms_items[0];
      currentData.value[index].pair_of_terms_items[0] = currentData.value[draggingItem1Index].pair_of_terms_items[0];
      currentData.value[draggingItem1Index].pair_of_terms_items[0] = temp;
    }

    // Clear the currentlyDraggingItem1Id
    currentlyDraggingItem1Id.value = null;
  } else {
    // Find the index of the item2 currently being dragged
    const draggingItem2Index = currentData.value.findIndex(
      (pair) => pair.pair_of_terms_items[1].id === currentlyDraggingItem2Id.value,
    );

    // Swap item2 of dragging index with item2 of target index
    if (draggingItem2Index !== -1) {
      const temp = currentData.value[index].pair_of_terms_items[1];
      currentData.value[index].pair_of_terms_items[1] = currentData.value[draggingItem2Index].pair_of_terms_items[1];
      currentData.value[draggingItem2Index].pair_of_terms_items[1] = temp;
    }

    // Clear the currentlyDraggingItem2Id
    currentlyDraggingItem2Id.value = null;
  }

  currentlyDraggingOver.value[index] = false;
};

const addPair = () => {
  isSubmitting.value = true;
  currentData.value.push({
    id: uuidv4(),
    pair_of_terms_items: [
      {
        id: uuidv4(),
        // pair_of_terms_item_type: 'TEXT',
        content: '',
        description: null,
        media_item_id: null,
      },
      {
        id: uuidv4(),
        // pair_of_terms_item_type: 'TEXT',
        content: '',
        description: null,
        media_item_id: null,
      },
    ],
  });
  pairwiseResults.value.push(false);
  currentlyDraggingOver.value.push(false);
  isSubmitting.value = false;
};

const deletePair = (index: number) => {
  isSubmitting.value = true;
  currentData.value.splice(index, 1);
  pairwiseResults.value.splice(index, 1);
  currentlyDraggingOver.value.splice(index, 1);
  isSubmitting.value = false;
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

const shuffle = () => {
  const firstItems = currentData.value.map((pair) => pair.pair_of_terms_items[0]);
  const secondItems = currentData.value.map((pair) => pair.pair_of_terms_items[1]);

  shuffleArray(firstItems);
  shuffleArray(secondItems);

  for (let i = 0; i < currentData.value.length; i++) {
    currentData.value[i].pair_of_terms_items[0] = firstItems[i];
    currentData.value[i].pair_of_terms_items[1] = secondItems[i];
  }
};

watch(
  () => currentData.value?.length,
  () => {
    pairwiseResults.value = Array(currentData.value.length).fill(false);
    currentlyDraggingOver.value = Array(currentData.value.length).fill(false);
    currentData.value.forEach((pair: any) => {
      pairContainerRefs.value.push(ref<HTMLElement | null>(null));
    });
  },
);

watch(
  () => courseInteractionFetchCompleted.value,
  async () => {
    if (!props.allowEdit) {
      await nextTick();
      await fetchInteractionState();
    }
  },
  { immediate: true },
);

const initData = () => {
  // currentData.value = JSON.parse(JSON.stringify(props.pairOfTermsGameTuple));
  let tuples = props.contentItem.pair_of_terms_game.pair_of_terms_item_tuples;
  currentData.value = JSON.parse(JSON.stringify(tuples));

  prompt.value = props.contentItem.pair_of_terms_game.task_instructions;

  // currentData.value = props.contentItem.pair_of_terms_game.pair_of_terms_item_tuples;
  truePairs.value = JSON.parse(JSON.stringify(tuples));

  pairwiseResults.value = Array(tuples.length).fill(false);
  currentlyDraggingOver.value = Array(tuples.length).fill(false);
  tuples.forEach((pair: any) => {
    pairContainerRefs.value.push(ref<HTMLElement | null>(null));
  });

  if (!props.allowEdit) {
    shuffle();
  } else if (tuples.length === 0) {
    addPair();
  }
};

onMounted(async () => {
  import(`@/assets/images/thumbnails-and-icons/grandma_laughing.png`).then((img) => {
    grandmaThumbnail.value = img.default;
  });

  initData();

  document.addEventListener('click', handleClickOutside);

  console.log('fetchCompleted', fetchCompleted.value);
  if (props.allowEdit) fetchCompleted.value = true;
  console.log('fetchCompleted', fetchCompleted.value);

  if (!props.allowEdit) {
    await fetchInteractionState();
  }

  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      savePairOfTermsGame();
      return undefined;
    }
  };
});

onBeforeUnmount(async () => {
  if (unsavedChanges.value) {
    await savePairOfTermsGame();
  }
  document.removeEventListener('click', handleClickOutside);
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await savePairOfTermsGame();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const fetchInteractionState = async () => {
  let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(props.contentItem.id);
  // create local copy - we want to defer updates to wait for animations etc
  if (!!interactionState && interactionState !== {}) {
    fetchCompleted.value = true;
    itemInteractionState.value = { ...interactionState };
  }
};

const savePairOfTermsGame = async () => {
  // const pairOfTermsUpdate = {
  //   id: props.contentItem.pair_of_terms_game.id,
  //   pair_of_terms_item_tuples: currentData.value.map((item: any) => ({
  //     id: item.id,
  //     pair_of_terms_items: [
  //       {...item.item1},  // Add item1 to pair_of_terms_items
  //       {...item.item2}   // Add item2 to pair_of_terms_items
  //     ]
  //   }))
  // };

  if (isSavingChanges.value) return;
  isSavingChanges.value = true;

  // isSubmitting.value = true;
  const pairOfTermsUpdate = {
    id: props.contentItem.pair_of_terms_game.id,
    task_instructions: prompt.value,
    pair_of_terms_item_tuples: currentData.value,
  };
  console.log(pairOfTermsUpdate);

  await courseStore
    .updatePairOfTermsGame(props.contentItem.section_id, props.contentItem.id, pairOfTermsUpdate)
    .then(() => {
      alertStore.success('status.success.changesSaved');
    })
    .catch((error) => {
      alertStore.error('status.error.saveChangesError');
      console.error(error);
    })
    .finally(() => {
      // initData();
      // isSubmitting.value = false;
      unsavedChanges.value = false;
      isSavingChanges.value = false;
    });
};

const handleClickOutside = (event: Event) => {
  console.log('click outside');
  if (props.isMaximized && !!container.value && !container.value.contains(event.target)) {
    emit('viewFullHeightOff');
  }
};

const handleClickInside = () => {
  if (!props.isMaximized) {
    emit('viewFullHeightOn');
  }
};

const handleDraggingAtPosition = (position: { x: number; y: number }) => {
  for (let i = 0; i < pairContainerRefs.value.length; i++) {
    const pairContainer = pairContainerRefs.value[i]?.value?.[0];

    if (!pairContainer) {
      currentlyDraggingOver.value[i] = false;
      continue;
    }

    const rect = pairContainer.getBoundingClientRect();

    currentlyDraggingOver.value[i] =
      position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom;
  }
};

const handleDroppedAtPosition = (position: { x: number; y: number }) => {
  for (let i = 0; i < pairContainerRefs.value.length; i++) {
    const pairContainer = pairContainerRefs.value[i]?.value?.[0];
    if (!pairContainer) {
      continue;
    }

    const rect = pairContainer.getBoundingClientRect();

    if (position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom) {
      handleDrop(new Event('drop'), i);
    }
  }
};

const computeMaximizedHeight = () => {
  maximizedHeight.value = container.value?.scrollHeight;
};

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      setTimeout(() => {
        savePairOfTermsGame();
        unsavedChanges.value = false;
      }, 3000);
    }
  },
);

defineExpose({
  maximizedHeight,
});
</script>

<template>
  <div
    ref="container"
    class="w-full p-0.5 overflow-hidden relative"
    :class="{
      'h-fit': isMaximized,
      'h-[295px]': !isMaximized,
    }"
    @click.prevent="
      (event) => {
        handleClickInside();
        event.stopPropagation();
      }
    "
  >
    <div
      :class="{
        'opacity-25':
          !props.allowEdit &&
          (!fetchCompleted ||
            (itemInteractionState?.completed_at != null && itemInteractionState?.reopened_at == null)),
      }"
    >
      <h2 class="text font-semibold pt-2 text-center" v-if="props.allowEdit">Übung: Paare bilden</h2>
      <div>
        <div class="px-2 inline-flex items-center text-center text-blue-600 gap-x-4 py-2" v-show="isEditing">
          <span translate="no" class="material-symbols-outlined notranslate text-2xl select-none">info</span>
          <h3 class="text-sm text-justify">
            Lege Paare in der korrekten Zuordnung an. Dem User wird eine zufällige Sortierung angezeigt.
          </h3>
        </div>
        <h2
          class="bg-white pt-2 pb-1 w-full rounded-lg text-center"
          :class="{ 'border border-gray-200 shadow-sm': isEditing }"
        >
          <span v-if="!isEditing" class="text-xs md:text-sm">{{ prompt }}</span>
          <textarea
            v-if="isEditing"
            v-model="prompt"
            @input="unsavedChanges = true"
            placeholder="Aufgabenstellung, die dem User angezeigt werden soll, z.B. 'Ordne die Begriffe den Definitionen zu.'"
            class="w-full font-normal text-xs md:text-sm text-center border-gray-200 rounded-lg resize-none min-w-2"
          />
        </h2>
      </div>

      <div v-if="showGrandmaJoke" class="grandma-appear">
        <div class="inline-flex bg-white mx-auto w-fit p-2 border border-gray-200 rounded-lg">
          <img :src="grandmaThumbnail" alt="Oma gefällt's (nicht)" class="w-20 h-20 rounded-lg" />
          <div class="pl-2 text-left">
            <h2 class="">Oma würde sagen:</h2>
            <p class="italic">"Ein Satz mit X - das war wohl nix"</p>
          </div>
        </div>
      </div>

      <div class="w-full gap-y-1 overflow-y-scroll md:gap-y-4 relative">
        <div
          class=""
          v-for="(pair, index) in currentData"
          :key="pair.id"
          @dragover.prevent="handleDragOver($event, index)"
          @dragleave.prevent="handleDragLeave($event, index)"
          @drop.prevent="handleDrop($event, index)"
        >
          <div :ref="pairContainerRefs[index]">
            <CardPair
              :items="pair.pair_of_terms_items"
              :contentItemId="props.contentItem.id"
              :allowDrag="!allowEdit"
              :isEditing="isEditing"
              :isCorrect="pairwiseResults[index]"
              :showResults="showResults"
              :item1DraggedOver="currentlyDraggingOver[index] && currentlyDraggingItem1Id != null"
              :item2DraggedOver="currentlyDraggingOver[index] && currentlyDraggingItem2Id != null"
              @isLoading="isLoading = $event"
              @isDraggingItem2="
                (isDragging, item2Id) => {
                  handleItem2Dragging(isDragging, index, item2Id);
                }
              "
              @isDraggingItem1="
                (isDragging, item1Id) => {
                  handleItem1Dragging(isDragging, index, item1Id);
                }
              "
              @inputItem1="
                (newItem) => {
                  unsavedChanges = true;
                  pair.pair_of_terms_items[0] = newItem;
                }
              "
              @inputItem2="
                (newItem) => {
                  unsavedChanges = true;
                  pair.pair_of_terms_items[1] = newItem;
                }
              "
              @delete="
                () => {
                  unsavedChanges = true;
                  deletePair(index);
                }
              "
              @currentDraggingPosition="handleDraggingAtPosition"
              @droppedAtPosition="handleDroppedAtPosition"
              @scrollBy="(distance) => emit('scrollBy', distance)"
            />
          </div>
        </div>
      </div>

      <div class="w-fit items-center mx-auto">
        <div class="pt-1">
          <ProgressButton
            v-show="!allowEdit"
            @click="trySolve"
            :text="showResults ? 'Retry' : 'Check'"
            :disabled="isLoading || !fetchCompleted"
            :show-progress="isLoading"
          />
        </div>
        <div class="pt-1 mx-auto justify-center flex">
          <ProgressButton
            v-show="isEditing"
            :disabled="isSubmitting || !fetchCompleted"
            @click="
              () => {
                unsavedChanges = true;
                addPair();
              }
            "
            icon="add"
            iconSize="text-2xl"
            :text="'Add pair'"
          />
        </div>
        <!--        <div class="pt-1 mx-auto justify-center flex">-->
        <!--          <ProgressButton-->
        <!--            v-show="allowEdit"-->
        <!--            @click="savePairOfTermsGame"-->
        <!--            :showProgress="isSubmitting"-->
        <!--            :disabled="!unsavedChanges || isSubmitting || !fetchCompleted || unfilledData"-->
        <!--            :text="isEditing ? 'Save' : 'Edit'"-->
        <!--            icon="save"-->
        <!--            iconSize="text-2xl"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </div>
    <div
      class="absolute z-10 select-none top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      v-show="!fetchCompleted"
    >
      <LoadingSpinnerLarge />
    </div>
    <div
      v-if="
        fetchCompleted &&
        !props.allowEdit &&
        itemInteractionState?.completed_at != null &&
        itemInteractionState?.reopened_at == null
      "
      class="absolute z-10 cursor-pointer group select-none text-teal-500 text-2xl bg-teal-200/10 top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      @click="reopenExercise"
    >
      <span class="block group-hover:hidden">
        {{ t('message.exerciseAlreadyCompleted') }}
      </span>
      <span class="hidden group-hover:flex items-center hover:text-teal-600">
        {{ t('message.exercisePlayAgain') }}
        <span translate="no" class="material-symbols-outlined notranslate text-4xl pl-1">exercise</span>
      </span>
    </div>
  </div>
</template>

<style scoped>
@keyframes grandmaAppear {
  0% {
    height: 0;
  }
  30% {
    height: 110px; /* Slightly overshooting the target (100px + 10px) */
  }
  40% {
    height: 90px; /* Slightly undershooting the target (100px - 10px) */
  }
  50% {
    height: 105px; /* Smaller overshoot (100px + 5px) */
  }
  60% {
    height: 95px; /* Smaller undershoot (100px - 5px) */
  }
  70% {
    height: 102px; /* Even smaller overshoot (100px + 2px) */
  }
  80% {
    height: 98px; /* Even smaller undershoot (100px - 2px) */
  }
  90% {
    height: 101px; /* Tiny overshoot (100px + 1px) */
  }
  100% {
    height: 100px; /* Final target height */
  }
}

.grandma-appear {
  @apply overflow-hidden;
  animation: grandmaAppear 2s ease-out;
}
</style>
