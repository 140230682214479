import { defineStore } from 'pinia';
import {
  CaseInteraction,
  ExaminationReport,
  LabResult,
  MessageType,
  ObserverInteractionMessage,
  Patient,
  PatientInteractionMessage,
  InteractionMessageContent,
  Voice,
  VoiceProvider,
  VoiceModel,
  ClinicalNote,
} from '@/apiclient';

import { getApiClient } from '@/apiclient/client';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { Partial } from '@/helper';
import { getCurrentTask, useCaseInteractionStore } from './caseInteraction.store';
import { useAuthStore } from '@/stores/auth.store';

type LabResultWithTranslation = LabResult & {
  display_name: string;
};

interface patientInteractionStoreState {
  currentCaseInteraction: CaseInteraction | null;
  currentPatient: Patient | null;
  currentPatientInteractionId: string;
  currentObserverInteractionId: string;
  descMessages: ObserverInteractionMessage[];
  chatMessages: PatientInteractionMessage[];
  clinicalNotes: ClinicalNote[];
  labSheets: LabResult[][] | LabResultWithTranslation[][];
  examinationReports: ExaminationReport[];
  descIsStreaming: Boolean;
  chatIsStreaming: Boolean;
  noteIsStreaming: Boolean;
  labIsStreaming: Boolean;
  examinationIsStreaming: Boolean;
  defaultLabParameters: string[];
  labRemainingStreaming: number;
  requestedDescription: Boolean;
  initiatedPatientInteraction: Boolean;
}

export const usePatientInteractionStore = defineStore({
  id: 'patientInteraction',
  state: (): patientInteractionStoreState => ({
    // currentPatientInteraction: null,

    currentCaseInteraction: null as CaseInteraction | null,
    currentPatient: null as Patient | null,
    currentPatientInteractionId: '',
    currentObserverInteractionId: '',

    descMessages: [] as ObserverInteractionMessage[],
    chatMessages: [] as PatientInteractionMessage[],
    clinicalNotes: [] as ClinicalNote[],
    labSheets: [] as LabResult[][] | LabResultWithTranslation[][],
    examinationReports: [] as ExaminationReport[],

    descIsStreaming: false as Boolean,
    chatIsStreaming: false as Boolean,
    labIsStreaming: false as Boolean,
    noteIsStreaming: false as Boolean,
    examinationIsStreaming: false as Boolean,
    defaultLabParameters: [
      'hemoglobin',
      'leucocytes',
      'MCV',
      'MCH',
      'MCHC',
      'hematocrit',
      'thrombocytes',
      'CRP',
      'main electrolytes',
      'creatinin',
    ] as string[],
    labRemainingStreaming: 0 as number,
    requestedDescription: false as Boolean,
    initiatedPatientInteraction: false as Boolean,
  }),
  getters: {
    currentCaseInteractionId(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.id : '';
    },
    patientFirstName(state) {
      return state.currentPatient ? state.currentPatient.details.first_name : '';
    },
    patientLastName(state) {
      return state.currentPatient ? state.currentPatient.details.last_name : '';
    },
    patientName(state) {
      if (!state.currentPatient) {
        return '';
      }
      return state.currentPatient
        ? (state.currentPatient.details.academic_title ? +' ' : '') +
            state.currentPatient.details.first_name +
            ' ' +
            state.currentPatient.details.last_name
        : '';
    },
    patientAcademicTitle(state) {
      return state.currentPatient ? state.currentPatient.details.academic_title : null;
    },
    patientDob(state) {
      if (!state.currentPatient) {
        return '';
      }
      if (state.currentPatient.details.dob) {
        return state.currentPatient.details.dob;
      }
      const today = this.currentCaseInteraction.case.details
        ? new Date(this.currentCaseInteraction.case.details.date)
        : new Date('1989-10-08');
      const year = today.getFullYear() - this.patientAge;
      const month = Math.min(today.getMonth() - 1, 12);
      const day = Math.max(today.getDate() - 5, 1);
      return day + '.' + month + '.' + year;
    },
    patientAge(state) {
      return state.currentPatient ? state.currentPatient.details.age : 74;
    },
    patientHeight(state) {
      return state.currentPatient
        ? state.currentPatient.details.height
          ? state.currentPatient.details.height
          : null
        : null;
    },
    patientWeight(state) {
      return state.currentPatient
        ? state.currentPatient.details.weight
          ? state.currentPatient.details.weight
          : null
        : null;
    },
    patientVoice(state): Voice | Object {
      if (state.currentPatient && state.currentPatient.voice) {
        return {
          voice_id: state.currentPatient.voice.voice_id,
          voice_model: state.currentPatient.voice.voice_model,
          voice_provider: state.currentPatient.voice.voice_provider,
        } as Voice;
      } else {
        return {
          voice_id: '21m00Tcm4TlvDq8ikWAM',
          voice_model: 'eleven_multilingual_v2' as VoiceModel,
          voice_provider: 'elevenlabs' as VoiceProvider,
        } as Object;
      }
    },
    patientProfileImageSmall(state) {
      return state.currentPatient
        ? state.currentPatient.profile_image
          ? state.currentPatient.profile_image.image_urls.small
          : ''
        : '';
    },
    patientProfileImageMedium(state) {
      return state.currentPatient
        ? state.currentPatient.profile_image
          ? state.currentPatient.profile_image.image_urls.medium
          : ''
        : '';
    },
    patientProfileImageLarge(state) {
      return state.currentPatient
        ? state.currentPatient.profile_image
          ? state.currentPatient.profile_image.image_urls.large
          : ''
        : '';
    },
    patientInitials(state) {
      // TS problem: https://github.com/vuejs/pinia/discussions/1076
      // return initials based on userFirstName and userLastName
      // check if length of userFirstName and userLastName is > 0
      var firstInitial = '';
      var lastInitial = '';

      if (
        state.currentPatient &&
        state.currentPatient.details.first_name &&
        state.currentPatient.details.first_name.length > 0
      ) {
        firstInitial = state.currentPatient.details.first_name[0].toUpperCase();
      }
      if (
        state.currentPatient &&
        state.currentPatient.details.last_name &&
        state.currentPatient.details.last_name.length > 0
      ) {
        lastInitial = state.currentPatient.details.last_name[0].toUpperCase();
      }

      return firstInitial + lastInitial;
    },
    userNativeLanguage(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.user_language : '';
    },
    patientLanguage(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.patient_interaction.patient_language : '';
    },
    patientSexSymbol(state) {
      if (!state.currentPatient) {
        return '';
      }
      switch (state.currentPatient.details.sex) {
        case 'FEMALE':
          return '♀';
        case 'MALE':
          return '♂';
        case 'DIVERSE':
          return '⚧';
      }
    },
    someMessageToRedo(state) {
      if (state.chatMessages.length === 0) {
        return false;
      }
      // true if last message is undone and type is SAY, meaning that:
      // - if the last message is undone and then redone, this becomes false
      // - if the last message (or more messages) is undone but we then add a new message, this becomes false - in this case, old undone messages cannot be redone anymore. This is necessary for consistency.
      return (
        !!state.chatMessages[state.chatMessages.length - 1].undone_at &&
        state.chatMessages[state.chatMessages.length - 1].type === 'SAY'
      );
    },
    someMessageToUndo(state) {
      if (state.chatMessages.length === 0) {
        return false;
      }
      return (
        !state.chatMessages[state.chatMessages.length - 1].undone_at &&
        state.chatMessages[state.chatMessages.length - 1].type === 'SAY'
      );
    },
  },
  actions: {
    /**
     * Sets the current case interaction and fetches the patient from the API if necessary.
     * Using this setter it is ensured that case interaction, patient and patientInteractionId are consistent.
     */
    async _initiatePatientInteraction() {
      const caseInteractionStore = useCaseInteractionStore();
      const caseInteraction = caseInteractionStore.currentCaseInteraction;

      if (!caseInteraction) {
        console.warn('Null case interaction provided to setCaseInteraction');
        return;
      }

      // this is needed in any case, even if there is not patient at all:
      this.currentCaseInteraction = caseInteraction; // this
      this.currentObserverInteractionId = caseInteraction.observer_interaction.id; // and this also!

      this.currentPatientInteractionId = caseInteraction.patient_interaction.id;
      console.debug('Patient interaction is now: ' + this.currentPatientInteractionId);

      // console.debug('Case is: ' + JSON.stringify(caseInteraction.case));
      // check if patient is already set and the same as in the case interaction
      // otherwise, fetch patient from API

      console.log('Patient: ' + JSON.stringify(caseInteraction.case.patient));
      if (caseInteraction.case.patient == null) {
        console.log('No patient in case interaction');
        return;
      }

      this.currentPatient = caseInteraction.case.patient;

      console.debug(
        'Set case interaction ' +
          this.currentCaseInteraction.id +
          ' and patient interaction ' +
          this.currentPatientInteractionId +
          ' for patient ' +
          this.currentPatient.id,
      );
    },
    async createPreliminaryChatMessage(userMessage: string = '', type: string = 'SAY') {
      this.chatIsStreaming = true; // will be reset by appendToLastChatMessageAndRefetchOnceFinished
      console.log('Creating preliminary chat message for patient interaction ' + this.currentPatientInteractionId);

      const content: InteractionMessageContent = {
        processed_model_output: '',
        user_message: userMessage,
        user_message_language: null,
        timestamp: null,
      };
      const message: PatientInteractionMessage = {
        id: 'not_yet_in_database',
        patient_interaction_id: this.currentPatientInteractionId,
        content: content,
        type: type as MessageType,
        translations: null,
        created_at: 'incomplete',
      };
      console.debug(this);
      this.chatMessages.push(message);
      console.log('Chat messages after creation of preliminary: ' + this.chatMessages);
      console.log('Message length now after creation of preliminary: ' + this.chatMessages.length);
    },
    async createPreliminaryClinicalNote(userMessage: string = '', type: string = 'NOTE') {
      this.noteIsStreaming = true; // will be reset by appendToLastClinicalNoteAndRefetchOnceFinished
      console.log('Creating clincal note for patient interaction ' + this.currentPatientInteractionId);

      const message: ClinicalNote = {
        id: 'not_yet_in_database',
        patient_interaction_id: this.currentPatientInteractionId,
        content: '',
        translations: null,
        created_at: 'incomplete',
      };
      console.debug(this);
      this.clinicalNotes.push(message);
      console.log('Clinical notes after creation of preliminary: ' + this.clinicalNotes);
      console.log('Notes length now after creation of preliminary: ' + this.clinicalNotes.length);
    },
    async createPreliminaryDescMessage(userMessage: string = '', type: string = 'DESCRIPTION') {
      this.descIsStreaming = true;
      console.log('Creating preliminary desc message for patient interaction ' + this.currentPatientInteractionId);

      const content: InteractionMessageContent = {
        processed_model_output: '',
        user_message: userMessage,
        user_message_language: null,
        timestamp: null,
      };
      const message: ObserverInteractionMessage = {
        id: 'not_yet_in_database',
        observer_interaction_id: this.currentObserverInteractionId,
        content: content,
        type: type as MessageType,
        translations: null,
        created_at: 'incomplete',
      };
      console.debug(this);
      this.descMessages.push(message);
      console.log('Desc messages after creation of preliminary: ' + this.descMessages);
      console.log('Message length now after creation of preliminary: ' + this.descMessages.length);
    },
    async appendToChatMessage(idx: number, chunk: string) {
      let chunk_ = JSON.parse(chunk);
      try {
        if (chunk_.type === 'message') {
          this.chatMessages[idx].content['processed_model_output'] += chunk_['content'];
        } else if (chunk_.type === 'id') {
          this.chatMessages[idx].id = chunk_['content'];
          console.debug('Finished chat message with id ' + chunk_['content']);
        }
      } catch (e) {
        const caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('Case interaction set to null while streaming. Ok if left interaction.');
          return;
        }
        throw e;
      }
    },
    async appendToClinicalNote(idx: number, chunk: string) {
      let chunk_ = JSON.parse(chunk);
      try {
        if (chunk_.type === 'message') {
          this.chatMessages[idx].content['processed_model_output'] += chunk_['content'];
        } else if (chunk_.type === 'clinical_note_id') {
          this.clinicalNotes[idx].id = chunk_['content'];
          console.debug('Finished clinical note with id ' + chunk_['content']);
        }
      } catch (e) {
        const caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('Case interaction set to null while streaming. Ok if left interaction.');
        }
        throw e;
      }
    },
    async appendToDescMessage(idx: number, chunk: string) {
      let chunk_ = JSON.parse(chunk);
      try {
        if (chunk_.type === 'message') {
          this.descMessages[idx].content['processed_model_output'] += chunk_['content'];
        } else if (chunk_.type === 'id') {
          this.descMessages[idx].id = chunk_['content'];
          console.debug('Finished desc message with id ' + chunk_['content']);
        }
      } catch (e) {
        const caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('Case interaction set to null while streaming. Ok if left interaction.');
        }
        throw e;
      }
    },
    async appendToLastChatMessageAndRefetchOnceFinished(chunk: string) {
      const idx = this.chatMessages.length - 1;
      await this.appendToChatMessage(idx, chunk);
      if (this.chatMessages.length && this.chatMessages[idx].id !== 'not_yet_in_database') {
        // stream finished. Note: if this.chatMessages is empty, store has been reset while streaming
        this.chatIsStreaming = false;
        await this.fetchAndReplaceChatMessage(idx);
      }
    },
    async appendToLastClinicalNoteAndRefetchOnceFinished(chunk: string) {
      const idx = this.clinicalNotes.length - 1;
      await this.appendToClinicalNote(idx, chunk);
      if (!!this.clinicalNotes && this.clinicalNotes[idx].id !== 'not_yet_in_database') {
        // stream finished
        this.noteIsStreaming = false;
        await this.fetchAndReplaceClinicalNote(idx);
      }
    },
    async appendToLastDescMessageAndRefetchOnceFinished(chunk: string) {
      const idx = this.descMessages.length - 1;
      await this.appendToDescMessage(idx, chunk);
      if (this.descMessages && this.descMessages[idx].id !== 'not_yet_in_database') {
        // stream finished
        this.descIsStreaming = false;
        await this.fetchAndReplaceDescMessage(idx);
      }
    },
    async fetchAndReplaceChatMessage(idx: number) {
      const id = this.chatMessages[idx].id;
      console.debug(
        'Fetching complete message for incomplete chat message ' + id + ' of type ' + this.chatMessages[idx].type,
      );
      let completeMessage = await (await getApiClient()).patientMessages.getPatientInteractionMessage(id);
      this.chatMessages[idx] = completeMessage;
      console.debug('Finished fetching complete chat message for incomplete message ' + id);
    },
    async fetchAndReplaceClinicalNote(idx: number) {
      const id = this.clinicalNotes[idx].id;
      console.debug('Fetching complete message for incomplete clinical note ' + id);
      let completeMessage = await (await getApiClient()).clinicalNotes.getClinicalNote(id);
      this.clinicalNotes[idx] = completeMessage;
      console.debug('Finished fetching complete clinical note for incomplete message ' + id);
    },
    async fetchAndReplaceDescMessage(idx: number) {
      const id = this.descMessages[idx].id;
      console.debug(
        'Fetching complete message for incomplete desc message ' + id + ' of type ' + this.descMessages[idx].type,
      );
      let completeMessage = await (await getApiClient()).observerMessages.getObserverInteractionMessage(id);
      this.descMessages[idx] = completeMessage;
      console.debug('Finished fetching complete message for incomplete desc message ' + id);
    },
    _appendToIndexedChatMessageTranslation(
      index: number,
      target_language: string,
      translation_option: string,
      chunk: string,
    ) {
      let chunk_ = JSON.parse(chunk);
      if (chunk_.type === 'message') {
        this.chatMessages[index].translations[target_language][translation_option] += chunk_['content'];
      } else if (chunk_.type === 'id') {
        console.debug('Finished translation of chat message with id ' + chunk_['content']);
      }
    },
    _appendToIndexedClinicalNoteTranslation(
      index: number,
      target_language: string,
      translation_option: string,
      chunk: string,
    ) {
      let chunk_ = JSON.parse(chunk);
      if (chunk_.type === 'message') {
        this.clinicalNotes[index].translations[target_language][translation_option] += chunk_['content'];
      } else if (chunk_.type === 'id') {
        console.debug('Finished translation of clinical note with id ' + chunk_['content']);
      }
      // console.debug(this.chatMessages[index].translations[target_language][translation_option])
    },
    _appendToIndexedDescMessageTranslation(
      index: number,
      target_language: string,
      translation_option: string,
      chunk: string,
    ) {
      let chunk_ = JSON.parse(chunk);
      if (chunk_.type === 'message') {
        this.descMessages[index].translations[target_language][translation_option] += chunk_['content'];
      } else if (chunk_.type === 'id') {
        console.debug('Finished translation of desc message with id ' + chunk_['content']);
      }
      // console.debug(this.chatMessages[index].translations[target_language][translation_option])
    },
    appendToIndexedChatMessageTranslation: function (
      this: patientInteractionStoreState,
      index: number,
      target_language: string,
      translation_option: string,
      chunk?: string,
    ) {
      if (chunk !== undefined) {
        // Full call
        return this._appendToIndexedChatMessageTranslation(index, target_language, translation_option, chunk);
      } else {
        // Partial application
        const partialFn: (chunk: string) => Promise<void> = async (chunk: string): Promise<void> => {
          return this._appendToIndexedChatMessageTranslation(index, target_language, translation_option, chunk);
        };
        return partialFn;
        // NOTE: if function is async, need to be returned as object, i.e. return {partialFn}, then unpacked
        // as const {callback} = this.appendToIndexedChatMessageTranslation(index, target_language, translation_option)
      }
    } as Partial,
    appendToIndexedClinicalNoteTranslation: function (
      this: patientInteractionStoreState,
      index: number,
      target_language: string,
      translation_option: string,
      chunk?: string,
    ) {
      if (chunk !== undefined) {
        // Full call
        return this._appendToIndexedClinicalNoteTranslation(index, target_language, translation_option, chunk);
      } else {
        // Partial application
        const partialFn: (chunk: string) => Promise<void> = async (chunk: string): Promise<void> => {
          return this._appendToIndexedClinicalNoteTranslation(index, target_language, translation_option, chunk);
        };
        return partialFn;
        // NOTE: if function is async, need to be returned as object, i.e. return {partialFn}, then unpacked
        // as const {callback} = this.appendToIndexedChatMessageTranslation(index, target_language, translation_option)
      }
    } as Partial,
    appendToIndexedDescMessageTranslation: function (
      this: patientInteractionStoreState,
      index: number,
      target_language: string,
      translation_option: string,
      chunk?: string,
    ) {
      if (chunk !== undefined) {
        return this._appendToIndexedDescMessageTranslation(index, target_language, translation_option, chunk);
      } else {
        const partialFn: (chunk: string) => Promise<void> = async (chunk: string): Promise<void> => {
          return this._appendToIndexedDescMessageTranslation(index, target_language, translation_option, chunk);
        };
        return partialFn;
      }
    } as Partial,
    async translateChatMessage(message: PatientInteractionMessage, language: string, option: string) {
      // TODO: fix bug -- after reloading case (URL + enter), translations are loaded double (every types and time?)
      console.debug('Getting translation of ' + message.content + ' to ' + language);
      if (
        message.translations &&
        language in message.translations &&
        option in message.translations[language]
        // message.translations?.language?.option  -- TODO why does this not work and [] is needed?!
      ) {
        console.debug('Nothing to do here');
        return; // message.translations.language.option;
      }
      let index = this.chatMessages.indexOf(message);
      if (this.chatMessages[index].translations === null) {
        this.chatMessages[index].translations = {};
      }
      if (this.chatMessages[index].translations[language] === undefined) {
        this.chatMessages[index].translations[language] = {};
      }
      this.chatMessages[index].translations[language][option] = '';
      let origMessage = this.chatMessages[index];
      let url_base = '/patient-messages/';
      let url =
        url_base +
        origMessage.id +
        '/translation?' +
        new URLSearchParams({
          target_language: language,
          translation_option: option,
        }).toString();
      console.debug('URL is: ' + url);
      await (
        await getStreamingClient()
      ).streamFetchRequest('PATCH', url, null, this.appendToIndexedChatMessageTranslation(index, language, option));
    },
    async translateClinicalNote(message: PatientInteractionMessage, language: string, option: string) {
      // TODO: fix bug -- after reloading case (URL + enter), translations are loaded double (every types and time?)
      console.debug('Getting translation of ' + message.content + ' to ' + language);
      if (
        message.translations &&
        language in message.translations &&
        option in message.translations[language]
        // message.translations?.language?.option  -- TODO why does this not work and [] is needed?!
      ) {
        console.debug('Nothing to do here');
        return; // message.translations.language.option;
      }
      let index = this.clinicalNotes.indexOf(message);
      if (this.clinicalNotes[index].translations === null) {
        this.clinicalNotes[index].translations = {};
      }
      if (this.clinicalNotes[index].translations[language] === undefined) {
        this.clinicalNotes[index].translations[language] = {};
      }
      this.clinicalNotes[index].translations[language][option] = '';
      let origMessage = this.clinicalNotes[index];
      let url_base = '/patient-messages/';
      let url =
        url_base +
        origMessage.id +
        '/translation?' +
        new URLSearchParams({
          target_language: language,
          translation_option: option,
        }).toString();
      console.debug('URL is: ' + url);
      await (
        await getStreamingClient()
      ).streamFetchRequest('PATCH', url, null, this.appendToIndexedClinicalNoteTranslation(index, language, option));
    },
    async translateDescMessage(message: ObserverInteractionMessage, language: string, option: string) {
      console.debug('Getting translation of ' + message.content + ' to ' + language);
      if (
        message.translations &&
        language in message.translations &&
        option in message.translations[language]
        // message.translations?.language?.option  -- TODO why does this not work and [] is needed?!
      ) {
        console.debug('Nothing to do here');
        return; // message.translations.language.option;
      }
      let index = this.descMessages.indexOf(message);
      if (this.descMessages[index].translations === null) {
        this.descMessages[index].translations = {};
      }
      if (this.descMessages[index].translations[language] === undefined) {
        this.descMessages[index].translations[language] = {};
      }
      this.descMessages[index].translations[language][option] = '';
      let origMessage = this.descMessages[index];
      let url_base = '/observer-messages/';
      let url =
        url_base +
        origMessage.id +
        '/translation?' +
        new URLSearchParams({
          target_language: language,
          translation_option: option,
        }).toString();
      console.debug('URL is: ' + url);
      await (
        await getStreamingClient()
      ).streamFetchRequest('PATCH', url, null, this.appendToIndexedDescMessageTranslation(index, language, option));
    },
    async createPreliminaryExamReport(user_message: string) {
      this.examinationIsStreaming = true; // will be reset by appendToLastChatMessageAndRefetchOnceFinished
      console.debug('Creating preliminary chat message for patient interaction ' + this.currentPatientInteractionId);

      const report: ExaminationReport = {
        id: 'not_yet_in_database',
        ingame_time: '',
        patient_interaction_id: this.currentPatientInteractionId,
        name: user_message,
        report: '... loading ...',
        language: null,
        translations: null,
        created_at: 'incomplete',
        type: 'EXAMINATION',
      };
      this.examinationReports.push(report);
    },
    async appendToExamReport(idx: number, chunk: string) {
      let chunk_ = JSON.parse(chunk);
      try {
        if (chunk_.type === 'message') {
          this.examinationReports[idx].report += chunk_['content'];
        } else if (chunk_.type === 'examination_report_id') {
          this.examinationReports[idx].id = chunk_['content'];
          console.debug('Finished message with id ' + chunk_['content']);
        }
      } catch (e) {
        const caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('Case interaction set to null while streaming. Ok if left interaction.');
        }
        throw e;
      }
    },
    async appendToLastExamReportAndRefetchOnceFinished(chunk: string) {
      const idx = this.examinationReports.length - 1;
      await this.appendToExamReport(idx, chunk);
      if (this.examinationReports && this.examinationReports[idx].id !== 'not_yet_in_database') {
        // finished
        await this.fetchAndReplaceExamReport(idx);
        this.examinationIsStreaming = false;
      }
    },
    async fetchAndReplaceExamReport(idx: number) {
      const id = this.examinationReports[idx].id;
      console.debug('Fetching complete report for incomplete report ' + id);
      const completeReport = await (await getApiClient()).examinations.getPatientInteractionExamination(id);
      this.examinationReports[idx] = completeReport;
    },
    _appendToIndexedExaminationReportTranslation(index: number, target_language: string, chunk: string) {
      let chunk_ = JSON.parse(chunk);
      try {
        if (chunk_.type === 'message') {
          this.examinationReports[index].translations[target_language]['report'] += chunk_['content'];
        } else if (chunk_.type === 'id') {
          console.debug('Finished translation of message with id ' + chunk_['content']);
        }
      } catch (e) {
        const caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('Case interaction set to null while streaming. Ok if left interaction.');
        }
        throw e;
      }
      // console.debug(this.chatMessages[index].translations[target_language][translation_option])
    },
    appendToIndexedExaminationReportTranslation: function (
      this: patientInteractionStoreState,
      index: number,
      target_language: string,
      chunk?: string,
    ) {
      if (chunk !== undefined) {
        return this._appendToIndexedExaminationReportTranslation(index, target_language, chunk);
      } else {
        const partialFn: (chunk: string) => Promise<void> = async (chunk: string): Promise<void> => {
          return this._appendToIndexedExaminationReportTranslation(index, target_language, chunk);
        };
        return partialFn;
      }
    } as Partial,
    async translateExamination(examinationReport: ExaminationReport, language: string) {
      // TODO: fix bug -- after reloading case (URL + enter), translations are loaded double (every types and time?)
      console.debug('Getting translation of ' + examinationReport.name + ' to ' + language);
      if (
        examinationReport.translations &&
        language in examinationReport.translations
        // message.translations?.language?.option  -- TODO why does this not work and [] is needed?!
      ) {
        console.debug('Nothing to do here');
        return;
      }

      let index = this.examinationReports.indexOf(examinationReport);
      if (this.examinationReports[index].translations === null) {
        this.examinationReports[index].translations = {};
      }
      if (this.examinationReports[index].translations[language] === undefined) {
        this.examinationReports[index].translations[language] = {
          name: '',
          report: '',
        };
      }
      let origReport = this.examinationReports[index];
      let url =
        '/examinations/translation/' +
        origReport.id +
        '?' +
        new URLSearchParams({
          target_language: language,
        }).toString();
      console.debug('URL is: ' + url);

      await (
        await getStreamingClient()
      ).streamFetchRequest('PATCH', url, null, this.appendToIndexedExaminationReportTranslation(index, language));
    },
    async appendResultToLastLabSheetIfNew(chunk: string) {
      const parsedChunk = JSON.parse(chunk);

      // get current lab sheet if it exists otherwise create a new one
      let currentLabSheet: LabResult[] | LabResultWithTranslation[];
      if (this.labSheets.length === 0) {
        // create new lab sheet, this happens if no labs has been requested yet
        currentLabSheet = [];
      } else {
        currentLabSheet = this.labSheets[this.labSheets.length - 1];
      }
      const sheetIdx = this.labSheets.length - 1;

      if (parsedChunk.type === 'lab_result_id') {
        let id = parsedChunk['content'];
        let labResult = await (await getApiClient()).labSheets.getPatientInteractionLabResult(id);
        let labResultWithTranslation = labResult as LabResultWithTranslation;
        labResultWithTranslation.display_name = labResultWithTranslation.parameter;

        // append to the end of the current lab sheet
        currentLabSheet.push(labResultWithTranslation);

        // set the updated lab sheet via splice to trigger Vue state update
        this.labSheets.splice(sheetIdx, 1, currentLabSheet);
        this.labRemainingStreaming -= 1;

        // this.labIsStreaming = false;   // NOTE: this means that only the first
        // complete chunk = lab result is awaited. User can request more lab results
        // whilst still streaming. Should be ok? TODO
        // Alternatively, one could check for ID of whole patient interaction to be sent
        // == meaning that whole PIM ended (chunk_.type === 'id')
      }
      if (parsedChunk.type === 'id') {
        // all lab results received, end of streaming
        this.labIsStreaming = false;
        this.labRemainingStreaming = 0;
      }
    },
    async translateLabSheet(labSheet: LabResult[] | LabResultWithTranslation[], language: string) {
      console.debug('Translate lab sheet: ' + labSheet);
      const origLabSheet = labSheet;
      console.debug('Translate n=' + labSheet.length + ' parameter names to ' + language);
      for (let j = 0; j < labSheet.length; j++) {
        labSheet[j] = await this.translateLabResult(labSheet[j], language);
        console.debug('Translation: ' + labSheet[j].display_name);
      }
      this.replaceLabSheet(origLabSheet, labSheet);
      console.debug('Translation of first param of labSheet = func input: ' + labSheet[0].display_name);
      console.debug(
        'Translation for first param of first sheet in this.labSheets: ' + this.labSheets[0][0].display_name,
      );
    },
    async translateLabResult(labResult: LabResult | LabResultWithTranslation, language: string) {
      if (labResult.translations && language in labResult.translations) {
        labResult.display_name = labResult.translations[language].parameter;
      }
      labResult = await (await getApiClient()).labSheets.getPatientInteractionLabResult(labResult.id, language);
      labResult.display_name = labResult.translations[language].parameter;
      return labResult;
    },
    replaceLabSheet(
      origSheet: LabResult[] | LabResultWithTranslation[],
      replace: LabResult[] | LabResultWithTranslation[],
    ) {
      const index = this.labSheets.indexOf(origSheet);
      if (index !== -1) {
        this.labSheets[index] = replace;
        return;
      }
    },
    async fetchHistory(): Promise<boolean> {
      console.debug('Fetching history for patient interaction ' + this.currentPatientInteractionId);
      const client = await getApiClient();
      let [chatMessages, labSheets, examinationReports, clinicalNotes] = await Promise.all([
        client.patientInteractions.listPatientInteractionMessages(this.currentPatientInteractionId),
        client.labSheets.getPatientInteractionLabSheets(this.currentPatientInteractionId),
        client.examinations.getPatientInteractionExaminations(this.currentPatientInteractionId),
        client.clinicalNotes.listPatientInteractionClinicalNotes(this.currentPatientInteractionId),
      ]);
      chatMessages = [...chatMessages].filter((message) => message.type === 'SAY' || message.type === 'REACT');
      let descMessages = await client.observerInteractions.listObserverInteractionMessages(
        this.currentObserverInteractionId,
      );
      descMessages = [...descMessages].filter((message) => message.type === 'DESCRIPTION');

      console.debug('Retrieved ' + chatMessages.length + ' chat messages');
      console.debug('Retrieved ' + labSheets.length + ' lab sheets');
      console.debug('Retrieved ' + examinationReports.length + ' examination reports');
      console.debug('Retrieved ' + descMessages.length + ' desc messages');

      // load history if length > 0
      // if any message has been loaded, we return true to indicate that history has been loaded
      let historyLoaded = false;
      if (chatMessages.length > 0) {
        this.chatMessages.length = 0;
        for (let i = 0; i < chatMessages.length; i++) {
          this.chatMessages.push(chatMessages[i]);
        }
        historyLoaded = true;
      }
      if (descMessages.length > 0) {
        this.descMessages.length = 0;
        for (let i = 0; i < descMessages.length; i++) {
          this.descMessages.push(descMessages[i]);
        }
        historyLoaded = true;
      }
      if (labSheets.length > 0) {
        // set display name to default language
        for (let i = 0; i < labSheets.length; i++) {
          for (let j = 0; j < labSheets[i].length; j++) {
            // console.debug('Setting display name for lab result ' + i + ' ' + j);
            labSheets[i][j] = labSheets[i][j];
            labSheets[i][j].display_name = labSheets[i][j].parameter;
          }
        }
        this.labSheets.length = 0;
        for (let i = 0; i < labSheets.length; i++) {
          this.labSheets.push(labSheets[i]);
        }
        historyLoaded = true;
      }
      if (examinationReports.length > 0) {
        this.examinationReports.length = 0;
        for (let i = 0; i < examinationReports.length; i++) {
          this.examinationReports.push(examinationReports[i]);
        }
        historyLoaded = true;
      }
      if (clinicalNotes.length > 0) {
        this.clinicalNotes.length = 0;
        for (let i = 0; i < clinicalNotes.length; i++) {
          this.clinicalNotes.push(clinicalNotes[i]);
        }
        historyLoaded = true;
      }

      return historyLoaded;
    },
    async say(message: string) {
      await this.createPreliminaryChatMessage(message, 'SAY');
      let authStore = useAuthStore();
      const url = '/patient-interactions/' + this.currentPatientInteractionId;
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          type: 'SAY',
          content: message,
          current_task: getCurrentTask(),
          language_level: authStore.currentLanguageLevel,
        },
        this.appendToLastChatMessageAndRefetchOnceFinished,
      );
    },
    async noteDown(message: string) {
      await this.createPreliminaryClinicalNote(message, 'NOTE');
      let authStore = useAuthStore();
      const url = '/patient-interactions/' + this.currentPatientInteractionId;
      // just send, nothing to stream back
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          type: 'NOTE',
          content: message,
          current_task: getCurrentTask(),
          language_level: authStore.currentLanguageLevel,
        },
        this.appendToLastClinicalNoteAndRefetchOnceFinished,
      );
    },
    async editNoteById(noteId: string, noteContent: string) {
      await (
        await getApiClient()
      ).clinicalNotes.editClinicalNote(noteId, {
        content: noteContent,
      });
    },
    // async getPatientCaseDescription() {
    //   if (this.requestedDescription) {
    //     console.debug('Description already requested, skipping');
    //     return;
    //   }
    //   await this.createPreliminaryDescMessage('', 'DESCRIPTION');
    //   this.requestedDescription = true;
    //   console.debug('Length of desc messages between calls is : ' + this.descMessages.length);
    //   console.debug('Observer interaction ID is: ' + this.currentObserverInteractionId);
    //   await (
    //     await getStreamingClient()
    //   ).streamFetchRequest(
    //     'POST',
    //     '/observer-interactions/' + this.currentObserverInteractionId,
    //     {
    //       content: '',
    //       type: 'DESCRIPTION',
    //       event: {
    //         type: 'INTERACTION_START',
    //       },
    //     },
    //     this.appendToLastDescMessageAndRefetchOnceFinished,
    //   );
    // },
    async getLabParameters(parameters: string[] = [], exactNumber: boolean = false) {
      if (!parameters.length) {
        console.error('examination: ignore empty lab parameters');
        return;
      }

      console.debug('Getting ' + parameters.length + ' lab parameters: ' + parameters);
      this.labIsStreaming = true;
      if (exactNumber) {
        // we know the exact number of lab results to expect
        this.labRemainingStreaming = parameters.length;
      } else {
        // we don't know the exact number of lab results to expect, just show one loading indicator
        // this should be used for untrusted user input
        this.labRemainingStreaming = -1;
      }
      const url = '/patient-interactions/' + this.currentPatientInteractionId;
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          type: 'LAB',
          content: parameters.join(', '),
          current_task: getCurrentTask(),
        },
        this.appendResultToLastLabSheetIfNew,
      );
    },
    async doExamination(examination: string = '') {
      if (examination === '') {
        console.error('examination: ignore empty examination');
        return;
      }
      await this.createPreliminaryExamReport(examination);
      let authStore = useAuthStore();
      const url = '/patient-interactions/' + this.currentPatientInteractionId;
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          type: 'EXAMINATION',
          content: examination,
          current_task: getCurrentTask(),
          language_level: authStore.currentLanguageLevel,
        },
        this.appendToLastExamReportAndRefetchOnceFinished,
      );
    },
    async storeUserEditedChatMessage(message: PatientInteractionMessage, userEdit: string) {
      console.debug('Storing user edit for message ' + message.id);
      console.debug('Edited content: ' + userEdit);
      // upload to DB as "user_edited_output"
      await (
        await getApiClient()
      ).patientMessages.storeUserEditForPatientInteractionMessage(message.id, {
        content: userEdit,
      });
    },
    async undoSay() {
      // Find the last non-undone SAY message by iterating backwards
      for (let i = this.chatMessages.length - 1; i >= 0; i--) {
        const message = this.chatMessages[i];
        if (message.type === 'SAY' && !message.undone_at) {
          let updatedMessage = await (await getApiClient()).patientMessages.undoPatientInteractionMessage(message.id);
          this.chatMessages[i] = updatedMessage;
          return true;
        }
      }
      console.log('No non-undone SAY messages found to undo');
      return false;
    },
    async redoSay() {
      // Find the first undone SAY message by iterating forwards
      for (let i = 0; i < this.chatMessages.length; i++) {
        const message = this.chatMessages[i];
        if (message.type === 'SAY' && message.undone_at) {
          // send undo with revert=true to revert the undone state
          let updatedMessage = await (
            await getApiClient()
          ).patientMessages.undoPatientInteractionMessage(message.id, true);
          this.chatMessages[i] = updatedMessage;
          console.log('redoSay: updatedMessage', updatedMessage);
          return true;
        }
      }
      return false;
    },
    /**
     * Initializes the patient interaction by getting the description message and standard lab,
     * adding a new empty lab sheet, and getting the lab parameters for hemoglobin, leucocytes, MCV,
     * MCH, MCHC, hematocrit, thrombocytes, CRP, main electrolytes, and creatinin.
     */
    // async initPatientInteraction() {
    //   // this only makes sense if patient interaction is already set
    //   if (!this.currentCaseInteraction) {
    //     console.error('Cannot initialize patient interaction if case interaction not set.');
    //     return;
    //   }
    //   if (this.initiatedPatientInteraction) {
    //     console.debug('Patient interaction already initiated, skipping');
    //     return;
    //   }
    //
    //   this.initiatedPatientInteraction = true;
    //   // get description message and standard lab in parallel
    //   await Promise.all([this.getPatientCaseDescription(), this.getLabParameters(this.defaultLabParameters, true)]);
    // },

    async reset() {
      // TODO replace by proper store reset function
      this.requestedDescription = false;
      this.initiatedPatientInteraction = false;

      this.currentPatientInteractionId = '';
      this.currentObserverInteractionId = '';
      this.currentCaseInteraction = null;
      this.currentPatient = null;

      this.chatIsStreaming = false;
      this.descIsStreaming = false;
      this.noteIsStreaming = false;
      this.labIsStreaming = false;
      this.examinationIsStreaming = false;

      this.descMessages.length = 0;
      this.chatMessages.length = 0;
      this.labSheets.length = 0;
      this.labSheets = [] as LabResult[][] | LabResultWithTranslation[][]; // TODO: ok or breaks reactivity?!
      // TODO: when ending one case wihl lab is streaming and loading another case, lab values get appended there => FIX THIS
      this.examinationReports.length = 0;
    },
  },
});
