<script setup lang="ts">
// Add SoundMeter type definition first
interface SoundMeter {
  context: AudioContext;
  instant: number;
  slow: number;
  clip: number;
  script: ScriptProcessorNode;
  mic?: MediaStreamAudioSourceNode;
  connectToSource: (stream: MediaStream, callback?: (error: Error | null) => void) => void;
  stop: () => void;
}

declare global {
  interface Window {
    AudioContext: typeof AudioContext;
    webkitAudioContext: typeof AudioContext;
    audioContext?: AudioContext;
    soundMeter?: SoundMeter;
    isRecording?: boolean;
    lastTranscriptionAttempt?: number;
    lastTranscriptionText?: string;
    refusedTranscriptionText?: string;
  }
}

import { getApiClient } from '@/apiclient/client';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { storeToRefs } from 'pinia';

import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useAlertStore } from '@/stores';
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';

import { SoundMeter } from '/src/audio/soundmeter.js';
import { useAudioStore } from '@/stores/audio.store';

const AUDIO_TYPE = 'audio';

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  reset: {
    default: false,
    type: Boolean,
  },
  size: {
    type: String,
    default: 'normal',
    validator: (value: string) => ['small', 'normal'].includes(value),
  },
  testIdSuffix: {
    type: String,
    default: '',
  },
  // unlockAudioContext: {
  //   type: Function,
  //   default: null,
  // },
});

const mockProgress = ref(0);

const emit = defineEmits<{
  newTranscription: [text: string];
  transcriptionInProgress: [inProgress: boolean];
  resetComplete: [];
}>();

const caseInteractionStore = useCaseInteractionStore();
const alertStore = useAlertStore();
const { currentCaseInteractionLanguage } = storeToRefs(caseInteractionStore);

const volume = ref(0);

const containerClasses = computed(() => ({
  'h-32 w-32 lg:h-44 lg:w-44': props.size === 'normal',
  'h-8 w-8': props.size === 'small',
}));

function assert(condition: any, message: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

function startMockProgress(timeInMs: number) {
  // linearly increment mockProgress from 0 to 100 over timeInMs milliseconds
  mockProgress.value = 0;
  const interval = setInterval(() => {
    mockProgress.value += 1;
    if (mockProgress.value >= 100) {
      clearInterval(interval);
      mockProgress.value = 0;
    }
  }, timeInMs / 100);
}

const buttonClasses = computed(() => ({
  'h-24 w-24 lg:h-32 lg:w-32': props.size === 'normal',
  'h-8 w-8': props.size === 'small',
  'bg-gray-500 cursor-not-allowed focus:z-10 group-focus:outline-none shadow-none shadow-gray-400': props.disabled,
  'bg-blue-300 cursor-pointer group-hover:bg-blue-300 shadow-none shadow-gray-400':
    !props.disabled && transcribedText.value,
  'bg-blue-600 cursor-pointer group-hover:bg-blue-700 shadow-none shadow-gray-400':
    !props.disabled && !transcribedText.value && !isRecording.value,
  'bg-red-500 cursor-pointer group-hover:bg-red-600 shadow-inner shadow-none shadow-red-400': isRecording.value,
  'shadow-none': props.mockProgress > 20,
}));

const iconClasses = computed(() => ({
  'text-8xl md:text-7xl lg:text-8xl lg:-mt-2': props.size === 'normal',
  'text-3xl': props.size === 'small',
}));

const recorder = ref(null);
const stream = ref(null);
const isRecording = ref(false);
const isStopped = ref(true);
const isPaused = ref(false);
const transcribedText = ref('');
const pttStartTime = ref(null);
const pttStopTime = ref(null);
const minPttDuration = 500; // in ms

const audioStore = useAudioStore();

onMounted(async () => {
  try {
    await audioStore.requestMicrophoneAccess();
  } catch (e) {
    throw e;
    // Error already handled in store
  }
});

const isDev = !!import.meta.env.DEV;

watch(isRecording, (newVal, oldVal) => {
  if (isDev) {
    console.log(`isRecording changed from ${oldVal} to ${newVal}`);
    window.isRecording = newVal;
  }
});

async function startRecording() {
  if (props.disabled || isRecording.value) {
    console.log('Early return - disabled:', props.disabled, 'isRecording:', isRecording.value);
    return;
  }
  console.log('START');
  startMockProgress(300);

  // if (props.unlockAudioContext) {
  //   console.log('Unlocking audio context from AudioRecorderSTT...');
  //   await props.unlockAudioContext();
  // }

  try {
    transcribedText.value = '';
    pttStartTime.value = new Date();
    isStopped.value = false;

    // Initialize audio context through store if needed
    try {
      audioStore.initAudioContext();
      console.log('Audio context state:', window.audioContext?.state);

      if (window.audioContext?.state === 'suspended') {
        await window.audioContext.resume();
        console.log('Audio context resumed');
      }
    } catch (e) {
      isRecording.value = false;
      isStopped.value = true;
      console.error('Error preparing audio context:', e);
      alertStore.error('Error preparing audio system.', 'Error', e as Error);
      throw e;
    }

    // Get microphone access
    stream.value = await audioStore.requestMicrophoneAccess();
    console.log('Got microphone access, tracks:', stream.value.getTracks().length);

    // Create recorder with the initialized stream
    console.log('Creating recorder...');
    try {
      recorder.value = new RecordRTCPromisesHandler(stream.value, {
        type: AUDIO_TYPE,
        mimeType: 'audio/webm',
        timeSlice: 1000,
        ondataavailable: (blob) => {
          console.log('Data available:', blob.size, 'bytes');
        },
      });
      console.log('Recorder created successfully');
    } catch (e) {
      console.error('Error when creating recorder.', e);
      alertStore.error('Error when creating recorder.', 'Error', e as Error);
      isRecording.value = false;
      isStopped.value = true;
      throw e;
    }

    // Start recording
    console.log('Starting recording...');
    try {
      await recorder.value.startRecording();
      console.log('Recording started successfully');
      isRecording.value = true;
      if (isDev) console.log('isRecording set to true');
    } catch (e) {
      console.error('Error when starting recording:', {
        error: e,
        message: e?.message || String(e),
        name: e?.name || 'RecordingError',
        stack: e?.stack,
        toString: e?.toString?.(),
        constructor: e?.constructor?.name,
      });
      alertStore.error(`Error when starting recording: ${e?.toString?.() || 'Unknown error'}`, 'Error', e as Error);
      isRecording.value = false;
      isStopped.value = true;
      throw e;
    }

    // Start volume tracking
    await trackVolume(stream.value);
  } catch (error) {
    console.error('Overall error in startRecording:', error);
    isRecording.value = false;
    isStopped.value = true;
    alertStore.error('Error starting recording', 'Error', error as Error);
    throw error;
  }
}

const trackVolume = async (stream) => {
  try {
    // Create sound meter
    const soundMeter = new SoundMeter(window.audioContext);
    window.soundMeter = soundMeter;

    // Connect to source using Promise
    await new Promise<void>((resolve, reject) => {
      soundMeter.connectToSource(stream, (error) => {
        if (error) {
          console.error('Error connecting sound meter:', error);
          reject(error);
          return;
        }
        console.log('Sound meter connected successfully');
        resolve();
      });
    });

    // Setup volume tracking interval
    const meterRefresh = setInterval(() => {
      if (!window.soundMeter || !isRecording.value) {
        clearInterval(meterRefresh);
        volume.value = 0.0;
        return;
      }
      volume.value = Number(soundMeter.instant.toFixed(2));
    }, 50);

    console.log('Volume tracking started');
  } catch (e) {
    console.error('Error in trackVolume:', e);
    alertStore.error('Error when setting up volume tracking.', 'Error', e as Error);
    throw e;
  }
};

async function stopRecording() {
  console.log('STOP called, current isRecording:', isRecording.value);
  if (!isRecording.value) {
    console.log('Early return from stopRecording - not recording');
    return;
  }
  console.log('STOP');

  try {
    isRecording.value = false;
    isStopped.value = true;
    isPaused.value = false;
    pttStopTime.value = new Date();
    volume.value = 0.0;

    console.log('Duration:', pttStopTime.value - pttStartTime.value);
    console.log('Min PTT Duration:', minPttDuration);

    if (pttStopTime.value - pttStartTime.value < minPttDuration) {
      console.log('Recording too short. Not sending.');
      alertStore.info('Mikrofon-Knopf gedrückt halten, um zu sprechen.');
      return;
    }

    if (!recorder.value) {
      console.error('No recorder found when stopping');
      throw new Error('Cannot stop recording: no recorder');
    }

    console.log('Stopping recording...');
    await recorder.value.stopRecording();
    console.log('Recording stopped');

    const blob = await recorder.value.getBlob();
    console.log('Blob obtained:', blob, 'type:', blob.type, 'size:', blob.size);

    if (!blob || blob.size === 0) {
      console.error('Invalid blob received from recorder');
      alertStore.error('Error: No audio data recorded', 'Error');
      return;
    }

    // Supported formats: ['flac', 'm4a', 'mp3', 'mp4', 'mpeg', 'mpga', 'oga', 'ogg', 'wav', 'webm']"
    let audioFormat = '';
    if (blob.type.includes('audio/webm')) {
      audioFormat = 'webm';
    } else if (blob.type.includes('audio/wav')) {
      audioFormat = 'wav';
    } else if (blob.type.includes('audio/mp4')) {
      audioFormat = 'mp4';
    } else if (blob.type.includes('audio/ogg')) {
      audioFormat = 'ogg';
    } else if (blob.type.includes('audio/mp3')) {
      audioFormat = 'mp3';
    } else if (blob.type.includes('audio/mpeg')) {
      audioFormat = 'mpeg';
    } else if (blob.type.includes('audio/mpga')) {
      audioFormat = 'mpga';
    } else if (blob.type.includes('audio/oga')) {
      audioFormat = 'oga';
    } else if (blob.type.includes('audio/flac')) {
      audioFormat = 'flac';
    } else if (blob.type.includes('audio/m4a')) {
      audioFormat = 'm4a';
    } else {
      // fallback: try send as webm ...
      alertStore.info('Audio format ' + blob.type + ' not supported. Sending as webm.');
      audioFormat = 'webm';
    }
    console.log('Audio format:', audioFormat);

    // if (!blob.type.includes('audio/webm')) {
    //   console.error('Invalid blob type received from recorder:', blob.type);
    //   alertStore.error('Error: Invalid audio of type ' + blob.type + '', 'Error');
    //   return;
    // }

    stream.value?.getTracks().forEach((track) => {
      track.stop();
      console.log('Track stopped:', track.id);
    });

    // Cleanup
    recorder.value = null;
    stream.value = null;

    if (window.soundMeter) {
      window.soundMeter.stop();
      window.soundMeter = null;
    }
    // if (window.audioContext) {
    //   await window.audioContext.close();
    // }

    startMockProgress(1000);
    await sendToBackend(blob, audioFormat, currentCaseInteractionLanguage.value || 'deu');
  } catch (error) {
    alertStore.error('Error when stopping recording.', 'Error', error as Error);
    console.error('Error in stopRecording:', error);
    isRecording.value = false;
    isStopped.value = true;
    throw error;
  }
}

async function sendToBackend(blob, audioFormat, case_language) {
  emit('transcriptionInProgress', true);
  if (isDev) window.lastTranscriptionAttempt = Date.now();

  await (
    await getApiClient()
  ).stt
    .speechToText(
      {
        audio: blob,
      },
      audioFormat,
      case_language,
    )
    .then((result) => {
      transcribedText.value = result;
      if (
        transcribedText.value === "Das war's. Bis zum nächsten Mal." ||
        transcribedText.value === 'Untertitel der Amara.org-Community' ||
        transcribedText.value === "Hallo und herzlichen Dank für's Zuschauen." ||
        transcribedText.value === "So, das war's. Ich hoffe, es hat euch gefallen. Bis zum nächsten Mal!" ||
        transcribedText.value.includes('Untertitel im Auftrag des ZDF') ||
        transcribedText.value === 'Untertitelung aufgrund der Audioqualität nicht möglich' ||
        transcribedText.value === 'Untertitel von Stephanie Geiges' ||
        transcribedText.value === 'Hallo und herzlich willkommen bei der Live-Untertitelung von SWISS TXT.' ||
        transcribedText.value.includes(
          'It was different for them when you demand them by talking to as a language designed to communicate with',
        ) ||
        transcribedText.value.includes('Update Google Chrome Today, I am going to demo this openSRC') ||
        transcribedText.value.includes('Ich hoffe, das Video hat euch gefallen, wenn ja, dann lasst mir einen Daumen')
      ) {
        console.warn('Transcription failed. Refusing "' + transcribedText.value + '". Sending empty string.');
        if (isDev) {
          window.refusedTranscriptionText = transcribedText.value;
        }
        transcribedText.value = '';
      }
      if (transcribedText.value.length > 0) {
        emit('newTranscription', transcribedText.value);
        startMockProgress(4000);
        console.log('Transcription result: ', result);
        if (isDev) {
          console.log('Setting lastTranscriptionText to: ', result);
          window.lastTranscriptionText = result;
        }
      }
    })
    .catch((error) => {
      console.error('Error in sendToBackend: ', error);
      alertStore.error('Error when transcribing audio.', 'Error', error);
      throw error;
    })
    .finally(() => {
      emit('transcriptionInProgress', false);
    });
}

// Watch reset prop
watch(
  () => props.reset,
  (newVal) => {
    if (newVal) {
      console.debug('Resetting audio recorder');
      transcribedText.value = '';
      emit('resetComplete'); // notify parent that reset is complete
    }
  },
);

// Computed properties for visual feedback
const showHalo = computed(() => {
  // Determine whether to show the halo based on volume
  return isRecording.value && volume.value > 0;
});

const haloSize = computed(() => {
  // Calculate halo size based on volume
  return `${Math.min(184, 112 + (156 - 112) * (volume.value * 10))}px`;
});

const haloScale = computed(() => {
  return `${Math.min(1.4, 1 + volume.value * 10)}`;
});

const haloInverseScale = computed(() => {
  return `${1.1 / Math.min(1.4, 1 + volume.value * 10)}`;
});

onUnmounted(() => {
  audioStore.cleanup();
});
</script>

<template>
  <!-- Vol: {{ volume }}. -->
  <div
    class="group pointer-events-none relative flex z-[90] items-center justify-center opacity-100 rounded-full select-none transition-all duration-300 ease-in-out"
    draggable="false"
    :class="containerClasses"
    @contextmenu.prevent
  >
    <div
      class="absolute flex z-[90] pointer-events-none items-center justify-center bg-red-400/50 rounded-full"
      :class="{
        'h-24 w-24 lg:h-32 lg:w-32': size === 'normal',
        'h-8 w-8': size === 'small',
      }"
      :style="{ transform: `scale(${haloScale})` }"
      @contextmenu.prevent
    >
      <div class="grid pointer-events-none" :style="{ transform: `scale(${haloInverseScale})` }">
        <div
          class="col-start-1 row-start-1 pointer-events-none relative items-center justify-center"
          :class="{
            'h-24 w-24 lg:h-32 lg:w-32': size === 'normal',
            'h-8 w-8': size === 'small',
          }"
        >
          <svg
            class="h-full w-full pointer-events-none"
            width="40"
            height="40"
            viewBox="0 0 36 36"
            xmlns="http://www.w3.org/2000/svg"
          >
            <!-- Background Circle -->
            <circle
              v-show="mockProgress > 0"
              cx="18"
              cy="18"
              r="16"
              fill="none"
              class="stroke-current pointer-events-none text-transparent shadow-none shadow-gray-400"
              stroke-width="4"
            ></circle>
            <!-- Progress Circle inside a group with rotation -->
            <g class="origin-center -rotate-90 transform pointer-events-none">
              <circle
                v-show="mockProgress > 0"
                cx="18"
                cy="18"
                r="16"
                fill="none"
                class="stroke-current pointer-events-none transition-colors duration-300"
                :class="[
                  !disabled // if so: grey. Else: blue
                    ? !transcribedText // If filled: light blue. If not: dark blue (not recording) or red (recording)
                      ? isRecording
                        ? 'text-red-500 group-hover:text-red-600 shadow-inner shadow-none shadow-red-400 '
                        : 'text-blue-600 group-hover:text-blue-600 shadow-none shadow-gray-400  '
                      : 'text-blue-300 group-hover:text-blue-300 shadow-none shadow-gray-400  '
                    : 'text-gray-500 focus:ring-2 focus:ring-blue-600 focus:z-10 focus:outline-none shadow-none shadow-gray-400',
                ]"
                stroke-width="4"
                stroke-dasharray="100"
                :stroke-dashoffset="100 - mockProgress"
              ></circle>
            </g>
          </svg>
        </div>
        <!-- actual button-->
        <button
          @mousedown.prevent="startRecording"
          @mouseup.prevent="stopRecording"
          @touchstart.prevent="startRecording"
          @touchend.prevent="stopRecording"
          @touchcancel.prevent="stopRecording"
          @contextmenu.prevent
          draggable="false"
          type="button"
          :style="{ transform: `scale(${1 / 1.1})` }"
          class="z-[90] robust-button row-start-1 col-start-1 pointer-events-auto flex border-none flex-shrink-0 opacity-100 justify-center items-center rounded-full text-white transition-colors duration-300"
          :class="buttonClasses"
          :data-testid="`audio-recorder-ptt-${testIdSuffix}`"
        >
          <div class="items-center opacity-100 group-hover:scale-100">
            <div class="items-center opacity-100">
              <div translate="no" class="material-symbols-outlined notranslate select-none" :class="iconClasses">
                mic
              </div>
            </div>
            <div
              class="items-center -mt-4 mb-2 hidden text-sm select-none"
              :class="{
                'lg:block': size === 'normal',
                hidden: size === 'small',
              }"
            >
              Push to talk
            </div>
            <div
              class="items-center -mt-2 mb-2 text-sm select-none"
              :class="{
                'hidden md:block lg:hidden': size === 'normal',
                hidden: size === 'small',
              }"
            >
              PTT
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
@keyframes pulse {
  0% {
    opacity: 0.95;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.95;
  }
}

.animate-pulse {
  animation: pulse 50s infinite;
}

.robust-button {
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* iOS-specific */
  -ms-user-select: none; /* IE/Edge */
  touch-action: manipulation; /* Prevent unwanted touch behaviors */
  pointer-events: auto; /* Ensure the button itself is still clickable */
}
</style>
