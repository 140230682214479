<script setup lang="ts">
import DarkModeToggle from '@/components/DarkModeToggle.vue';
import SocialIcon from '@/views/about-us/SocialIcon.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores';

const authStore = useAuthStore();
const { isSignedIn } = storeToRefs(authStore);
</script>

<template>
  <footer class="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
    <!-- Grid -->
    <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-10">
      <div class="col-span-full hidden lg:col-span-1 lg:block">
        <a class="flex-none text-xl font-semibold dark:text-white" href="#" aria-label="Brand"
          >casuu<span class="text-blue-600">.health</span></a
        >
        <p class="mt-3 text-xs sm:text-sm text-gray-600 dark:text-gray-400">
          © 2024-25 casuu GmbH. casuu® ist eine eingetragene Marke. Alle Rechte vorbehalten.
        </p>
      </div>
      <!-- End Col -->

      <div>
        <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Unternehmen</h4>

        <div class="mt-3 grid space-y-3 text-sm">
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="/about-us"
              >Unser Team</a
            >
          </p>
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="/values-and-purpose"
              >Unsere Werte</a
            >
          </p>
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="/patient-advisory-board"
              >Unser Patient:innenbeirat</a
            >
          </p>
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="/nurse-expert-board"
              >Unser Expert:innenbeirat</a
            >
          </p>
        </div>
      </div>

      <div>
        <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Support</h4>

        <div class="mt-3 grid space-y-3 text-sm">
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="mailto:hello@casuu.care"
              >Preise</a
            >
          </p>
          <!--            <p>-->
          <!--              <a-->
          <!--                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"-->
          <!--                href="/about-us"-->
          <!--                >FAQ</a-->
          <!--              >-->
          <!--            </p>-->
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="mailto:hello@casuu.care"
              >Hilfe</a
            >
          </p>
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="mailto:hello@casuu.care"
              >Kontakt</a
            >
          </p>
        </div>
      </div>
      <!-- End Col -->

      <div>
        <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">
          Damit alles seine Richtigkeit hat
        </h4>
        <div class="mt-3 grid space-y-3 text-sm">
          <p v-if="isSignedIn">
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="/attributions"
              >Attributions</a
            >
          </p>
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="/privacy-and-terms"
              >AGB</a
            >
          </p>
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="/privacy-and-terms"
              >Datenschutz</a
            >
          </p>
          <p>
            <a
              class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
              href="/impressum"
              >Impressum</a
            >
          </p>
        </div>
      </div>
      <!-- End Col -->
    </div>
    <!-- End Grid -->

    <div class="flex justify-center pt-5 mt-5 border-t border-gray-200 dark:border-gray-700 min-w-full w-full">
      <div class="sm:flex sm:justify-between sm:items-center min-w-full w-full">
        <div class="grid items-center min-w-full w-full relative">
          <div class="row-start-1 col-start-1 flex justify-center items-center">
            <div class="z-10 flex justify-center items-center gap-x-3">
              <SocialIcon brand="linkedin" to="https://www.linkedin.com/company/casuu" size="4" />
              <SocialIcon brand="x" to="https://x.com/casuucare" size="4" />
              <SocialIcon brand="email" to="mailto:hello@casuu.care" size="4" />
            </div>
          </div>
          <div class="row-start-1 col-start-1 flex justify-end items-center gap-x-3">
            <div v-show="false" class="z-10 flex justify-center items-center">
              <DarkModeToggle />
            </div>
          </div>
        </div>
        <!-- End Col -->
      </div>
    </div>
  </footer>
</template>

<style scoped></style>
