import { LoggedInRoot } from '@/views/root';
import { OrganizationDashboard } from '@/views/dashboard';
import CreateUser from '@/views/dashboard/CreateUser.vue';
import ListUsers from '@/views/dashboard/ListUsers.vue';
import Subscription from '@/views/dashboard/Subscription.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';

export default {
  path: '/dashboard',
  component: LoggedInRoot,
  children: [
    {
      path: '/dashboard',
      component: OrganizationDashboard,
      children: [
        { path: '', component: Dashboard, meta: { forceNewRouterKey: 'dashboard-main' } },
        { path: 'subscription', component: Subscription, meta: { forceNewRouterKey: 'dashboard-subscriptions' } },
        { path: 'users/add', component: CreateUser, meta: { forceNewRouterKey: 'dashboard-users-add' } },
        { path: 'users', component: ListUsers, meta: { forceNewRouterKey: 'dashboard-users' } },
      ],
    },
  ],
};
