/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanionInteractionMessage } from '../models/CompanionInteractionMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompanionMessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Companion Interaction Message
   * :param session:
   * :param user:
   * :param companion_interaction_message_id:
   * :return:
   * @param companionInteractionMessageId
   * @returns CompanionInteractionMessage Successful Response
   * @throws ApiError
   */
  public getCompanionInteractionMessage(
    companionInteractionMessageId: string,
  ): CancelablePromise<CompanionInteractionMessage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/companion-messages/{companion_interaction_message_id}',
      path: {
        companion_interaction_message_id: companionInteractionMessageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Companion Message Translation
   * :param session:
   * :param user:
   * :param companion_interaction_message_id:
   * :param target_language:
   * :param translation_option:
   * :return:
   * @param companionInteractionMessageId
   * @param targetLanguage
   * @param translationOption
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestCompanionMessageTranslation(
    companionInteractionMessageId: string,
    targetLanguage: string,
    translationOption: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/companion-messages/{companion_interaction_message_id}/translation',
      path: {
        companion_interaction_message_id: companionInteractionMessageId,
      },
      query: {
        target_language: targetLanguage,
        translation_option: translationOption,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
