import { VocabItem } from '@/apiclient';

export enum PairOfTermsItemType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export type PairOfTermsItem = {
  id: string;
  // pair_of_terms_item_type: PairOfTermsItemType;
  content: string;
  description: string;
};

export type PairOfTermsItemPair = PairOfTermsItem[];

export type CategorizationCategoryType = {
  id: string;
  name: string;
  items: Array<CategorizationItemType>;
};

export type CategorizationItemType = {
  id: string;
  content: string;
  categoryId: string;
  isIncorrect: boolean;
};

export const roleTypes = [
  { value: 'PHYSICIAN', display_name: 'Ärzt:in' },
  { value: 'NURSE', display_name: 'Pfleger:in' },
  { value: 'PATIENT', display_name: 'Patient:in' },
  { value: 'RELATIVE', display_name: 'Angehöriger' },
  { value: 'OTHER', display_name: 'Andere' },
]; // TODO load from backend (ThirdPersonType enum + PATIENT)

export enum ResourceType {
  READING = 'READING',
  LISTENING = 'LISTENING',
  NO_RESOURCE = 'NO_RESOURCE',
}
