<script setup lang="ts">
import { ref, watch, computed } from 'vue';

const props = defineProps<{
  show: boolean;
}>();

const emit = defineEmits<{
  complete: [];
}>();

const isVisible = ref(false);

const successSymbols = [
  { type: 'material', symbol: 'check' },
  { type: 'emoji', symbol: '🎉' },
  { type: 'emoji', symbol: '👏' },
  { type: 'emoji', symbol: '💫' },
  { type: 'emoji', symbol: '💪' },
  { type: 'emoji', symbol: '❤️' },
  { type: 'emoji', symbol: '👍' },
  { type: 'emoji', symbol: '⭐' },
  { type: 'emoji', symbol: '🏆' },
];

const randomSymbol = computed(() => {
  // 35% chance for check
  if (Math.random() < 0.35) {
    return successSymbols[0];
  }
  // else randomly select from the remaining symbols
  const randomIndex = Math.floor(Math.random() * (successSymbols.length - 1)) + 1;
  return successSymbols[randomIndex];
});

watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      isVisible.value = true;
      setTimeout(() => {
        isVisible.value = false;
      }, 500);
    }
  },
);
</script>

<template>
  <Transition @after-leave="emit('complete')">
    <div v-if="isVisible" class="success-animation">
      <span
        :class="[randomSymbol.type === 'material' ? 'material-symbols-outlined' : 'emoji', 'check-icon text-teal-500']"
      >
        {{ randomSymbol.symbol }}
      </span>
    </div>
  </Transition>
</template>

<style scoped>
.success-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 150;
  pointer-events: none;
}

.check-icon {
  font-size: 48px;
  animation: grow-fade 500ms ease-out forwards;
}

.emoji {
  font-size: 24px;
}

@keyframes grow-fade {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(16);
    opacity: 0;
  }
}

.v-leave-active {
  transition: opacity 100ms ease-out;
}

.v-leave-to {
  opacity: 0;
}
</style>
