/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VoiceCreate } from '../models/VoiceCreate';
import type { VoiceCreateResponse } from '../models/VoiceCreateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VoicesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve Voice
   * @param requestBody
   * @returns VoiceCreateResponse Successful Response
   * @throws ApiError
   */
  public retrieveVoice(requestBody: VoiceCreate): CancelablePromise<VoiceCreateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/voices/retrieve',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
