/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VoiceCreate = {
  sex: VoiceCreate.sex;
  age: number;
  role: string;
  mood: string;
  accent: string;
};

export namespace VoiceCreate {
  export enum sex {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    DIVERSE = 'DIVERSE',
  }
}
