<!-- TODO: enable edit option -->

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const { userFirstName, userLastName } = storeToRefs(authStore);

const { message, showFooter } = defineProps(['message', 'showFooter']);
const editOn = ref(false);
const editedMessage = ref('');
const originalMessage = ref('');
// const hasBeenEdited = ref(false);

// import shared functions
import { useChatBubbleFunctions } from '@/components/chat_bubbles/chatBubbleFunctions';
import { getSentenceForCharacter, unobfuscateUserName } from '@/helper';
import DOMPurify from 'dompurify';
import InstantTranslation from '@/components/case_interaction/InstantTranslation.vue';
import { useAuthStore } from '@/stores';

const { selectedSentenceIndcs, calculateCols, calculateRows, getSelectedCharacterPositions } = useChatBubbleFunctions();

const solveFeedbackSelectableDiv = ref(null);

function handleClickOutside(event) {
  // Check if the clicked element is outside the solveFeedbackSelectableDiv
  if (!solveFeedbackSelectableDiv.value.contains(event.target)) {
    // Reset the selected positions
    selectedSentenceIndcs.value = [];
  }
}

function onSelectedText() {
  let characterPositions = getSelectedCharacterPositions(solveFeedbackSelectableDiv.value);
  console.log('Selected character positions: ' + characterPositions);
  console.log(message);
  console.log(message.plainContent);
  selectedSentenceIndcs.value = [];
  for (let i = 0; i < characterPositions.length; i++) {
    let sentenceIdx = getSentenceForCharacter(message.plainContent, characterPositions[i]);
    console.log('Sentence index: ' + sentenceIdx);
    if (!selectedSentenceIndcs.value.includes(sentenceIdx)) {
      selectedSentenceIndcs.value.push(sentenceIdx);
    }
  }
}

async function editToggled(new_value) {
  console.debug('Not implemented yet');
}

onMounted(() => {
  // Listen for a click event on the document
  document.addEventListener('click', handleClickOutside);
});

// Cleanup the event listener when the component is unmounted
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

function sanitize(str) {
  return DOMPurify.sanitize(str);
}
</script>

<template>
  <div>
    <div
      class="p-4 border-t dark:border-gray-700 mb-2 text-sm pointer-events-auto"
      @mouseup="onSelectedText"
      ref="solveFeedbackSelectableDiv"
    >
      <div
        v-html="sanitize(unobfuscateUserName(message.formattedContent, message.userFirstName, message.userLastName))"
      />
    </div>

    <div class="pointer-events-auto" v-show="showFooter">
      <InstantTranslation :message="message.plainContent" :markedSentencesOrigin="selectedSentenceIndcs" />
    </div>
  </div>
</template>

<style scoped></style>
