<script setup>
import { nextTick, onBeforeUnmount, onMounted, ref, computed } from 'vue';
import { HSOverlay } from 'preline';
import { useAlertStore } from '@/stores';

const alertStore = useAlertStore();

const emit = defineEmits(['approved', 'declined']);

// Props
const props = defineProps({
  overlayId: {
    type: String,
    required: true,
  },
  heading: {
    type: String,
    default: 'Bestätigung erforderlich',
  },
  prompt_message: {
    type: String,
    required: true,
  },
  approve_message: {
    type: String,
    default: 'Bestätigen',
  },
  approve_color: {
    type: String,
    default: 'bg-blue-600 hover:bg-blue-700',
  },
  discard_color: {
    type: String,
    default: 'bg-gray-200 hover:bg-gray-50',
  },
  discard_message: {
    type: String,
    default: 'Abbrechen',
  },
  header_icon: {
    type: String,
    default: null,
  },
  width: {
    type: Number,
    default: 0.3,
  },
  closePrevModal: {
    type: Boolean,
    default: true,
  },
  disableApproveButton: {
    type: Boolean,
    default: false,
  },
});

const approved = ref(false);
const modal = ref(null); // we need this for the .close(). Ugly.
const target = ref(null);

// Computed properties
const hashtagOverlayId = computed(() => `#${props.overlayId}`);

// Methods
const promptUserConformation = async (targetLoad) => {
  target.value = targetLoad;
  await nextTick(); // Ensure DOM updates are complete. Without this, modal window itself opens and closes, but backdrop remains there

  // HSOverlay.open(modalRef.value);  // works
  // modal.value.open();  // does NOT work - even though close does
  console.log(hashtagOverlayId.value);
  console.log(modal.value);
  HSOverlay.open(hashtagOverlayId.value);

  console.log('opened modal');
};

const approveModal = async (event) => {
  event.stopPropagation();
  console.debug('approved', target.value);

  approved.value = true;
  close();
  await nextTick(); // Ensure DOM updates are complete
};

const declineModal = async (event) => {
  event.stopPropagation();
  approved.value = false;
  console.debug('declined', target.value);

  close(); // this manages to close the modal (even though modal.value.open does not work, cf. above)
  // HSOverlay.close(hashtagOverlayId.value);  // this reopens modal diretly after closing
  // HSOverlay.close(modalRef.value);  // this reopens modal diretly after closing

  await nextTick();
};

// Lifecycle hooks
onMounted(async () => {
  // wait 200 ms
  await new Promise((resolve) => setTimeout(resolve, 200));
  await HSOverlay.autoInit();

  // Get the modal element and initialize it
  const modalElement = document.querySelector(hashtagOverlayId.value);
  modal.value = HSOverlay.getInstance(modalElement);

  // If no instance exists, create one
  if (!modal.value) {
    console.log('Creating new modal instance');
    modal.value = new HSOverlay(modalElement);
  }

  await nextTick();

  // Add click handler to the overlay element itself
  modalElement?.addEventListener('click', (event) => {
    // Only handle clicks directly on the overlay, not its children
    if (event.target === modalElement) {
      console.log('Backdrop clicked');
      declineModal(event);
    }
  });

  modal.value.addEventListener('close.hs.overlay', () => {
    console.debug('closing dialog');
    if (approved.value) {
      console.debug('was approved before close');
      emit('approved', target.value);
    } else {
      console.debug('was declined before close or closed thru backdrop/ esc');
      emit('declined', target.value);
    }
  });
});

const approveButtonClasses = computed(() => {
  return props.approve_color + ' ' + (props.disableApproveButton ? 'opacity-50' : '');
});

onBeforeUnmount(async () => {
  if (modal.value) {
    close();
  }
});

const close = () => {
  HSOverlay.close(hashtagOverlayId.value);
};

defineExpose({
  promptUserConformation,
});
</script>

<template>
  <div
    :id="overlayId"
    class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[100] overflow-x-hidden overflow-y-auto"
  >
    <div
      class="hs-overlay-open:mt-28 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-36 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto"
    >
      <div
        class="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-gray-700 dark:shadow-neutral-700/[.7]"
      >
        <div class="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
          <h3 class="font-bold text-gray-800 dark:text-white flex items-center gap-x-4">
            <span v-if="header_icon" translate="no" class="material-symbols-outlined notranslate">
              {{ header_icon }}
            </span>
            {{ heading }}
          </h3>
          <button class="hs-dropup-toggle hidden" :data-hs-overlay="hashtagOverlayId">
            <!-- hidden button because preline requires a hs-dropup-toggle to be present -->
          </button>
          <button
            type="button"
            class="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
            :data-testid="`close-button-${overlayId}`"
            @click.prevent="
              (event) => {
                declineModal(event);
              }
            "
          >
            <span class="sr-only">Close</span>
            <svg
              class="w-3.5 h-3.5"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <div class="p-4 overflow-y-auto">
          <div class="mt-1 text-gray-800 dark:text-gray-400">
            <div class="modal-content">
              <slot></slot>
            </div>
          </div>
        </div>
        <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
          <button
            type="button"
            class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
            :class="discard_color"
            @click.prevent="
              (event) => {
                declineModal(event);
              }
            "
            :data-testid="`discard-button-${overlayId}`"
          >
            {{ discard_message }}
          </button>
          <button
            type="button"
            class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
            :class="approveButtonClasses"
            @click.prevent="
              (event) => {
                if (props.disableApproveButton) {
                  alertStore.info(
                    'Bitte stimme den angegebenen Bestimmungen zu, um fortfahren zu können.',
                    'Zustimmung erforderlich',
                  );
                  return;
                }
                approveModal(event);
              }
            "
            :data-testid="`approve-button-${overlayId}`"
          >
            {{ approve_message }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
