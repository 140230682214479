<script setup lang="ts">
import CreateListeningDialog from './didactics/listening_dialog/create/CreateListeningDialog.vue';
import { ref } from 'vue';
import { onMounted } from 'vue';
import DialogHistory from './didactics/listening_dialog/history/DialogHistory.vue';
import { getApiClient } from '@/apiclient/client';

const showSuccess = ref(false);
const dialog = ref([]);
const createListeningDialogModal = ref(null);

onMounted(async () => {
  dialog.value = await (await getApiClient()).dialogs.getDialog('c390f869-99a6-4f7f-8188-74f584eb49e6');
});
</script>

<template>
  <div class="flex flex-col w-full h-full justify-center items-center bg-gray-50">
    <CreateListeningDialog ref="createListeningDialogModal" :overlayId="'create-listening-dialog-modal'" />
    <button @click="createListeningDialogModal.open()">Open</button>

    <!-- <DialogHistory :dialog="dialog" /> -->
  </div>
</template>
