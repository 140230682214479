<script setup>
import { computed, nextTick, ref, onBeforeUnmount, onMounted } from 'vue';
import CaseHighlightCard from '@/components/cases/CaseHighlightCard.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { useAuthStore, useCaseStore } from '@/stores';
import { formatDate } from '@/helper';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['selected']);

// Define props
const props = defineProps({
  overlayId: {
    type: String,
    required: true,
  },
  heading: {
    type: String,
    default: 'Fälle wählen',
  },
  submitMessage: {
    type: String,
    default: 'Bestätigen',
  },
  closeMessage: {
    type: String,
    default: 'Zurück',
  },
  width: {
    type: Number,
    default: 0.3,
  },
  height: {
    type: Number,
    default: 0.7,
  },
  caseListId: {
    type: String,
    default: '',
  },
  allowCreationOfNewCase: {
    type: Boolean,
    default: true,
  },
});

// Store references
const caseStore = useCaseStore();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

// Reactive references
const selectedCases = ref([]);
const overlayRef = ref(null);
const isShowing = ref(false);
const caseDetailsFilter = ref('');

// Computed properties
const filteredCases = computed(() => {
  if (caseDetailsFilter.value === '') {
    return caseStore.allCases;
  }
  if (!caseStore.allCases) {
    return [];
  }
  let filter = caseDetailsFilter.value.trim();
  return caseStore.allCases.filter((case_) => {
    return (
      JSON.stringify(case_.details).toLowerCase().includes(filter.toLowerCase()) ||
      JSON.stringify(case_.tags ? case_.tags : {})
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      JSON.stringify(case_.specialties ? case_.specialties : {})
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      JSON.stringify(case_.patient ? case_.patient.details : [])
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      (case_.third_persons ? case_.third_persons : []).some((thirdPerson) => {
        return JSON.stringify(thirdPerson.details).toLowerCase().includes(filter.toLowerCase());
      }) ||
      (case_.tasks ? case_.tasks : []).some((task) => {
        return JSON.stringify(task.details).toLowerCase().includes(filter.toLowerCase());
      })
    );
  });
});
const hashtagOverlayId = computed(() => `#${props.overlayId}`);
const submitMessageWithNumberOfSelected = computed(
  () => `${props.submitMessage}${selectedCases.value.length ? ` (${selectedCases.value.length} ausgewählt)` : ''}`,
);

const fakeCase = {
  id: null,
  user: user.value,
  details: {
    setting: '',
    date: '',
    time: '',
    specialties: [],
    tags: [],
    situation: '',
  },
  patient: {
    id: null,
    details: {
      first_name: 'Neuen Fall erstellen',
      last_name: '',
    },
    created_at: '',
  },
  is_ready: true,
  created_at: formatDate(new Date().toDateString()),
};

// Lifecycle hooks
onMounted(async () => {
  await nextTick();
});

onBeforeUnmount(async () => {
  console.log('Before unmounting SelectCasesModal');
  if (overlayRef.value) {
    await window.HSOverlay.close(overlayRef.value);
  }
});

// Methods
const promptSelection = async () => {
  console.debug('Initiating meta chat dialog');
  await caseStore.fetchCasesIfEmpty();
  await window.HSOverlay.open(overlayRef.value);
  isShowing.value = true;
  console.debug('Returning promise');
};

const close = async () => {
  console.debug('close()');
  await window.HSOverlay.close(overlayRef.value);
  isShowing.value = false;
};

const toggleSelect = async (case_) => {
  console.log('Toggle select case: ', case_);
  if (selectedCases.value.includes(case_)) {
    selectedCases.value = selectedCases.value.filter((c) => c !== case_);
    return;
  }
  selectedCases.value.push(case_);
};

const handleSubmit = async () => {
  let selectedCaseIds = selectedCases.value.map((c) => c.id);
  console.log('Submit selected cases: ', selectedCaseIds);
  emit('selected', selectedCaseIds);
  await close();
};

const closeModalAfterNextTick = async () => {
  await nextTick();
  await close();
};

defineExpose({
  promptSelection,
  close,
});
</script>

<template>
  <div
    ref="overlayRef"
    :id="overlayId"
    class="hs-overlay [--overlay-backdrop:true] hidden w-full h-full fixed top-0 start-0 z-[100] overflow-x-hidden overflow-y-hidden pointer-events-none"
  >
    <div
      class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all sm:max-w-3xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)]"
    >
      <div
        class="max-h-full overflow-y-auto flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-gray-700 dark:shadow-neutral-700/[.7]"
      >
        <!-- upper right corner "X" button -->
        <div class="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
          <h3 class="font-bold text-gray-800 dark:text-white">
            {{ heading }}
          </h3>

          <!--                    <div class="grid grid-cols-12 gap-2 py-2 max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto items-center">-->
          <!--                      <div class="col-span-2 text-gray-500 font-medium text-sm">Tags</div>-->
          <!--                      <div class="col-span-10">-->
          <!--                        <TagInput @input="selectTags"/>-->
          <!--                      </div>-->

          <!--                      <div class="col-span-2 text-gray-500 font-medium text-sm">Fachgebiete</div>-->
          <!--                      <div class="col-span-10">-->
          <!--                        <SpecialtyInput @input="selectSpecialties"/>-->
          <!--                      </div>-->
          <!--                    </div>-->

          <button
            type="button"
            class="pointer-events-auto inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
            :data-hs-overlay="hashtagOverlayId"
            @mouseup.capture="
              isShowing = false;
              console.log('closed');
            "
          >
            <span class="sr-only">Close</span>
            <svg
              class="w-3.5 h-3.5"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <!-- end of "X" button -->

        <!-- modal contents -->
        <!-- header: search mask + submit -->
        <div class="sticky top-0 z-30 flex flex-wrap bg-blue-100/50 pointer-events-auto">
          <div class="gap-2 py-2 w-full px-2 sm:px-4 lg:px-4 mx-auto items-center">
            <div class="w-full inline-flex pl-12 gap-x-12 justify-between">
              <div class="relative px-12 flex flex-grow">
                <div
                  class="absolute inset-y-0 start-0 flex mx-12 min-w-full w-full items-center pointer-events-none z-20 ps-3.5"
                >
                  <span
                    translate="no"
                    class="material-symbols-outlined notranslate text-gray-500 dark:text-neutral-400"
                  >
                    search
                  </span>
                </div>
                <input
                  type="text"
                  class="py-[7px] px-3 ps-10 flex min-w-full w-full bg-white border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
                  :placeholder="$t('message.filterCases')"
                  v-model="caseDetailsFilter"
                />
              </div>

              <!-- Submit button bottom -->
              <div class="pointer-events-auto disabled:pointer-events-none">
                <ProgressButton
                  :onclick="handleSubmit"
                  color="blue"
                  :disabled="caseStore.fetchInProgress"
                  type="button"
                  :text="submitMessageWithNumberOfSelected"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- case highlight cards -->
        <div class="grid grid-cols-2 md:grid-cols-3 gap-6 pt-6">
          <div class="group pointer-events-auto" @mousedown.capture="closeModalAfterNextTick">
            <!-- new case: create with association to case list -->
            <!--            <CaseHighlightCard-->
            <!--                :case="fakeCase"-->
            <!--                :asPartOfCaseList="caseListId"-->
            <!--                :openCaseSelectionListIfCaseIsNull="false"-->
            <!--                :allowRouteToCase="true"-->
            <!--            />-->
            <!-- TODO re-enable new case creation after refactor -->
          </div>
          <div
            v-if="!caseStore.fetchInProgress && isShowing"
            class="group pointer-events-auto"
            v-for="case_ in filteredCases"
          >
            <div
              class="pointer-events-auto"
              :class="{ 'ring-2 ring-blue-600 rounded-xl': selectedCases.includes(case_) }"
              v-if="!!case_.id"
              :key="case_.id"
              @mousedown.capture="toggleSelect(case_)"
            >
              <!-- existing cases: not yet in case list so null -->
              <CaseHighlightCard
                :case="case_"
                :asPartOfCaseList="caseListId"
                :openCaseSelectionListIfCaseIsNull="false"
                :allowRouteToCase="false"
                :allowRemoveFromCaseList="false"
              />
            </div>
          </div>
        </div>
        <!-- end of modal contents -->

        <!-- modal footer buttons -->
        <div class="pointer-events-auto flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
          <!-- Submit button bottom -->
          <div class="pointer-events-auto disabled:pointer-events-none">
            <ProgressButton
              :onclick="handleSubmit"
              color="blue"
              :disabled="caseStore.fetchInProgress"
              type="button"
              :text="submitMessageWithNumberOfSelected"
            />
          </div>
          <a
            class="py-2 px-3 text-sm min-w-24 min-h-10 inline-flex font-medium justify-center items-center gap-2 rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
            :data-hs-overlay="hashtagOverlayId"
            @mouseup.capture="
              isShowing = false;
              console.log('closed');
            "
          >
            {{ closeMessage }}
          </a>
        </div>
        <!-- end of modal footer buttons -->
      </div>
    </div>
  </div>
</template>
