/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MessageType {
  SAY = 'SAY',
  REACT = 'REACT',
  SAY_SETUP = 'SAY_SETUP',
  NOTE = 'NOTE',
  NOTE_SETUP = 'NOTE_SETUP',
  NOTE_REACT = 'NOTE_REACT',
  EXAMINATION = 'EXAMINATION',
  EXAMINATION_SETUP = 'EXAMINATION_SETUP',
  LAB = 'LAB',
  LAB_SETUP = 'LAB_SETUP',
  THERAPY = 'THERAPY',
  THERAPY_SETUP = 'THERAPY_SETUP',
  ATTENDING = 'ATTENDING',
  ATTENDING_REACT = 'ATTENDING_REACT',
  ATTENDING_SETUP = 'ATTENDING_SETUP',
  ORAL_SETUP = 'ORAL_SETUP',
  ORAL_REACT = 'ORAL_REACT',
  ORAL = 'ORAL',
  THIRD_PERSON_SETUP = 'THIRD_PERSON_SETUP',
  THIRD_PERSON_REACT = 'THIRD_PERSON_REACT',
  THIRD_PERSON = 'THIRD_PERSON',
  MC_SETUP = 'MC_SETUP',
  MC_REACT = 'MC_REACT',
  MC = 'MC',
  OBSERVER_SETUP = 'OBSERVER_SETUP',
  DESCRIPTION = 'DESCRIPTION',
  OBSERVER_REACT = 'OBSERVER_REACT',
  TRANSLATION_SETUP = 'TRANSLATION_SETUP',
  TRANSLATION = 'TRANSLATION',
  VOCAB_SCORE_SETUP = 'VOCAB_SCORE_SETUP',
  VOCAB_SCORE = 'VOCAB_SCORE',
  VOCAB_TRANSLATE_SETUP = 'VOCAB_TRANSLATE_SETUP',
  VOCAB_TRANSLATE = 'VOCAB_TRANSLATE',
  VOCAB_EXPLAIN_SETUP = 'VOCAB_EXPLAIN_SETUP',
  VOCAB_EXPLAIN = 'VOCAB_EXPLAIN',
  COMPANION_SETUP = 'COMPANION_SETUP',
  COMPANION_SAY = 'COMPANION_SAY',
  COMPANION_REACT = 'COMPANION_REACT',
  AUDIO_EFFECT = 'AUDIO_EFFECT',
}
