import { defineStore } from 'pinia';

import { router } from '@/router';

import { getApiClient } from '@/apiclient/client';
import {
  AttendingInteractionMessage,
  AttendingInteractionMessageContent,
  CaseInteraction,
  McExaminer,
  McExamInteractionMessage,
  MessageType,
  ThirdPerson,
  ThirdPersonInteraction,
  ThirdPersonInteractionMessage,
  ThirdPersonInteractionMessageContent,
  Voice,
} from '@/apiclient';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { getCurrentTask, useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useAuthStore } from '@/stores/auth.store';

interface thirdPersonInteractionState {
  currentCaseInteraction: CaseInteraction | null;
  currentThirdPersons: ThirdPerson[];
  currentThirdPersonInteractionIds: string[];
  chatMessages: ThirdPersonInteractionMessage[][];
  chatIsStreaming: Boolean[];
}

export const useThirdPersonInteractionStore = defineStore({
  id: 'thirdPersonInteraction',
  state: () => ({
    currentCaseInteraction: null as CaseInteraction | null,
    currentThirdPersons: [] as ThirdPerson[],
    currentThirdPersonInteractionIds: [] as string[],
    chatMessages: [[]] as ThirdPersonInteractionMessage[][],
    chatIsStreaming: [] as Boolean[],
    requestedWelcomeMessage: [] as Boolean[],
  }),
  getters: {
    currentCaseInteractionId(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.id : '';
    },
    anyChatIsStreaming(state) {
      return state.chatIsStreaming.some((streaming) => streaming);
    },
  },
  actions: {
    async _initiateThirdPersonInteraction() {
      const caseInteractionStore = useCaseInteractionStore();
      const caseInteraction = caseInteractionStore.currentCaseInteraction;

      if (!caseInteraction) {
        console.warn('Null case interaction provided to setCaseInteraction');
        return;
      }
      this.currentCaseInteraction = caseInteraction;
      // console.log('#third person interactions: ' + caseInteraction.third_person_interactions.length);
      // console.log('caseInteraction: ' + JSON.stringify(caseInteraction));
      for (let i = 0; i < caseInteraction.third_person_interactions.length; i++) {
        this.currentThirdPersonInteractionIds.push(caseInteraction.third_person_interactions[i].id);
      }

      this.currentThirdPersons = caseInteraction.case.third_persons;

      console.debug('Set case interaction ' + this.currentCaseInteraction.id);
    },
    someMessageToRedo(personIndex: number) {
      // true if last message is undone and type is THIRD_PERSON, meaning that:
      // - if the last message is undone and then redone, this becomes false
      // - if the last message (or more messages) is undone but we then add a new message, this becomes false - in this case, old undone messages cannot be redone anymore. This is necessary for consistency.
      if (this.chatMessages[personIndex].length === 0) {
        return false;
      }
      return (
        !!this.chatMessages[personIndex][this.chatMessages[personIndex].length - 1].undone_at &&
        this.chatMessages[personIndex][this.chatMessages[personIndex].length - 1].type === 'THIRD_PERSON'
      );
    },
    someMessageToUndo(personIndex: number) {
      if (this.chatMessages[personIndex].length === 0) {
        return false;
      }
      return (
        !this.chatMessages[personIndex][this.chatMessages[personIndex].length - 1].undone_at &&
        this.chatMessages[personIndex][this.chatMessages[personIndex].length - 1].type === 'THIRD_PERSON'
      );
    },
    thirdPersonFirstName(personIndex: number): string {
      if (!this.currentThirdPersons) {
        return '';
      }
      return this.currentThirdPersons[personIndex] ? this.currentThirdPersons[personIndex].details.first_name : '';
    },
    thirdPersonLastName(personIndex: number): string {
      if (!this.currentThirdPersons) {
        return '';
      }
      return this.currentThirdPersons[personIndex] ? this.currentThirdPersons[personIndex].details.last_name : '';
    },
    thirdPersonName(personIndex: number): string {
      if (!this.currentThirdPersons) {
        return '';
      }
      if (!!this.currentThirdPersons[personIndex]) {
        console.log('currentThirdPersons: ' + JSON.stringify(this.currentThirdPersons[personIndex]));
      }
      return !!this.currentThirdPersons[personIndex]
        ? (this.currentThirdPersons[personIndex].details.academic_title ? +' ' : '') +
            this.currentThirdPersons[personIndex].details.first_name +
            ' ' +
            this.currentThirdPersons[personIndex].details.last_name
        : '';
    },
    thirdPersonAcademicTitle(personIndex: number): string | null {
      if (!this.currentThirdPersons) {
        return null;
      }
      return this.currentThirdPersons[personIndex]
        ? this.currentThirdPersons[personIndex].details.academic_title
        : null;
    },
    thirdPersonProfileImageSmall(personIndex: number): string {
      if (!this.currentThirdPersons) {
        return '';
      }
      return this.currentThirdPersons[personIndex]
        ? this.currentThirdPersons[personIndex].profile_image
          ? this.currentThirdPersons[personIndex].profile_image.image_urls.small
          : ''
        : '';
    },
    thirdPersonProfileImageMedium(personIndex: number): string {
      if (!this.currentThirdPersons) {
        return '';
      }
      return this.currentThirdPersons[personIndex]
        ? this.currentThirdPersons[personIndex].profile_image
          ? this.currentThirdPersons[personIndex].profile_image.image_urls.medium
          : ''
        : '';
    },
    thirdPersonProfileImageLarge(personIndex: number): string {
      if (!this.currentThirdPersons) {
        return '';
      }
      return this.currentThirdPersons[personIndex]
        ? this.currentThirdPersons[personIndex].profile_image
          ? this.currentThirdPersons[personIndex].profile_image.image_urls.large
          : ''
        : '';
    },
    thirdPersonInitials(personIndex: number): string {
      if (!this.currentThirdPersons) {
        return '';
      }
      // TS problem: https://github.com/vuejs/pinia/discussions/1076
      // return initials based on userFirstName and userLastName
      // check if length of userFirstName and userLastName is > 0
      let firstInitial = '';
      let lastInitial = '';

      if (
        this.currentThirdPersons[personIndex] &&
        this.currentThirdPersons[personIndex].details.first_name &&
        this.currentThirdPersons[personIndex].details.first_name.length > 0
      ) {
        firstInitial = this.currentThirdPersons[personIndex].details.first_name[0].toUpperCase();
      }
      if (
        this.currentThirdPersons[personIndex] &&
        this.currentThirdPersons[personIndex].details.last_name &&
        this.currentThirdPersons[personIndex].details.last_name.length > 0
      ) {
        lastInitial = this.currentThirdPersons[personIndex].details.last_name[0].toUpperCase();
      }

      return firstInitial + lastInitial;
    },
    thirdPersonVoice(personIndex: number): any {
      if (!this.currentThirdPersons) {
        return null;
      }
      if (this.currentThirdPersons[personIndex] && this.currentThirdPersons[personIndex].voice) {
        return {
          voice_id: this.currentThirdPersons[personIndex].voice.voice_id,
          voice_model: this.currentThirdPersons[personIndex].voice.voice_model,
          voice_provider: this.currentThirdPersons[personIndex].voice.voice_provider,
        } as Voice;
      } else {
        return {
          voice_id: '21m00Tcm4TlvDq8ikWAM',
          voice_model: 'eleven_multilingual_v2',
          voice_provider: 'elevenlabs',
        } as Voice;
      }
    },
    thirdPersonUserInteraction(personIndex: number): string {
      if (!this.currentThirdPersons) {
        return '';
      }
      return this.currentThirdPersons[personIndex]
        ? this.currentThirdPersons[personIndex].details.role_and_task_description_for_user
        : 'ansprechen';
    },
    async createPreliminaryChatMessage(personIndex: number, userMessage: string = '', type: string = 'THIRD_PERSON') {
      console.log('Creating preliminary third person chat message?');

      if (!this.currentThirdPersonInteractionIds) {
        console.error('No third person interaction ID set');
        return;
      }

      this.chatIsStreaming[personIndex] = true;
      console.log(
        'Creating preliminary chat message for third person interaction ' +
          this.currentThirdPersonInteractionIds[personIndex],
      );

      const content: ThirdPersonInteractionMessageContent = {
        processed_model_output: '',
        user_message: userMessage,
        user_message_language: null,
        timestamp: null,
      };
      const message: ThirdPersonInteractionMessage = {
        id: 'not_yet_in_database',
        third_person_interaction_id: this.currentThirdPersonInteractionIds[personIndex],
        content: content,
        type: type as MessageType,
        translations: null,
        created_at: 'incomplete',
      };

      this.chatMessages[personIndex].push(message);
    },
    async appendToChatMessage(personIdx: number, idx: number, chunk: string) {
      // console.debug(chunk)
      let chunk_ = JSON.parse(chunk);
      try {
        if (chunk_.type === 'message') {
          this.chatMessages[personIdx][idx].content['processed_model_output'] += chunk_['content'];
        } else if (chunk_.type === 'id') {
          this.chatMessages[personIdx][idx].id = chunk_['content'];
          console.debug('Finished message with id ' + chunk_['content']);
        }
      } catch (e) {
        const caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('Case interaction set to null while streaming. Ok if left interaction.');
        }
        throw e;
      }
      // console.debug('Last message now reads: ' + this.chatMessages[idx].content['processed_model_output'])
    },
    async appendToLastChatMessageAndRefetchOnceFinished(personIdx: number, chunk: string) {
      const idx = this.chatMessages[personIdx].length - 1;
      await this.appendToChatMessage(personIdx, idx, chunk);
      if (this.chatMessages.length && idx >= 0 && this.chatMessages[personIdx][idx].id !== 'not_yet_in_database') {
        // stream completed
        this.chatIsStreaming[personIdx] = false;
        await this.fetchAndReplaceChatMessage(personIdx, idx);
      }
    },
    async fetchAndReplaceChatMessage(personIdx: number, idx: number) {
      const id = this.chatMessages[personIdx][idx].id;
      console.debug('Fetching complete message for incomplete message ' + id);
      const completeMessage = await (await getApiClient()).thirdPersonMessages.getThirdPersonInteractionMessage(id);
      this.chatMessages[personIdx][idx].id = completeMessage.id;
      this.chatMessages[personIdx][idx].created_at = completeMessage.created_at;
      this.chatMessages[personIdx][idx].content.processed_model_output = completeMessage.content.processed_model_output;
    },
    appendToIndexedChatMessageTranslation(
      personIndex: number,
      index: number,
      target_language: string,
      translation_option: string,
      chunk: string,
    ) {
      let chunk_ = JSON.parse(chunk);
      if (chunk_.type === 'message') {
        this.chatMessages[personIndex][index].translations[target_language][translation_option] += chunk_['content'];
      } else if (chunk_.type === 'id') {
        console.debug('Finished translation of message with id ' + chunk_['content']);
      }
      // console.debug(this.chatMessages[index].translations[target_language][translation_option])
    },
    // appendToIndexedChatMessageTranslation: function (
    //     this: thirdPersonInteractionState,
    //     personIndex: number,
    //     index: number,
    //     target_language: string,
    //     translation_option: string,
    //     chunk?: string,
    // ) {
    //     if (chunk !== undefined) {
    //         // Full call
    //         return this._appendToIndexedChatMessageTranslation(personIndex, index, target_language, translation_option, chunk);
    //     } else {
    //         // Partial application
    //         const partialFn: (chunk: string) => Promise<void> = async (chunk: string): Promise<void> => {
    //             return this._appendToIndexedChatMessageTranslation(personIndex, index, target_language, translation_option, chunk);
    //         };
    //         return partialFn;
    //         // NOTE: if function is async, need to be returned as object, i.e. return {partialFn}, then unpacked
    //         // as const {callback} = this.appendToIndexedChatMessageTranslation(index, target_language, translation_option)
    //     }
    // } as Partial,
    async translateChatMessage(
      personIndex: number,
      message: ThirdPersonInteractionMessage,
      language: string,
      option: string,
    ) {
      // TODO: fix bug -- after reloading case (URL + enter), translations are loaded double (every types and time?)

      console.debug('Getting translation of ' + message.content + ' to ' + language);

      if (
        message.translations &&
        language in message.translations &&
        option in message.translations[language]
        // message.translations?.language?.option  -- TODO why does this not work and [] is needed?!
      ) {
        console.debug('Nothing to do here');
        return; // message.translations.language.option;
      }

      let index = this.chatMessages[personIndex].indexOf(message);
      if (this.chatMessages[personIndex][index].translations === null) {
        this.chatMessages[personIndex][index].translations = {};
      }
      if (this.chatMessages[personIndex][index].translations[language] === undefined) {
        this.chatMessages[personIndex][index].translations[language] = {};
      }
      this.chatMessages[personIndex][index].translations[language][option] = '';
      let origMessage = this.chatMessages[personIndex][index];
      let url =
        '/third-person-messages/' +
        origMessage.id +
        '/translation?' +
        new URLSearchParams({
          target_language: language,
          translation_option: option,
        }).toString();
      console.debug('URL is: ' + url);

      await (
        await getStreamingClient()
      ).streamFetchRequest('PATCH', url, null, (chunk: string) =>
        this.appendToIndexedChatMessageTranslation(personIndex, index, language, option, chunk),
      );
    },
    replaceChatMessage(
      personIndex: number,
      orig: ThirdPersonInteractionMessage,
      replace: ThirdPersonInteractionMessage,
    ) {
      const index = this.chatMessages[personIndex].indexOf(orig);
      if (index !== -1) {
        this.chatMessages[personIndex][index] = replace;
        return;
      }
    },
    // async getThirdPersonWelcomeMessage(personIndex: number) {
    //   if (this.requestedWelcomeMessage[personIndex]) {
    //     console.debug('Already requested welcome message. Doing nothing.');
    //     return;
    //   }
    //   await this.createPreliminaryChatMessage(personIndex, '', 'THIRD_PERSON_REACT');
    //   this.requestedWelcomeMessage[personIndex] = true;
    //   const url = '/third-person-interactions/' + this.currentThirdPersonInteractionIds[personIndex];
    //   await (
    //     await getStreamingClient()
    //   ).streamFetchRequest(
    //     'POST',
    //     url,
    //     {
    //       content: '',
    //       type: 'THIRD_PERSON_REACT',
    //       event: {
    //         type: 'INTERACTION_START',
    //       },
    //     },
    //     (chunk: string) => this.appendToLastChatMessageAndRefetchOnceFinished(personIndex, chunk),
    //   );
    // },
    async fetchHistories() {
      if (!this.currentThirdPersonInteractionIds) {
        console.error('No third person interaction ID set');
        return false;
      }
      console.debug('Fetching histories for third person interactions');
      let anyHistoryLoaded = false;
      for (let i = 0; i < this.currentThirdPersonInteractionIds.length; i++) {
        anyHistoryLoaded = anyHistoryLoaded || (await this.fetchHistory(i));
      }
      return anyHistoryLoaded;
    },
    async fetchHistory(personIndex: number): Promise<boolean> {
      console.debug(
        'Fetching history for third person interaction ' + this.currentThirdPersonInteractionIds[personIndex],
      );
      const chatMessages = await (
        await getApiClient()
      ).thirdPersonInteractions.listThirdPersonInteractionMessages(this.currentThirdPersonInteractionIds[personIndex]);

      console.debug('Retrieved ' + chatMessages.length + ' chat messages');

      // load history if length > 0
      // if any message has been loaded, we return true to indicate that history has been loaded
      let historyLoaded = false;
      if (chatMessages.length > 0) {
        this.chatMessages[personIndex] = chatMessages;
        historyLoaded = true;
      }

      return historyLoaded;
    },
    async say(personIndex: number, message: string) {
      if (!this.currentThirdPersonInteractionIds) {
        console.error('No third person interaction ID set');
        return;
      }
      await this.createPreliminaryChatMessage(personIndex, message, 'THIRD_PERSON');
      const url = '/third-person-interactions/' + this.currentThirdPersonInteractionIds[personIndex];
      let authStore = useAuthStore();
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          type: 'THIRD_PERSON',
          content: message,
          current_task: getCurrentTask(),
          language_level: authStore.currentLanguageLevel,
        },
        (chunk: string) => this.appendToLastChatMessageAndRefetchOnceFinished(personIndex, chunk),
      );
    },
    async storeUserEditedChatMessage(message: ThirdPersonInteractionMessage, userEdit: string) {
      console.debug('Storing user edit for message ' + message.id);
      console.debug('Edited content: ' + userEdit);
      // upload to DB as "user_edited_output"
      await (
        await getApiClient()
      ).thirdPersonMessages.storeUserEditForThirdPersonInteractionMessage(message.id, {
        content: userEdit,
      });
    },
    async undoSay(personIndex: number) {
      // Find the last non-undone THIRD_PERSON message
      let lastIndex = this.chatMessages[personIndex].length - 1;
      while (lastIndex >= 0) {
        const message = this.chatMessages[personIndex][lastIndex];
        if (message.type === 'THIRD_PERSON' && !message.undone_at) {
          let updatedMessage = await (
            await getApiClient()
          ).thirdPersonMessages.undoThirdPersonInteractionMessage(message.id);
          this.chatMessages[personIndex][lastIndex] = updatedMessage;
          return true;
        }
        lastIndex--;
      }
      console.log('No undoable THIRD_PERSON messages found');
      return false;
    },
    async redoSay(personIndex: number) {
      // Find the first undone THIRD_PERSON message by iterating forwards
      for (let i = 0; i < this.chatMessages[personIndex].length; i++) {
        const message = this.chatMessages[personIndex][i];
        if (message.type === 'THIRD_PERSON' && message.undone_at) {
          // send undo with revert=true to revert the undone state
          let updatedMessage = await (
            await getApiClient()
          ).thirdPersonMessages.undoThirdPersonInteractionMessage(message.id, true);
          this.chatMessages[personIndex][i] = updatedMessage;
          return true;
        }
      }
      return false;
    },
    async reset() {
      this.requestedWelcomeMessage.length = 0;

      this.currentThirdPersons.length = 0;
      this.currentThirdPersonInteractionIds.length = 0;
      this.currentCaseInteraction = null;

      this.chatMessages = [[]] as ThirdPersonInteractionMessage[][];
      this.chatIsStreaming.length = 0;
    },
  },
});
