import { defineStore } from 'pinia';
import {
  CourseInteraction,
  Course,
  Chapter,
  ChapterInteraction,
  SectionInteraction,
  SectionContentItemInteraction,
} from '@/apiclient';

import { getApiClient } from '@/apiclient/client';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { useCourseStore } from '@/stores/course.store';
import { useAlertStore } from '@/stores/alert.store';
import { useAuthStore } from '@/stores/auth.store';

interface CourseInteractionState {
  currentCourseInteraction: CourseInteraction | null;
  chapterInteractions: Record<string, ChapterInteraction>; // key: chapter_id
  sectionInteractions: Record<string, SectionInteraction>; // key: section_id
  sectionContentInteractions: Record<string, SectionContentItemInteraction>; // key: section_content_item_id

  // For maintaining order/hierarchy when needed
  chapterOrder: string[]; // chapter_ids in order
  sectionOrderByChapter: Record<string, string[]>; // chapter_id -> section_ids
  contentOrderBySection: Record<string, string[]>; // section_id -> content_item_ids

  // Other state properties
  numSectionsPerChapter: number[];
  numSectionsStartedPerChapter: number[];
  numSectionsCompletedPerChapter: number[];
  fetchingNumCompleted: number;
  fetchingNumTotal: number;

  // For page completion tracking
  pageStartedStatus: Record<string, Record<number, boolean>>; // key: sectionId -> pageIndex -> isStarted
  pageCompletionStatus: Record<string, Record<number, boolean>>; // key: sectionId -> pageIndex -> isCompleted
}

export const useCourseInteractionStore = defineStore({
  id: 'courseInteraction',
  state: () => {
    // Define default state
    const defaultState = {
      currentCourseInteraction: null as CourseInteraction | null,
      chapterInteractions: {} as Record<string, ChapterInteraction>,
      sectionInteractions: {} as Record<string, SectionInteraction>,
      sectionContentInteractions: {} as Record<string, SectionContentItemInteraction>,
      chapterOrder: [] as string[],
      sectionOrderByChapter: {} as Record<string, string[]>,
      contentOrderBySection: {} as Record<string, string[]>,
      numSectionsPerChapter: [] as number[],
      numSectionsStartedPerChapter: [] as number[],
      numSectionsCompletedPerChapter: [] as number[],
      fetchingNumCompleted: 0 as number,
      fetchingNumTotal: 0 as number,
      pageCompletionStatus: {} as Record<string, Record<number, boolean>>,
      pageStartedStatus: {} as Record<string, Record<number, boolean>>,
    };

    try {
      // Load all items at once
      const items = {
        currentCourseInteraction: localStorage.getItem('currentCourseInteraction'),
        chapterInteractions: localStorage.getItem('chapterInteractions'),
        sectionInteractions: localStorage.getItem('sectionInteractions'),
        sectionContentInteractions: localStorage.getItem('sectionContentInteractions'),
        chapterOrder: localStorage.getItem('chapterOrder'),
        sectionOrderByChapter: localStorage.getItem('sectionOrderByChapter'),
        contentOrderBySection: localStorage.getItem('contentOrderBySection'),
        numSectionsPerChapter: localStorage.getItem('numSectionsPerChapter'),
        numSectionsStartedPerChapter: localStorage.getItem('numSectionsStartedPerChapter'),
        numSectionsCompletedPerChapter: localStorage.getItem('numSectionsCompletedPerChapter'),
        pageCompletionStatus: localStorage.getItem('pageCompletionStatus'),
        pageStartedStatus: localStorage.getItem('pageStartedStatus'),
      };

      // Parse all items
      const parsed = {
        currentCourseInteraction: items.currentCourseInteraction ? JSON.parse(items.currentCourseInteraction) : null,
        chapterInteractions: items.chapterInteractions ? JSON.parse(items.chapterInteractions) : {},
        sectionInteractions: items.sectionInteractions ? JSON.parse(items.sectionInteractions) : {},
        sectionContentInteractions: items.sectionContentInteractions
          ? JSON.parse(items.sectionContentInteractions)
          : {},
        chapterOrder: items.chapterOrder ? JSON.parse(items.chapterOrder) : [],
        sectionOrderByChapter: items.sectionOrderByChapter ? JSON.parse(items.sectionOrderByChapter) : {},
        contentOrderBySection: items.contentOrderBySection ? JSON.parse(items.contentOrderBySection) : {},
        numSectionsPerChapter: items.numSectionsPerChapter ? JSON.parse(items.numSectionsPerChapter) : [],
        numSectionsStartedPerChapter: items.numSectionsStartedPerChapter
          ? JSON.parse(items.numSectionsStartedPerChapter)
          : [],
        numSectionsCompletedPerChapter: items.numSectionsCompletedPerChapter
          ? JSON.parse(items.numSectionsCompletedPerChapter)
          : [],
        pageCompletionStatus: items.pageCompletionStatus ? JSON.parse(items.pageCompletionStatus) : {},
        pageStartedStatus: items.pageStartedStatus ? JSON.parse(items.pageStartedStatus) : {},
      };

      // If we get here, all data is valid
      return {
        ...defaultState,
        ...parsed,
        fetchingNumCompleted: 0,
        fetchingNumTotal: 0,
      };
    } catch (e) {
      // On any error, clear everything
      console.error('Error loading courseInteraction store from localStorage:', e);
      const keysToRemove = [
        'currentCourseInteraction',
        'chapterInteractions',
        'sectionInteractions',
        'sectionContentInteractions',
        'chapterOrder',
        'sectionOrderByChapter',
        'contentOrderBySection',
        'numSectionsPerChapter',
        'numSectionsStartedPerChapter',
        'numSectionsCompletedPerChapter',
        'pageCompletionStatus',
        'pageStartedStatus',
      ];

      keysToRemove.forEach((key) => localStorage.removeItem(key));

      // Return clean default state
      return defaultState;
    }
  },
  getters: {
    isEnrolled: (state) => !!state.currentCourseInteraction,
    currentCourseInteractionId: (state) => (state.currentCourseInteraction ? state.currentCourseInteraction.id : null),
    numChaptersCompleted(state) {
      let counter = 0;
      if (!state.currentCourseInteraction || !state.chapterInteractions) {
        return 0;
      }
      for (const chapterId of Object.values(state.chapterInteractions)) {
        if (chapterId?.completed_at) {
          counter++;
        }
      }
      return counter;
    },
    currentChapterInteraction: (state) => {
      if (!state.currentCourseInteraction || !state.chapterInteractions) {
        return null;
      }
      // We need to use the current chapter ID to look up the interaction, not the index - record!
      const courseStore = useCourseStore();
      const currentChapterId = courseStore.currentChapterId;
      return currentChapterId ? state.chapterInteractions[currentChapterId] : null;
    },
    fetchingProgress: (state) => {
      return state.fetchingNumTotal === 0 ? 0 : state.fetchingNumCompleted / state.fetchingNumTotal;
    },
    fetchCompleted: (state) => {
      return (
        state.currentCourseInteraction != null &&
        state.chapterInteractions != null &&
        state.sectionInteractions != null &&
        state.sectionContentInteractions != null
      );
    },
    fetchCompletedAndChapterSet: (state) => {
      return (
        state.currentCourseInteraction != null &&
        state.chapterInteractions != null &&
        state.sectionInteractions != null &&
        state.sectionContentInteractions != null
      );
    },
  },
  actions: {
    async setChapterInteraction(chapterInteraction: ChapterInteraction) {
      // TODO: refine what has to be loaded/ inited at course load and what at chapter load! (e.g. sectionInteractions and sectionContentItemInteractions)
      throw new Error('Not implemented');
    },
    async setCourseInteraction(courseInteraction: CourseInteraction) {
      const courseStore = useCourseStore();

      if (!courseInteraction) {
        console.log('setCourseInteraction: No course interaction provided');
        return;
      }

      // Early return if already set
      if (this.currentCourseInteraction?.id === courseInteraction.id) {
        console.log('CourseInteraction already set.');
        return true;
      }

      // Ensure course is loaded
      if (!courseStore.currentCourse || courseStore.currentCourse.id !== courseInteraction.course_id) {
        await courseStore.setCurrentCourse(courseInteraction.course_id);
      }

      // Set and save course interaction
      this.currentCourseInteraction = courseInteraction;
      localStorage.setItem('currentCourseInteraction', JSON.stringify(courseInteraction));

      // Load interactions for current chapter if set
      if (courseStore.currentChapterId) {
        await this.setInteractionsForCurrentChapter();
      }

      return true;
    },
    async setInteractionsForCurrentChapter(forceRefetch: boolean = false) {
      const courseStore = useCourseStore();
      const currentChapter = courseStore.currentChapter;

      if (!currentChapter) {
        console.warn('No current chapter set');
        return;
      }

      // Check if we need to fetch
      if (!forceRefetch && !!this.chapterInteractions[currentChapter.id]) {
        return;
      }

      // Fetch chapter interaction
      const chapterInteraction = await (
        await getApiClient()
      ).chapters.fetchOrCreateChapterInteraction(currentChapter.id);

      // Prepare parallel section interaction fetches
      const sectionPromises = (currentChapter.sections || []).map(async (section) => {
        const sectionInteraction = await (await getApiClient()).sections.fetchOrCreateSectionInteraction(section.id);

        // Prepare parallel content item interaction fetches
        const contentPromises = (section.section_content_items || []).map(async (item) => {
          if (!item.id) {
            console.warn('No id for section content item', item);
            return null;
          }
          let contentInteraction = null;
          try {
            contentInteraction = await (
              await getApiClient()
            ).courseSectionItems.fetchOrCreateSectionContentItemInteraction(item.id);
          } catch (error) {
            // This can happen if the UI requests an interaction for deleted items that still exist in the user's local store.
            console.warn('Error fetching or creating section content item interaction', error);
            return null;
          }

          return {
            id: item.id,
            interaction: contentInteraction,
          };
        });

        // Wait for all content item interactions
        let contentInteractions = await Promise.all(contentPromises);
        // delete null interactions (e.g. if item was deleted on server)
        contentInteractions = contentInteractions.filter((content) => content !== null);

        return {
          sectionId: section.id,
          interaction: sectionInteraction,
          contentInteractions,
        };
      });

      // Wait for all section interactions
      const sectionResults = await Promise.all(sectionPromises);

      // Update store and localStorage
      this.chapterInteractions[currentChapter.id] = chapterInteraction;
      localStorage.setItem('chapterInteractions', JSON.stringify(this.chapterInteractions));

      // Update section interactions
      sectionResults.forEach((result) => {
        this.sectionInteractions[result.sectionId] = result.interaction;
        result.contentInteractions.forEach((content) => {
          this.sectionContentInteractions[content.id] = content.interaction;
        });
      });

      localStorage.setItem('sectionInteractions', JSON.stringify(this.sectionInteractions));
      localStorage.setItem('sectionContentInteractions', JSON.stringify(this.sectionContentInteractions));

      return true;
    },
    async setCourseInteractionForCurrentCourseIfEnrolled() {
      let courseStore = useCourseStore();
      if (!courseStore.currentCourse) {
        console.warn('No current course set');
        return;
      }
      let courseInteraction = await (
        await getApiClient()
      ).courses.tryGetCourseInteraction(courseStore.currentCourse.id);
      if (!courseInteraction) {
        console.log('Not enrolled for course ' + courseStore.currentCourse.id);
        await this.reset();
        return;
      }
      if (!!this.currentCourseInteraction && this.currentCourseInteraction.id !== courseInteraction.id) {
        console.log('Course interaction tree for other course found. Reset and load new tree.');
        await this.reset();
      }
      console.log(
        'Found course interaction ' + courseInteraction.id + ' for course ' + courseStore.currentCourse.id + '.',
      );
      return await this.setCourseInteraction(courseInteraction);
    },
    async enrollForCurrentCourse(t: any = null) {
      let courseStore = useCourseStore();
      if (this.isEnrolled) {
        return;
      }
      if (!courseStore.currentCourse?.id) {
        return;
      }
      let response = null;
      let courseInteraction = await (
        await getApiClient()
      ).courses.tryGetCourseInteraction(courseStore.currentCourse.id);
      if (!courseInteraction) {
        response = await (await getApiClient()).courses.createCourseInteraction(courseStore.currentCourse.id);
        courseInteraction = response['course_interaction'];
      }
      await this.setCourseInteraction(courseInteraction);

      let authStore = useAuthStore();
      if (!!response && !!response['notifications'] && !!t) {
        const alertStore = useAlertStore();
        for (const notification of response['notifications']) {
          if (notification.type === 'XP') {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          }
        }
      }
      await authStore.fetchUserXp(); // update xp
    },
    async startCurrentChapter() {
      let courseInteractionStore = useCourseInteractionStore();
      let courseStore = useCourseStore();
      if (!courseInteractionStore.currentCourseInteraction) {
        console.warn('No current course interaction found');
        return;
      }
      const store = useCourseInteractionStore();
      await store.setInteractionsForCurrentChapter(true);
      const firstSectionId = courseStore.currentChapter?.sections?.[0]?.id;
      if (!firstSectionId) {
        console.warn('No first section found');
        return;
      }
      await store.startSectionIfNotAlready(firstSectionId);
    },
    async startSectionIfNotAlready(sectionId: string) {
      let courseStore = useCourseStore();
      let courseInteractionStore = useCourseInteractionStore();
      const sectionInteraction = courseInteractionStore.sectionInteractions[sectionId];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section id ' + sectionId);
        return;
      }
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter interaction found');
        return;
      }
      console.log('sectionInteraction', sectionInteraction);
      console.log('sectionInteraction.started_at', sectionInteraction?.started_at);
      if (!!sectionInteraction?.started_at) {
        // already started
        return;
      }
      this.numSectionsStartedPerChapter[courseStore.currentChapterIndex!] += 1;
      sectionInteraction.started_at = new Date().toISOString();
      localStorage.setItem('sectionInteractions', JSON.stringify(this.sectionInteractions));
      await (
        await getApiClient()
      ).sectionInteractions.updateSectionInteraction(sectionInteraction.id, sectionInteraction);
    },
    async startSectionContentItemIfNotAlready(sectionContentItemId: string) {
      let courseInteractionStore = useCourseInteractionStore();
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter interaction found');
        return;
      }
      let sectionContentItemInteraction = courseInteractionStore.sectionContentInteractions[sectionContentItemId];
      if (sectionContentItemInteraction.started_at != null) {
        // already started
        return;
      }
      sectionContentItemInteraction.started_at = new Date().toISOString();
      localStorage.setItem('sectionContentInteractions', JSON.stringify(this.sectionContentInteractions));
      return (await getApiClient()).sectionItemInteractions.updateSectionContentItemInteraction(
        sectionContentItemInteraction.id,
        sectionContentItemInteraction,
      );
    },
    async evaluateFormStructuredExercise(sectionContentItemId: string, exerciseData: any) {
      let courseInteractionStore = useCourseInteractionStore();
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter interaction found');
        return;
      }
      let sectionContentItemInteraction = courseInteractionStore.sectionContentInteractions[sectionContentItemId];
      return (await getApiClient()).sectionItemInteractions.evaluateFormStructuredExerciseItem(
        sectionContentItemInteraction.id,
        exerciseData,
      );
    },
    async evaluateMcQuestion(sectionContentItemId: string, mcQuestionId: string, answers: Object[]) {
      let courseInteractionStore = useCourseInteractionStore();
      console.log('evaluateMcQuestion', sectionContentItemId, mcQuestionId, answers);
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter interaction found');
        return;
      }
      let sectionContentItemInteraction = courseInteractionStore.sectionContentInteractions[sectionContentItemId];
      return (await getApiClient()).sectionItemInteractions.evaluateMcQuestion(sectionContentItemInteraction.id, {
        mc_question_id: mcQuestionId,
        answers: answers,
      });
    },
    async evaluateOpenQuestion(sectionContentItemId: string, resourceId: string, questionId: string, answer: string) {
      let courseInteractionStore = useCourseInteractionStore();
      console.log('evaluateOpenQuestion', sectionContentItemId, resourceId, questionId, answer);
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter set');
        return;
      }
      let sectionContentItemInteraction = courseInteractionStore.sectionContentInteractions[sectionContentItemId];
      return (await getApiClient()).sectionItemInteractions.evaluateOpenQuestion(sectionContentItemInteraction.id, {
        resource_with_questions_id: resourceId,
        open_question_id: questionId,
        answer: answer,
      });
    },
    async setSectionContentItemCompletedIfNotAlready(
      sectionContentItemId: string,
      sectionIndex: number,
      pageIndex: number,
      t: any = null,
      forceReopen: boolean = false,
    ) {
      let courseInteractionStore = useCourseInteractionStore();
      let courseStore = useCourseStore();
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter set');
        return;
      }
      if (pageIndex == null) {
        console.warn('No valid page index set');
        return;
      }
      let sectionContentItemInteraction = courseInteractionStore.sectionContentInteractions[sectionContentItemId];
      if (
        sectionContentItemInteraction.completed_at != null &&
        sectionContentItemInteraction.reopened_at == null &&
        !forceReopen
      ) {
        // already completed and not reopened
        console.log('Already completed');
        return;
      }
      sectionContentItemInteraction.completed_at = new Date().toISOString();
      sectionContentItemInteraction.reopened_at = null;

      // check if this completes the page or even section (steps 1-5):
      courseInteractionStore = useCourseInteractionStore(); // updated store

      // - 1. get all section content item interactions for this section
      let contentItemsForSection = courseStore.currentChapter?.sections?.[sectionIndex]?.section_content_items;
      if (!contentItemsForSection) {
        console.warn('No content items found for section ' + sectionIndex);
        return;
      }

      // - 2. get all of these where content_item has same page_index
      let contentItemsForPage = contentItemsForSection.filter((item) => item.page_index === pageIndex);

      // - 3. check if all of these are completed. If yes, set pageCompleted to true
      let pageCompleted = contentItemsForPage.every(
        (item) => courseInteractionStore.sectionContentInteractions[item.id].completed_at != null,
      );
      let sectionId = courseStore.currentChapter?.sections?.[sectionIndex]?.id;
      if (pageCompleted) {
        console.log('completed page ', pageIndex, ' at sectionId ', sectionId);
        this.setPageCompletion(sectionId!, pageIndex, true);
      }

      // -  4. if yes, check if also all of the section are completed
      let sectionCompleted = false;
      if (pageCompleted) {
        sectionCompleted = contentItemsForSection.every(
          (item) => courseInteractionStore.sectionContentInteractions[item.id].completed_at != null,
        );
      }

      // - 5. if yes, mark section as completed
      if (sectionCompleted) {
        console.log('completed section', sectionId);
        await this.markSectionCompleted(sectionId!, t);
      }

      if (!this.pageStartedStatus[sectionId!]) {
        this.pageStartedStatus[sectionId!] = {};
      }
      console.log('pageIndex', pageIndex);
      this.pageStartedStatus[sectionId!][pageIndex] = true;

      // update local storage
      localStorage.setItem('sectionContentInteractions', JSON.stringify(this.sectionContentInteractions));
      console.log('send completed');
      console.log('pageCompleted', pageCompleted);
      console.log('sectionCompleted', sectionCompleted);
      return (await getApiClient()).sectionItemInteractions.updateSectionContentItemInteraction(
        sectionContentItemInteraction.id,
        sectionContentItemInteraction,
      );
    },
    async getSectionContentItemInteractionState(sectionContentItemId: string) {
      if (!sectionContentItemId) {
        console.warn('No section content item id');
        return;
      }
      let courseInteractionStore = useCourseInteractionStore();
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter set');
        return;
      }
      let interactionState = courseInteractionStore.sectionContentInteractions[sectionContentItemId];
      if (!interactionState && courseInteractionStore.fetchCompleted) {
        // fetch/create from/via API if not found and fetchCompleted is true
        interactionState = await (
          await getApiClient()
        ).courseSectionItems.fetchOrCreateSectionContentItemInteraction(sectionContentItemId);
        courseInteractionStore.sectionContentInteractions[sectionContentItemId] = interactionState;
        localStorage.setItem('sectionContentInteractions', JSON.stringify(this.sectionContentInteractions));
      }
      return interactionState;
    },
    async reopenSectionContentItem(sectionContentItemId: string) {
      let courseInteractionStore = useCourseInteractionStore();
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter set');
        return;
      }
      let sectionContentItemInteraction = courseInteractionStore.sectionContentInteractions[sectionContentItemId];

      if (sectionContentItemInteraction.completed_at == null || sectionContentItemInteraction.reopened_at != null) {
        console.log('Already completed or reopened');
        console.log('sectionContentItemInteraction', sectionContentItemInteraction);
        console.log('completed_at', sectionContentItemInteraction.completed_at);
        // not completed or already reopened
        return;
      }
      sectionContentItemInteraction.reopened_at = new Date().toISOString();
      console.log('reopened_at', sectionContentItemInteraction.reopened_at);

      localStorage.setItem('sectionContentInteractions', JSON.stringify(this.sectionContentInteractions));
      return sectionContentItemInteraction;
    },
    async markSectionCompleted(sectionId: string, t: any = null) {
      let courseInteractionStore = useCourseInteractionStore();
      let courseStore = useCourseStore();
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.sectionInteractions[sectionId];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section id ' + sectionId);
        return;
      }
      if (!!sectionInteraction?.completed_at) {
        return; // already completed
      } else {
        sectionInteraction.completed_at = new Date().toISOString();
        localStorage.setItem('sectionInteractions', JSON.stringify(this.sectionInteractions));
        let chapterIndex = courseStore.currentChapterIndex;
        this.numSectionsCompletedPerChapter[chapterIndex!] += 1;
        localStorage.setItem('numSectionsCompletedPerChapter', JSON.stringify(this.numSectionsCompletedPerChapter));
      }
      let response = await (
        await getApiClient()
      ).sectionInteractions.updateSectionInteraction(sectionInteraction.id, sectionInteraction, true);
      await this.checkCurrentChapterCompletion(t);
      let newSectionInteraction = response['section_interaction'];
      // start next section, if any
      let sectionIndex = courseStore.currentChapter?.sections?.findIndex((section) => section.id === sectionId);
      const nextSectionId = courseStore.currentChapter?.sections?.[sectionIndex + 1]?.id;
      if (nextSectionId) {
        await this.startSectionIfNotAlready(nextSectionId);
      }

      let authStore = useAuthStore();
      if (!!response['notifications'] && !!t) {
        const alertStore = useAlertStore();
        for (const notification of response['notifications']) {
          if (notification.type === 'XP') {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          }
        }
      }
      await authStore.fetchUserXp(); // update xp
    },
    async checkCurrentChapterCompletion(t: any = null) {
      const courseStore = useCourseStore();
      const currentChapter = courseStore.currentChapter;

      if (!currentChapter) {
        console.warn('No current chapter set');
        return;
      }

      const chapterInteraction = this.chapterInteractions[currentChapter.id];
      if (!chapterInteraction) {
        console.warn('No chapter interaction found');
        return;
      }

      // Check if all sections are completed
      const chapterSections = currentChapter.sections || [];
      const chapterCompleted = chapterSections.every((section) => {
        const sectionInteraction = this.sectionInteractions[section.id];
        return sectionInteraction?.completed_at != null;
      });

      if (chapterCompleted && !chapterInteraction.completed_at) {
        // Mark chapter as completed
        chapterInteraction.completed_at = new Date().toISOString();

        // Update localStorage
        localStorage.setItem('chapterInteractions', JSON.stringify(this.chapterInteractions));

        // Update API and handle response
        const response = await (
          await getApiClient()
        ).chapterInteractions.updateChapterInteraction(chapterInteraction.id, chapterInteraction);

        // Handle XP notifications if any
        const authStore = useAuthStore();
        if (response['notifications'] && t) {
          const alertStore = useAlertStore();
          for (const notification of response['notifications']) {
            if (notification.type === 'XP') {
              alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
            }
          }
        }
        await authStore.fetchUserXp();
      } else if (!chapterCompleted && chapterInteraction.completed_at) {
        // Chapter was marked complete but is no longer - update status
        chapterInteraction.completed_at = null;
        localStorage.setItem('chapterInteractions', JSON.stringify(this.chapterInteractions));
        await (
          await getApiClient()
        ).chapterInteractions.updateChapterInteraction(chapterInteraction.id, chapterInteraction);
      }
    },
    async trackFirstCaseStarted(sectionId: string) {
      const sectionInteraction = this.sectionInteractions[sectionId];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section id', sectionId);
        return;
      }

      if (sectionInteraction.first_case_started_at) {
        return;
      }

      sectionInteraction.first_case_started_at = new Date().toISOString();
      localStorage.setItem('sectionInteractions', JSON.stringify(this.sectionInteractions));

      await (await getApiClient()).sectionInteractions.updateSectionInteraction(sectionId, sectionInteraction);
    },
    async saveLastViewedForCurrentCourse(sectionId: string, pageIndex: number) {
      let courseStore = useCourseStore();
      let currentCourse = courseStore.currentCourse;
      if (!currentCourse) {
        console.warn('No current course set');
        return;
      }
      let lastViewed = {
        sectionId: sectionId,
        pageIndex: pageIndex,
      };
      localStorage.setItem('lastViewed', JSON.stringify(lastViewed));
      // TODO make persistent, send to API / DB
    },
    async getLastViewedForCurrentCourse() {
      try {
        let lastViewed = localStorage.getItem('lastViewed');
        if (!lastViewed) {
          return null;
        }
        // TODO try fetch from API / DB
        return JSON.parse(lastViewed);
      } catch (e) {
        console.error('Error getting last viewed for current course', e);
        return null;
      }
    },
    sectionStarted(sectionId: string) {
      return this.sectionInteractions[sectionId]?.started_at != null;
    },
    sectionCompleted(sectionId: string) {
      return this.sectionInteractions[sectionId]?.completed_at != null;
    },
    sectionFirstCaseStarted(sectionId: string) {
      return this.sectionInteractions[sectionId]?.first_case_started_at != null;
    },
    async reset() {
      this.currentCourseInteraction = null;
      this.chapterInteractions = {};
      this.sectionInteractions = {};
      this.sectionContentInteractions = {};
      this.chapterOrder = [];
      this.sectionOrderByChapter = {};
      this.contentOrderBySection = {};
      this.numSectionsPerChapter = [];
      this.numSectionsStartedPerChapter = [];
      this.numSectionsCompletedPerChapter = [];
      this.fetchingNumTotal = 0;
      this.fetchingNumCompleted = 0;
    },
    isPageCompleted(sectionId: string, pageIndex: number): boolean {
      return !!this.pageCompletionStatus[sectionId]?.[pageIndex];
    },
    setPageCompletion(sectionId: string, pageIndex: number, completed: boolean) {
      if (!this.pageCompletionStatus[sectionId]) {
        this.pageCompletionStatus[sectionId] = {};
      }
      this.pageCompletionStatus[sectionId][pageIndex] = completed;
      localStorage.setItem('pageCompletionStatus', JSON.stringify(this.pageCompletionStatus));
    },
    chapterIsComplete(chapterId: string) {
      const chapterInteraction = this.chapterInteractions[chapterId];
      return chapterInteraction?.completed_at != null;
    },
    currentCourseIsComplete() {
      const courseStore = useCourseStore();
      return courseStore.currentCourse?.chapters?.every((chapter) => this.chapterIsComplete(chapter.id));
    },
  },
});
