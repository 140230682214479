<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue';
import { HSDropdown, HSStaticMethods } from 'preline';
import { useI18n } from 'vue-i18n';
import { HSCopyMarkup } from 'preline';
import { getApiClient } from '@/apiclient/client';

const { t } = useI18n();
import { v4 as uuidv4 } from 'uuid';

const props = defineProps({
  maxNumber: {
    type: Number,
    default: 5,
  },
  contentForExtraction: {
    type: String,
    default: '',
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const componentId = uuidv4();
const learningObjectives = ref([] as { description: string; importance: number }[]);
const learningObjectiveComplexInputRefs = ref([] as (HTMLElement | null)[]); // may be more than an input field, e.g. input field + importance slider or alike
const isLoading = ref(false);

// define emits for notifying parent component that learning objectives have been changes
const emit = defineEmits(['change', 'requestCompute']);

function getLearningObjectives() {
  return learningObjectives.value;
}

async function extractAndSetLearningObjectives(content: string) {
  if (!content) {
    throw new Error('No content passed for learning objective extraction');
  }

  try {
    isLoading.value = true;
    // extract learning objectives from passed content
    console.debug('Extracting learning objectives from content: ' + content);
    const client = await getApiClient();
    const learningObjectives = await client.evaluation.extractLearningObjectives({ content });
    await setLearningObjectives(learningObjectives, true);
  } catch (error) {
    console.error('Error extracting learning objectives: ' + error);
    throw error;
  } finally {
    isLoading.value = false;
  }
}

function setLearningObjectives(
  newLearningObjectives: { description: string; importance: number }[],
  emitChange = false,
) {
  learningObjectives.value = newLearningObjectives;
  learningObjectives.value.forEach((objective) => {
    learningObjectiveComplexInputRefs.value.push(null);
  });
  if (emitChange) emit('change'); // only emit if changed internally, i.e. thru extract
}

function addObjective() {
  learningObjectives.value.push({ description: '', importance: 100 });
  learningObjectiveComplexInputRefs.value.push(null);
}

defineExpose({
  getLearningObjectives,
  setLearningObjectives,
  extractAndSetLearningObjectives,
  numLearningObjectives: computed(() => learningObjectives.value.length),
});
</script>

<template>
  <div v-if="isLoading" class="flex items-center justify-center h-32">
    <div
      class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    ></div>
    <p class="text-gray-800 dark:text-white ml-4">{{ $t('message.learningObjectivesBeingExtracted') }}</p>
  </div>

  <div
    class="flex rounded-lg shadow-sm"
    v-for="(objective, index) in learningObjectives"
    :key="index"
    :ref="learningObjectiveComplexInputRefs[index]"
  >
    <input
      v-if="editable"
      type="text"
      :placeholder="$t('message.enterLearningObjective')"
      @input="emit('change')"
      v-model="learningObjectives[index].description"
      class="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-s-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    />
    <span
      v-else
      class="py-3 px-4 block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    >
      {{ objective.description }}
    </span>
    <button
      v-show="editable"
      type="button"
      @click.prevent="
        learningObjectives.splice(index, 1);
        learningObjectiveComplexInputRefs.splice(index, 1);
        emit('change');
      "
      class="w-[2.875rem] h-[2.875rem] shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
    >
      <span translate="no" class="material-symbols-outlined notranslate">delete</span>
    </button>
  </div>

  <div class="mt-3 space-x-4 text-end">
    <button
      v-show="editable"
      :disabled="isLoading"
      type="button"
      @click.prevent="addObjective"
      class="py-1.5 px-2 inline-flex items-center gap-x-1 text-xs font-medium rounded-full border border-dashed border-gray-200 bg-white text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
    >
      <span translate="no" class="material-symbols-outlined notranslate">add</span
      >{{ $t('message.addLearningObjective') }}
    </button>

    <button
      v-show="editable"
      :disabled="isLoading"
      type="button"
      @click="emit('requestCompute')"
      class="mt-3 py-1.5 px-2 inline-flex items-center gap-x-1 text-xs font-medium rounded-full border border-dashed border-gray-200 bg-white text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
    >
      <!-- <span translate="no" class="material-symbols-outlined notranslate">manufacturing</span
      > -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-stars mysterious-pulse"
        viewBox="0 0 16 16"
      >
        <path
          d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"
        />
      </svg>
      {{ $t('message.extractLearningObjectives') }}
    </button>
  </div>
</template>
