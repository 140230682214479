/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvolvementType {
  NOT_PRESENT = 'NOT_PRESENT',
  ON_THE_PHONE = 'ON_THE_PHONE',
  IN_THE_ROOM = 'IN_THE_ROOM',
  UNKNOWN = 'UNKNOWN',
}
