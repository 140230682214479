<script setup lang="ts">
const { isLoading, buttonText } = defineProps(['isLoading', 'buttonText']);
const emits = defineEmits(['onClicked']);
</script>

<template>
  <button
    type="button"
    class="py-3 px-2 flex w-full items-center rounded-full items-center text-base gap-x-2 font-medium border border-blue-600 text-blue-600 hover:border-blue-700 hover:text-blue-700 bg-white hover:bg-blue-50 disabled:opacity-50 disabled:pointer-events-none"
    @click="emits('onClicked')"
  >
    <div
      v-if="isLoading"
      class="animate-spin inline-block w-4 h-4 text-white rounded-full"
      role="status"
      aria-label="loading"
    >
      <span class="sr-only">Loading</span>
    </div>
    <div class="flex min-w-full gap-x-3 text-sm items-center justify-center" v-else>
      <span translate="no" class="material-symbols-outlined notranslate text-2xl -m-2">add</span>
      {{ buttonText }}
    </div>
  </button>
</template>

<style scoped></style>
