/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventType {
  LEAVE_TASK = 'LEAVE_TASK',
  BEGIN_TASK = 'BEGIN_TASK',
  REVISIT_TASK = 'REVISIT_TASK',
  SUBMIT_DIAGNOSIS = 'SUBMIT_DIAGNOSIS',
  SCORE_GENERAL_ANAMNESIS_CONTENTS = 'SCORE_GENERAL_ANAMNESIS_CONTENTS',
  SCORE_GENERAL_ANAMNESIS_LANGUAGE = 'SCORE_GENERAL_ANAMNESIS_LANGUAGE',
  SCORE_MEDICATION_ANAMNESIS_CONTENTS = 'SCORE_MEDICATION_ANAMNESIS_CONTENTS',
  SCORE_MEDICATION_ANAMNESIS_LANGUAGE = 'SCORE_MEDICATION_ANAMNESIS_LANGUAGE',
  SCORE_ALLERGY_ANAMNESIS_CONTENTS = 'SCORE_ALLERGY_ANAMNESIS_CONTENTS',
  SCORE_ALLERGY_ANAMNESIS_LANGUAGE = 'SCORE_ALLERGY_ANAMNESIS_LANGUAGE',
  SCORE_SOCIAL_ANAMNESIS_CONTENTS = 'SCORE_SOCIAL_ANAMNESIS_CONTENTS',
  SCORE_SOCIAL_ANAMNESIS_LANGUAGE = 'SCORE_SOCIAL_ANAMNESIS_LANGUAGE',
  SCORE_SUBSTANCE_ANAMNESIS_CONTENTS = 'SCORE_SUBSTANCE_ANAMNESIS_CONTENTS',
  SCORE_SUBSTANCE_ANAMNESIS_LANGUAGE = 'SCORE_SUBSTANCE_ANAMNESIS_LANGUAGE',
  SCORE_PROPOSED_PROCEDURE_CONTENTS = 'SCORE_PROPOSED_PROCEDURE_CONTENTS',
  SCORE_PROPOSED_PROCEDURE_LANGUAGE = 'SCORE_PROPOSED_PROCEDURE_LANGUAGE',
  REQUEST_TIP_FOR_CASE = 'REQUEST_TIP_FOR_CASE',
  CASE_DISCUSSION = 'CASE_DISCUSSION',
}
