/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dialog } from '../models/Dialog';
import type { DialogCreate } from '../models/DialogCreate';
import type { DialogUpdate } from '../models/DialogUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DialogsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Dialog
   * @param requestBody
   * @returns Dialog Successful Response
   * @throws ApiError
   */
  public createDialog(requestBody: DialogCreate): CancelablePromise<Dialog> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/dialogs/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Dialog
   * @param dialogId
   * @returns Dialog Successful Response
   * @throws ApiError
   */
  public getDialog(dialogId: string): CancelablePromise<Dialog> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/dialogs/{dialog_id}',
      path: {
        dialog_id: dialogId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Dialog
   * Update an existing dialog.
   *
   * If any message content or voice changes, new audio will be
   * generated.
   * @param dialogId
   * @param requestBody
   * @returns Dialog Successful Response
   * @throws ApiError
   */
  public updateDialog(dialogId: string, requestBody: DialogUpdate): CancelablePromise<Dialog> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/dialogs/{dialog_id}',
      path: {
        dialog_id: dialogId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
