<script setup lang="ts">
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import PersonCard from '@/components/new_case/sections/PersonCard.vue';

interface Person {
  id: number;
  details: Object;
}

const { isLoading, requireAtLeastOnePatient } = defineProps(['isLoading', 'requireAtLeastOnePatient']);
const persons = defineModel({
  type: Array as () => Array<Person>,
  required: true,
});
const personCards = ref([] as any[]);
const personCardsAreValid = ref([] as boolean[]);
const emit = defineEmits(['scrollToBottom', 'isValid']);

const scrollContainer = ref(null);

function centerPerson(item: any) {
  const container = scrollContainer.value;

  if (!container) {
    return;
  }

  // Get the container's width and scroll position
  const containerWidth = container.clientWidth;
  const containerScrollLeft = container.scrollLeft;

  // Get the item's width and position relative to the container
  const itemWidth = item.clientWidth;
  const itemOffsetLeft = item.offsetLeft;

  // Calculate the scroll position to center the item
  const scrollTo = itemOffsetLeft - containerWidth / 2 + itemWidth / 2;
  console.log('scrollTo', scrollTo);

  if (scrollTo < 0) {
    return;
  }

  // Smoothly scroll the container to the calculated position
  container.scroll({
    left: scrollTo,
    behavior: 'smooth',
  });
}

// onMounted(() => {
//   personCardsAreValid.value.length = 0;
//   for (let i = 0; i < persons.value.length; i++) {
//     personCardsAreValid.value.push(personCards.value.allValid);
//   }
//   emit('isValid', isValid.value);
// });

watch(
  () => personCards,
  (newValue) => {
    personCardsAreValid.value.length = 0;
    for (let i = 0; i < persons.value.length; i++) {
      personCardsAreValid.value.push(personCards.value.allValid);
    }
    emit('isValid', isValid.value);
  },
);

const noPatient = computed(() => {
  if (!requireAtLeastOnePatient) {
    return false;
  }
  if (!persons.value) {
    return true;
  }
  let noPatient = !persons.value.some((person) => person.details?.role?.value === 'PATIENT');
  console.log('noPatient', noPatient);
  return noPatient;
});

const moreThanOnePatient = computed(() => {
  if (!persons.value) {
    return false;
  }
  let moreThanOnePatient = persons.value.filter((person) => person.details?.role?.value === 'PATIENT').length > 1;
  console.log('moreThanOnePatient', moreThanOnePatient);
  return moreThanOnePatient;
});

const isValid = computed(() => {
  console.log('!!personCardsAreValid', !!personCardsAreValid.value);
  console.log('!noPatient', !noPatient.value);
  console.log(
    'checking all cards',
    personCardsAreValid.value.every((isValid) => isValid),
  );
  console.log('number of cards', personCardsAreValid.value.length);
  if (personCardsAreValid.value.length === 0) {
    // console.log('no persons, returning false');
    return false;
  }
  console.log(
    persons.value.length,
    !!personCardsAreValid.value,
    !noPatient.value,
    !moreThanOnePatient.value,
    personCardsAreValid.value.every((isValid) => isValid),
  );
  console.log('!moreThanOnePatient', !moreThanOnePatient.value);
  let isValid =
    persons.value.length &&
    !!personCardsAreValid.value &&
    !noPatient.value &&
    !moreThanOnePatient.value &&
    personCardsAreValid.value.every((isValid) => isValid);
  emit('isValid', isValid);
  console.log('emittted');
  return isValid;
});

watch(
  () => isValid.value,
  (isValid) => {
    console.log('isValid (review all persons) emitting', isValid);
    emit('isValid', isValid);
  },
);
</script>

<template>
  <div class="py-8 grid grid-cols-11 gap-y-1">
    <!--    personCardsAreValid: {{ personCardsAreValid }}# isValid: {{ isValid }}#-->
    <h2 class="col-span-11 inline-block text-xl mt-2.5 font-bold text-gray-800 dark:text-gray-200">
      Bitte überprüfe kurz die detaillierten Profile deiner Personen
    </h2>
    <div class="col-span-11">
      <div class="text-gray-600 dark:text-gray-400 text-sm">
        Wir haben für alle beteiligten Personen entsprechend deiner Beschreibung ein Profil erzeugt. Dies umfasst eine
        kurze Biographie und Details dazu, wie die Person sich ausdrückt. Für Patient:innen haben wir eine detaillierte
        Krankengeschichte angelegt. Bitte überprüf kurz, ob Profile und Krankengeschicht(en) deinen Wünschen
        entsprechen!
      </div>
      <div class="mt-6 col-span-11 w-full inline-flex overflow-x-auto gap-x-2" ref="scrollContainer">
        <PersonCard
          :ref="personCards[index]"
          v-for="(person, index) in persons"
          :key="person.create_id"
          v-model="persons[index]"
          :allowEdit="true"
          :allowRemove="false"
          :requireCompleteness="true"
          :alwaysShowDetails="true"
          :alwaysShowPatientDetails="true"
          @centerMe="centerPerson($event.target)"
          @scrollToBottom="emit('scrollToBottom')"
          @isValid="personCardsAreValid[index] = $event"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
