<script setup lang="ts">
import { getApiClient } from '@/apiclient/client';
import TextEditor from '@/views/courses/TextEditor.vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useCourseStore } from '@/stores';
import { useAlertStore } from '@/stores';
import { onBeforeRouteLeave } from 'vue-router';

const courseStore = useCourseStore();
const alertStore = useAlertStore();

const emit = defineEmits(['scrollTo', 'onToggleIsEditing']);
const titleEditor = ref(null);
const subtitleEditor = ref(null);

const props = defineProps({
  allowEditing: {
    type: Boolean,
    required: false,
    default: true,
  },
  allowToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  chapter: {
    type: Object,
    required: true,
  },
});

const titleHasUnsavedChanges = ref(false);
const descriptionHasUnsavedChanges = ref(false);
const isSavingChanges = ref(false);
const unsavedChanges = computed(() => {
  return titleHasUnsavedChanges.value || descriptionHasUnsavedChanges.value;
});

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.allowEditing) return;
      setTimeout(() => {
        saveTitleAndDescription();
      }, 3000);
    }
  },
);

onMounted(() => {
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      saveTitleAndDescription();
      return undefined;
    }
  };
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveTitleAndDescription();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const saveTitleAndDescription = async () => {
  if (!props.allowEditing) return;
  if (!titleEditor.value) return;
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;
  courseStore
    .updateChapter(props.chapter.id, {
      title: titleEditor.value.getRawTextContent(),
      description: subtitleEditor.value.getRawTextContent(),
    })
    .then(() => {
      titleHasUnsavedChanges.value = false;
      descriptionHasUnsavedChanges.value = false;
      alertStore.success('Titel und Beschreibung gespeichert', 'Gespeichert');
      if (!!titleEditor.value) titleEditor.value.resetEmitState();
      if (!!subtitleEditor.value) subtitleEditor.value.resetEmitState();
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave title', 'Error', error);
      throw new Error('Failed to autosave title');
    })
    .finally(() => {
      isSavingChanges.value = false;
    });
};

defineExpose({
  titleEditor,
  subtitleEditor,
});
</script>

<template>
  <!-- Blog Article -->
  <div
    class="w-full flex-col flex px-4 pt-6 lg:pt-10 pb-6 lg:pb-10 sm:px-6 lg:px-8 mx-auto bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 justify-center"
  >
    <div class="w-full flex-col flex">
      <!-- Content -->
      <div class="space-y-5 md:space-y-8 flex-col flex">
        <div class="space-y-3 justify-center flex-col flex">
          <h2 class="text-4xl text-center flex-col flex font-bold md:text-5xl dark:text-white">
            <div class="inline-flex justify-between items-center w-full">
              <div class="w-[35px]"></div>

              <div class="text-gray-300 font-bold text-3xl">
                {{ props.chapter.index + 1 }}
              </div>

              <div class="inline-flex relative justify-start text-base items-center" v-show="props.allowToggleEditing">
                <span class="absolute font-normal end-12 text-gray-500">Bearbeiten </span>
                <input
                  :checked="props.allowEditing"
                  @change="emit('onToggleIsEditing', $event.target.checked)"
                  :disabled="props.disableToggleEditing"
                  type="checkbox"
                  id="hs-xs-switch-edit-chapter"
                  class="relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-600 before:inline-block before:w-4 before:h-4 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
                />
              </div>
            </div>

            <TextEditor
              ref="titleEditor"
              :content="props.chapter.title"
              :allowFormatting="false"
              @unsavedChanges="titleHasUnsavedChanges = true"
              @changesCleared="titleHasUnsavedChanges = false"
              :allow-edit="props.allowEditing"
              editor-classes="pb-1"
            />
          </h2>

          <p class="text-base pt-2 text-gray-800 text-center dark:text-gray-200">
            <span class="hidden uppercase tracking-wide"> Abstract </span>
            <TextEditor
              ref="subtitleEditor"
              :allowFormatting="false"
              :content="props.chapter.description"
              @unsavedChanges="descriptionHasUnsavedChanges = true"
              @changesCleared="descriptionHasUnsavedChanges = false"
              :allow-edit="props.allowEditing"
            />
          </p>

          <!--          <p class="text-base pt-3 text-gray-800 text-center dark:text-gray-200">-->
          <!--            <span class="uppercase tracking-wide"> Inhalt </span>-->
          <!--            <ChapterTableOfContents-->
          <!--              :sections="props.chapter.sections"-->
          <!--              :chapterIndex="props.chapter.index"-->
          <!--              @scrollTo="(sectionTitle) => emit('scrollTo', sectionTitle)"-->
          <!--            />-->
          <!--          </p>-->
        </div>
      </div>
      <!-- End content -->
    </div>
  </div>
  <!-- End Blog Article -->
</template>

<style scoped></style>
