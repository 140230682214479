/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ObserverDetails = {
  age: number;
  sex: ObserverDetails.sex;
  first_name: string;
  last_name: string;
  style_of_speech?: string;
};

export namespace ObserverDetails {
  export enum sex {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    DIVERSE = 'DIVERSE',
  }
}
