<script setup>
import { ref, nextTick, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import {
  useAlertStore,
  useAuthStore,
  useCaseInteractionStore,
  useLanguageStore,
  usePatientInteractionStore,
} from '@/stores';
import { storeToRefs } from 'pinia';
import { getApiClient } from '@/apiclient/client';
import ProgressButton from '@/components/ProgressButton.vue';
import { updateMinTextareaHeight, VocabMode } from '@/helper';
import { HSOverlay } from 'preline';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import TextareaWithAudioInput from '@/components/inputs/TextareaWithAudioInput.vue';

const { t } = useI18n();

const props = defineProps({
  vocabItems: { type: Array, required: true },
  overlayId: { type: String, required: true },
  heading: { type: String, default: 'Beispielsätze bilden' },
  promptMessage: { type: String, default: 'Chat window goes here' },
  closeMessage: { type: String, default: 'Zurück' },
  submitMessage: { type: String, default: 'Lösungen zeigen' },
  submitAlternativeMessage: { type: String, default: 'Lösungen verbergen' },
  getNewVocabsMessage: { type: String, default: 'Neue Vokabeln' },
  width: { type: Number, default: 0.3 },
  height: { type: Number, default: 0.7 },
  showSolutions: { type: Boolean, default: false },
  nVocabs: { type: Number, default: 8 },
  defaultDifficulty: { type: Number, default: 2 },
});

const placeholderVariants = [
  'Bilde einen Beispielsatz mit "{term}!"',
  'Verwende "{term}" in einem Beispielsatz',
  'Wie würdest du "{term}" in einem Satz verwenden?',
  'Kannst du einen Satz mit {term} bilden?',
  'Kannst du "{term}" in einem Beispielsatz verwenden?',
  'Fällt dir ein Beispielsatz mit "{term}" ein?',
  'Überlege dir einen Beispielsatz mit "{term}"',
];

const emit = defineEmits(['closed']);

// Main cpt refs
const modal = ref(null); // we need this for the .close(). Ugly.
const testSessionId = ref(null);

// Stores and store references
const alertStore = useAlertStore();
const authStore = useAuthStore();

const { favoriteVocabList } = storeToRefs(authStore);

// Refs for component data
const vocabs = ref([]);
const isFavorite = ref([]);
const addingToFavorite = ref([]);
const hideIndividualSolution = ref([]);
const initiallyHideIndividualVocab = ref([]);
const exampleSentenceIsCorrect = ref([]);
const exampleSentenceIsWrong = ref([]);
const termIsCorrect = ref([]);
const termIsWrong = ref([]);
const isSubmitting = ref([]);
const showingSolutions = ref(props.showSolutions);
const showingFeedback = ref(false);
const difficulty = ref(props.defaultDifficulty);
const isLoadingNewVocab = ref(false);
const isLoadingFeedback = ref(false);
const resolvePromise = ref(null);
const makeSentenceAttempt = ref(Array.from({ length: props.nVocabs }, () => ''));
const findTermAttempt = ref(Array.from({ length: props.nVocabs }, () => ''));
const randomPlaceholders = ref(Array.from({ length: props.nVocabs }, () => 'Bilde einen Beispielsatz'));
const modalWindow = ref(null);
const modalWindowOverflowsX = ref(false);

// Computed-like functions
const hashtagOverlayId = computed(() => `#${props.overlayId}`);
const showOrHideSolutionsMessage = computed(() =>
  showingSolutions.value ? props.submitAlternativeMessage : props.submitMessage,
);
const hsOverlayClass = computed(() =>
  showingFeedback.value
    ? 'hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all lg:max-w-6xl lg:w-full m-3 lg:mx-auto h-[calc(100%-3.5rem)]'
    : 'hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all lg:max-w-3xl lg:w-full m-3 lg:mx-auto h-[calc(100%-3.5rem)]',
);
const isLoading = computed(() => isLoadingNewVocab.value || isLoadingFeedback.value);

const computeOverflow = () => {
  if (!modalWindow.value) return;
  modalWindowOverflowsX.value = modalWindow.value.scrollWidth > modalWindow.value.clientWidth;
};

// Lifecycle hooks
onMounted(async () => {
  await HSOverlay.autoInit(); // we need to do this in order to make modals for components work which are mounted AFTER the app is initialized and AFTER the router re-initialized HSStaticMethods.autoInit()

  // Get the modal element and initialize it
  const modalElement = document.querySelector(hashtagOverlayId.value);
  modal.value = HSOverlay.getInstance(modalElement);

  // If no instance exists, create one
  if (!modal.value) {
    modal.value = new HSOverlay(modalElement);
  }

  await nextTick();

  modal.value.addEventListener('close.hs.overlay', () => {
    console.debug('closing dialog');
    emit('closed');
  });

  await getNewVocabs();
  computeOverflow();
});

onBeforeUnmount(() => {});

watch(
  () => modalWindow.value,
  async () => {
    await nextTick();
    computeOverflow();
  },
);

watch(
  () => showingSolutions.value,
  async () => {
    await nextTick();
    computeOverflow();
  },
);

watch(
  () => showingFeedback.value,
  async () => {
    await nextTick();
    computeOverflow();
  },
);

// Methods

const takeTest = async () => {
  await nextTick(); // Ensure DOM updates are complete. Without this, modal window itself opens and closes, but backdrop remains there

  // HSOverlay.open(modalRef.value);  // works
  // modal.value.open();  // does NOT work - even though close does
  console.log(hashtagOverlayId.value);
  console.log(modal.value);
  HSOverlay.open(hashtagOverlayId.value); // works

  testSessionId.value = uuidv4();

  console.log('opened modal');
};

async function handleShowOrHide() {
  showingSolutions.value = !showingSolutions.value;
  showingFeedback.value = !showingFeedback.value;

  hideIndividualSolution.value.length = 0;
  vocabs.value.forEach((item) => {
    hideIndividualSolution.value.push(!showingSolutions.value);
  });
}

async function getNewVocabs() {
  if (!props.vocabItems) return;
  isLoadingNewVocab.value = true;
  showingSolutions.value = props.showSolutions;
  showingFeedback.value = false;

  // randomly show nVocabs from vocabItems
  let localVocabItems = JSON.parse(JSON.stringify(props.vocabItems));
  localVocabItems = localVocabItems.sort(() => Math.random() - 0.5).slice(0, props.nVocabs);

  vocabs.value = localVocabItems.map((item) => {
    return {
      id: item.id,
      term: item.term,
      usage_examples: item.usage_examples,
      feedback: '',
    };
  });
  exampleSentenceIsCorrect.value = Array.from({ length: vocabs.value.length }, () => false);
  exampleSentenceIsWrong.value = Array.from({ length: vocabs.value.length }, () => false);
  termIsCorrect.value = Array.from({ length: vocabs.value.length }, () => false);
  termIsWrong.value = Array.from({ length: vocabs.value.length }, () => false);
  isSubmitting.value = Array.from({ length: vocabs.value.length }, () => false);
  isFavorite.value = Array.from({ length: vocabs.value.length }, () => false);
  isFavorite.value.length = 0;
  vocabs.value.forEach((vocab) => {
    isFavorite.value.push(authStore.isFavorite(vocab));
  });

  randomPlaceholders.value.length = 0;
  vocabs.value.forEach((item) => {
    randomPlaceholders.value.push(getRandomPlaceholder(item.term));
  });

  setInitialVocabVisibility();
  isLoadingNewVocab.value = false;
}

watch(
  () => props.vocabItems,
  async (newVal) => {
    if (!!newVal) {
      await getNewVocabs();
    }
  },
  { deep: true },
);

const addFavorite = async (index) => {
  await (
    await getApiClient()
  ).vocabLists
    .copyVocabToPersonalVocabList(favoriteVocabList.value.id, vocabs.value[index].id)
    .then(async (response) => {
      alertStore.success('Vocabulary added to your Wortschatzkiste');
      // play the add animation, so:
      addingToFavorite.value[index] = true;
      // wait 300 ms
      await new Promise((resolve) => setTimeout(resolve, 300));
      addingToFavorite.value[index] = false;
      isFavorite.value[index] = true;
    })
    .catch((error) => {
      alertStore.error('Failed to add vocab item to favorites', 'Error', error);
    });
};

watch(
  () => props.nVocabs,
  async (newVal) => {
    makeSentenceAttempt.value.length = 0;
    for (let i = 0; i < newVal; i++) {
      makeSentenceAttempt.value.push('');
      findTermAttempt.value.push('');
    }
  },
);

function setInitialVocabVisibility() {
  if (!showingSolutions.value) {
    hideIndividualSolution.value.length = 0;
    vocabs.value.forEach((item) => {
      hideIndividualSolution.value.push(true);
    });
  }
}

const getRandomEmoji = (answerIsCorrect) => {
  const positiveEmojis = ['😊', '🎉', '👍', '👏', '😄', '😁', '🥳', '💪'];
  const motivationalEmojis = ['🤔', '😌', '🙂', '🌱', '🔄', '🌟', '✊'];

  if (answerIsCorrect) {
    return positiveEmojis[Math.floor(Math.random() * positiveEmojis.length)];
  } else {
    return motivationalEmojis[Math.floor(Math.random() * motivationalEmojis.length)];
  }
};

async function sendAttempt(index, attempt) {
  console.log('make sentence attempt: ', attempt);
  if (!hideIndividualSolution.value[index]) {
    return;
  }

  isSubmitting.value[index] = true;

  let response = await (
    await getApiClient()
  ).evaluation
    .evaluateVocabMakeSentenceFromTermSingle({
      term: vocabs.value[index].term,
      user_sentence: attempt,
      vocab_item_id: vocabs.value[index].id,
      test_session_id: testSessionId.value,
    })
    .finally(() => {
      isSubmitting.value[index] = false;
    });

  vocabs.value[index].feedback = response.feedback + ' ' + getRandomEmoji(response.is_correct);
  if (response.is_correct) {
    exampleSentenceIsCorrect.value[index] = true;
    exampleSentenceIsWrong.value[index] = false;
  } else {
    exampleSentenceIsCorrect.value[index] = false;
    exampleSentenceIsWrong.value[index] = true;
  }

  alertStore.xp(t(response.notification.message), t('message.receivedXP', response.notification.xp));
  await authStore.fetchUserXp();

  hideIndividualSolution.value[index] = false;
  showingFeedback.value = true;
}

const getRandomElement = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

function getRandomPlaceholder(term) {
  const selectedVariant = getRandomElement(placeholderVariants);
  return selectedVariant.replace('{term}', term);
}

const scrollRight = () => {
  modalWindow.value.scrollTo({
    left: modalWindow.value.scrollWidth,
    behavior: 'smooth',
  });
};

defineExpose({
  takeTest,
});
</script>

<template>
  <div
    :id="overlayId"
    class="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[100] overflow-hidden pointer-events-none"
  >
    <div data-testid="make-sentences-modal" :class="hsOverlayClass">
      <div
        ref="modalWindow"
        class="max-h-full px-2 flex flex-col bg-white overflow-auto border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-gray-700 dark:shadow-neutral-700/[.7]"
      >
        <!-- upper right corner "X" button -->
        <div class="flex justify-between items-center px-1 py-3">
          <h3 class="font-bold text-gray-800 dark:text-white">
            {{ heading }}
          </h3>
          <button
            data-testid="make-sentences-top-close-button"
            type="button"
            class="pointer-events-auto hs-dropup-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
            :data-hs-overlay="hashtagOverlayId"
          >
            <span class="sr-only">Close</span>
            <svg
              class="w-3.5 h-3.5"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <!-- end of "X" button -->

        <!-- modal contents -->
        <div class="autofocus pointer-events-auto">
          <table
            class="table-fixed min-w-full divide-y divide-gray-200 dark:divide-gray-700 border-t border-gray-200 dark:border-gray-700"
          >
            <thead class="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th
                  scope="col"
                  class="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase dark:text-gray-400"
                >
                  Begriff / Redemittel
                </th>
                <th
                  scope="col"
                  class="px-1 py-3 text-left min-w-[200px] text-xs font-medium text-gray-500 uppercase dark:text-gray-400"
                >
                  Beispielsatz
                  <div
                    v-show="modalWindowOverflowsX"
                    class="inline-flex items-center px-4 text-blue-600 hover:text-blue-700"
                    @click="scrollRight"
                  >
                    <span class="text-xs">Feedback zeigen</span>
                    <span class="material-symbols-outlined">arrow_right_alt</span>
                  </div>
                </th>
                <th v-if="showingFeedback" scope="col" class="px-1 py-3">
                  <span class="sr-only">Feedback</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
              <tr v-for="(item, index) in vocabs">
                <td
                  :data-testid="`make-sentences-table-row-${index}`"
                  class="px-1 py-2 mt-2 inline-flex items-center gap-x-2 whitespace-normal text-xs md:text-sm font-medium text-gray-800 dark:text-gray-200"
                >
                  {{ item.term }}
                  <span
                    @click="addFavorite(index)"
                    v-show="!isFavorite[index]"
                    :style="{
                      fontVariationSettings: addingToFavorite[index] ? `'FILL' 1` : `'FILL' 0`,
                      fontFamily: 'Material Symbols Outlined',
                    }"
                    translate="no"
                    :class="{ 'animate-grow-pop': addingToFavorite[index] }"
                    class="flex w-24 items-center text-center material-symbols-outlined notranslate cursor-pointer text-gray-400 hover:text-gray-500"
                  >
                    star
                  </span>
                </td>
                <td
                  class="relative px-1 py-2 items-center whitespace-normal text-xs md:text-sm gap-x-2 text-gray-800 dark:text-gray-200"
                >
                  <div v-if="!hideIndividualSolution[index] || showingSolutions">
                    <div
                      :class="{
                        'text-teal-500': exampleSentenceIsCorrect[index] && showingFeedback,
                        'text-red-500': exampleSentenceIsWrong[index] && showingFeedback,
                      }"
                    >
                      {{ makeSentenceAttempt[index] }}
                    </div>
                    <div v-show="makeSentenceAttempt[index] === '' && showingFeedback">
                      <span class="italic">Du könntest zum Beispiel sagen: </span>
                      {{ getRandomElement(item.usage_examples) }}
                    </div>
                  </div>
                  <div v-else class="relative">
                    <TextareaWithAudioInput
                      v-model="makeSentenceAttempt[index]"
                      :placeholder="randomPlaceholders[index]"
                      :disabled="isSubmitting[index]"
                      class="hidden md:flex border-gray-200 px-1 w-full py-2 bg-transparent resize-none rounded-lg text-xs md:text-sm"
                      :rows="3"
                    >
                      <template #actions>
                        <button
                          @click="sendAttempt(index, makeSentenceAttempt[index])"
                          type="button"
                          :disabled="!makeSentenceAttempt[index] || isSubmitting[index]"
                          class="z-10 rounded-md m-0.5 h-7 w-7 flex-col flex"
                          :class="[
                            isSubmitting[index] || !makeSentenceAttempt[index]
                              ? 'bg-gray-500 focus:ring-2 cursor-not-allowed focus:ring-blue-600 focus:z-10 focus:outline-none'
                              : 'bg-blue-600 hover:bg-blue-700',
                          ]"
                        >
                          <span
                            v-show="!isSubmitting[index]"
                            translate="no"
                            class="-mt-1 material-symbols-outlined notranslate text-white text-3xl"
                          >
                            send
                          </span>
                          <span
                            v-show="isSubmitting[index]"
                            class="animate-spin inline-block mt-1 ml-1 w-5 h-5 border-[2px] border-current border-t-transparent text-white rounded-full"
                            role="status"
                            aria-label="loading"
                          />
                        </button>
                      </template>
                    </TextareaWithAudioInput>
                    <TextareaWithAudioInput
                      v-model="makeSentenceAttempt[index]"
                      :placeholder="randomPlaceholders[index]"
                      :disabled="isSubmitting[index]"
                      class="flex md:hidden border-gray-200 px-1 w-full py-2 bg-transparent resize-none rounded-lg text-xs md:text-sm"
                      :rows="3"
                    >
                      <template #actions>
                        <button
                          @click="sendAttempt(index, makeSentenceAttempt[index])"
                          type="button"
                          :disabled="!makeSentenceAttempt[index] || isSubmitting[index]"
                          class="z-10 rounded-md m-0.5 h-7 w-7 flex-col flex"
                          :class="[
                            isSubmitting[index] || !makeSentenceAttempt[index]
                              ? 'bg-gray-500 focus:ring-2 cursor-not-allowed focus:ring-blue-600 focus:z-10 focus:outline-none'
                              : 'bg-blue-600 hover:bg-blue-700',
                          ]"
                        >
                          <span
                            v-show="!isSubmitting[index]"
                            translate="no"
                            class="-mt-1 material-symbols-outlined notranslate text-white text-3xl"
                          >
                            send
                          </span>
                          <span
                            v-show="isSubmitting[index]"
                            class="animate-spin inline-block mt-1 ml-1 w-5 h-5 border-[2px] border-current border-t-transparent text-white rounded-full"
                            role="status"
                            aria-label="loading"
                          />
                        </button>
                      </template>
                    </TextareaWithAudioInput>
                  </div>
                </td>
                <td
                  v-if="showingFeedback"
                  class="px-1 py-2 items-center whitespace-normal min-w-[200px] max-w-[300px] text-xs md:text-sm text-gray-800 dark:text-gray-200"
                >
                  <div class="text-xs md:text-sm text-gray-800 dark:text-gray-200">
                    {{ item.feedback !== '' ? item.feedback : ' ' }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- end of modal contents -->

        <!-- modal footer buttons -->
        <div class="pointer-events-auto flex justify-end items-center gap-x-2 py-3 px-1 border-t dark:border-gray-700">
          <!-- Get new button -->
          <div class="h-fit w-full inline-flex justify-between">
            <div class="inline-flex gap-x-2">
              <div>
                <ProgressButton
                  :showProgress="isLoadingNewVocab"
                  :onclick="getNewVocabs"
                  color="blue"
                  :disabled="isLoading"
                  type="button"
                  :text="getNewVocabsMessage"
                />
              </div>
              <!-- End of get new button -->

              <!-- Submit button -->
              <div>
                <ProgressButton
                  v-if="false"
                  :showProgress="isLoadingFeedback"
                  :onclick="handleShowOrHide"
                  color="white"
                  :disabled="isLoading"
                  type="button"
                  :text="showOrHideSolutionsMessage"
                  data-testid="make-sentences-show-hide-solutions"
                />
              </div>
            </div>
            <!-- End of submit button -->

            <!-- back to case bottom button -->
            <div>
              <a
                class="py-2 px-3 text-xs md:text-sm min-w-16 md:min-w-24 min-h-8 md:min-h-10 h-full text-center inline-flex justify-center items-center gap-2 rounded-lg border border-transparent font-medium bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
                href="#"
                :data-hs-overlay="hashtagOverlayId"
                data-testid="make-sentences-bottom-close-button"
                @click.stop="close"
              >
                {{ closeMessage }}
              </a>
            </div>
          </div>
          <!-- end of back to case bottom button -->
        </div>
        <!-- end of modal footer buttons -->
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes grow-pop {
  0% {
    font-size: 24px; /* Initial text size */
    opacity: 1;
  }
  50% {
    font-size: 30px; /* Pop out to larger size */
    opacity: 1;
  }
  100% {
    font-size: 0px; /* Shrink to disappear */
    opacity: 0;
  }
}

.animate-grow-pop {
  animation: grow-pop 300ms ease-out;
}
</style>
