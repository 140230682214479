<script setup>
import { reactive, computed, ref, watch, onMounted } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { getApiClient } from '@/apiclient/client';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const authStore = useAuthStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const feedback = ref(null);
const isSolved = ref(false);
const isSavingChanges = ref(false);

const props = defineProps({
  contentItem: {
    type: Object,
    required: false,
  },
  sectionIndex: {
    type: Number,
    required: false,
  },
  allowSubmit: {
    type: Boolean,
    required: false,
    default: true,
  },
  isEditingSolution: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  forCase: {
    type: Boolean,
    required: false,
  },
});

const unsavedChanges = ref(false);
const internalIsLoading = ref(!props.isEditingSolution);
const isLoading = computed(() => props.isLoading || internalIsLoading.value || isGettingFeedback.value);
const isGettingFeedback = ref(false);
const showingResults = ref(false);

const categories = [
  {
    name: 'Essen',
    key: 'essen',
    options: [
      { label: 'unabhängig', points: 10 },
      { label: 'braucht etwas Hilfe (Brot, Fleisch schneiden)', points: 5 },
      { label: 'unselbständig (Essen muss angereicht werden)', points: 0 },
    ],
  },
  {
    name: 'Bett- / (Roll-)Stuhltransfer',
    key: 'transfer',
    options: [
      { label: 'unabhängig', points: 15 },
      { label: 'geringe Hilfen bzw. Beaufsichtigung', points: 10 },
      { label: 'erhebliche Hilfen bei Transfer, Lagewechsel', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Waschen',
    key: 'waschen',
    options: [
      { label: 'selbständig', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Toilettenbenutzung',
    key: 'toilette',
    options: [
      { label: 'selbständig', points: 10 },
      { label: 'benötigt z.T. Hilfe (Gleichgewicht, Kleidung)', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Baden',
    key: 'baden',
    options: [
      { label: 'selbständig', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Gehen auf Flurebene bzw. Rollstuhlfahren',
    key: 'gehen',
    options: [
      { label: 'selbständig >50m Hilfsmittel erlaubt (kein Gehwagen)', points: 15 },
      { label: 'geringe Hilfe, Überwachung kann mit Hilfsm. 50m gehen', points: 10 },
      { label: 'unselbständig, kann aber Rollstuhl alleine benutzen, mind. 50m', points: 5 },
      { label: 'völlig unselbständig', points: 0 },
    ],
  },
  {
    name: 'Treppensteigen',
    key: 'treppensteigen',
    options: [
      { label: 'selbständig', points: 10 },
      { label: 'Hilfe erford. Jedoch 50% selbständig', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'An- Auskleiden',
    key: 'auskleiden',
    options: [
      { label: 'selbständig', points: 10 },
      { label: 'Hilfe erford. Jedoch 50% selbständig', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Stuhlkontrolle',
    key: 'stuhlkontrolle',
    options: [
      { label: 'kontinent', points: 10 },
      { label: 'gelegentlich inkontinent (max. 1x/Woche)', points: 5 },
      { label: '(häufiger) inkontinent', points: 0 },
    ],
  },
  {
    name: 'Urinkontrolle',
    key: 'urinkontrolle',
    options: [
      { label: 'kontinent (ggf. selbständige DK/Cystofixversorgung)', points: 10 },
      { label: 'gelegentlich inkontinent (max. 1x/Tag)', points: 5 },
      { label: '(häufiger) inkontinent', points: 0 },
    ],
  },
];

const footerCategories = [
  { label: 'Summe:', key: 'sum' },
  { label: 'erhoben am:', key: 'erhobenAm' },
  { label: 'erhoben von (Handzeichen):', key: 'erhobenVon' },
];

const formData = reactive(
  categories.reduce((acc, category) => {
    acc[category.key] = { aufnahme: '', entlassung: '' };
    return acc;
  }, {}),
);

const footerFormData = reactive(
  footerCategories.reduce((acc, category) => {
    acc[category.key] = { aufnahme: '', entlassung: '' };
    return acc;
  }, {}),
);

const solutionData = reactive(
  categories.reduce((acc, category) => {
    acc[category.key] = { aufnahme: '', entlassung: '' };
    return acc;
  }, {}),
);

const sum = reactive({
  aufnahme: computed(() => {
    return Object.values(formData).reduce((total, values) => total + (Number(values.aufnahme) || 0), 0);
  }),
  entlassung: computed(() => {
    return Object.values(formData).reduce((total, values) => total + (Number(values.entlassung) || 0), 0);
  }),
});

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.isEditingSolution) {
        {
          if (!courseInteractionFetchCompleted.value) return;
          courseInteractionStore.startSectionContentItemIfNotAlready(props.contentItem.id);
        }
      }
      setTimeout(() => {
        saveForm();
        unsavedChanges.value = false;
      }, 3000);
    }
  },
);

watch(
  () => props.contentItem?.form_structured_exercise?.form_data,
  (newValue) => {
    if (newValue) {
      loadForm();
    }
  },
);

watch(
  () => props.contentItem?.form_case?.form_data,
  (newValue) => {
    if (newValue) {
      loadForm();
    }
  },
);

onMounted(() => {
  loadForm();

  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      saveForm();
      return undefined;
    }
  };
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveForm();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

// Convert results in human-readable format
const toHumanReadable = () => {
  const result = {};

  // Process each category
  ['aufnahme', 'entlassung'].forEach((type) => {
    categories.forEach((category) => {
      const selectedValue = formData[category.key][type];
      console.log(selectedValue);
      console.log(Number(selectedValue));
      const selectedOption = category.options.find((option) => option.points === Number(selectedValue));
      const otherOptions = category.options.filter((option) => option.points !== Number(selectedValue));
      const betterOptions = category.options.filter((option) => option.points > selectedValue);

      if (!result[type]) {
        result[type] = {};
      }

      result[type][category.key] = {
        selected:
          (selectedOption != null ? selectedOption.label : 'No selection') +
          (betterOptions.length ? '' : ' (best possible)'),
        otherOptions: otherOptions.map((option) => option.label),
        betterOptions: betterOptions.map((option) => option.label),
      };
    });
  });

  console.log(result);
  console.log(sum.aufnahme, sum.entlassung);

  // Generate the patient summary string for further use
  const patientSummary = generatePatientSummary(result.aufnahme);
  // console.log('Patient Summary (at Aufnahme, with disabilities):', patientSummary);
  return patientSummary;
  //
  // const patientSummary2 = generatePatientSummary(result.aufnahme, false);
  // console.log('Patient Summary (at Aufnahme, only abilities):', patientSummary2);
};

// Function to generate a human-readable summary of the patient's abilities
const generatePatientSummary = (result, showInabilities = true) => {
  let summary = "Patient's Barthel Index Summary:\n";

  for (const [key, data] of Object.entries(result)) {
    summary += `\nCategory: ${key.charAt(0).toUpperCase() + key.slice(1)}\n`;
    if (showInabilities) {
      summary += `- Patient's abilities: ${data.selected}\n`;
    } else {
      summary += `- ${data.selected}\n`;
    }
    if (data.betterOptions.length > 0 && showInabilities) {
      summary += `- Patient does not fulfill the following: ${data.betterOptions.join('. Neither fulfilled: ')}\n`;
    }
  }

  return summary;
};

const courseStore = useCourseStore();

const saveForm = async () => {
  if (!props.contentItem?.id) {
    throw new Error('Cannot save form: No content item ID');
  }
  if (!props.isEditingSolution) {
    throw new Error('Cannot save form: Not in editing mode');
  }
  if (isSavingChanges.value) {
    throw new Error('Cannot save form: Already saving changes');
  }
  isSavingChanges.value = true;

  let humanReadable = toHumanReadable();
  console.log('readable: ', humanReadable);

  try {
    await courseStore.updateBarthelForm(
      props.contentItem.section_id,
      props.contentItem.id,
      {
        form_data: formData,
        human_readable_form_data: humanReadable,
      },
      props.forCase,
    );
    unsavedChanges.value = false;
  } catch (error) {
    alertStore.error('Failed to autosave form', 'Error', error);
    throw new Error('Failed to autosave Asklepios Barthel form');
  } finally {
    isSavingChanges.value = false;
  }
};

function loadForm() {
  if (!props.forCase) {
    // this is in a structured exercise
    if (!props.contentItem?.form_structured_exercise?.form_data) return;

    if (props.isEditingSolution) {
      let loadedData = props.contentItem.form_structured_exercise.form_data;
      Object.keys(loadedData).forEach((key) => {
        if (formData[key]) {
          Object.assign(formData[key], loadedData[key]);
        }
      });
    } else {
      // so we are playing, not editing as a teacher
      let loadedData = props.contentItem.form_structured_exercise.form_data;
      Object.keys(loadedData).forEach((key) => {
        if (solutionData[key]) {
          Object.assign(solutionData[key], loadedData[key]);
        }
      });
    }
  } else {
    // this is in a case
    if (!props.isEditingSolution) {
      // we are playing a case, so nothing to load here
      internalIsLoading.value = false;
      return;
    }
    if (!props.contentItem?.form_case?.form_data) return;

    if (props.isEditingSolution) {
      let loadedData = props.contentItem.form_case.form_data;
      Object.keys(loadedData).forEach((key) => {
        if (formData[key]) {
          Object.assign(formData[key], loadedData[key]);
        }
      });
    } else {
      // so we are playing, not editing as a teacher
      let loadedData = props.contentItem.form_case.form_data;
      Object.keys(loadedData).forEach((key) => {
        if (solutionData[key]) {
          Object.assign(solutionData[key], loadedData[key]);
        }
      });
    }
  }
  internalIsLoading.value = false;
}

function fillFormWithRandomValues() {
  categories.forEach((category) => {
    // Select a random option from the category's options array
    const randomOption = category.options[Math.floor(Math.random() * category.options.length)];

    // Assign the random option's points to the `aufnahme` field
    formData[category.key].aufnahme = randomOption.points;
  });
  unsavedChanges.value = true;
}

const aufnahmeAllFilled = computed(() => {
  return Object.values(formData).every((category) => category.aufnahme !== '');
});

const entlassungAllFilled = computed(() => {
  return Object.values(formData).every((category) => category.entlassung !== '');
});

const isValid = computed(() => aufnahmeAllFilled.value || entlassungAllFilled.value);

const fieldClasses = (section, field) => {
  const userValue = formData[section][field];
  const solutionValue = solutionData[section][field];

  if (!showingResults.value) return '';

  if (userValue === solutionValue) {
    return 'text-teal-500'; // Correct input
  } else if (userValue !== '') {
    return 'text-red-500'; // Incorrect input
  }
  return ''; // Neutral/default
};

// Handle form submission
const handleSubmit = async () => {
  showingResults.value = true;
  feedback.value = null;
  isGettingFeedback.value = true;
  console.log('Form Data:', formData);
  console.log('Comparing to Solution:', solutionData);

  // Trigger class updates by ensuring reactivity
  for (const section in formData) {
    for (const field in formData[section]) {
      fieldClasses(section, field); // Update styling dynamically
    }
  }

  if (props.forCase) {
    // TODO do the eval stuff
    return;
  }

  courseInteractionStore
    .evaluateFormStructuredExercise(props.contentItem.id, {
      dialog: props.contentItem.form_structured_exercise.case_story,
      description: props.contentItem.form_structured_exercise.case_description,
      human_readable_form_data_true: props.contentItem.form_structured_exercise.human_readable_form_data,
      human_readable_form_data_student: toHumanReadable(),
    })
    .then(async (response) => {
      feedback.value = response;
      console.log(response);
      response.notifications?.forEach((notification) => {
        alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
      });
      await authStore.fetchUserXp();
      isSolved.value = response.wrong_answers.length === 0;
    })
    .catch((error) => {
      alertStore.error('Failed to evaluate form', 'Error', error);
      console.error(error);
      throw new Error('Failed to evaluate form');
    })
    .finally(() => {
      isGettingFeedback.value = false;
    });
};

defineExpose({
  saveForm,
  isValid,
  feedback,
  isSolved,
});
</script>

<template>
  <div v-show="isLoading" class="pt-20 w-full flex justify-center items-center h-full">
    <LoadingSpinnerLarge />
  </div>
  <div
    v-show="!isLoading"
    class="form-container h-full w-full flex-col flex overflow-auto"
    @blur="
      async () => {
        console.log('form blurred');
        if (props.isEditingSolution) await saveForm();
      }
    "
  >
    <div class="w-full flex items-center mt-1 mb-6">
      <div class="mx-auto" v-show="props.isEditingSolution">
        <ProgressButton @click="fillFormWithRandomValues" text="Fill form with random values" color="red" />
      </div>
    </div>
    <h1 class="form-title">Barthel Index (Aktivitäten des täglichen Lebens - ADL)</h1>
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Aufnahme</th>
          <th>Entlassung</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="category in categories" :key="category.name">
          <!-- For categories with borders -->
          <tr>
            <td colspan="3" class="category-spacing"></td>
          </tr>
          <tr v-if="category.key === 'gehen' || category.key === 'transfer'" class="bordered-category-heading">
            <td colspan="3" class="category-heading">{{ category.name }}</td>
          </tr>
          <tr v-if="category.key === 'gehen' || category.key === 'transfer'" class="bordered-category-content">
            <td class="category-content">
              <div v-for="option in category.options" :key="option.label" class="option-container">
                <span class="option-label">{{ option.label }}</span>
                <span class="points">{{ option.points }}</span>
              </div>
            </td>
            <td class="input-cell">
              <select
                @change="unsavedChanges = true"
                :class="fieldClasses(category.key, 'aufnahme')"
                v-model="formData[category.key].aufnahme"
                class="select-field bottom-aligned"
              >
                <option v-for="option in category.options" :key="option.points" :value="option.points">
                  {{ option.points }}
                </option>
              </select>
            </td>
            <td class="input-cell">
              <select
                @change="unsavedChanges = true"
                :class="fieldClasses(category.key, 'aufnahme')"
                v-model="formData[category.key].entlassung"
                class="select-field bottom-aligned"
              >
                <option v-for="option in category.options" :key="option.points" :value="option.points">
                  {{ option.points }}
                </option>
              </select>
            </td>
          </tr>

          <!-- For categories without borders -->
          <tr v-else>
            <td colspan="3" class="category-heading">{{ category.name }}</td>
          </tr>
          <tr v-if="category.key !== 'gehen' && category.key !== 'transfer'">
            <td class="category-content">
              <div v-for="option in category.options" :key="option.label" class="option-container">
                <span class="option-label">{{ option.label }}</span>
                <span class="points">{{ option.points }}</span>
              </div>
            </td>
            <td class="input-cell">
              <select
                @change="unsavedChanges = true"
                :class="fieldClasses(category.key, 'aufnahme')"
                v-model="formData[category.key].aufnahme"
                class="select-field bottom-aligned"
              >
                <option v-for="option in category.options" :key="option.points" :value="option.points">
                  {{ option.points }}
                </option>
              </select>
            </td>
            <td class="input-cell">
              <select
                @change="unsavedChanges = true"
                :class="fieldClasses(category.key, 'aufnahme')"
                v-model="formData[category.key].entlassung"
                class="select-field bottom-aligned"
              >
                <option v-for="option in category.options" :key="option.points" :value="option.points">
                  {{ option.points }}
                </option>
              </select>
            </td>
          </tr>
        </template>
        <template
          v-if="!props.isEditingSolution && !isLoading"
          v-for="category in footerCategories"
          :key="category.key"
        >
          <tr>
            <td class="footer-label-container">
              <div
                :class="{
                  'text-teal-500':
                    category.key !== 'sum' && showingResults && footerFormData[category.key]?.aufnahme !== '',
                  'text-red-500':
                    category.key !== 'sum' &&
                    showingResults &&
                    (!footerFormData[category.key].aufnahme || footerFormData[category.key].aufnahme === ''),
                }"
                class="footer-label"
              >
                {{ category.label }}
              </div>
            </td>
            <td v-if="category.key === 'sum'" class="footer-input-cell">
              <div class="sum-field">{{ sum.aufnahme }}</div>
            </td>
            <td v-else class="footer-input-cell">
              <input
                :class="{
                  'text-teal-500':
                    category.key !== 'sum' && showingResults && footerFormData[category.key]?.aufnahme !== '',
                  'text-red-500':
                    category.key !== 'sum' &&
                    showingResults &&
                    (!footerFormData[category.key]?.aufnahme || footerFormData[category.key]?.aufnahme === ''),
                }"
                type="text"
                v-model="footerFormData[category.key].aufnahme"
                class="input-field"
              />
              <span
                v-if="
                  category.key === 'erhobenAm' &&
                  showingResults &&
                  (!footerFormData[category.key].aufnahme || footerFormData[category.key].aufnahme === '')
                "
              >
                <span class="text-red-500 flex leading-0 text-xs justify-between">
                  Es ist sehr wichtig, dass du deine Doku mit einem Datum versiehst! Es kommt sonst zu medizinischen (!)
                  oder Abrechnungsfehlern.
                </span>
              </span>
              <span
                v-if="
                  category.key === 'erhobenVon' &&
                  showingResults &&
                  (!footerFormData[category.key].aufnahme || footerFormData[category.key].aufnahme === '')
                "
              >
                <span class="text-red-500 flex leading-0 text-xs justify-between">
                  Es ist sehr wichtig, dass du deine Doku "abzeichntest" (=dein Handzeichen darunter machst). Dies ist
                  von deinem Arbeitgeber so vorgeschrieben.
                </span>
              </span>
            </td>
            <td v-if="category.key === 'sum'" class="footer-input-cell">
              <div class="sum-field">{{ sum.entlassung }}</div>
            </td>
            <td v-else class="footer-input-cell">
              <input type="text" v-model="footerFormData[category.key].entlassung" class="input-field" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-show="props.allowSubmit">
      <ProgressButton :disabled="!isValid || props.isEditingSolution" @click="handleSubmit" />
    </div>
  </div>
</template>

<style scoped>
.form-container {
  font-family: Arial, sans-serif;
  background-color: white;
  overflow: auto;
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  text-decoration: underline;
  background-color: #f0f0f0; /* Gray background for the title */
  padding: 10px 15px; /* Added padding to make it visually consistent */
  margin-bottom: 15px; /* Space between the title and the table */
}

table {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are clean */
  margin-bottom: 20px;
}

table th {
  background-color: white;
  padding: 5px; /* Reduced padding for denser rows */
  text-align: center;
  font-weight: bold;
}

th,
td {
  border: none;
}

td {
  padding: 5px; /* Reduced padding to make rows more compact */
  text-align: left; /* Ensure all text is left-aligned */
  vertical-align: bottom; /* Align all inputs and content consistently at the bottom */
}

.option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px; /* Reduced margin to make rows denser */
}

.option-label {
  flex: 1;
  padding-left: 10px;
  font-size: 14px; /* Reduced font size for compact layout */
  text-align: left; /* Ensures all option labels are left-aligned */
}

.points {
  margin-left: 10px; /* Reduced space to make the layout tighter */
  font-weight: bold;
  font-size: 14px; /* Consistent smaller font size */
}

.input-field {
  width: 95%; /* Slightly increased width for better alignment */
  margin-top: 0; /* Removed top margin for better alignment at the bottom */
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid black;
}

.bottom-aligned {
  margin-bottom: 0; /* Align input field strictly at the bottom */
}

.category-heading {
  font-weight: bold;
  padding-left: 10px;
  text-align: left; /* Ensures the category headings are left-aligned */
  background-color: #ffffff;
}

.footer-label-container {
  text-align: right;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: bold;
  vertical-align: middle;
}

.footer-label {
  padding-top: 3px; /* Reduced padding for tighter alignment */
  padding-bottom: 3px;
}

.footer-input-cell {
  text-align: center;
  padding: 3px; /* Reduced padding for compactness */
}

/* Adding black borders for specific categories that encompass the entire section */
.bordered-category-heading {
  border: 2px solid black;
  border-bottom: none; /* Remove bottom border to merge with the next row */
}

.bordered-category-content {
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.category-content {
  padding-top: 2px; /* Reduced padding for a tighter layout */
  padding-bottom: 2px; /* Reduced padding for a tighter layout */
  padding-right: 40px;
}

.category-spacing {
  height: 20px; /* Increased height for better separation between categories */
}

.select-field {
  width: 95%; /* Width to match previous input fields */
  padding: 2px; /* Reduced padding for compact selects */
  margin-top: 0; /* Removed top margin for better alignment at the bottom */
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid black; /* Bottom border only */
  background: #f5f5f5;
  -webkit-appearance: none; /* Remove default styles in Webkit browsers */
  -moz-appearance: none; /* Remove default styles in Firefox */
  appearance: none; /* Remove default styles for modern browsers */
  text-align: center; /* Center the text inside the select element */
  text-align-last: center; /* Ensures the currently selected option is centered */
}
</style>
