<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { HSAccordion, HSDropdown, HSStaticMethods } from 'preline';
import ProfileImage from '@/components/ProfileImage.vue';
import PersonCard from './PersonCard.vue';
import { updateMinTextareaHeight } from '@/helper';
import { ErrorMessage, useField } from 'vee-validate';
import SettingInput from '@/components/SettingInput.vue';
import { InvolvementType } from '@/apiclient';

const message = defineModel({
  type: Object,
  required: true,
});

const { number, startingFromCompleteDialog, persons } = defineProps([
  'number',
  'startingFromCompleteDialog',
  'persons',
]);
const selectedPersonIds = ref([]);
const personMessagesValid = ref([]);
const personMessageRefs = ref([]);

const criterionErrors = ref([]);

const emit = defineEmits(['removeMessage', 'closeAllOtherMessageEdits', 'isValid']);
const accordion = ref(null);
const isEditing = ref(true);
const scrollContainer = ref(null);

const messageIndexOrCreate = computed(() => {
  return message.value.index !== null ? message.value.index : 'create';
});

const isTouched = ref(false);

const dropdownId = computed(() => {
  return 'hs-dropdown-message-create-trigger-' + messageIndexOrCreate.value;
});
const accordionId = computed(() => {
  return 'hs-basic-with-arrow-heading-message-' + messageIndexOrCreate.value;
});
const accordionContentId = computed(() => {
  return 'hs-basic-with-arrow-collapse-message-' + messageIndexOrCreate.value;
});

const { value: localTitle, errors: contentErrors } = useField('content', 'max:400|required', {
  initialValue: message.value.content,
});

async function emitRemoveMessage(index) {
  emit('removeMessage', index);
}

function toggleSelectPerson(id, personMessage, personDialogInvolvement = InvolvementType.IN_THE_ROOM) {
  console.log('toggleSelectPerson', id, personMessage, personDialogInvolvement);

  // only one person can be selected at a time
  selectedPersonIds.value.length = 0;
  selectedPersonIds.value.push(id);

  message.value.person_message_involvements.length = 0;
  message.value.person_message_involvements.push({ create_id: id, involvement_type: personDialogInvolvement });

  setPersonDialogInvolvement(id, personDialogInvolvement);

  emit('isValid', allValid.value); // manually trigger
  console.log('toggle emitted (manually triggered as person selected): isValid', allValid.value);
}

const isSelected = (index) => {
  return selectedPersonIds.value.includes(index);
};

const exactlyOnePersonSelected = computed(() => {
  return selectedPersonIds.value.length === 1;
});

function toggleEditMessage(event) {
  event.stopPropagation();

  console.log('toggleEditMessage');

  if (isEditing.value) {
    // closed for the first time => set isTouch to true
    isTouched.value = true;
  }

  isEditing.value = !isEditing.value;

  if (isEditing.value) {
    console.log('Showing ', accordion.value);
    accordion.value.show();
    emit('closeAllOtherMessageEdits');
    return;
  }
  accordion.value.hide();
}

function closeEdit() {
  if (!isEditing.value) {
    return;
  }
  isEditing.value = false;
  accordion.value.hide();
}

function setPersonDialogInvolvement(create_id, involvement) {
  // console.log('Received: ', create_id, involvement);
  const index = message.value.person_message_involvements.findIndex(
    (involvement) => involvement.create_id === create_id,
  );
  if (index === -1) {
    console.log('concern not found for create_id: ', create_id);
    // message.value.person_message_involvement.push({ create_id: create_id, type: involvement });
  } else {
    message.value.person_message_involvements[index].involvement_type = involvement;
    selectedPersonIds.value.push(create_id);
  }
}

onMounted(async () => {
  await nextTick(async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    await HSAccordion.autoInit();
    await nextTick();
    let { el } = HSAccordion.getInstance('#' + accordionId.value, true);

    if (!el) {
      el = new HSAccordion(document.querySelector('#' + accordionId.value));
    }
    accordion.value = el;

    console.log('accordion: ', accordion.value);
    console.log('accordingId: ', accordionId.value);

    // this is a workaround for https://github.com/htmlstreamofficial/preline/issues/252
    let originalEditState = isEditing.value;
    isEditing.value = true;
    await nextTick();
    if (originalEditState) {
      accordion.value.show();
      emit('closeAllOtherMessageEdits');
    } else {
      console.log('Hiding ', accordion.value);
      accordion.value.hide();
    }
    isEditing.value = originalEditState;
    // end of workaround

    for (let i = 0; i < persons.length; i++) {
      personMessageRefs.value.push(ref(null));
    }

    for (let involvement of message.value.person_message_involvements) {
      setPersonDialogInvolvement(involvement.create_id, involvement.involvement_type);
    }
  });
});

watch(
  () => persons,
  (newValue) => {
    for (let i = 0; i < newValue.length; i++) {
      personMessageRefs.value.push(ref(null));
    }
  },
);

function centerPerson(item) {
  const container = scrollContainer.value;

  if (!container) {
    return;
  }

  // Get the container's width and scroll position
  const containerWidth = container.clientWidth;
  const containerScrollLeft = container.scrollLeft;

  // Get the item's width and position relative to the container
  const itemWidth = item.clientWidth;
  const itemOffsetLeft = item.offsetLeft;

  // Calculate the scroll position to center the item
  const scrollTo = itemOffsetLeft - containerWidth / 2 + itemWidth / 2;
  // console.log('scrollTo', scrollTo);

  // Smoothly scroll the container to the calculated position
  container.scroll({
    left: scrollTo,
    behavior: 'smooth',
  });
}

const allValid = computed(() => {
  let valid =
    message.value.content !== '' &&
    message.value.content != null &&
    contentErrors.value.length === 0 &&
    exactlyOnePersonSelected.value &&
    !criterionErrors.value.some((error) => error.length > 0) &&
    personMessagesValid.value.every((isValid) => isValid);
  console.log(
    'SceneCard: isValid',
    message.value.content !== '' && message.value.content != null,
    contentErrors.value.length === 0,
    exactlyOnePersonSelected.value,
    !criterionErrors.value.some((error) => error.length > 0),
    personMessagesValid.value.every((isValid) => isValid),
  );
  console.log('personMessagesValid', personMessagesValid.value);
  console.log('SceneCard: isValid', valid);

  return valid;
});

const audioSrc = computed(() => {
  if (!message.value.id) {
    // not in DB, so no audio
    return null;
  }
  return `https://audiodata-prd.data.casuu.health/${message.value.id}.mp3`;
});

watch(
  () => allValid.value,
  (value) => {
    console.log('SceneCard watcher emitting: isValid', value);
    emit('isValid', value);
  },
);

defineExpose({
  closeEdit,
});
</script>

<template>
  <div class="hs-accordion-group flex w-[40vH]">
    <div
      @click="toggleEditMessage"
      class="hs-accordion group cursor-pointer p-4 min-w-full w-full max-w-full flex flex-col justify-center bg-white border border-gray-400 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
      :class="{
        active: isEditing,
        'border border-red-600': !allValid && isTouched,
        'hover:bg-gray-50 dark:hover:bg-neutral-700': !isEditing,
      }"
      :id="accordionId"
    >
      <!-- setting 'active' as a conditional class is part of the workaround for https://github.com/htmlstreamofficial/preline/issues/252 -->

      <!-- Message header -->
      <!-- Note: trying to "hs-accordion-toggle" to handle it in own function only BREAKS accordion, do not do! -->
      <button class="hidden hs-accordion-toggle"></button>
      <button class="min-w-full w-full max-w-full flex flex-row justify-between">
        <div class="relative flex-grow justify-between gap-x-4">
          <!-- Title + type + desc -->
          <div class="text-start">
            <!-- Title -->
            <div class="grow flex flex-col justify-start text-start">
              <div class="inline-flex items-center gap-x-2 pb-2">
                <h3 class="flex flex-grow font-medium text-gray-800 dark:text-neutral-200 pr-1">
                  <span
                    v-if="!isEditing && message.content === null"
                    class="flex max-w-full w-full min-w-full text-left"
                  >
                    Zum Bearbeiten klicken
                  </span>
                  <span
                    v-else-if="!isEditing && message.content !== null"
                    class="flex min-h-fit h-fit max-h-fit max-w-full w-full min-w-full"
                  >
                    {{ message.content }}
                  </span>
                  <span class="flex max-w-full w-full min-w-full" v-else>
                    <textarea
                      @click="
                        (event) => {
                          event.stopPropagation();
                        }
                      "
                      @keyup.space.prevent="(event) => event.stopPropagation()"
                      v-model="message.content"
                      :disabled="!isEditing"
                      placeholder="Nachricht angeben"
                      class="resize-none px-0 py-0.5 my-0.5 mr-0.5"
                      @input="
                        (event) => {
                          updateMinTextareaHeight(event.target);
                          localTitle = event.target.value;
                        }
                      "
                      rows="3"
                      :class="{
                        'cursor-text border border-gray-200 rounded-lg bg-white': isEditing,
                        'border-transparent bg-transparent': !isEditing,
                        'border-red-500 focus:border-red-500 focus:ring-red-500':
                          (message.content === '' || message.content == null || contentErrors.length > 0) && isTouched,
                      }"
                      :style="{
                        width: '100%',
                        'box-sizing': 'border-box',
                      }"
                    />
                  </span>
                </h3>
              </div>
              <span v-if="isEditing" class="text-xs text-red-600 mt-1">{{ contentErrors[0] }}</span>
              <span
                v-if="
                  isEditing &&
                  (message.content === '' || message.content == null) &&
                  contentErrors.length === 0 &&
                  isTouched
                "
                class="text-xs text-red-600"
                >Dies ist ein Pflichtfeld</span
              >
            </div>
            <!-- End content -->
          </div>
          <!-- End Title + type + desc -->
        </div>
        <!-- End Col -->

        <!-- Profile images of selected persons + top-level buttons -->
        <div class="mt-0 min-w-fit w-fit max-w-fit">
          <div class="flex justify-end items-center gap-x-4">
            <div class="flex items-center gap-x-4">
              <div class="flex flex-row pl-4 -mr-2 -space-x-2">
                <div class="relative flex-shrink-0 items-center justify-center" v-for="(person, index) in persons">
                  <img
                    v-if="!!person.profile_image"
                    class="flex h-[38px] w-[38px] sm:w-[46px] sm:h-[46px] mx-auto rounded-full transition-all duration-500"
                    :class="{
                      'w-[38px] sm:w-[46px] opacity-100': selectedPersonIds.includes(person.create_id),
                      'w-0 sm:w-0 opacity-0': !selectedPersonIds.includes(person.create_id),
                    }"
                    :src="person.profile_image.image_urls.small"
                    alt="Image Description"
                  />
                  <span
                    v-else
                    translate="no"
                    class="material-symbols-outlined notranslate flex h-[38px] sm:h-[46px] mx-auto rounded-full bg-white text-gray-800 border border-gray-500 text-5xl items-center justify-center transition-all duration-500"
                    :class="{
                      'w-[38px] sm:w-[46px] opacity-100': selectedPersonIds.includes(person.create_id),
                      'w-0 sm:w-0 opacity-0': !selectedPersonIds.includes(person.create_id),
                    }"
                    :style="{ 'font-weight': '100' }"
                  >
                    person
                  </span>
                </div>
              </div>

              <!-- Buttons rights -->
              <div class="top-0 end-0 flex-col sticky flex justify-center">
                <!-- Delete button -->
                <button
                  class="z-10 flex items-center justify-items-center w-full font-semibold text-start text-gray-800 hover:text-gray-800 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-600 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  :aria-controls="accordionContentId"
                  @click="emitRemoveMessage(message.index)"
                >
                  <span
                    translate="no"
                    class="material-symbols-outlined notranslate text-xl text-red-600 hover:text-red-800 block bg-white"
                    :class="{ 'group-hover:bg-gray-50': !isEditing }"
                  >
                    delete
                  </span>
                </button>
                <!-- End Delete button -->
                <!-- Accordion toggle -->
                <button
                  class="flex items-center justify-items-center w-full font-semibold text-start text-gray-800 hover:text-gray-800 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-600 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  :aria-controls="accordionContentId"
                  @click.capture="toggleEditMessage"
                >
                  <span
                    translate="no"
                    class="material-symbols-outlined notranslate text-xl"
                    :class="{ hidden: isEditing }"
                  >
                    expand_more
                  </span>
                  <span
                    translate="no"
                    class="material-symbols-outlined notranslate text-xl"
                    :class="{ hidden: !isEditing }"
                  >
                    expand_less
                  </span>
                </button>
                <!-- End accordion toggle -->
              </div>
              <!-- End buttons rights -->
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End profile images of selected persons + top-level buttons -->
      </button>
      <!-- End row = message header -->

      <!-- Additional content = message details -->
      <div
        :id="accordionContentId"
        class="hs-accordion-content w-full overflow-hidden transition-[height] duration-500 flex min-w-full"
        :aria-labelledby="accordionId"
        @click="toggleEditMessage"
      >
        <div class="pt-2 mt-12 flex-col border-t border-gray-400 w-full">
          <div class="text-xs text-gray-800 pt-4 pb-2 font-semibold">Sprecher:in</div>
          <div class="w-full flex overflow-x-auto overflow-y-hidden gap-x-2 pb-2" ref="scrollContainer">
            <div v-for="(person, index) in persons" :key="person.create_id" class="flex-none">
              <PersonCard
                :ref="personMessageRefs[index]"
                v-model="persons[index]"
                :isSelected="isSelected(person.create_id)"
                @toggleSelect="toggleSelectPerson"
                @editedPersonDialogInvolvement="setPersonDialogInvolvement(person.create_id, $event)"
                :allowSelect="true"
                :allowEdit="false"
                :allowRemove="false"
                :requireCompleteness="false"
                :startingFromCompleteDialog="startingFromCompleteDialog"
                :validateEdits="false"
                :validatePersonalConcern="true"
                @centerMe="centerPerson($event.target)"
              />
            </div>
          </div>
          <div
            v-show="selectedPersonIds?.length === 0"
            class="text-xs pt-2"
            :class="{ 'text-blue-600': !isTouched, 'text-red-600': isTouched }"
          >
            Gib eine:n Sprecher:in an
          </div>
        </div>

        <div v-if="!!audioSrc">
          <div class="text-xs text-gray-800 pt-4 pb-2 font-semibold">Aktuelle Audio</div>
          <div class="w-full flex">
            <audio class="w-full h-8" controlsList="nodownload" oncontextmenu="return false;" controls>
              <source :src="audioSrc" />
            </audio>
          </div>
        </div>
      </div>
    </div>
    <!-- End additional content -->
  </div>
</template>

<style scoped>
.disabled {
  pointer-events: none;
}
</style>
