<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useAuthStore, useOrganizationStore } from '@/stores';
import { feedbackIntegration } from '@sentry/vue';

const authStore = useAuthStore();
const organizationStore = useOrganizationStore();

const { organization } = storeToRefs(organizationStore);
const { isSignedIn } = storeToRefs(authStore);

const emit = defineEmits(['closeMenu']);

function handleLogout() {
  authStore.logout();
}

const isOrgManager = computed(() => {
  if (!isSignedIn.value) {
    return false;
  }
  return !!organization.value;
});

async function openReportBugModal() {
  const feedback = feedbackIntegration({
    // Disable injecting the default widget
    autoInject: false,
    theme: 'light',
    useSentryUser: {
      name: 'fullName',
      email: 'email',
    },
    themeLight: {
      accentBackground: '#2563eb',
    },
    themeDark: {
      accentBackground: '#2563eb',
    },
  });

  const form = await feedback.createForm();
  form.appendToDom();
  form.open();
}
</script>

<template>
  <div class="md:py-2 first:pt-0 last:pb-0">
    <span class="hidden md:block py-3 px-3 text-xs uppercase text-gray-400 dark:text-gray-500"> Einstellungen </span>
    <router-link
      to="/profile"
      @click="emit('closeMenu')"
      class="flex border-b md:border-none border-dashed border-gray-200 items-center gap-x-3.5 py-3 md:px-3 md:rounded-md text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
    >
      <span translate="no" class="material-symbols-outlined notranslate"> manage_accounts </span>
      {{ $t('message.profile') }}
    </router-link>

    <router-link
      v-show="isOrgManager"
      to="/dashboard"
      @click="emit('closeMenu')"
      class="flex border-b md:border-none border-dashed border-gray-200 items-center gap-x-3.5 py-3 md:px-3 md:rounded-md text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
    >
      <span translate="no" class="material-symbols-outlined notranslate"> team_dashboard </span>
      {{ $t('message.dashboard') }}
    </router-link>
  </div>

  <div class="md:py-2 first:pt-0 last:pb-0">
    <a
      @click="openReportBugModal"
      class="flex border-b md:border-none border-dashed border-gray-200 items-center gap-x-3.5 py-3 md:px-3 md:rounded-md text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 cursor-pointer"
    >
      <span translate="no" class="material-symbols-outlined notranslate"> bug_report </span>
      {{ $t('message.reportABug') }}
    </a>
    <a
      @click="handleLogout"
      class="flex items-center gap-x-3.5 py-3 md:px-3 md:rounded-md text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 cursor-pointer"
      data-testid="sign-out-button"
    >
      <span translate="no" class="material-symbols-outlined notranslate"> logout </span>
      {{ $t('message.signOut') }}
    </a>
  </div>
</template>

<style scoped></style>
