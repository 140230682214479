/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Companion } from '../models/Companion';
import type { CompanionInteraction } from '../models/CompanionInteraction';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompanionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Or Create Companion
   * Get or create a companion for the current user.
   * @returns Companion Successful Response
   * @throws ApiError
   */
  public getOrCreateCompanion(): CancelablePromise<Companion> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/companions/get-or-create',
    });
  }

  /**
   * Interact With Companion
   * @param companionId
   * @returns CompanionInteraction Successful Response
   * @throws ApiError
   */
  public interactWithCompanion(companionId: string): CancelablePromise<CompanionInteraction> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/companions/{companion_id}/interact',
      path: {
        companion_id: companionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
