<!--
TODO: Fetch text from DB
TODO: Replace preline icon by patient's "profile" image
-->

<script setup>
import SystemMessageFooter from '@/components/case_interaction/SystemMessageFooter.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import { useAuthStore, usePatientInteractionStore } from '@/stores';
import { onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';

const patientInteractionStore = usePatientInteractionStore();
const { chatIsStreaming } = storeToRefs(patientInteractionStore);
const authStore = useAuthStore();
const { userFirstName, userLastName } = storeToRefs(authStore);

const { message, isLatestBubble } = defineProps(['message', 'isLatestBubble']);
const emit = defineEmits(['playingAudio']);
const editOn = ref(false);
const editedMessage = ref('');
const originalMessage = ref('');
const hasBeenEdited = ref(false);
const showingTranslation = ref(false);

// import shared functions
import { useChatBubbleFunctions } from '@/components/chat_bubbles/chatBubbleFunctions';
import { getSentenceForCharacter, unobfuscateUserName } from '@/helper';

const { selectedSentenceIndcs, calculateCols, calculateRows, getSelectedCharacterPositions } = useChatBubbleFunctions();

const selectableDiv = ref(null);

function handleClickOutside(event) {
  // Check if the clicked element is outside the selectableDiv
  if (!selectableDiv.value.contains(event.target)) {
    // Reset the selected positions
    selectedSentenceIndcs.value = [];
  }
}

function onSelectedText() {
  let characterPositions = getSelectedCharacterPositions(selectableDiv.value);
  selectedSentenceIndcs.value = [];
  for (let i = 0; i < characterPositions.length; i++) {
    let sentenceIdx = getSentenceForCharacter(message.content['processed_model_output'], characterPositions[i]);
    if (!selectedSentenceIndcs.value.includes(sentenceIdx)) {
      selectedSentenceIndcs.value.push(sentenceIdx);
    }
  }
}

async function editToggled(new_value) {
  editOn.value = new_value.value;
  console.debug('Patient chat bubble: Edit mode toggled to: ' + editOn.value);
  if (editOn.value === true) {
    console.debug('Edit on.');
    editedMessage.value = message.content['processed_model_output'];
    originalMessage.value = message.content['processed_model_output'];
    return;
  }
  console.debug('Edit off. Checking for new values');
  console.debug('Old message: ' + originalMessage.value);
  console.debug('New message: ' + editedMessage.value);
  if (editedMessage.value !== originalMessage.value) {
    console.debug('Message changed. Pushing to DB.');
    await patientInteractionStore.storeUserEditedChatMessage(message, editedMessage.value);
    originalMessage.value = editedMessage.value;
    hasBeenEdited.value = true;
  }
}

async function translationToggled(new_value) {
  showingTranslation.value = new_value;
}

function playingAudio(playing) {
  console.log('Patient - Playing audio: ' + playing);
  emit('playingAudio', playing);
}

onMounted(() => {
  // Listen for a click event on the document
  document.addEventListener('click', handleClickOutside);
});

// Cleanup the event listener when the component is unmounted
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <li class="flex gap-x-2 sm:gap-x-4">
    <!-- Icon -->
    <span
      class="flex-shrink-0 inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-600"
    >
      <span class="text-sm font-medium text-white leading-none">
        <ProfileImage
          :image="patientInteractionStore.patientProfileImageSmall"
          :initials="patientInteractionStore.patientInitials"
        />
      </span>
    </span>
    <!-- End of icon -->

    <div>
      <!-- class="grow max-w-[90%] md:max-w-2xl w-full space-y-3" -->
      <!-- Content -->
      <div class="bg-white border border-gray-200 rounded-lg p-4 space-y-3 dark:bg-neutral-900 dark:border-gray-700">
        <p v-if="editOn">
          <textarea
            class="border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
            v-model="editedMessage"
            :rows="calculateRows(editedMessage)"
            :cols="calculateCols(editedMessage)"
          >
          </textarea>
        </p>
        <p v-else-if="hasBeenEdited" class="text-sm text-gray-800 dark:text-white">
          {{ unobfuscateUserName(originalMessage, userFirstName, userLastName) }}
        </p>
        <p v-else class="text-sm text-gray-800 dark:text-white" @mouseup="onSelectedText" ref="selectableDiv">
          {{ unobfuscateUserName(message.content['processed_model_output'], userFirstName, userLastName) }}
        </p>
      </div>
      <!-- End Content -->
      <!---->
      <!--           {{ message.content['processed_model_output'] }} -->

      <!-- streaming: {{ chatIsStreaming }} -->
      <!-- Button Group -->
      <SystemMessageFooter
        :message="message"
        message-type="SAY-PATIENT"
        conversation="PATIENT"
        @onEditToggled="editToggled"
        @onTranslationToggled="translationToggled"
        :hideFooter="chatIsStreaming"
        :markedSentenceIndcs="selectedSentenceIndcs"
      />
      <!-- :hideFooter="chatIsStreaming" -->

      <!-- End Button Group -->
    </div>
  </li>
</template>

<style scoped></style>
