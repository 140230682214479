<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { debounce } from 'lodash';
import MediaUploadCard from '@/components/didactics/media/MediaUploadCard.vue';

const alertStore = useAlertStore();

const fetchFinished = ref(false);

const approved = ref(false);
const isDragging = ref(false);

const allowedMediaTypes = ref([]);
const allowedMediaFormats = ref([]);
const allowedMediaSizes = ref([]);

const emit = defineEmits(['uploadedItem']);

const props = defineProps({
  hasProfileImage: {
    type: Boolean,
    required: false,
  },
  uploadProfileImage: {
    type: Function,
    required: true,
  },
});
</script>

<template>
  <MediaUploadCard
    :upload-file="uploadProfileImage"
    border-rounded="rounded-full"
    :always-maximized="false"
    :allow-maximize="false"
    :minimal="true"
    :textWhenMinimized="hasProfileImage ? 'Ändern' : 'Hinzufügen'"
    height-px-when-minimized="100"
    width-px-when-minimized="100"
    :upload-in-progress="uploadInProgress"
    headerText="Profilbild"
    :dark-background="true"
    :allow-image="true"
    :allow-video="false"
    :allow-audio="false"
  />
</template>
