<script setup>
import { ref, computed, reactive, onMounted, defineProps, onBeforeUnmount, onBeforeMount, watch } from 'vue';
import { router } from '@/router';
import { useAuthStore, usePatientInteractionStore, useCaseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

import SolveModal from '@/components/case_interaction/SolveModal.vue';
import { VocabMode } from '@/helper';
import TaskProgressModal from '@/components/case_interaction/TaskProgressModal.vue';
import TaskTransitionModal from '@/components/case_interaction/TaskTransitionModal.vue';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import CaseInteractionMain from '@/components/case_interaction/CaseInteractionMain.vue';
import ChatBubbleSystem from '@/components/chat_bubbles/ChatBubbleSystem.vue';
import TaskInfoCard from '@/components/case_interaction/TaskInfoCard.vue';
import CaseFinishedModal from '@/components/case_interaction/CaseFinishedModal.vue';
import { useI18n } from 'vue-i18n';
import ProgressButton from '@/components/ProgressButton.vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { getApiClient } from '@/apiclient/client';

const { t } = useI18n();

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const { user } = storeToRefs(authStore);
const { descMessages, descIsStreaming } = storeToRefs(patientInteractionStore);
const { newSubtaskCompleted, currentTaskIndex } = storeToRefs(caseInteractionStore);

const explainMeChatHandle = ref();
const oralExamHandle = ref();
const mcExamHandle = ref();
const solveModalHandle = ref(null);
const taskProgressModalHandle = ref(null);
const taskTransitionModalHandle = ref(null);
const caseFinishedModalHandle = ref(null);
const translateVocabHandle = ref();
const explainVocabHandle = ref();
const reportHandle = ref();
const twoColumnLayoutHandle = ref(null);
const thirdPersonHandles = ref([]);
const showFirstTask = ref(false);

const isLoading = ref(true);
const userIsReady = ref(false);
const loadingMessage = ref(t('message.loading'));
const originChapterId = ref('');

onMounted(async () => {
  console.log('onMounted CaseInteraction');

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  await router.isReady();
  const caseInteractionId = router.currentRoute.value.params.caseInteractionId;
  if (!!router.currentRoute.value.query.originChapterId) {
    console.log(
      'CaseInteraction: originChapterId found in query params: ' + router.currentRoute.value.query.originChapterId,
    );
    originChapterId.value = router.currentRoute.value.query.originChapterId;
  } else {
    console.log('CaseInteraction: No originChapterId found in query params');
  }

  // TODO HTTP 404 if caseInteractionId is not found

  // if (
  //   caseInteractionId !== patientInteractionStore.currentCaseInteractionId ||
  //   caseInteractionId !== caseInteractionStore.currentCaseInteractionId ||
  //   caseInteractionId !== thirdPersonInteractionStore.currentCaseInteractionId
  // ) {
  //   console.debug('case interaction id mismatch, refreshing stores');

  // refresh stores
  console.log('initing stores');

  await Promise.all([
    caseInteractionStore.reset(),
    patientInteractionStore.reset(),
    thirdPersonInteractionStore.reset(),
  ]);

  (await getApiClient()).caseInteractions.getCaseInteraction(caseInteractionId).then(async (caseInteraction) => {
    await caseInteractionStore.setCaseInteraction(caseInteraction);

    // disable audio autoplay, enable it later if user is ready and clicks on next
    caseInteractionStore.disableAudioAutoplay();

    await loadHistoryOrInitNewInteraction();

    console.log('third persons ', JSON.stringify(thirdPersonInteractionStore.currentThirdPersons));
    console.log('# third persons ', thirdPersonInteractionStore.currentThirdPersons.length);
    for (let i = 0; i < thirdPersonInteractionStore.currentThirdPersons.length; i++) {
      thirdPersonHandles.value.push(ref(null));
    }
  });
  // }
});

onBeforeUnmount(async () => {
  await Promise.all([
    caseInteractionStore.reset(),
    patientInteractionStore.reset(),
    thirdPersonInteractionStore.reset(),
  ]);
});

watch(
  () => newSubtaskCompleted.value,
  (newValue) => {
    if (!newValue) return;
    taskProgressModalHandle.value.show();
  },
);

async function loadHistoryOrInitNewInteraction() {
  // first we check if there is a history for patient or third person interaction
  try {
    let historyLoaded =
      (await patientInteractionStore.fetchHistory()) || (await thirdPersonInteractionStore.fetchHistories());
    console.debug('history available and loaded: ' + historyLoaded);
    if (!historyLoaded) {
      // no history found, so we init the interaction and start streaming
      await caseInteractionStore.goToTask(0, true);
    } else {
      // we have a history, so we enable audio autoplay and forward the user directly to the case interaction
      await caseInteractionStore.continueCaseInteraction();
      userIsReady.value = true;
    }
  } catch (e) {
    console.error('Error while loading history or init new interaction: ' + e);
  }
  isLoading.value = false;
}

async function onTrySolve() {
  console.debug('try solve hit');

  // open modal
  const test = await solveModalHandle.value.pauseForMetaChat(caseInteractionStore.currentObserverInteractionId);
  console.debug('try solve closed with answer ' + test);
}

async function onWriteReport() {
  console.debug('report writing started');

  const test = await reportHandle.value.pauseForMetaChat();
  console.debug('report modal closed with answer ' + test);
}

/**
 * Sets the value of `userIsReady` to `true`, indicating that the user is ready to proceed to the case interaction.
 */
const proceedToCaseInteraction = async () => {
  // Try to unlock audio immediately on click

  if (twoColumnLayoutHandle.value) {
    console.log('Unlocking audio context from proceedToCaseInteraction...');
    await twoColumnLayoutHandle.value.unlockAudioContext();
  }

  userIsReady.value = true;

  // Wait for the ref if we haven't unlocked yet, try up to 10 times
  if (!twoColumnLayoutHandle.value) {
    let attempts = 0;
    await new Promise((resolve) => {
      const checkRef = setInterval(() => {
        attempts++;
        console.log('Checking if twoColumnLayoutHandle.value is available... Attempt', attempts);
        if (twoColumnLayoutHandle.value) {
          clearInterval(checkRef);
          console.log('Unlocking audio context from proceedToCaseInteraction...');
          twoColumnLayoutHandle.value.unlockAudioContext();
          resolve();
        }
        if (attempts >= 10) {
          clearInterval(checkRef);
          console.warn('Failed to get twoColumnLayoutHandle after 10 attempts');
          resolve();
        }
      }, 300);
    });
  }

  caseInteractionStore.enableAudioAutoplay();
};

const goToNextTask = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  taskTransitionModalHandle.value.show(caseInteractionStore.currentTaskIndex + 1);
  await caseInteractionStore.goToNextTask(false);
};

const onFinishCase = async () => {
  console.debug('case is finished');
  await new Promise((resolve) => setTimeout(resolve, 1000));
  await caseFinishedModalHandle.value.show();
};

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px)`,
  };
});

onBeforeMount(() => {
  console.log('onBeforeMount CaseInteraction');
});
</script>

<template>
  <div
    v-if="isLoading || !userIsReady"
    class="fixed top-0 left-0 w-screen flex items-center justify-center z-10"
    :style="computedStyleMain"
  >
    <div v-if="!descMessages.length">
      <LoadingSpinnerLarge />
    </div>
    <div v-else-if="showFirstTask === false" class="mt-5 space-y-2 max-w-96">
      <ChatBubbleSystem :message="descMessages[0]" :isStreaming="descIsStreaming" data-testid="initial-desc-message" />
      <ProgressButton
        :onclick="
          () => {
            console.log('showFirstTask set to true');
            showFirstTask = true;
          }
        "
        text="Aufgaben ansehen"
        :showProgress="descIsStreaming"
        type="button"
        :disabled="descIsStreaming"
        class="ml-auto"
        data-testid="show-first-task-button"
      />
    </div>
    <div v-else class="mt-5 space-y-2 max-w-96">
      <TaskInfoCard :task="caseInteractionStore.currentCase.tasks[0]" />
      <ProgressButton
        :onclick="proceedToCaseInteraction"
        text="Loslegen"
        :showProgress="isLoading"
        type="button"
        :disabled="isLoading"
        class="ml-auto"
        data-testid="start-case-next-button"
      />
    </div>
  </div>
  <div
    :class="{
      'opacity-0': isLoading,
      'opacity-15': !isLoading && !userIsReady,
      'opacity-100': !isLoading && userIsReady,
    }"
    class="w-full flex flex-col flex-grow overflow-hidden transition-opacity duration-500"
    :style="computedStyleMain"
  >
    <SolveModal ref="solveModalHandle" />
    <TaskProgressModal ref="taskProgressModalHandle" @goToNextTask="goToNextTask" @finishCase="onFinishCase" />
    <TaskTransitionModal ref="taskTransitionModalHandle" />
    <CaseFinishedModal ref="caseFinishedModalHandle" />
    <!--    <ReportModal ref="reportHandle" overlayId="report-modal" />-->

    <!-- <button @click="taskTransitionModalHandle.show(1)" class="pb-4 bg-red-100">
      open transition modal
    </button>

    <button @click="taskProgressModalHandle.show()"> open progress modal </button> -->

    <CaseInteractionMain
      ref="twoColumnLayoutHandle"
      @startReport="onWriteReport"
      @trySolve="onTrySolve"
      :outerHeaderHeight="props.outerHeaderHeight"
      :originChapterId="originChapterId"
      :isLoadingInteractionHistory="isLoading"
      :showingMainCaseView="userIsReady"
    />
    <!-- <button @click="onFinishCase">finish case</button> -->
  </div>
  <!-- if transitioning subtask or task: show TaskInfoCard as modal -->
</template>

<style></style>
