<script setup>
import { ref, nextTick, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import {
  useAlertStore,
  useAuthStore,
  useCaseInteractionStore,
  useCourseInteractionStore,
  useLanguageStore,
  usePatientInteractionStore,
} from '@/stores';
import { storeToRefs } from 'pinia';
import { HSOverlay } from 'preline';
import { v4 as uuidv4 } from 'uuid';
import confetti from 'canvas-confetti';
import TaskInfoCard from '@/components/case_interaction/TaskInfoCard.vue';
import SuccessAnimation from '@/components/SuccessAnimation.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { useRouter } from 'vue-router';
import { getApiClient } from '@/apiclient/client';

import { useI18n } from 'vue-i18n';
import LoadingSpinnerLarge from '../LoadingSpinnerLarge.vue';
const { t } = useI18n();

const props = defineProps({});

const emit = defineEmits(['closed']);

// Main cpt refs
const modal = ref(null);
const router = useRouter();

// Stores and store references
const caseInteractionStore = useCaseInteractionStore();
const { currentTask, currentCaseInteractionId } = storeToRefs(caseInteractionStore);
const courseInteractionStore = useCourseInteractionStore();
// Refs for component data
const playSuccessAnimation = ref(false);
const canBeClosed = ref(false);

const routeBackToCourse = ref({});
const suggestedVocab = ref([]);

// Computed-like functions

const computeOverflow = () => {
  if (!modalWindow.value) return;
  modalWindowOverflowsX.value = modalWindow.value.scrollWidth > modalWindow.value.clientWidth;
};

const close = () => {
  HSOverlay.close('#case-finished-modal');
};

// Lifecycle hooks
onMounted(async () => {
  await HSOverlay.autoInit(); // we need to do this in order to make modals for components work which are mounted AFTER the app is initialized and AFTER the router re-initialized HSStaticMethods.autoInit()

  // Get the modal element and initialize it
  const modalElement = document.querySelector('#case-finished-modal');
  modal.value = HSOverlay.getInstance(modalElement);

  // If no instance exists, create one
  if (!modal.value) {
    modal.value = new HSOverlay(modalElement);
  }

  await nextTick();

  modal.value.addEventListener('close.hs.overlay', () => {
    console.debug('closing dialog');
    emit('closed');
  });

  let lastViewed = await courseInteractionStore.getLastViewedForCurrentCourse();
  if (lastViewed) {
    console.debug('Resuming : ' + lastViewed.sectionId + '@ page=' + lastViewed.pageIndex);
    routeBackToCourse.value = {
      sectionId: lastViewed.sectionId,
      pageIndex: lastViewed.pageIndex,
    };
  }
});

const hasRouteBackToCourse = computed(() => {
  return routeBackToCourse.value.sectionId && routeBackToCourse.value.pageIndex != null;
});

onBeforeUnmount(() => {});

// Methods
const show = async () => {
  await nextTick();

  console.log(modal.value);
  HSOverlay.open('#case-finished-modal'); // works

  console.log('opened modal');

  await new Promise((resolve) => setTimeout(resolve, 500));
  playSuccessAnimation.value = true;

  const vocabs = await (
    await getApiClient()
  ).vocabs.createVocabItemsFromCaseInteraction(currentCaseInteractionId.value);
  suggestedVocab.value = vocabs;

  await new Promise((resolve) => setTimeout(resolve, 300));
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
    },
  });

  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 140,
    spread: 70,
    origin: {
      y: 0.1,
      x: 0.5,
    },
  });

  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 250,
    spread: 70,
    origin: {
      y: 0.5,
      x: 0.55,
    },
  });

  setTimeout(() => {
    canBeClosed.value = true;
  }, 1200);
};

const getRandomEmoji = (answerIsCorrect) => {
  const positiveEmojis = ['😊', '🎉', '👍', '👏', '😄', '😁', '🥳', '💪'];
  const motivationalEmojis = ['🤔', '😌', '🙂', '🌱', '🔄', '🌟', '✊'];

  if (answerIsCorrect) {
    return positiveEmojis[Math.floor(Math.random() * positiveEmojis.length)];
  } else {
    return motivationalEmojis[Math.floor(Math.random() * motivationalEmojis.length)];
  }
};

const backToOrigin = () => {
  if (hasRouteBackToCourse.value) {
    router.push({
      name: 'course-section',
      params: {
        sectionId: routeBackToCourse.value.sectionId,
      },
      query: {
        page: routeBackToCourse.value.pageIndex.toString(),
      },
    });
  } else {
    router.push({
      name: 'user-dashboard',
    });
  }
};

defineExpose({
  show,
});
</script>

<template>
  <div
    id="case-finished-modal"
    class="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[100] overflow-x-hidden overflow-y-hidden pointer-events-none"
  >
    <div
      data-testid="case-finished-modal"
      class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all lg:max-w-6xl lg:w-full m-3 lg:mx-auto h-[calc(100%-3.5rem)]"
    >
      <div ref="modalWindow" class="flex w-full h-full pt-12 items-start justify-center">
        <div
          class="mx-auto flex-col flex bg-white border-teal-500 rounded-xl shadow-md dark:bg-neutral-800 dark:border-neutral-700"
        >
          <TaskInfoCard :task="currentTask" :delay="1200" :integrated="true" />

          <div class="pointer-events-auto px-4 py-2 text-gray-800">
            <h3 class="text-medium font-bold">Möchtest du diese Vokabeln deiner Wortschatzkiste hinzufügen?</h3>
            <ul v-if="suggestedVocab.length > 0" class="justify-center text-gray-500">
              <li v-for="vocab in suggestedVocab" :key="vocab.id">
                <span class="hidden"> Satzende. </span>
                {{ vocab.term }}
                <span class="hidden"> {{ vocab.example }}. </span>
              </li>
            </ul>
            <span
              v-else
              class="animate-spin inline-block w-4 h-4 border-[3px] border-teal-600 border-current border-t-transparent text-blue-600 rounded-full"
            />
          </div>

          <!-- Popover Footer -->
          <div class="py-2 px-2 gap-x-2 flex justify-end dark:bg-neutral-800 pointer-events-auto">
            <ProgressButton
              color="teal"
              :disabled="!canBeClosed"
              @click="
                () => {
                  close();
                  backToOrigin();
                }
              "
              :text="hasRouteBackToCourse ? 'Zurück zum Kurs' : 'Zurück zum Dashboard'"
            />
          </div>

          <!-- end of modal contents -->

          <!-- hidden toggle button -->
          <div>
            <a
              class="hidden py-2 px-3 text-xs md:text-sm min-w-16 md:min-w-24 min-h-8 md:min-h-10 h-full text-center inline-flex justify-center items-center gap-2 rounded-lg border border-transparent font-medium bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
              data-hs-overlay="#case-finished-modal"
              data-testid="case-finished-modal-bottom-close-button"
              @click.stop="close"
            >
              Zurück zum Fall
            </a>
          </div>
        </div>
      </div>
    </div>

    <SuccessAnimation :show="playSuccessAnimation" @complete="playSuccessAnimation = false" />
  </div>
</template>

<style scoped>
@keyframes grow-pop {
  0% {
    font-size: 24px; /* Initial text size */
    opacity: 1;
  }
  50% {
    font-size: 30px; /* Pop out to larger size */
    opacity: 1;
  }
  100% {
    font-size: 0px; /* Shrink to disappear */
    opacity: 0;
  }
}

.animate-grow-pop {
  animation: grow-pop 300ms ease-out;
}
</style>
