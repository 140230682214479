<script setup lang="ts">
import { defineProps, defineEmits, watch, ref } from 'vue';
import RemoveButton from '@/components/RemoveButton.vue';
import { updateMinTextareaHeight } from '@/helper';
import { DragEvent } from 'vue-advanced-cropper';

const props = defineProps({
  category: { type: Object, required: true },
  isEditing: { type: Boolean, default: false },
  color: { type: String, default: 'gray' },
  icon: { type: String, default: 'place_item' },
  onClick: { type: Function, required: false, default: null },
  isDraggingOver: { type: Boolean, default: false },
});

const emit = defineEmits(['dropItem', 'changeCategory', 'deleteCategory']);

const categoryZone = ref(null);

const handleDrop = (event: Event) => {
  console.log('drop');
  categoryZone.value.style.backgroundColor = 'initial';
  console.log(props.category.id);
  emit('dropItem', props.category.id);
};

const handleDragOver = (event: Event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};

watch(
  () => props.isDraggingOver,
  (newValue) => {
    if (newValue) {
      if (!categoryZone.value) return;
      categoryZone.value.style.backgroundColor = '#e0e0e0';
    } else {
      categoryZone.value.style.backgroundColor = 'initial';
    }
  },
);

const handleDragEnter = (event: Event) => {
  if (!categoryZone.value) return;
  categoryZone.value.style.backgroundColor = '#e0e0e0';
};

const handleDragLeave = (event: Event) => {
  if (!categoryZone.value) return;
  console.log(event);
  const rect = categoryZone.value.getBoundingClientRect();
  if (
    event.clientX < rect.left ||
    event.clientX > rect.right ||
    event.clientY < rect.top ||
    event.clientY > rect.bottom
  ) {
    // we manually check if event is ACTUALLY outside container as absolutely positioned elements are considered wrongly else (as left, even if inside)
    categoryZone.value.style.backgroundColor = 'initial';
  }
};
</script>

<template>
  <div
    ref="categoryZone"
    class="relative bg-white group p-2 min-h-16 md:min-h-28 h-fit w-full rounded-lg border-2 border-dashed border-gray-300 flex flex-col items-center justify-start"
    :class="[!!props.onClick ? 'cursor-pointer hover:bg-gray-50' : 'cursor-default', props.isEditing ? 'pb-10' : '']"
    @click.prevent="onClick"
    @drop.prevent="handleDrop"
    @dragover.prevent="handleDragOver"
    @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave"
    @dragend.prevent="handleDragLeave"
  >
    <span
      v-if="!isEditing || !!props.onClick"
      class="select-none text-xs md:text-sm fallback-break text-center mb-2"
      :class="[
        `text-${props.color}-600 dark:text-${props.color}-400`,
        props.onClick ? `group-hover:text-${props.color}-700 dark:group-hover:text-${props.color}-300` : '',
      ]"
      >{{ category.name }}</span
    >
    <textarea
      ref="textareaRef"
      v-show="isEditing && !props.onClick"
      type="text"
      class="resize-none mb-2 min-w-[calc(50%-20px)] md:w-[190px] text-xs md:text-sm p-2 border border-gray-200 rounded-md dark:bg-neutral-800 dark:border-neutral-700"
      :value="category.name"
      placeholder="Kategorie"
      @input="
        async (event) => {
          await updateMinTextareaHeight(event.target);
        }
      "
      @change="
        async (event) => {
          await updateMinTextareaHeight(event.target);
          emit('changeCategory', category.id, event.target.value);
        }
      "
      :rows="1"
    />

    <div class="w-full flex-col flex">
      <slot></slot>
    </div>

    <!-- <div
      class="absolute top-24 md:top-28"
      @drop.prevent="
        (event) => {
          handleDrop(event);
          event.stopPropagation();
        }
      "
    >
      <span
        translate="no"
        class="material-symbols-outlined notranslate text-4xl"
        :class="[
          `text-${props.color}-600 dark:text-${props.color}-400`,
          props.onClick ? `group-hover:text-${props.color}-700 dark:group-hover:text-${props.color}-300` : '',
        ]"
      >
        {{ props.icon }}
      </span>
    </div> -->
    <div class="absolute bottom-10 end-0">
      <RemoveButton v-if="props.isEditing" @click="() => emit('deleteCategory', category.id)" />
    </div>
  </div>
</template>

<style scoped>
/* Styling for hover and drop feedback */
</style>
