<script setup lang="ts">
import { computed, useSlots } from 'vue';

const { icon, markUnread, animatePing, innerSize, outerSize } = defineProps({
  icon: {
    default: '',
    type: String,
  },
  markUnread: {
    default: false,
    type: Boolean,
  },
  animatePing: {
    default: false,
    type: Boolean,
  },
  innerPingSize: {
    default: '4px',
    type: String,
  },
  outerPingSize: {
    default: '10px',
    type: String,
  },
  iconSize: {
    default: '',
    type: String,
  },
});

const slots = useSlots();

const hasDefaultSlot = computed(() => !!slots.default);
</script>

<template>
  <div class="relative">
    <span v-if="icon === 'ai_sparkle'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-stars mysterious-pulse"
        viewBox="0 0 16 16"
      >
        <path
          d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"
        />
      </svg>
    </span>
    <span v-else translate="no" class="material-symbols-outlined notranslate" :class="iconSize">
      {{ icon }}
    </span>
    <span>
      <slot></slot>
    </span>
    <span
      v-if="markUnread"
      class="grid absolute z-10 -mt-1.5 -me-1.5 items-center justify-center"
      :class="{
        'end-0.5 top-0.5': !hasDefaultSlot,
        '-end-0.5 top-1': hasDefaultSlot,
      }"
    >
      <span
        class="row-start-1 col-start-1 animate-ping inline-flex size-full rounded-full bg-blue-400 opacity-75 dark:bg-blue-600"
        :style="{ width: outerPingSize, height: outerPingSize }"
        v-if="animatePing"
      >
      </span>
      <span
        class="row-start-1 col-start-1 inline-flex justify-center items-center bg-transparent rounded-full"
        :style="{ width: outerPingSize, height: outerPingSize }"
      >
        <span
          class="row-start-1 col-start-1 inline-flex justify-center items-center bg-blue-600 rounded-full"
          :style="{ width: innerPingSize, height: innerPingSize }"
        >
        </span>
      </span>
    </span>
  </div>
</template>

<style scoped></style>
