/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanionInteractionMessage } from '../models/CompanionInteractionMessage';
import type { CompanionInteractionMessageCreate } from '../models/CompanionInteractionMessageCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompanionInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Companion Interaction Messages
   * List user messsages and companion answers, as well as companion answers
   * to request-like events.
   *
   * :param session:
   * :param user:
   * :param companion_interaction_id:
   * :return:
   * @param companionInteractionId
   * @returns CompanionInteractionMessage Successful Response
   * @throws ApiError
   */
  public listCompanionInteractionMessages(
    companionInteractionId: string,
  ): CancelablePromise<Array<CompanionInteractionMessage>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/companion-interactions/{companion_interaction_id}',
      path: {
        companion_interaction_id: companionInteractionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Companion Interaction Message
   * @param companionInteractionId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public createCompanionInteractionMessage(
    companionInteractionId: string,
    requestBody: CompanionInteractionMessageCreate,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/companion-interactions/{companion_interaction_id}',
      path: {
        companion_interaction_id: companionInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
