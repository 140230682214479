/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatientInteractionMessage } from '../models/PatientInteractionMessage';
import type { UserEditedMessage } from '../models/UserEditedMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PatientMessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Patient Interaction Message
   * :param session:
   * :param user:
   * :param patient_interaction_message_id:
   * :return:
   * @param patientInteractionMessageId
   * @returns PatientInteractionMessage Successful Response
   * @throws ApiError
   */
  public getPatientInteractionMessage(
    patientInteractionMessageId: string,
  ): CancelablePromise<PatientInteractionMessage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/patient-messages/{patient_interaction_message_id}',
      path: {
        patient_interaction_message_id: patientInteractionMessageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Store User Edit For Patient Interaction Message
   * @param patientInteractionMessageId
   * @param requestBody
   * @returns PatientInteractionMessage Successful Response
   * @throws ApiError
   */
  public storeUserEditForPatientInteractionMessage(
    patientInteractionMessageId: string,
    requestBody: UserEditedMessage,
  ): CancelablePromise<PatientInteractionMessage> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/patient-messages/{patient_interaction_message_id}',
      path: {
        patient_interaction_message_id: patientInteractionMessageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Patient Interaction Message Translation
   * :param session:
   * :param user:
   * :param patient_interaction_message_id:
   * :param target_language:
   * :param translation_option:
   * :return:
   * @param patientInteractionMessageId
   * @param targetLanguage
   * @param translationOption
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestPatientInteractionMessageTranslation(
    patientInteractionMessageId: string,
    targetLanguage: string,
    translationOption: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/patient-messages/{patient_interaction_message_id}/translation',
      path: {
        patient_interaction_message_id: patientInteractionMessageId,
      },
      query: {
        target_language: targetLanguage,
        translation_option: translationOption,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Undo Patient Interaction Message
   * Sets a patient interaction message as undone or reverts the undone
   * state.
   *
   * Args:
   * database_client: Injected database client
   * user: Current authenticated user
   * patient_interaction_message_id: ID of the message to undo
   * revert: If True, the undo state is reverted.
   *
   * Returns:
   * The updated patient interaction message
   * @param patientInteractionMessageId
   * @param revert
   * @returns PatientInteractionMessage Successful Response
   * @throws ApiError
   */
  public undoPatientInteractionMessage(
    patientInteractionMessageId: string,
    revert: boolean = false,
  ): CancelablePromise<PatientInteractionMessage> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/patient-messages/{patient_interaction_message_id}/undo',
      path: {
        patient_interaction_message_id: patientInteractionMessageId,
      },
      query: {
        revert: revert,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
