<script setup>
import SystemMessageFooter from '@/components/case_interaction/SystemMessageFooter.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import { useAuthStore } from '@/stores';
import { onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { MessageType } from '@/apiclient';

const authStore = useAuthStore();
const { userFirstName, userLastName } = storeToRefs(authStore);

const { message, isLatestBubble, leftAligned, centered, active } = defineProps([
  'message',
  'isLatestBubble',
  'leftAligned',
  'centered',
  'active',
]);
const emit = defineEmits(['playingAudio']);
const editOn = ref(false);
const editedMessage = ref('');
const originalMessage = ref('');
const hasBeenEdited = ref(false);
const showingTranslation = ref(false);

// import shared functions
import { useChatBubbleFunctions } from '@/components/chat_bubbles/chatBubbleFunctions';
import { getSentenceForCharacter, unobfuscateUserName } from '@/helper';

const { selectedSentenceIndcs, calculateCols, calculateRows, getSelectedCharacterPositions } = useChatBubbleFunctions();

const selectableDiv = ref(null);

function handleClickOutside(event) {
  // Check if the clicked element is outside the selectableDiv
  if (!selectableDiv.value.contains(event.target)) {
    // Reset the selected positions
    selectedSentenceIndcs.value = [];
  }
}

function onSelectedText() {
  let characterPositions = getSelectedCharacterPositions(selectableDiv.value);
  selectedSentenceIndcs.value = [];
  for (let i = 0; i < characterPositions.length; i++) {
    let sentenceIdx = getSentenceForCharacter(message.content['processed_model_output'], characterPositions[i]);
    if (!selectedSentenceIndcs.value.includes(sentenceIdx)) {
      selectedSentenceIndcs.value.push(sentenceIdx);
    }
  }
}

async function editToggled(new_value) {
  editOn.value = new_value.value;
  console.debug('Attending chat bubble: Edit mode toggled to: ' + editOn.value);
  if (editOn.value === true) {
    console.debug('Edit on.');
    editedMessage.value = message.content['processed_model_output'];
    originalMessage.value = message.content['processed_model_output'];
    return;
  }
  console.debug('Edit off. Checking for new values');
  console.debug('Old message: ' + originalMessage.value);
  console.debug('New message: ' + editedMessage.value);
  if (editedMessage.value !== originalMessage.value) {
    console.debug('Message changed. Pushing to DB.');
    await thirdPersonInteractionStore.storeUserEditedChatMessage(message, editedMessage.value);
    originalMessage.value = editedMessage.value;
    hasBeenEdited.value = true;
    // message.content['processed_model_output'] = editedMessage.value
  }
}

async function translationToggled(new_value) {
  showingTranslation.value = new_value;
}

function playingAudio(playing) {
  console.log('ThirdPerson - Playing audio: ' + playing);
  emit('playingAudio', playing);
}

onMounted(() => {
  // Listen for a click event on the document
  document.addEventListener('click', handleClickOutside);
});

// Cleanup the event listener when the component is unmounted
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <li
    class="flex w-full gap-x-2 sm:gap-x-4"
    :class="{
      'flex-row-reverse': !leftAligned,
      'justify-center': centered,
    }"
  >
    <!-- Icon -->
    <span
      v-if="!!message.profile_image"
      class="flex-shrink-0 inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-600"
    >
      <span class="text-sm font-medium text-white leading-none">
        <ProfileImage :image="message.profile_image.image_urls['small']" />
      </span>
    </span>
    <!-- End of icon -->

    <div>
      <div
        class="transition-all duration-300 rounded-lg p-4 space-y-3 dark:bg-neutral-900 dark:border-gray-700"
        :class="{
          'bg-white': !centered,
          'bg-gray-100': centered,
          'border border-blue-500 opacity-100 text-blue-500': active,
          'border border-gray-200 opacity-50 text-gray-800 dark:text-white': !active,
        }"
      >
        <p class="text-sm" @mouseup="onSelectedText" ref="selectableDiv">
          {{ message.content }}
        </p>
      </div>
      <!-- End of Content -->

      <!-- TODO: shall we have a footer for translations? Or just context menu as is? -->
    </div>
  </li>
</template>

<style scoped></style>
