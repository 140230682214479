import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useAlertStore } from '@/stores';
import { PCMPlayer } from '@/audio';

export const useAudioStore = defineStore('audio', () => {
  const audioContext = ref<AudioContext | null>(null);
  const mediaStream = ref<MediaStream | null>(null);
  const pcmPlayer = ref<PCMPlayer | null>(null);
  const alertStore = useAlertStore();

  // Add a map to store event listeners
  const eventListeners = ref(new Map<string, Set<Function>>());

  function initAudioContext() {
    if (!audioContext.value) {
      const AudioContextClass = window.AudioContext || window.webkitAudioContext;
      audioContext.value = new AudioContextClass();
    }
    window.audioContext = audioContext.value;
    return audioContext.value;
  }

  function getAudioContext() {
    return audioContext.value || initAudioContext();
  }

  function initPCMPlayer(options = {}) {
    if (!pcmPlayer.value) {
      pcmPlayer.value = new PCMPlayer({
        encoding: '16bitInt',
        channels: 1,
        sampleRate: 16000,
        flushingTime: 200,
        ...options,
      });
      // Initialize event listeners map
      eventListeners.value = new Map();
    }
    return pcmPlayer.value;
  }

  function getPCMPlayer(options = {}) {
    return pcmPlayer.value || initPCMPlayer(options);
  }

  async function requestMicrophoneAccess() {
    // Check if stream exists AND is active
    if (
      mediaStream.value &&
      mediaStream.value.active &&
      mediaStream.value.getAudioTracks().some((track) => track.readyState === 'live')
    ) {
      return mediaStream.value;
    }

    // If we get here, either there's no stream or it's inactive
    // Clean up any existing stream first
    if (mediaStream.value) {
      mediaStream.value.getTracks().forEach((track) => track.stop());
      mediaStream.value = null;
    }

    try {
      mediaStream.value = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: { ideal: true },
          noiseSuppression: { ideal: true },
          autoGainControl: { ideal: false },
        },
      });
      console.log('New stream obtained');
      return mediaStream.value;
    } catch (e) {
      console.error('Error getting media stream:', e);
      alertStore.error('Microphone access denied. Please allow microphone access to use voice input.', 'Error');
      throw e;
    }
  }

  async function unlockAudioContext() {
    const ctx = getAudioContext();
    if (ctx.state === 'suspended') {
      await ctx.resume();
      const silentBuffer = ctx.createBuffer(1, 1, 22050);
      const source = ctx.createBufferSource();
      source.buffer = silentBuffer;
      source.connect(ctx.destination);
      source.start();
    }
  }

  function cleanup() {
    if (mediaStream.value) {
      mediaStream.value.getTracks().forEach((track) => track.stop());
      mediaStream.value = null;
    }
    if (pcmPlayer.value) {
      pcmPlayer.value.destroy();
      pcmPlayer.value = null;
    }
    if (audioContext.value) {
      try {
        audioContext.value.close();
      } catch (e) {
        console.log('Error closing audio context:', e);
      }
      audioContext.value = null;
    }
    eventListeners.value.clear();
  }

  // Add methods to manage event listeners
  function addEventListener(event: string, callback: Function) {
    if (!eventListeners.value.has(event)) {
      eventListeners.value.set(event, new Set());
    }
    eventListeners.value.get(event)?.add(callback);

    // Add to PCMPlayer if it's a timeupdate event
    if (event === 'timeupdate' && pcmPlayer.value) {
      pcmPlayer.value.on(event, callback);
    }
  }

  function removeEventListener(event: string, callback: Function) {
    eventListeners.value.get(event)?.delete(callback);
  }

  return {
    audioContext,
    mediaStream,
    pcmPlayer,
    initAudioContext,
    getAudioContext,
    initPCMPlayer,
    getPCMPlayer,
    requestMicrophoneAccess,
    unlockAudioContext,
    cleanup,
    addEventListener,
    removeEventListener,
  };
});
