<!--TODO
TODO: Send input to DB
TODO: Buttons for examination (Sthethoskop-Icon?) und Blutabnahme (Nadel-Icon?) neben "Senden"
TODO v1: scene desc from AI. Patient chat w/ AI
- v1.b: patient avatar
TODO v2: try therapy. Get updated scene desc as feedback.
TODO v3: user input suggestions? (toggle on/off?)

-->

<script setup>
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';

const emit = defineEmits(['startReport', 'playingAudio']);

import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch, onBeforeMount } from 'vue';
import { useAuthStore, useCaseInteractionStore, usePatientInteractionStore } from '@/stores';

import ChatBubblePatient from '@/components/chat_bubbles/ChatBubblePatient.vue';
import ChatBubbleUser from '@/components/chat_bubbles/ChatBubbleUser.vue';
import ChatBubbleThirdPerson from '@/components/chat_bubbles/ChatBubbleThirdPerson.vue';
import SystemMessageFooter from '@/components/case_interaction/SystemMessageFooter.vue';

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const patientInteractionStore = usePatientInteractionStore();
const { chatMessages: patientChatMessages, chatIsStreaming: patientChatIsStreaming } =
  storeToRefs(patientInteractionStore);
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const { chatMessages: thirdPersonChatMessages, anyChatIsStreaming: anyThirdPersonChatIsStreaming } =
  storeToRefs(thirdPersonInteractionStore);

// const chatMessages = ref([]);

const props = defineProps({
  selectedPerson: Object,
});

function stringNotEmptyOrNull(str) {
  return str !== null && str.trim() !== '';
}

async function loadHistoryOrInitNewThirdPersonInteraction(index) {
  // first we check if there is a history for this patient interaction
  const historyLoaded = await thirdPersonInteractionStore.fetchHistory(index);
  console.debug('history for 3rd persojn available and loaded: ' + historyLoaded);
  if (!historyLoaded) {
    // no history found, so we init the 3rd person interaction and start streaming
    await thirdPersonInteractionStore.getThirdPersonWelcomeMessage(index);
  }
}

function playingAudio(playing) {
  console.log('ChatHistory - Playing audio: ' + playing);
  emit('playingAudio', playing);
}

watch(
  () => props.selectedPerson,
  async (newPerson) => {
    console.log('History received new person: ', newPerson);
    if (!newPerson) {
      return;
    }
    console.log('selectedPerson ', newPerson, ' of type ', newPerson.type);
    if (newPerson.type === 'PATIENT') {
      return;
    } else if (newPerson.type === 'THIRD_PERSON') {
      return;
      // await loadHistoryOrInitNewThirdPersonInteraction(newPerson.thirdPersonIndex);
    } else {
      console.warn('selectedPerson of type ', newPerson.type, ' not recognized: ', newPerson);
      return;
    }
  },
);

const chatMessages = computed(() => {
  if (!props.selectedPerson) {
    return [];
  }
  console.log('selectedPerson ', props.selectedPerson, ' of type ', props.selectedPerson.type);
  if (props.selectedPerson.type === 'PATIENT') {
    return patientInteractionStore.chatMessages;
  } else if (props.selectedPerson.type === 'THIRD_PERSON') {
    return thirdPersonInteractionStore.chatMessages[props.selectedPerson.thirdPersonIndex];
  } else {
    console.warn('selectedPerson of type ', props.selectedPerson.type, ' not recognized: ', props.selectedPerson);
    return [];
  }
});

// Add a ref to track messages being removed and messages we've seen undone
const messagesToRemove = ref(new Set());
const seenUndoneMessages = ref(new Set());
const messagesToRestore = ref(new Set());

// Initialize seenUndoneMessages with messages already undone before opening the chat
onBeforeMount(() => {
  if (chatMessages.value) {
    chatMessages.value.forEach((message) => {
      if (message.undone_at) {
        seenUndoneMessages.value.add(message.id);
      }
    });
  }
});

// Add a function to handle message removal
function handleMessageRemoval(messageId) {
  messagesToRemove.value.add(messageId);
  // Remove from set after animation completes
  setTimeout(() => {
    messagesToRemove.value.delete(messageId);
  }, 800); // Increased to 800ms to match new animation duration
}

function handleMessageRestoration(messageId) {
  messagesToRestore.value.add(messageId);
  // Remove from set after animation completes
  setTimeout(() => {
    messagesToRestore.value.delete(messageId);
  }, 800); // Increased to 800ms to match new animation duration
}

// Watch for changes to messages that become undone
watch(
  () => chatMessages.value,
  (newMessages, oldMessages) => {
    if (!oldMessages) return;
    console.log('oldMessages: ', oldMessages);
    console.log('newMessages: ', newMessages);
    newMessages.forEach((message) => {
      if (message.undone_at && !seenUndoneMessages.value.has(message.id)) {
        console.log('New undone message detected:', message.id);
        seenUndoneMessages.value.add(message.id);
        handleMessageRemoval(message.id);
      }
      if (!message.undone_at && seenUndoneMessages.value.has(message.id)) {
        console.log('New redone message detected:', message.id);
        seenUndoneMessages.value.delete(message.id);
        handleMessageRestoration(message.id);
      }
    });
  },
  { deep: true, immediate: true },
);
</script>

<template>
  <div class="grow">
    <div v-if="!!props.selectedPerson" class="flex flex-col space-y-2 scroll-container">
      <div
        v-for="(message, index) in chatMessages"
        :key="message.id"
        :class="{
          'animate-pop-out': message.undone_at,
          'animate-pop-in': messagesToRestore.has(message.id),
          hidden: message.undone_at && !messagesToRemove.has(message.id) && !messagesToRestore.has(message.id),
        }"
      >
        <!-- user + patient chat messages pairs -->
        <div v-if="message.type === 'SAY'" class="space-y-2 mt-2">
          <div v-if="stringNotEmptyOrNull(message.content['user_message'])">
            <ChatBubbleUser :message="message" conversation="PATIENT" />
          </div>
          <div>
            <ChatBubblePatient
              :message="message"
              :isLatestBubble="index === chatMessages.length - 1"
              @playingAudio="playingAudio"
            />
          </div>
        </div>

        <!-- Similar changes for other message types -->
        <div v-else-if="message.type === 'REACT'" class="space-y-2 mt-2">
          <div>
            <ChatBubblePatient
              :message="message"
              :isLatestBubble="index === chatMessages.length - 1"
              @playingAudio="playingAudio"
            />
          </div>
        </div>

        <div v-else-if="message.type === 'THIRD_PERSON'" class="space-y-2 mt-2">
          <div v-if="stringNotEmptyOrNull(message.content['user_message'])">
            <ChatBubbleUser :message="message" conversation="PATIENT" />
          </div>
          <div>
            <ChatBubbleThirdPerson
              :message="message"
              :isLatestBubble="index === chatMessages.length - 1"
              :personIndex="selectedPerson.thirdPersonIndex"
              @playingAudio="playingAudio"
            />
          </div>
        </div>

        <div v-else-if="message.type === 'THIRD_PERSON_REACT'" class="space-y-2 mt-2">
          <div>
            <ChatBubbleThirdPerson
              :message="message"
              :isLatestBubble="index === chatMessages.length - 1"
              :personIndex="selectedPerson.thirdPersonIndex"
              @playingAudio="playingAudio"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.scroll-container {
  scroll-behavior: smooth;
}

.animate-pop-out {
  animation: pop-out 800ms ease-in forwards;
}

.animate-pop-in {
  animation: pop-out 800ms ease-in reverse;
}

@keyframes pop-out {
  0% {
    transform: scale(1);
    opacity: 1;
    height: var(--original-height);
    margin-top: 0.5rem; /* mt-2 */
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
    height: var(--original-height);
    margin-top: 0.5rem;
  }
  62.5% {
    /* 500ms mark */
    transform: scale(0);
    opacity: 0;
    height: var(--original-height);
    margin-top: 0.5rem;
  }
  100% {
    transform: scale(0);
    opacity: 0;
    height: 0;
    margin-top: 0;
  }
}
</style>
