<script setup lang="ts">
import { HSStaticMethods } from 'preline';
import { HSDropdown } from 'preline';
import { onMounted, ref, watch, nextTick } from 'vue';
import QuickTipButton from './QuickTipButton.vue';
import CompanionChatBubble from './CompanionChatBubble.vue';
import { useCompanionInteractionStore } from '@/stores';
const companionInteractionStore = useCompanionInteractionStore();
import TextareaWithAudioInput from '@/components/inputs/TextareaWithAudioInput.vue';
import { storeToRefs } from 'pinia';

const companionChat = ref(null);
const dropdownElement = ref(null);
const fallbackDetectedIsOpen = ref(false);
const companionChatScrollContainer = ref<HTMLElement | null>(null);
const { chatIsStreaming } = storeToRefs(companionInteractionStore);

const inputText = ref('');

const emit = defineEmits(['closed']);

const initializeDropdown = () => {
  let el = new HSDropdown(document.getElementById('outerCompanionChat'));
  dropdownElement.value = el;
  if (el) {
    el.on('close', async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      emit('closed');
    });
  }

  // fallback observer that detects if the opacity of companionChat goes from >0 to 0 and emits closed
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
        if (!companionChat.value) return;
        let opacityString = window.getComputedStyle(companionChat.value as Element).opacity;
        let opacity = parseFloat(opacityString);
        if (opacity > 0) {
          fallbackDetectedIsOpen.value = true;
        }
        if (fallbackDetectedIsOpen.value && opacity === 0) {
          console.log('companionChat is hidden (fallback detection)');
          fallbackDetectedIsOpen.value = false;
          emit('closed');
        }
      }
    });
  });

  // Start observing the target node for style changes
  if (companionChat.value) {
    observer.observe(companionChat.value, {
      attributes: true,
      attributeFilter: ['style'],
    });
  }
};

onMounted(async () => {
  await new Promise((resolve) => setTimeout(resolve, 200));
  await initializeDropdown();
  await companionInteractionStore.initialize();
});

const showCompanionChat = async (show: boolean) => {
  if (show) {
    HSDropdown.open('#outerCompanionChat');
    await new Promise((resolve) => setTimeout(resolve, 100));
    if (companionChatScrollContainer.value) {
      companionChatScrollContainer.value.scrollTop = companionChatScrollContainer.value.scrollHeight;
    }
  } else {
    HSDropdown.close('#outerCompanionChat');
  }
};

const getHelpWithCase = async (message: string = 'Wie kann ich fortfahren?') => {
  console.log('getHelpWithCase');
  await companionInteractionStore.getTipForCase(message);
};

const sendMessage = async (message: string) => {
  if (message.trim().length === 0) return;
  console.log('sendMessage: "', message, '"');
  await companionInteractionStore.discussCase(message);
  inputText.value = '';
};

const isScrolledToBottom = () => {
  const container = companionChatScrollContainer.value;
  if (!container) return false;

  // Calculate how far we are from bottom as a percentage of container height
  const distanceFromBottom = container.scrollHeight - container.clientHeight - container.scrollTop;
  const percentageFromBottom = (distanceFromBottom / container.clientHeight) * 100;

  // Consider "at bottom" if within X% of container height from bottom
  return percentageFromBottom < 10;
};

watch(
  () => companionInteractionStore.chatMessages,
  async () => {
    await nextTick();
    if (companionChatScrollContainer.value && isScrolledToBottom()) {
      companionChatScrollContainer.value.scrollTop = companionChatScrollContainer.value.scrollHeight;
    }
  },
  { deep: true },
);

defineExpose({
  showCompanionChat,
});
</script>

<template>
  <!-- Welcome Dropdown -->
  <div id="outerCompanionChat" class="hs-dropdown [--auto-close:inside] [--placement:top-right] relative inline-flex">
    <!-- Dropdown -->
    <div
      ref="companionChat"
      class="hs-dropdown-menu hs-dropdown-open:opacity-100 pl-8 pr-1 w-full transition-[opacity,margin] duration opacity-0 hidden z-[100] bg-transparent overflow-hidden"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="hs-pro-chwcnd"
    >
      <div
        class="bg-white overflow-hidden rounded-xl shadow-md border border-gray-200 sm:shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] sm:dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900"
      >
        <div class="h-[500px] relative">
          <!-- Header -->
          <div
            class="py-2 px-4 flex justify-between items-center gap-x-3 border-b border-gray-200 dark:border-neutral-700"
          >
            <!-- Avatar -->
            <div class="w-full">
              <div class="truncate flex items-center gap-x-2">
                <a class="flex-none rounded-md text-xl inline-block font-semibold focus:outline-none focus:opacity-80">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-stars"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"
                    />
                  </svg>
                </a>
                <span class="grow truncate">
                  <span class="truncate block font-semibold text-sm leading-4 text-gray-800 dark:text-neutral-200">
                    Dein casuu <span class="text-blue-600 dark:text-blue-500">companion</span>
                  </span>
                </span>
              </div>
            </div>
            <!-- End Avatar -->

            <div>
              <!-- Button -->
              <button
                type="button"
                class="hs-dropdown-close flex justify-center items-center gap-x-3 size-8 text-sm text-gray-600 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 dark:hover:text-neutral-200 dark:focus:text-neutral-200"
                aria-label="Close"
              >
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
                <span class="sr-only">Close</span>
              </button>
              <!-- End Button -->
            </div>
          </div>
          <!-- End Header -->

          <div
            ref="companionChatScrollContainer"
            class="h-[350px] overflow-y-auto grow [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
          >
            <div class="pt-2 pb-5 px-4">
              <div class="py-5 w-64 mx-auto text-center">
                <a class="flex-none rounded-md text-xl inline-block font-semibold focus:outline-none focus:opacity-80">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-stars mysterious-pulse"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"
                    />
                  </svg>
                </a>

                <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">Möchtest du einen Tipp zum Fall?</p>
              </div>

              <div class="relative space-y-5">
                <!-- Time -->
                <div class="sticky top-0 inset-x-0 z-[8] max-w-lg mx-auto text-center">
                  <span
                    class="py-0.5 px-1.5 bg-gray-100 text-xs text-gray-500 rounded-full dark:bg-neutral-900 dark:text-neutral-500"
                    >Today</span
                  >
                </div>
                <!-- End Time -->

                <!-- List -->
                <div class="w-full space-y-4">
                  <!-- Item -->
                  <div class="max-w-md flex gap-x-2 flex-col flex">
                    <!-- Chat Bubbles -->
                    <div class="space-y-1 w-full" v-for="message in companionInteractionStore.chatMessages">
                      <CompanionChatBubble
                        v-if="!!message.content.user_message"
                        :content="message.content.user_message"
                        author="user"
                        :timestamp="message.created_at"
                        :firstOfThatAuthorInGroup="true"
                        :lastOfThatAuthorInGroup="true"
                      />

                      <CompanionChatBubble
                        v-if="!!message.content.processed_model_output"
                        :content="message.content.processed_model_output"
                        author="companion"
                        :timestamp="message.created_at"
                        :firstOfThatAuthorInGroup="true"
                        :lastOfThatAuthorInGroup="true"
                      />

                      <!-- <CompanionChatBubble
                        author="companion"
                        content="Tipp 1"
                        :firstOfThatAuthorInGroup="true"
                        :lastOfThatAuthorInGroup="false"
                      />
                      <CompanionChatBubble
                        author="companion"
                        content="Tipp 2"
                        :firstOfThatAuthorInGroup="false"
                        :lastOfThatAuthorInGroup="false"
                      />
                      <CompanionChatBubble
                        author="companion"
                        content="Tipp 3"
                        :firstOfThatAuthorInGroup="false"
                        :lastOfThatAuthorInGroup="true"
                      />

                      <CompanionChatBubble
                        author="user"
                        content="Danke!"
                        :firstOfThatAuthorInGroup="true"
                        :lastOfThatAuthorInGroup="true"
                      /> -->
                    </div>
                    <!-- End Chat Bubbles -->
                  </div>
                  <!-- End Item -->
                </div>
                <!-- End List -->
              </div>

              <!-- Topics -->
              <div class="mt-5 flex flex-col gap-y-1.5" :class="{ 'opacity-50': chatIsStreaming }">
                <QuickTipButton
                  text="Wie soll ich weitermachen?"
                  @clicked="getHelpWithCase('Wie soll ich weitermachen?')"
                  :disabled="chatIsStreaming"
                />
                <QuickTipButton
                  text="Was soll ich als nächstes sagen?"
                  @clicked="getHelpWithCase('Was soll ich als nächstes sagen?')"
                  :disabled="chatIsStreaming"
                />
              </div>
              <!-- End Topics -->
            </div>
          </div>

          <!-- Textarea -->
          <footer
            class="absolute bottom-0 inset-x-0 z-10 px-1 rounded-b-2xl bg-white border-t border-gray-200 dark:bg-neutral-800 dark:border-neutral-700"
          >
            <label for="hs-chat-widgets-help-topics-autoheight-textarea" class="sr-only">Message</label>

            <TextareaWithAudioInput
              v-model="inputText"
              @change="async (event: any) => await sendMessage(event.target.value)"
              placeholder="Frag deinen companion"
              :forceDesktop="true"
              :asFooter="true"
              :disabled="chatIsStreaming"
            >
              <template #actions>
                <button
                  @click="sendMessage(inputText)"
                  type="button"
                  :disabled="chatIsStreaming"
                  class="z-10 rounded-full mt-2 m-0.5 h-7 w-7 flex-col flex"
                  :class="[
                    chatIsStreaming
                      ? 'bg-gray-500 focus:ring-2 cursor-not-allowed focus:ring-blue-600 focus:z-10 focus:outline-none'
                      : 'bg-blue-600 hover:bg-blue-700',
                  ]"
                >
                  <span
                    v-show="!chatIsStreaming"
                    translate="no"
                    class="-mt-1 material-symbols-outlined notranslate text-white text-3xl"
                  >
                    send
                  </span>
                  <div
                    v-show="chatIsStreaming"
                    class="animate-spin inline-block mt-1 ml-1 w-5 h-5 border-[2px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                  />
                </button>
              </template>
            </TextareaWithAudioInput>
          </footer>
          <!-- End Textarea -->
        </div>
      </div>
    </div>
    <!-- End Dropdown -->

    <!-- NOTE: without this button, the auto-close attribute is bugged: false prohibits only the first click from closing but not the second etc. -->
    <button
      id="hs-pro-chwcnd"
      type="button"
      class="hs-dropdown-toggle hidden flex justify-center items-center size-12 text-sm font-medium rounded-full border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
      aria-haspopup="menu"
      aria-expanded="false"
      aria-label="Dropdown"
    >
      <svg
        class="hs-dropdown-open:block hidden shrink-0 size-5"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="m6 9 6 6 6-6" />
      </svg>
      <svg
        class="hs-dropdown-open:hidden shrink-0 size-5"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path
          d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
        />
      </svg>
    </button>
  </div>
  <!-- End Welcome Dropdown -->
</template>

<style scoped>
@keyframes mysteriousPulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.mysterious-pulse {
  animation: mysteriousPulse 3s ease-in-out infinite;
}
</style>
