import { nextTick } from 'vue';

export async function adjustHeight(el: HTMLTextAreaElement, minRows: number = 1) {
  await nextTick();

  if (el.rows > minRows) {
    el.rows = Math.max(el.rows - Math.min(el.rows - minRows, 12), minRows);
  }

  // add rows until scrollHeight is reached
  while (el.scrollHeight > el.clientHeight && el.rows < 20) {
    // Add a maximum limit
    el.rows += 1;
  }
}

export async function updateMinTextareaHeight(textareaElement: EventTarget | null, minRows: number = 1) {
  if (!textareaElement) {
    return;
  }
  await adjustHeight(textareaElement as HTMLTextAreaElement, minRows);
}
