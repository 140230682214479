<script setup>
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  value: {
    type: Number,
    default: 0,
  },
  maxValue: {
    type: Number,
    default: null,
  },
  recentChange: {
    type: Number,
    default: null,
  },
  recentChangeUnit: {
    type: String,
    default: null,
  },
  iconAtChangePosition: {
    type: String,
    default: null,
  },
  unit: {
    type: String,
    default: null,
  },
  tooltipText: {
    type: String,
    default: null,
  },
  showCentered: {
    type: Boolean,
    default: false,
  },
  increasedFontSize: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <!-- Card -->
  <div
    class="overflow-hidden px-4 xl:px-8 py-1 sm:py-2 xl:py-4 flex flex-col bg-white border min-h-full border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
  >
    <div :class="{ 'my-auto mx-auto': showCentered }">
      <div class="flex justify-start items-center -mb-1 xl:mb-1">
        <h3
          class="mt-2 text-xl md:text-2xl lg:text-4xl text-gray-800 dark:text-neutral-200"
          :class="{ 'text-2xl sm:text-3xl lg:text-8xl': increasedFontSize }"
        >
          <span class="font-semibold">{{ props.value }}</span>
          <span
            v-if="props.unit != null"
            class="pl-1.5 text-gray-500 dark:text-neutral-500"
            :class="{
              'text-base md:text-lg lg:text-xl': !increasedFontSize,
              'text-xl lg:text-3xl': increasedFontSize,
            }"
            >{{ props.unit }}</span
          >
          <span v-if="props.maxValue != null" class="pl-1.5 text-gray-500 dark:text-neutral-500"
            >/ {{ props.maxValue }}</span
          >
        </h3>
        <div
          v-if="props.recentChange != null || props.iconAtChangePosition != null"
          class="-mt-2 xl:-mt-4 pl-2 xl:pl-2 flex items-center text-xs md:text-sm text-gray-500 dark:text-neutral-500"
          :class="{
            'text-green-600': props.recentChange > 0,
            'text-red-600': props.recentChange < 0,
            'xl:-mt-[54px] xl:-ml-8': props.increasedFontSize,
          }"
        >
          <span>{{ props.recentChange > 0 ? '+' : '' }}</span>
          <span class="md:pl-0.5">{{ props.recentChange !== 0 ? props.recentChange : '-' }}</span>
          <span v-if="props.recentChange != null" class="md:pl-0.5" v-show="props.recentChange !== 0">{{
            props.recentChangeUnit != null ? props.recentChangeUnit : props.unit
          }}</span>
          <span v-if="props.recentChange > 0" class="pl-[1px] md:pl-1 -mt-0.5">
            <svg
              class="shrink-0 size-2 sm:size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="22 7 13.5 15.5 8.5 10.5 2 17" />
              <polyline points="16 7 22 7 22 13" />
            </svg>
          </span>
          <span v-else-if="props.recentChange < 0" class="pl-[1px] md:pl-1 mt-0.5">
            <svg
              class="shrink-0 size-2 sm:size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="22 17 13.5 8.5 8.5 13.5 2 7" />
              <polyline points="16 17 22 17 22 11" />
            </svg>
          </span>
          <span v-if="props.iconAtChangePosition" class="pl-1.5">
            <span translate="no" class="material-symbols-outlined notranslate">{{ props.iconAtChangePosition }}</span>
          </span>
        </div>
      </div>
      <div class="inline-flex items-center gap-x-2">
        <h3 class="text-gray-500 text-xs md:text-sm xl:text-base dark:text-neutral-500">
          {{ props.title }}
        </h3>

        <!-- Tooltip -->
        <div v-if="!!props.tooltipText" class="hs-tooltip inline-block">
          <svg
            class="hs-tooltip-toggle flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
            />
          </svg>
          <div
            class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 inline-block absolute invisible z-20 w-60 py-1.5 px-2.5 bg-gray-900 text-xs text-white rounded dark:bg-neutral-700"
            role="tooltip"
          >
            {{ props.tooltipText }}
          </div>
        </div>
        <!-- End Tooltip -->
      </div>
    </div>
  </div>
  <!-- End Card -->
</template>
