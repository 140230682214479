<script setup>
import { computed, onMounted, ref, onUnmounted } from 'vue';
import { watch } from 'vue';

const emit = defineEmits(['trackChanged']);

const props = defineProps({
  audioFiles: {
    type: Array,
    required: true,
  },
});

// Audio file URLs and state
// const audioFiles = ref([
//   'https://assets-prd.data.casuu.health/hallo_guten_tag.wav',
//   'https://assets-prd.data.casuu.health/hallo_guten_tag.wav',
//   'https://assets-prd.data.casuu.health/hallo_guten_tag.wav',
// ]);

const currentIndex = ref(0);
const totalDuration = ref(null);
const currentProgress = ref(0);
const fileDurations = ref([]); // Store individual file durations

const audioPlayer = ref(null);

// Compute the progress percentage (Note: this is just computed from the total duration of the audio files)
const progressPercentage = computed(() => {
  if (!totalDuration.value) return 0;
  return ((currentProgress.value / totalDuration.value) * 100).toFixed(2);
});

// Format time in MM:SS format
const formatTime = (timeInSeconds) => {
  if (!timeInSeconds || isNaN(timeInSeconds)) return '0:00';
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

// Computed properties for formatted times
const currentTimeFormatted = computed(() => formatTime(currentProgress.value));
const totalDurationFormatted = computed(() => formatTime(totalDuration.value));

// Calculate duration on mount
onMounted(async () => {
  if (audioPlayer.value && props.audioFiles && props.audioFiles.length > 0) {
    await calculateTotalDuration();
  }
});

watch(
  () => props.audioFiles,
  async () => {
    if (audioPlayer.value && props.audioFiles && props.audioFiles.length > 0) {
      await calculateTotalDuration();
    }
  },
  { deep: true },
);

// Update calculateTotalDuration to be more robust
async function calculateTotalDuration() {
  currentProgress.value = 0;
  totalDuration.value = 0;
  fileDurations.value = [];

  for (const file of props.audioFiles) {
    try {
      audioPlayer.value.src = file;
      await new Promise((resolve, reject) => {
        const onMetadata = () => {
          fileDurations.value.push(audioPlayer.value.duration);
          totalDuration.value += audioPlayer.value.duration;
          resolve();
        };

        const onError = () => {
          reject(new Error(`Failed to load audio file: ${file}`));
        };

        audioPlayer.value.addEventListener('loadedmetadata', onMetadata, { once: true });
        audioPlayer.value.addEventListener('error', onError, { once: true });
      });
    } catch (error) {
      console.error(error);
      // Handle error appropriately
    }
  }

  // Reset audio player after duration calculation
  audioPlayer.value.src = '';
}

let progressInterval = null;

// Start continuous progress update
function startProgressUpdate() {
  // Clear any existing interval
  if (progressInterval) clearInterval(progressInterval);

  // Update progress every 100ms
  progressInterval = setInterval(() => {
    if (audioPlayer.value && !audioPlayer.value.paused) {
      currentProgress.value += 0.1; // Increment by 100ms
    }
  }, 100);
}

const isPlaying = ref(false);

// Function to pause audio
function pauseAudio() {
  if (audioPlayer.value) {
    audioPlayer.value.pause();
    clearInterval(progressInterval);
    isPlaying.value = false;
  }
}

// Function to resume audio
function resumeAudio() {
  if (audioPlayer.value) {
    audioPlayer.value.play();
    startProgressUpdate();
    isPlaying.value = true;
  }
}

const handlePlayHeadClick = () => {
  if (isPlaying.value) {
    pauseAudio();
  } else {
    resumeAudio();
  }
};

// Handle progress bar click to set position
async function handleProgressBarClick(event) {
  const rect = event.currentTarget.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const percentage = x / rect.width;

  // Set new progress
  currentProgress.value = percentage * totalDuration.value;

  // Find which audio file and position to play
  let accumulatedTime = 0;
  for (let i = 0; i < fileDurations.value.length; i++) {
    const duration = fileDurations.value[i];
    if (accumulatedTime + duration > currentProgress.value) {
      // Found the right file
      currentIndex.value = i;
      audioPlayer.value.src = props.audioFiles[i];
      audioPlayer.value.currentTime = currentProgress.value - accumulatedTime;

      // Resume playback if it was playing
      if (isPlaying.value) {
        await audioPlayer.value.play();
        startProgressUpdate();
      }
      break;
    }
    accumulatedTime += duration;
  }
}

// Modify startPlaylist to set initial playing state
async function startPlaylist() {
  if (!audioPlayer.value || !props.audioFiles?.length > 0) return;

  emit('trackChanged', 0);

  currentIndex.value = 0;
  currentProgress.value = 0;
  await calculateTotalDuration();

  audioPlayer.value.src = props.audioFiles[currentIndex.value];
  await audioPlayer.value.play();
  isPlaying.value = true;
  startProgressUpdate();
}

// Play the next file in the sequence
async function playNext() {
  currentIndex.value++;
  if (currentIndex.value < props.audioFiles.length) {
    audioPlayer.value.src = props.audioFiles[currentIndex.value];
    await audioPlayer.value.play();
  } else {
    // Playback finished
    clearInterval(progressInterval);
    currentIndex.value = 0;
    currentProgress.value = totalDuration.value;
  }
}

const isDragging = ref(false);
const progressBarRef = ref(null);

// Start dragging
function startDragging(event) {
  event.stopPropagation(); // Prevent click event on progress bar
  isDragging.value = true;
  document.addEventListener('mousemove', handleDrag);
  document.addEventListener('mouseup', stopDragging);
}

// Handle drag movement
function handleDrag(event) {
  if (!isDragging.value || !progressBarRef.value) return;

  const rect = progressBarRef.value.getBoundingClientRect();
  const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
  const percentage = x / rect.width;

  // Update progress value
  currentProgress.value = percentage * totalDuration.value;
}

// Stop dragging and set audio position
async function stopDragging() {
  if (!isDragging.value) return;

  isDragging.value = false;
  document.removeEventListener('mousemove', handleDrag);
  document.removeEventListener('mouseup', stopDragging);

  // Find which audio file and position to play
  let accumulatedTime = 0;
  for (let i = 0; i < fileDurations.value.length; i++) {
    const duration = fileDurations.value[i];
    if (accumulatedTime + duration > currentProgress.value) {
      // Found the right file
      currentIndex.value = i;
      audioPlayer.value.src = props.audioFiles[i];
      audioPlayer.value.currentTime = currentProgress.value - accumulatedTime;

      // Resume playback if it was playing
      if (isPlaying.value) {
        await audioPlayer.value.play();
        startProgressUpdate();
      }
      break;
    }
    accumulatedTime += duration;
  }
}

const togglePlayback = async () => {
  if (currentProgress.value === 0) {
    await startPlaylist();
    return;
  }
  if (currentProgress.value === totalDuration.value) {
    await startPlaylist();
    return;
  }
  if (isPlaying.value) {
    pauseAudio();
  } else {
    resumeAudio();
  }
};

watch(
  () => currentProgress.value === totalDuration.value,
  () => {
    if (isPlaying.value) {
      isPlaying.value = false;
    }
  },
);

// Watch for track changes
watch(
  () => currentIndex.value,
  (newIndex) => {
    emit('trackChanged', newIndex);
  },
);

// Clean up on component unmount
onUnmounted(() => {
  clearInterval(progressInterval);
  if (audioPlayer.value) {
    audioPlayer.value.pause();
    audioPlayer.value.src = '';
  }
  document.removeEventListener('mousemove', handleDrag);
  document.removeEventListener('mouseup', stopDragging);
});

// Function to set progress by percentage (0-100)
async function setProgressByPercentage(percentage) {
  if (!totalDuration.value) return;

  // Clamp percentage between 0 and 100
  const clampedPercentage = Math.max(0, Math.min(100, percentage));

  // Calculate target time
  const targetTime = (clampedPercentage / 100) * totalDuration.value;
  currentProgress.value = targetTime;

  // Find which audio file and position to play
  let accumulatedTime = 0;
  for (let i = 0; i < fileDurations.value.length; i++) {
    const duration = fileDurations.value[i];
    if (accumulatedTime + duration > targetTime) {
      // Found the right file
      currentIndex.value = i;
      audioPlayer.value.src = props.audioFiles[i];
      audioPlayer.value.currentTime = targetTime - accumulatedTime;

      // Resume playback if it was playing
      if (isPlaying.value) {
        await audioPlayer.value.play();
        startProgressUpdate();
      }
      break;
    }
    accumulatedTime += duration;
  }
}

// Make the function available to parent components
defineExpose({
  setProgressByPercentage,
});
</script>

<template>
  <div class="inline-flex w-full gap-x-2 items-end">
    <button
      @click="togglePlayback"
      class="bg-blue-500 text-white rounded-full rounded hover:bg-blue-600 items-center justify-center flex"
    >
      <span translate="no" class="material-symbols-outlined notranslate">
        {{ isPlaying ? 'pause' : 'play_arrow' }}
      </span>
    </button>

    <div class="flex flex-col items-center w-full">
      <!-- Time Display -->
      <div class="w-full flex justify-between text-sm text-gray-600 mb-1">
        <span>{{ currentTimeFormatted }}</span>
        <span>{{ totalDurationFormatted }}</span>
      </div>

      <!-- Progress Bar with draggable play head -->
      <div
        ref="progressBarRef"
        class="group relative w-full bg-gray-300 h-2 rounded-full overflow-hidden mb-2 cursor-pointer"
        @click="handleProgressBarClick"
      >
        <!-- Progress fill -->
        <div
          class="bg-blue-500 h-full"
          :style="{
            width: `${progressPercentage}%`,
            transition: isDragging ? 'none' : 'width 0.25s linear',
          }"
        ></div>

        <!-- Play head -->
        <div
          class="absolute top-1/2 w-3 h-3 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity cursor-grab"
          :class="{ 'cursor-grabbing': isDragging }"
          :style="{
            left: `${progressPercentage}%`,
            transform: 'translate(-50%, -50%)',
            transition: isDragging ? 'none' : 'left 0.25s linear',
          }"
          @mousedown="startDragging"
          @click="handlePlayHeadClick"
        >
          <!-- Inner circle -->
          <div
            class="absolute top-1/2 left-1/2 w-1.5 h-1.5 bg-blue-500 rounded-full"
            style="transform: translate(-50%, -50%)"
          ></div>
        </div>
      </div>

      <!-- hidden audio player -->
      <audio
        ref="audioPlayer"
        @ended="playNext"
        controls
        controlsList="nodownload"
        oncontextmenu="return false;"
        class="hidden"
      ></audio>
    </div>
  </div>
</template>

<style scoped></style>
