<script setup lang="ts">
import PatientCaseDropdown from '@/components/dropdowns/PatientCaseDropdown.vue';
import PatientInteractionListDropdown from '@/components/dropdowns/PatientInteractionListDropdown.vue';
import SignInOrEditProfile from '@/components/headers/SignInOrEditProfile.vue';
import { useAuthStore, useCaseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { feedbackIntegration } from '@sentry/vue';
import CourseDropdown from '@/components/dropdowns/CourseDropdown.vue';
import IconWithUnreadMark from '@/components/IconWithUnreadMark.vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import VocabDropdown from '@/components/dropdowns/VocabDropdown.vue';
import { HSCollapse, HSDropdown } from 'preline';
import SignInOrEditProfileDropdownContents from '@/components/headers/SignInOrEditProfileDropdownContents.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import { debounce } from 'lodash';
import { is } from '@vee-validate/rules';
import ProgressButton from '@/components/ProgressButton.vue';

const authStore = useAuthStore();
const caseInteractionStore = useCaseInteractionStore();

const { dailyCasePlayed, isSignedIn } = storeToRefs(authStore);

const emit = defineEmits(['takeDailyQuickTest']);

const courseDropdown = ref(null);
const vocabDropdown = ref(null);
const patientCaseDropdown = ref(null);
const menu = ref(null);
const showMenu = ref(false);
const animating = ref(false); // menu open/close animation
const isLargeScreen = ref(false);

const toggleMenu = () => {
  animating.value = true;
  showMenu.value = !showMenu.value;
};

const handleTransitionEnd = () => {
  if (!showMenu.value) {
    animating.value = false;
  }
};

const updateScreenSize = () => {
  isLargeScreen.value = window.innerWidth >= 768; // Matches Tailwind's `md` breakpoint
  if (isLargeScreen.value) {
    showMenu.value = true; // Always open on large screens
  }
};

const debounceUpdateScreenSize = debounce(updateScreenSize, 200);

const props = defineProps({
  showRootLink: {
    type: Boolean,
    default: true,
  },
  showHomeLink: {
    type: Boolean,
    default: true,
  },
  showCommunityLink: {
    type: Boolean,
    default: false,
  },
  showCoursesDropdown: {
    type: Boolean,
    default: true,
  },
  showVocabDropdown: {
    type: Boolean,
    default: true,
  },
  showPatientCaseDropdown: {
    type: Boolean,
    default: true,
  },
  showChallengesAndEventsLink: {
    type: Boolean,
    default: true,
  },
  showAboutUsLink: {
    type: Boolean,
    default: true,
  },
  showSignInOrEditProfile: {
    type: Boolean,
    default: true,
  },
  showCourseInteractionsLink: {
    type: Boolean,
    default: true,
  },
  testModalRef: {
    type: Object,
    default: null,
  },
});

async function openReportBugModal() {
  const feedback = feedbackIntegration({
    // Disable injecting the default widget
    autoInject: false,
    theme: 'light',
    useSentryUser: {
      name: 'fullName',
      email: 'email',
    },
    themeLight: {
      accentBackground: '#2563eb',
    },
    themeDark: {
      accentBackground: '#2563eb',
    },
  });

  const form = await feedback.createForm();
  form.appendToDom();
  form.open();
}

const dailyVocabTestPlayed = ref(false);

onMounted(async () => {
  updateScreenSize();

  await new Promise((resolve) => setTimeout(resolve, 200)); // wait for App.vue's HSStaticMethods.autoInit() to finish
  setTimeout(() => {
    HSDropdown.autoInit();
    // HSCollapse.autoInit();
  }, 200);

  courseDropdown.value = new HSDropdown(document.getElementById('courseDropdown'));
  vocabDropdown.value = new HSDropdown(document.getElementById('vocabDropdown'));
  patientCaseDropdown.value = new HSDropdown(document.getElementById('patientCaseDropdown'));
  window.addEventListener('resize', debounceUpdateScreenSize);

  console.log('courseDropdown', courseDropdown.value);
  console.log('vocabDropdown', vocabDropdown.value);
  console.log('patientCaseDropdown', patientCaseDropdown.value);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceUpdateScreenSize);
});

const closeMenu = () => {
  showMenu.value = false;

  if (courseDropdown.value) {
    courseDropdown.value.close();
  }
  if (vocabDropdown.value) {
    vocabDropdown.value.close();
  }
  if (patientCaseDropdown.value) {
    patientCaseDropdown.value.close();
  }
};
</script>

<template>
  <nav
    class="max-w-[85rem] w-full mx-auto md:-my-3.5 px-3 sm:px-1 md:px-4 lg:px-6 xl:px-8 overflow-visible"
    aria-label="Global"
  >
    <div class="relative w-full max-w-full h-full max-h-full md:flex md:items-center md:justify-between">
      <div class="flex items-center justify-between">
        <div v-if="isSignedIn" class="block md:hidden">
          <ProfileImage
            :image="authStore.userProfileImageSmall"
            :initials="authStore.userInitials"
            :showIngameLevel="true"
          />
        </div>
        <router-link to="/" v-show="showRootLink">
          <a class="flex-none text-xl font-semibold dark:text-white" aria-label="Brand">casuu</a>
          <a class="flex-none text-xl font-semibold text-blue-600" aria-label="Brand">.health</a>
        </router-link>
        <div v-if="isSignedIn" class="md:hidden">
          <button
            type="button"
            class="p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
            @click.prevent="toggleMenu"
          >
            <div :class="{ 'block md:hidden': !showMenu, hidden: showMenu }">
              <IconWithUnreadMark :mark-unread="!dailyCasePlayed || !dailyVocabTestPlayed" :animate-ping="true">
                {{ $t('message.menu') }}
              </IconWithUnreadMark>
            </div>
            <div :class="{ hidden: !showMenu, block: showMenu }">
              <IconWithUnreadMark :mark-unread="!dailyCasePlayed || !dailyVocabTestPlayed">
                {{ $t('message.menu') }}
              </IconWithUnreadMark>
            </div>
            <div class="hidden md:block">
              {{ $t('message.menu') }}
            </div>
            <span
              translate="no"
              class="material-symbols-outlined notranslate"
              :class="{
                block: !showMenu,
                hidden: showMenu,
              }"
            >
              menu
            </span>
            <span
              translate="no"
              class="material-symbols-outlined notranslate"
              :class="{
                hidden: !showMenu,
                block: showMenu,
              }"
            >
              close
            </span>
          </button>
        </div>
        <div v-else class="block md:hidden">
          <ProgressButton
            routerLinkTo="/account/sign-in"
            color="blue"
            size="sm"
            :text="$t('message.signIn')"
          ></ProgressButton>
        </div>
      </div>

      <div
        ref="menu"
        class="pl-4 md:pl-0 overflow-hidden md:overflow-visible transition-all duration-300 basis-full grow"
        :style="{ maxHeight: isLargeScreen || showMenu ? '1000px' : '0px' }"
        @transitionend="handleTransitionEnd"
      >
        <div class="overflow-visible">
          <div
            class="flex flex-col gap-x-0 divide-y divide-dashed divide-gray-200 md:flex-row md:items-center md:justify-end md:gap-x-7 mt-5 md:mt-0 md:pl-7 md:divide-y-0 md:divide-solid dark:divide-gray-700"
          >
            <!-- User dashboard = home -->
            <router-link
              to="/home"
              v-show="showHomeLink"
              class="font-medium inline-flex text-gray-500 hover:text-gray-400 py-2 dark:text-gray-400 dark:hover:text-gray-500"
            >
              <span translate="no" class="material-symbols-outlined notranslate block md:hidden pr-2">home</span>
              {{ $t('message.home') }}
            </router-link>

            <!-- Community -->
            <a
              v-show="showCommunityLink"
              href="https://community.casuu.care/"
              class="font-medium inline-flex text-gray-500 hover:text-gray-400 py-2 dark:text-gray-400 dark:hover:text-gray-500"
            >
              <span translate="no" class="material-symbols-outlined notranslate block md:hidden pr-2">forum</span>
              {{ $t('message.community') }}
            </a>

            <!-- Courses -->
            <div
              class="hs-dropdown group [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:click] py-2"
              v-show="showCoursesDropdown"
              id="courseDropdown"
            >
              <button
                type="button"
                class="gap-x-0.5 hs-dropdown-toggle flex items-center w-full text-gray-500 group-hover:text-gray-400 font-medium dark:text-gray-400 dark:group-hover:text-gray-500"
                data-testid="course-dropdown-button"
              >
                <span translate="no" class="material-symbols-outlined notranslate block md:hidden pr-2"
                  >local_library</span
                >
                {{ $t('message.courses') }}
                <span
                  translate="no"
                  class="material-symbols-outlined notranslate text-2xl block hs-dropdown-open:rotate-180 transition-all transform duration-300 ease-in-out"
                >
                  expand_more
                </span>
              </button>
              <CourseDropdown @closeDropdowns="closeMenu" />
            </div>

            <!-- Vocab -->
            <div
              class="hs-dropdown group [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:click] py-2"
              v-show="showVocabDropdown && isSignedIn"
              id="vocabDropdown"
            >
              <button
                type="button"
                class="hs-dropdown-toggle flex items-center w-full text-gray-500 group-hover:text-gray-400 font-medium dark:text-gray-400 dark:group-hover:text-gray-500"
                :class="{
                  'gap-x-1': !dailyVocabTestPlayed,
                  'gap-x-0.5': dailyVocabTestPlayed,
                }"
                data-testid="vocab-dropdown-button"
              >
                <span translate="no" class="material-symbols-outlined notranslate block md:hidden pr-2">exercise</span>
                <div class="block md:hidden hs-dropdown-open:hidden">
                  <IconWithUnreadMark :mark-unread="!dailyVocabTestPlayed" :animate-ping="true">
                    {{ $t('message.vocabDropdown') }}
                  </IconWithUnreadMark>
                </div>
                <div class="hidden hs-dropdown-open:block md:hs-dropdown-open:hidden">
                  <IconWithUnreadMark :mark-unread="!dailyVocabTestPlayed">
                    {{ $t('message.vocabDropdown') }}
                  </IconWithUnreadMark>
                </div>
                <div class="hidden md:block">
                  <IconWithUnreadMark :mark-unread="!dailyVocabTestPlayed">
                    {{ $t('message.vocabDropdown') }}
                  </IconWithUnreadMark>
                </div>
                <span
                  translate="no"
                  class="material-symbols-outlined notranslate text-2xl block hs-dropdown-open:rotate-180 transition-all transform duration-300 ease-in-out"
                >
                  expand_more
                </span>
              </button>
              <VocabDropdown
                @takeDailyQuickTest="
                  () => {
                    console.log('MainHeader: forwarding event takeDailyQuickTest');
                    dailyVocabTestPlayed = true;
                    emit('takeDailyQuickTest');
                  }
                "
                :dailyVocabTestTaken="dailyVocabTestPlayed"
                @closeDropdowns="closeMenu"
              />
            </div>

            <!-- Patient cases -->
            <div
              class="hs-dropdown group [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:click] py-2 md:pt-2 md:pb-2"
              v-show="showPatientCaseDropdown && isSignedIn"
              id="patientCaseDropdown"
            >
              <button
                type="button"
                class="hs-dropdown-toggle flex items-center w-full text-gray-500 group-hover:text-gray-400 font-medium dark:text-gray-400 dark:group-hover:text-gray-500"
                :class="{
                  'gap-x-1': !dailyCasePlayed,
                  'gap-x-0.5': dailyCasePlayed,
                }"
              >
                <span translate="no" class="material-symbols-outlined notranslate block md:hidden pr-2"
                  >personal_injury</span
                >
                <div class="block md:hidden hs-dropdown-open:hidden">
                  <IconWithUnreadMark :mark-unread="!dailyCasePlayed" :animate-ping="true">
                    {{ $t('message.newCase') }}
                  </IconWithUnreadMark>
                </div>
                <div class="hidden hs-dropdown-open:block md:hs-dropdown-open:hidden">
                  <IconWithUnreadMark :mark-unread="!dailyCasePlayed">
                    {{ $t('message.newCase') }}
                  </IconWithUnreadMark>
                </div>
                <div class="hidden md:block">
                  <IconWithUnreadMark :mark-unread="!dailyCasePlayed">
                    {{ $t('message.newCase') }}
                  </IconWithUnreadMark>
                </div>
                <span
                  translate="no"
                  class="material-symbols-outlined notranslate text-2xl block hs-dropdown-open:rotate-180 transition-all transform duration-300 ease-in-out"
                >
                  expand_more
                </span>
              </button>
              <PatientCaseDropdown @closeDropdowns="closeMenu" />
            </div>

            <!-- Report bug -->
            <div
              class="hidden md:block hs-dropdown group [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:click] py-2"
            >
              <button
                class="font-medium text-gray-500 hover:text-gray-400 py-2 md:py-6 dark:text-gray-400 dark:hover:text-gray-500"
                @click="openReportBugModal"
              >
                {{ $t('message.reportABug') }}
              </button>
            </div>

            <!-- Über uns -->
            <div
              class="hidden md:block hs-dropdown group [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:click] py-2"
            >
              <a
                class="font-medium text-gray-500 hover:text-gray-400 py-2 md:py-6 dark:text-gray-400 dark:hover:text-gray-500"
                href="/about-us"
                v-show="showAboutUsLink"
              >
                {{ $t('message.aboutUs') }}
              </a>
            </div>

            <!-- Large screen: Sign in or edit profile -->
            <div
              class="hidden md:block py-2"
              v-show="showSignInOrEditProfile"
              data-testid="large-screen-sign-in-or-edit-profile-button"
            >
              <SignInOrEditProfile />
            </div>

            <!-- Small screen: Sign in or edit profile -->
            <div class="block md:hidden">
              <SignInOrEditProfileDropdownContents @closeMenu="closeMenu" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped></style>
