<!--
Shows messages from system, e.g. initial case description, therapy effects, ...
TODO: Fetch text from DB
TODO: replace logo by casoo icon or sth similar
-->

<script setup>
import SystemMessageFooter from '@/components/case_interaction/SystemMessageFooter.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore, usePatientInteractionStore } from '@/stores';

const patientInteractionStore = usePatientInteractionStore();
const { chatIsStreaming } = storeToRefs(patientInteractionStore);

const authStore = useAuthStore();
const { userFirstName, userLastName } = storeToRefs(authStore);

const { message, isStreaming } = defineProps(['message', 'isStreaming']);
const editOn = ref(false);
const editedMessage = ref('');
const originalMessage = ref('');
const hasBeenEdited = ref(false);

// import shared functions
import { useChatBubbleFunctions } from '@/components/chat_bubbles/chatBubbleFunctions';
import { getSentenceForCharacter, unobfuscateUserName } from '@/helper';

const { selectedSentenceIndcs, calculateCols, calculateRows, getSelectedCharacterPositions } = useChatBubbleFunctions();

const selectableDiv = ref(null);

function handleClickOutside(event) {
  // Check if the clicked element is outside the selectableDiv
  if (!selectableDiv.value.contains(event.target)) {
    // Reset the selected positions
    selectedSentenceIndcs.value = [];
  }
}

function onSelectedText() {
  console.log('ref', selectableDiv);
  let characterPositions = getSelectedCharacterPositions(selectableDiv.value);
  selectedSentenceIndcs.value = [];
  for (let i = 0; i < characterPositions.length; i++) {
    let sentenceIdx = getSentenceForCharacter(message.content['processed_model_output'], characterPositions[i]);
    if (!selectedSentenceIndcs.value.includes(sentenceIdx)) {
      selectedSentenceIndcs.value.push(sentenceIdx);
    }
  }
}

async function editToggled(new_value) {
  editOn.value = new_value.value;
  console.debug('System chat bubble: Edit mode toggled to: ' + editOn.value);
  if (editOn.value === true) {
    console.debug('Edit on.');
    editedMessage.value = message.content['processed_model_output'];
    originalMessage.value = message.content['processed_model_output'];
    return;
  }
  console.debug('Edit off. Checking for new values');
  console.debug('Old message: ' + originalMessage.value);
  console.debug('New message: ' + editedMessage.value);
  if (editedMessage.value !== originalMessage.value) {
    console.debug('Message changed. Pushing to DB.');
    await patientInteractionStore.storeUserEditedChatMessage(message, editedMessage.value);
    originalMessage.value = editedMessage.value;
    hasBeenEdited.value = true;
  }
}

onMounted(() => {
  // Listen for a click event on the document
  document.addEventListener('click', handleClickOutside);
});

// Cleanup the event listener when the component is unmounted
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

const messageHasContent = computed(() => {
  return message.content['processed_model_output'] !== '';
});
</script>

<template>
  <li v-show="messageHasContent" class="flex">
    <div class="flex-col">
      <!-- Content -->
      <div
        class="border rounded-lg p-4 space-y-3 dark:bg-neutral-900 dark:border-gray-700 flex"
        :class="{
          'bg-white': !isStreaming,
          'bg-gray-200': isStreaming,
        }"
      >
        <p v-if="editOn">
          <textarea
            class="border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
            v-model="editedMessage"
            :rows="calculateRows(editedMessage)"
            :cols="calculateCols(editedMessage)"
          >
          </textarea>
        </p>
        <p v-else-if="hasBeenEdited" class="text-sm text-gray-800 dark:text-white" data-testid="desc-message-content">
          {{ unobfuscateUserName(originalMessage, userFirstName, userLastName) }}
        </p>
        <div
          v-else
          class="text-sm text-gray-800 text-justify dark:text-white"
          @mouseup="onSelectedText"
          ref="selectableDiv"
          data-testid="desc-message-content"
        >
          {{ unobfuscateUserName(message.content['processed_model_output'], userFirstName, userLastName) }}
        </div>
      </div>
      <!-- End of Content -->

      <!-- Button Group -->
      <SystemMessageFooter
        :message="message"
        messageType="DESCRIPTION"
        conversation="PATIENT"
        @onEditToggled="editToggled"
        :hideFooter="chatIsStreaming"
        :markedSentenceIndcs="selectedSentenceIndcs"
      />
      <!-- End Button Group -->
    </div>
  </li>
</template>

<style scoped></style>
