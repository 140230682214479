<script setup lang="ts">
import AddNewButton from '@/components/new_case/AddNewButton.vue';
import { onMounted, ref, computed, watch } from 'vue';
import { HSDropdown } from 'preline';
import { useAlertStore } from '@/stores';
import MessageCard from './MessageCard.vue';
import AudioEffectCard from './AudioEffectCard.vue';
import DescMessageCard from './DescMessageCard.vue';
import { v4 as uuidv4 } from 'uuid';

interface Message {
  index: number;
  content: string;
  person_message_involvements: Array<Object> | null;
  type: string;
  audio_url: string;
}

const messages = defineModel({
  type: Array as () => Array<Message>,
  required: true,
});

const { isLoading, startingFromCompleteDialog, persons } = defineProps([
  'isLoading',
  'startingFromCompleteDialog',
  'persons',
]);
const emit = defineEmits(['click', 'isValid']);

const alertStore = useAlertStore();

const messageCardRefs = ref<Array<any>>([]);
const messageCardsAreValid = ref([] as boolean[]);

const audioEffectDropdown = ref(null);
const audioEffectDropdownId = ref(`audio-effect-dropdown-${uuidv4()}`);

async function addMessage() {
  console.log('addMessage');
  messages.value.push({
    index: messages.value.length,
    content: '',
    person_message_involvements: [],
    type: 'PERSON_SAY',
    audio_url: '',
  });
  messageCardRefs.value.push(ref(null));
  messageCardsAreValid.value.push(false); // no criteria, no persons assigned etc. so not valid from start
}

async function addAudioEffect(effectType: string) {
  switch (effectType) {
    case 'phone_ringing':
      messages.value.push({
        index: messages.value.length,
        content: '',
        person_message_involvements: null,
        type: 'AUDIO_EFFECT',
        audio_url:
          'https://assets-prd.data.casuu.health/audio_effects/phone_ringing_725111__dachou21__phone-ringing.wav',
      });
      return;
    case 'phone_pickup':
      messages.value.push({
        index: messages.value.length,
        content: '',
        person_message_involvements: null,
        type: 'AUDIO_EFFECT',
        audio_url:
          'https://assets-prd.data.casuu.health/audio_effects/phone_pickup_454105__kyles__phone-hotel-pickup-put-down-various.wav',
      });
      return;
    case 'footsteps':
      messages.value.push({
        index: messages.value.length,
        content: '',
        person_message_involvements: null,
        type: 'AUDIO_EFFECT',
        audio_url:
          'https://assets-prd.data.casuu.health/audio_effects/footsteps_672065__silverillusionist__footsteps-on-dirt-retro-style.wav',
      });
      return;
  }
  alertStore.error('Audioeffekt "' + effectType + '" nicht gefunden', 'Bitte wähle einen anderen Audioeffekt');
}

function addStageDirection() {
  messages.value.push({
    index: messages.value.length,
    content: '',
    person_message_involvements: null,
    type: 'DESC',
    audio_url: '',
  });
}

async function removeMessage(id: number) {
  console.log('removeMessage', id);
  // get id of task with task.id = id
  const indexToRemove = messages.value.findIndex((task) => task.index === id);
  messages.value.splice(indexToRemove, 1);
  messageCardRefs.value.splice(indexToRemove, 1);
  messageCardsAreValid.value.splice(indexToRemove, 1);
}

async function closeAllOtherMessageEdits(index: number) {
  messageCardRefs.value.forEach((ref, i) => {
    if (i !== index && ref.value) {
      ref.value[0].closeEdit();
    }
  });
}

onMounted(async () => {
  for (let i = 0; i < messages.value.length; i++) {
    messageCardRefs.value.push(ref(null));
    messageCardsAreValid.value.push(false);
  }

  await new Promise((resolve) => setTimeout(resolve, 200));
  audioEffectDropdown.value = new HSDropdown(document.getElementById(audioEffectDropdownId.value));
});

const isValid = computed(() => {
  if (!persons.value) {
    return false;
  }
  console.log('!!messageCardsAreValid', !!messageCardsAreValid.value);
  console.log('any task: ', !!messages.value);
  console.log(
    'checking all cards',
    messageCardsAreValid.value.every((isValid) => isValid),
  );
  console.log(
    persons.value.length,
    !!messageCardsAreValid.value,
    !!messages.value,
    messageCardsAreValid.value.every((isValid) => isValid),
  );
  let isValid =
    !!messageCardsAreValid.value && !!messages.value && messageCardsAreValid.value.every((isValid) => isValid);
  console.log(
    !!messageCardsAreValid.value,
    !!messages.value,
    messageCardsAreValid.value.every((isValid) => isValid),
  );
  console.log('Scenes and messages are valid: ', isValid);
  return isValid;
});

function messageCardValidityChanged(isValid: boolean, index: number) {
  console.log('messageCardValidityChanged', isValid, index);
  messageCardsAreValid.value[index] = isValid;
}

watch(
  () => isValid.value,
  (isValid) => {
    console.log('isValid (scenes&messages) emitting', isValid);
    emit('isValid', isValid);
  },
);

watch(
  () => messages.value?.length,
  (newVal) => {
    console.log('change of messages length');
    for (let i = messageCardRefs.value.length; i < messages.value.length; i++) {
      console.log('adding new scene card after external change, i:', i);
      messageCardRefs.value.push(ref(null));
      messageCardsAreValid.value.push(false);
    }
  },
);
</script>

<template>
  <div class="py-8 grid grid-cols-11 gap-y-1">
    <h2 class="col-span-11 inline-block text-xl mt-2.5 font-bold text-gray-800 dark:text-gray-200">
      Leg den Gesprächsverlauf an
    </h2>
    <div class="col-span-11">
      <div class="mt-6 col-span-11 w-full inline-flex overflow-y-auto gap-y-2">
        <!-- Card List Group -->
        <div class="space-y-3 flex-col w-full justify-center">
          <span v-show="messages?.length === 0" class="text-sm text-blue-600 cursor-pointer" @click="addMessage"
            >Füge eine Nachricht hinzu</span
          >

          <div v-for="(message, index) in messages" class="flex-col flex justify-center text-center w-full">
            <div class="inline-flex items-start justify-center w-full">
              <span class="text-sm text-gray-500 mt-8 text-2xl pr-4 font-bold">{{ index + 1 }}</span>
              <MessageCard
                v-if="message.type === 'PERSON_SAY'"
                :ref="messageCardRefs[index]"
                v-model="messages[index]"
                :persons="persons"
                :number="index + 1"
                :key="'person-say-' + message.index"
                :startingFromCompleteDialog="startingFromCompleteDialog"
                @removeMessage="removeMessage"
                @closeAllOtherMessageEdits="closeAllOtherMessageEdits(index)"
                @isValid="messageCardValidityChanged($event, index)"
              />
              <AudioEffectCard
                v-else-if="message.type === 'AUDIO_EFFECT'"
                :ref="messageCardRefs[index]"
                v-model="messages[index]"
                :persons="persons"
                :number="index + 1"
                :key="'audio-effect-' + message.index"
                :startingFromCompleteDialog="startingFromCompleteDialog"
                @removeMessage="removeMessage"
                @closeAllOtherMessageEdits="closeAllOtherMessageEdits(index)"
                @isValid="messageCardValidityChanged($event, index)"
              />
              <DescMessageCard
                v-else-if="message.type === 'DESC'"
                :ref="messageCardRefs[index]"
                v-model="messages[index]"
                :number="index + 1"
                :key="'desc-' + message.index"
                :startingFromCompleteDialog="startingFromCompleteDialog"
                @removeMessage="removeMessage"
                @closeAllOtherMessageEdits="closeAllOtherMessageEdits(index)"
                @isValid="messageCardValidityChanged($event, index)"
              />
            </div>
          </div>
          <!-- Note: we need v-model="messages[index]" to make the changes in the child component reflect in the parent -->
        </div>
        <!-- End Card List Group -->
      </div>
      <div class="flex mx-auto w-[50vH] mt-6 gap-x-4">
        <AddNewButton @click="addMessage" :isLoading="isLoading" buttonText="Nachricht" />

        <!-- Audio Effect Dropdown -->
        <div class="hs-dropdown relative inline-flex" :id="audioEffectDropdownId">
          <AddNewButton type="button" class="hs-dropdown-toggle" :isLoading="isLoading" buttonText="Audioeffekt" />

          <div
            class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100 opacity-0 w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700"
          >
            <div class="py-2 first:pt-0 last:pb-0">
              <button
                @click="addAudioEffect('phone_ringing')"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 w-full"
              >
                <span class="material-symbols-outlined">phone_in_talk</span>
                Telefon klingelt
              </button>
              <button
                @click="addAudioEffect('phone_pickup')"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 w-full"
              >
                <span class="material-symbols-outlined">call_end</span>
                Telefon wird abgehoben
              </button>
              <button
                @click="addAudioEffect('footsteps')"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 w-full"
              >
                <span class="material-symbols-outlined">directions_walk</span>
                Schritte
              </button>
            </div>
          </div>
        </div>

        <AddNewButton @click="addStageDirection" :isLoading="isLoading" buttonText="Regieanweisung" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
