<script setup lang="ts">
import { Subtask, SubtaskInteraction, TaskInteraction } from '@/apiclient';
import ProfileImage from '@/components/ProfileImage.vue';
import { useCaseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
  hightlightNextSubtask: {
    type: Boolean,
    default: true,
  },
  delay: {
    type: Number,
    default: 0,
  },
  integrated: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['allSubtaskInteractionsCompletedAndAnimationFinished']);
const caseInteractionStore = useCaseInteractionStore();
const delayedSubtasks = ref([]);
const delayedCurrentSubtaskInteraction = ref<SubtaskInteraction | null>(null);
const delayedTaskInteraction = ref<TaskInteraction | null>(null);

const { currentSubtaskInteraction } = storeToRefs(caseInteractionStore);

const taskInteraction = computed(() => {
  if (!props.task) return null;
  return caseInteractionStore.currentCaseInteraction?.task_interactions[props.task.index];
});

onMounted(() => {
  if (!!props.task) {
    delayedSubtasks.value = props.task.subtasks ? JSON.parse(JSON.stringify(props.task.subtasks)) : [];
    delayedTaskInteraction.value = taskInteraction.value ? JSON.parse(JSON.stringify(taskInteraction.value)) : null;
  }
});

watch(
  () => taskInteraction.value,
  (newTaskInteraction) => {
    setTimeout(() => {
      delayedTaskInteraction.value = newTaskInteraction ? JSON.parse(JSON.stringify(newTaskInteraction)) : null;
    }, props.delay);
  },
  { deep: true, immediate: true },
);

const delayedSubtaskInteractionIsComplete = computed(() => {
  let completed: boolean[] = [];
  if (!delayedTaskInteraction.value) return completed;
  if (!delayedTaskInteraction.value.subtask_interactions) return completed;
  for (let i = 0; i < delayedTaskInteraction.value.subtask_interactions.length; i++) {
    completed.push(delayedTaskInteraction.value.subtask_interactions[i].completed_at !== null);
  }
  return completed;
});

const allSubtaskInteractionsCompletedAndAnimationFinished = computed(() => {
  return delayedSubtaskInteractionIsComplete.value.every((completed: boolean) => completed);
});

const profileImagePathOfFirstNonNullPersonForTask = () => {
  if (!props.task) return '';
  if (props.task.index === null) return '';

  let person = caseInteractionStore.getFirstNonNullPerson(props.task.index);
  return person?.profileImageSmall || '';
};

const numTask = computed(() => {
  if (!props.task) return '';
  if (props.task.index === null) return '';

  return props.task.index + 1;
});

const taskTitle = computed(() => {
  if (!props.task) return '';
  return props.task.title;
});

const taskDescription = computed(() => {
  if (!props.task) return '';
  return props.task.details.description ? props.task.details.description : '';
});

watch(
  () => props.task?.subtasks,
  (newSubtasks) => {
    setTimeout(() => {
      delayedSubtasks.value = newSubtasks ? JSON.parse(JSON.stringify(newSubtasks)) : [];
    }, props.delay);
  },
  { deep: true },
);

watch(
  () => currentSubtaskInteraction.value,
  (newCurrentSubtaskInteraction) => {
    setTimeout(() => {
      delayedCurrentSubtaskInteraction.value = newCurrentSubtaskInteraction
        ? JSON.parse(JSON.stringify(newCurrentSubtaskInteraction))
        : null;
    }, props.delay);
  },
  { deep: true },
);

watch(
  () => allSubtaskInteractionsCompletedAndAnimationFinished.value,
  (newCompletionState) => {
    if (!newCompletionState) return;
    emit('allSubtaskInteractionsCompletedAndAnimationFinished');
  },
);
</script>

<template>
  <div
    class="text-start after:absolute after:top-0 after:-start-4 after:w-4 after:h-full"
    :class="{
      'bg-white border border-gray-100 rounded-xl shadow-md dark:bg-neutral-800 dark:border-neutral-700': !integrated,
      'bg-transparent border-none rounded-none shadow-none': integrated,
    }"
  >
    <!-- # {{ props.task?.index }}
    # {{ taskInteraction?.id }}
    # {{ taskInteraction?.index }}
    # {{ currentSubtaskInteraction?.id }}
    # {{ currentSubtaskInteraction?.index }}
    #########################################################
    # {{ delayedSubtasks }}
    #########################################################
    # {{ delayedTaskInteraction }} -->

    <!-- Popover Header -->
    <div class="inline-flex items-start py-3 px-4 gap-x-3 w-full border-b border-gray-200 dark:border-neutral-700">
      <div class="items-center">
        <ProfileImage :image="profileImagePathOfFirstNonNullPersonForTask()" />
      </div>
      <div class="flex flex-col">
        <p class="flex grow text-sm text-gray-500 dark:text-neutral-500">Aufgabe {{ numTask }}</p>
        <h4 class="flex grow text-sm font-semibold text-gray-800 dark:text-white">
          {{ taskTitle }}
        </h4>
        <p class="flex grow text-sm text-gray-500 dark:text-neutral-500">
          {{ taskDescription }}
        </p>
      </div>
    </div>

    <!-- Popover List -->
    <ul class="py-3 space-y-1.5">
      <li
        v-for="(subtask, index) in delayedSubtasks"
        :key="subtask.id"
        v-if="delayedSubtasks?.length === delayedTaskInteraction?.subtask_interactions?.length"
      >
        <div
          class="inline-flex items-center gap-x-3 text-sm relative pl-16 pr-4 w-full transition-all duration-500"
          :class="{
            'text-gray-900 dark:text-neutral-200 bg-gray-200':
              delayedTaskInteraction?.subtask_interactions[index].id === delayedCurrentSubtaskInteraction?.id,
            'text-gray-600 dark:text-neutral-400':
              delayedTaskInteraction?.subtask_interactions[index].id !== delayedCurrentSubtaskInteraction?.id,
            'text-teal-600 dark:text-teal-400': delayedSubtaskInteractionIsComplete[index],
          }"
        >
          <span
            class="absolute left-0 flex items-center font-light px-2"
            v-if="delayedTaskInteraction?.subtask_interactions[index].id === delayedCurrentSubtaskInteraction?.id"
          >
            Jetzt
            <span translate="no" class="material-symbols-outlined notranslate text-2xl -ml-1">chevron_right</span>
          </span>
          <span translate="no" class="material-symbols-outlined notranslate text-2xl">
            {{ delayedSubtaskInteractionIsComplete[index] ? 'task_alt' : 'circle' }}
          </span>
          {{ subtask.details.target }}
        </div>
      </li>
    </ul>
  </div>
</template>
