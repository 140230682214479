<script setup>
import CaseHighlightCard from '@/components/cases/CaseHighlightCard.vue';
import { router } from '@/router';

const props = defineProps({
  cases: {
    type: Array,
    default: () => [],
  },
  inlineOverflowingX: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div
    :class="{
      'max-w-[85rem] px-4 py-2 sm:px-6 lg:px-8 lg:py-2 mx-auto': !props.inlineOverflowingX,
      'inline-flex w-full overflow-x-scroll': props.inlineOverflowingX,
    }"
  >
    <!-- Grid -->
    <div
      class="gap-6"
      :class="{
        'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3': !props.inlineOverflowingX,
        'inline-flex min-w-fit overflow-visible': props.inlineOverflowingX,
      }"
    >
      <div v-for="case_ in cases" :class="{ 'w-[250px] md:w-[400px]': props.inlineOverflowingX }">
        <CaseHighlightCard :case="case_" />
      </div>
    </div>
    <!-- End Grid -->
  </div>
</template>

<style scoped></style>
