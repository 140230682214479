<script setup lang="ts">
import AddNewButton from '@/components/new_case/AddNewButton.vue';
import SceneCard from '@/components/new_case/sections/SceneCard.vue';
import { onMounted, ref, computed, watch } from 'vue';
import { HSDropdown, HSStaticMethods } from 'preline';

interface Task {
  index: number;
  type: string | null;
  title: string | null;
  icon: string;
  success: boolean;
  details: Object;
  subtasks: Array<Object>;
  isIncomplete: boolean;
  person_task_concerns: Array<Object>;
  person_task_involvements: Array<Object>;
}

const tasks = defineModel('tasks', {
  type: Array as () => Array<Task>,
  required: true,
});
const persons = defineModel('persons', {
  type: Array as () => Array<Object>,
  required: true,
});

const { isLoading, startingFromCompleteCase } = defineProps(['isLoading', 'startingFromCompleteCase']);
const emit = defineEmits(['click', 'isValid']);

const sceneCardRefs = ref<Array<any>>([]);
const sceneCardsAreValid = ref([] as boolean[]);

async function addTask() {
  console.log('addTask');
  tasks.value.push({
    index: tasks.value.length,
    type: 'MAKE_OPEN_CONVERSATION',
    title: null,
    icon: 'touch_app',
    success: false,
    details: {
      description: '',
      starting_point: '',
      location: '',
      date: '',
      time: '',
    },
    subtasks: [
      {
        index: 0,
        details: { target: '' },
      },
    ],
    isIncomplete: true,
    person_task_concerns: [],
    person_task_involvements: [],
  });
  sceneCardRefs.value.push(ref(null));
  sceneCardsAreValid.value.push(false); // no criteria, no persons assigned etc. so not valid from start
}

async function removeTask(id: number) {
  console.log('removeTask', id);
  // get id of task with task.id = id
  const indexToRemove = tasks.value.findIndex((task) => task.index === id);
  tasks.value.splice(indexToRemove, 1);
  sceneCardRefs.value.splice(indexToRemove, 1);
  sceneCardsAreValid.value.splice(indexToRemove, 1);
}

async function closeAllOtherTaskEdits(index: number) {
  sceneCardRefs.value.forEach((ref, i) => {
    if (i !== index && ref.value) {
      ref.value[0].closeEdit();
    }
  });
}

onMounted(async () => {
  for (let i = 0; i < tasks.value.length; i++) {
    sceneCardRefs.value.push(ref(null));
    sceneCardsAreValid.value.push(false);
  }
});

const isValid = computed(() => {
  console.log('!!sceneCardsAreValid', !!sceneCardsAreValid.value);
  console.log('any task: ', !!tasks.value);
  console.log(
    'checking all cards',
    sceneCardsAreValid.value.every((isValid) => isValid),
  );
  console.log(
    persons.value.length,
    !!sceneCardsAreValid.value,
    !!tasks.value,
    sceneCardsAreValid.value.every((isValid) => isValid),
  );
  let isValid = !!sceneCardsAreValid.value && !!tasks.value && sceneCardsAreValid.value.every((isValid) => isValid);
  console.log(
    !!sceneCardsAreValid.value,
    !!tasks.value,
    sceneCardsAreValid.value.every((isValid) => isValid),
  );
  console.log('Scenes and tasks are valid: ', isValid);
  return isValid;
});

function sceneCardValidityChanged(isValid: boolean, index: number) {
  console.log('sceneCardValidityChanged', isValid, index);
  sceneCardsAreValid.value[index] = isValid;
}

watch(
  () => isValid.value,
  (isValid) => {
    console.log('isValid (scenes&tasks) emitting', isValid);
    emit('isValid', isValid);
  },
);

watch(
  () => tasks.value.length,
  (newVal) => {
    console.log('change of tasks length');
    for (let i = sceneCardRefs.value.length; i < tasks.value.length; i++) {
      console.log('adding new scene card after external change, i:', i);
      sceneCardRefs.value.push(ref(null));
      sceneCardsAreValid.value.push(false);
    }
  },
);
</script>

<template>
  <div class="py-8 grid grid-cols-11 gap-y-1">
    <h2 class="col-span-11 inline-block text-xl mt-2.5 font-bold text-gray-800 dark:text-gray-200">
      Lege alle Szenen mit ihren dazugehörigen Aufgaben fest
    </h2>
    <div class="col-span-11">
      <div class="text-sm text-gray-600 dark:text-gray-400 pb-2.5">
        Die Szene werden in der Reihenfolge von oben nach unten durchlaufen.
      </div>
      <div class="text-sm text-gray-600 dark:text-gray-400">
        Für jede Szene wählst du aus,
        <ul class="list-disc list-inside">
          <li>welche anderen Personen beteiligt sind</li>
          <li>wann und wo die Szene spielt und was der Lernende zu Beginn vorfindet</li>
          <li>welche Aufgaben er oder sie zu erledigen hat</li>
        </ul>
        Die Aufgaben einer einzelnen Szene werden in der von dir gewählten Reihenfolge vorgeschlagen und dem Lernenden
        angezeigt; er oder sie ist aber frei, sie in anderer Reihenfolge zu bearbeiten.
      </div>

      <div class="mt-6 col-span-11 w-full inline-flex overflow-y-auto gap-y-2">
        <!-- Card List Group -->
        <div class="space-y-3 flex-col w-full justify-center">
          <span v-show="tasks.length === 0" class="text-sm text-blue-600 cursor-pointer" @click="addTask"
            >Füge eine Szene hinzu</span
          >

          <div v-for="(task, index) in tasks" class="flex-col justify-center text-center">
            <SceneCard
              :ref="sceneCardRefs[index]"
              v-model:task="tasks[index]"
              v-model:persons="persons"
              :number="index + 1"
              :key="task.index"
              :startingFromCompleteCase="startingFromCompleteCase"
              @removeTask="removeTask"
              @closeAllOtherTaskEdits="closeAllOtherTaskEdits(index)"
              @isValid="sceneCardValidityChanged($event, index)"
            />
            <div
              class="mx-auto material-symbols-outlined text-gray-300 text-5xl mt-3"
              v-show="index < tasks.length - 1"
            >
              keyboard_double_arrow_down
            </div>
          </div>
          <!-- Note: we need v-model="tasks[index]" to make the changes in the child component reflect in the parent -->
        </div>
        <!-- End Card List Group -->
      </div>
      <div class="flex mx-auto w-[30vH] mt-6">
        <AddNewButton @click="addTask" :isLoading="isLoading" buttonText="Szene hinzufügen" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
