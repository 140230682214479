<script setup lang="ts">
import { ErrorMessage, Field, useField, useForm } from 'vee-validate';
import { computed, nextTick, onMounted, ref, toRef, watch } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { PersonDetailsForm } from '@/components/new_dialog/typing';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import { PatientCreate, PatientDetails, ThirdPersonCreate, ThirdPersonDetails } from '@/apiclient';
import { roleTypes } from '@/helper';

const alertStore = useAlertStore();

const details = defineModel({
  type: Object as () => Object,
  required: true,
});

onMounted(async () => {
  if (generate) {
    await generateImage();
    await generateVoice();
  }
});

const emit = defineEmits([
  'click',
  'scrollToBottom',
  'detailsToggled',
  'isValid',
  'isGenerating',
  'generateFinished',
  'setProfileImage',
  'setVoice',
]);
const { startingFromCompleteDialog, requireFullValidity, alwaysShowDetails, alwaysShowPatientDetails, generate } =
  defineProps({
    startingFromCompleteDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    requireFullValidity: {
      type: Boolean,
      required: false,
      default: true,
    },
    alwaysShowDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    alwaysShowPatientDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    editTouched: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectTouched: {
      type: Boolean,
      required: false,
      default: false,
    },
    generate: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

const { value: localFirstName, errors: firstNameErrors } = useField('first_name', 'max:50|required', {
  initialValue: details.value.first_name,
});
const { value: localLastName, errors: lastNameErrors } = useField('last_name', 'max:50|required', {
  initialValue: details.value.last_name,
});
const { value: localAge, errors: ageErrors } = useField('age', 'numeric|min_value:1|max_value:120|required', {
  initialValue: details.value.age,
});
const { value: localSex, errors: sexErrors } = useField('sex', 'required', {
  initialValue: details.value.sex,
});

const showDetails = ref(alwaysShowDetails);
const detailsAreValid = ref(false);
const isGeneratingProfileImage = ref(false);
const isGeneratingVoice = ref(false);
const roleIsValid = computed(() => {
  return !!details.value.role;
});

const sexIsValid = computed(() => {
  return !!details.value.sex;
});

const allValid = computed(() => {
  // return roleIsValid.value; //  && sexIsValid.value && Object.keys(errors).length === 0;
  let valid =
    roleIsValid.value &&
    ((detailsAreValid.value && sexIsValid.value) || !requireFullValidity) &&
    ((firstNameErrors.value.length === 0 && !!details.value.first_name) || !requireFullValidity) &&
    ((lastNameErrors.value.length === 0 && !!details.value.last_name) || !requireFullValidity) &&
    ((ageErrors.value.length === 0 && !!details.value.age) || !requireFullValidity);
  // emit('isValid', isValid);
  return valid;
});

const nameAndAgeFilled = computed(() => {
  return !!details.value.first_name && !!details.value.last_name && !!details.value.age;
});

function detailsValidityChanged(value) {
  detailsAreValid.value = value;
}

const isPhysician = computed(() => {
  return details.value.role && details.value.role.value === 'PHYSICIAN';
});

watch(
  () => showDetails.value,
  (value) => {
    emit('detailsToggled', value);
  },
);
watch(
  () => allValid.value,
  (value) => {
    emit('isValid', value);
  },
);

async function generateImage() {
  try {
    if (details.value?.role == null) {
      return;
    }
    isGeneratingProfileImage.value = true;

    let profileImage;
    if (details.value?.role?.value === 'PATIENT') {
      profileImage = await (
        await getApiClient()
      ).patients.createNewPatientProfileImage(details.value as PatientDetails);
    } else {
      profileImage = await (
        await getApiClient()
      ).thirdPersons.createNewThirdPersonProfileImage(details.value.role.value, details.value as ThirdPersonDetails);
    }

    emit('setProfileImage', profileImage);
  } catch (error) {
    console.error('Error generating profile image', error);
    alertStore.error('Fehler beim Generieren des Profilbildes', 'Error', error);
    throw error;
  } finally {
    isGeneratingProfileImage.value = false;
  }
}

async function generateVoice() {
  try {
    if (details.value.sex == null || details.value.age == null) {
      return;
    }
    isGeneratingVoice.value = true;
    let voice = await (
      await getApiClient()
    ).voices.retrieveVoice({
      sex: details.value.sex,
      age: details.value.age,
      role: !!details.value.role ? details.value.role.value : 'unknown',
      mood: 'neutral',
      accent: 'standard',
    });
    emit('setVoice', voice);
  } catch (error) {
    console.error('Error generating voice', error);
    alertStore.error('Fehler beim Generieren der Charakter-Stimme', 'Error', error);
    throw error;
  } finally {
    isGeneratingVoice.value = false;
  }
}
</script>

<template>
  <!-- Card -->
  <form>
    <div class="py-1 sm:py-2 px-1">
      <div class="p-2 space-y-5">
        <!-- Grid Role -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label for="hs-pro-daufnm" class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
              Rolle*
            </label>
          </div>
          <!-- End Col -->

          <div class="sm:col-span-9">
            <select
              @click="
                (event) => {
                  event.stopPropagation();
                  emit('click');
                }
              "
              class="py-2 mb-1.5 px-3 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
              :class="{
                'border-red-500 focus:border-red-500 focus:ring-red-500': details.role == null && editTouched,
              }"
              v-model="details.role"
            >
              <option selected="" :value="null">Auswählen</option>
              <option v-for="role in roleTypes" :value="role">{{ role.display_name }}</option>
            </select>
            <div
              v-show="details.role == null"
              class="text-xs"
              :class="{ 'text-red-600': editTouched, 'text-blue-600': !editTouched }"
            >
              Dies ist ein Pflichtfeld
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Grid -->

        <!-- Grid Name -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label for="hs-pro-daufnm" class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
              Name {{ requireFullValidity ? '*' : '' }}
            </label>
          </div>
          <!-- End Col -->

          <div class="sm:col-span-9">
            <div class="grid grid-cols-12 gap-2">
              <!-- title if physician -->
              <div v-if="isPhysician" class="col-span-2 overflow-hidden">
                <select
                  v-model="details.academic_title"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                    }
                  "
                  class="text-transparent py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                >
                  <option selected="" :value="null">Titel</option>
                  <option value="DR">Dr.</option>
                  <option value="PROF_DR">Prof. Dr.</option>
                </select>
              </div>

              <!-- first name -->
              <div :class="{ 'col-span-6': !isPhysician, 'col-span-5': isPhysician }">
                <input
                  type="text"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                    }
                  "
                  class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                  placeholder="Vorname"
                  v-model="details.first_name"
                  @input="localFirstName = $event.target.value"
                  :class="{
                    'border-red-500 focus:border-red-500 focus:ring-red-500': firstNameErrors.length > 0,
                  }"
                />
                <span v-if="firstNameErrors.length > 0" class="text-xs text-red-600">{{ firstNameErrors[0] }}</span>
                <span
                  v-if="details.first_name === '' && firstNameErrors.length === 0 && requireFullValidity"
                  class="text-xs text-red-600"
                  >Dies ist ein Pflichtfeld</span
                >
              </div>

              <!-- last name -->
              <div :class="{ 'col-span-6': !isPhysician, 'col-span-5': isPhysician }">
                <input
                  type="text"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                    }
                  "
                  class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                  placeholder="Zuname"
                  v-model="details.last_name"
                  @input="localLastName = $event.target.value"
                  :class="{
                    'border-red-500 focus:border-red-500 focus:ring-red-500': lastNameErrors.length > 0,
                  }"
                />
                <span v-if="lastNameErrors.length > 0" class="text-xs text-red-600">{{ lastNameErrors[0] }}</span>
                <span
                  v-if="details.last_name === '' && lastNameErrors.length === 0 && requireFullValidity"
                  class="text-xs text-red-600"
                  >Dies ist ein Pflichtfeld</span
                >
              </div>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Grid -->

        <!-- Grid Alter -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label for="hs-pro-daufph" class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
              Alter {{ requireFullValidity ? '*' : '' }}
            </label>
          </div>
          <!-- End Col -->

          <div class="sm:col-span-9 space-y-3">
            <div class="grid grid-cols-12 gap-2">
              <div class="col-span-3">
                <input
                  type="number"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                    }
                  "
                  class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                  placeholder="Jahre"
                  v-model="details.age"
                  @input="localAge = $event.target.value"
                  :class="{
                    'border-red-500 focus:border-red-500 focus:ring-red-500': ageErrors.length > 0,
                  }"
                />
                <span v-if="ageErrors.length > 0" class="text-xs text-red-600">{{ ageErrors[0] }}</span>
                <!-- if ageErrors not set but age undefined -->
                <span
                  v-if="details.age === '' && ageErrors.length === 0 && requireFullValidity"
                  class="text-xs text-red-600"
                  >Dies ist ein Pflichtfeld</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Grid Geschlecht -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
              Geschlecht {{ requireFullValidity ? '*' : '' }}
            </label>
          </div>
          <!-- End Col -->

          <div
            class="sm:col-span-9"
            :class="{
              'border-red-500 focus:border-red-500 focus:ring-red-500': sexErrors.length > 0,
            }"
          >
            <div class="sm:flex">
              <label
                class="flex py-2 px-3 w-full border border-gray-200 -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              >
                <input
                  type="radio"
                  v-model="details.sex"
                  value="MALE"
                  class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                  @input.prevent="localSex = $event.target.value"
                />
                <span class="text-sm text-gray-500 ml-3 dark:text-gray-400">männlich</span>
              </label>

              <label
                class="flex py-2 px-3 w-full border border-gray-200 -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              >
                <input
                  type="radio"
                  v-model="details.sex"
                  value="FEMALE"
                  class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                  @input.prevent="localSex = $event.target.value"
                />
                <span class="text-sm text-gray-500 ml-3 dark:text-gray-400">weiblich</span>
              </label>

              <label
                class="flex py-2 px-3 w-full border border-gray-200 -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
              >
                <input
                  type="radio"
                  v-model="details.sex"
                  value="DIVERSE"
                  class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                  @input.prevent="localSex = $event.target.value"
                />
                <span class="text-sm text-gray-500 ml-3 dark:text-gray-400">divers</span>
              </label>
            </div>
            <span v-if="!sexIsValid && requireFullValidity" class="text-xs text-red-600">Dies ist ein Pflichtfeld</span>
          </div>
        </div>

        <!-- Grid Füllen -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-3">
            <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500"> Details </label>
          </div>
          <!-- End Col -->

          <div class="sm:col-span-9">
            <!-- Logo Upload Group -->
            <div class="flex flex-wrap items-center gap-3 sm:gap-5">
              <div class="grow">
                <div class="flex-col items-center justify-start gap-x-2">
                  <button
                    @click="
                      async (event) => {
                        event.stopPropagation();
                        await generateImage();
                        await generateVoice();
                      }
                    "
                    type="button"
                    :disabled="isGeneratingProfileImage"
                    class="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <!--                    <span translate="no" class="material-symbols-outlined notranslate"> checklist_rtl </span>-->
                    <span translate="no" class="material-symbols-outlined notranslate">manufacturing</span>
                    Neues Profilbild generieren
                  </button>
                </div>
              </div>
            </div>
            <!-- End Logo Upload Group -->
          </div>
          <!-- End Col -->
        </div>
        <!-- End Grid -->
      </div>
    </div>
  </form>
</template>

<style scoped></style>
