<script lang="ts" setup>
import { ref, computed, reactive, onMounted, watch } from 'vue';
import CaseCarousel from '@/components/cases/CaseCarousel.vue';
import CaseHighlightList from '@/components/cases/CaseHighlightList.vue';

import { useCaseStore } from '@/stores';
import TagInput from '@/components/TagInput.vue';
import SpecialtyInput from '@/components/SpecialtyInput.vue';

const caseStore = useCaseStore();
const cases = ref([]);
const tagNames = ref([]);
const specialtyNames = ref([]);
const fetchInPgrogress = ref(false);
const caseDetailsFilter = ref('');

const filteredCases = computed(() => {
  if (caseDetailsFilter.value === '') {
    return cases.value;
  }
  if (!cases.value) {
    return [];
  }
  let filter = caseDetailsFilter.value.trim();
  return cases.value.filter((case_) => {
    return (
      JSON.stringify(case_.details).toLowerCase().includes(filter.toLowerCase()) ||
      JSON.stringify(case_.tags ? case_.tags : {})
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      JSON.stringify(case_.specialties ? case_.specialties : {})
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      JSON.stringify(case_.patient ? case_.patient.details : [])
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      (case_.third_persons ? case_.third_persons : []).some((thirdPerson) => {
        return JSON.stringify(thirdPerson.details).toLowerCase().includes(filter.toLowerCase());
      }) ||
      (case_.tasks ? case_.tasks : []).some((task) => {
        return JSON.stringify(task.details).toLowerCase().includes(filter.toLowerCase());
      })
    );
  });
});

function selectTags(tags_: object[]) {
  console.debug('selected tags: ' + JSON.stringify(tags_));
  tagNames.value = [];
  tags_.forEach((tag: any) => {
    if (tag.name === '') {
      return;
    }
    tagNames.value.push(tag.name);
  });
}

function selectSpecialties(specialties_: object[]) {
  console.debug('selected specialties: ' + JSON.stringify(specialties_));
  specialtyNames.value = [];
  specialties_.forEach((specialty: any) => {
    if (specialty === '') {
      fetchInPgrogress.value = true;
      return;
    }
    specialtyNames.value.push(specialty);
  });
}

watch([tagNames, specialtyNames], async () => {
  fetchInPgrogress.value = true;
  cases.value = await caseStore.getCasesByTagsAndSpecialties(tagNames.value, specialtyNames.value);
  fetchInPgrogress.value = false;
});

onMounted(async () => {
  fetchInPgrogress.value = true;
  cases.value = await caseStore.listAllCases(true);
  fetchInPgrogress.value = false;

  // reload every minute
  setInterval(async () => {
    if (tagNames.value.length > 0 || specialtyNames.value.length > 0) {
      cases.value = await caseStore.getCasesByTagsAndSpecialties(tagNames.value, specialtyNames.value);
    } else {
      cases.value = await caseStore.listAllCases(true);
    }
    fetchInPgrogress.value = false;
  }, 60000);
});
</script>

<template>
  <div v-if="fetchInPgrogress" class="fixed top-0 left-0 w-screen h-full flex items-center justify-center">
    <div
      class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    />
  </div>
  <div class="w-full h-full overflow-auto">
    <header class="sticky top-0 z-20 flex flex-wrap bg-blue-100/50">
      <div class="grid grid-cols-12 gap-2 py-2 w-[85rem] max-w-[85rem] px-2 sm:px-4 lg:px-4 mx-auto items-center">
        <!--        <div class="col-span-2 text-gray-500 font-medium text-sm">Tags</div>-->
        <!--        <div class="col-span-10">-->
        <!--          <TagInput @input="selectTags" />-->
        <!--        </div>-->

        <!--        <div class="col-span-2 text-gray-500 font-medium text-sm">Fachgebiete</div>-->
        <!--        <div class="col-span-10">-->
        <!--          <SpecialtyInput @input="selectSpecialties" />-->
        <!--        </div>-->
        <!-- Search Input -->
        <div
          class="relative col-start-2 col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 px-12 flex min-w-full w-full"
        >
          <div
            class="absolute inset-y-0 start-0 flex mx-12 min-w-full w-full items-center pointer-events-none z-20 ps-3.5"
          >
            <span translate="no" class="material-symbols-outlined notranslate text-gray-500 dark:text-neutral-400">
              search
            </span>
          </div>
          <input
            type="text"
            class="py-[7px] px-3 ps-10 flex min-w-full w-full bg-white border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
            :placeholder="$t('message.filterCases')"
            v-model="caseDetailsFilter"
          />
        </div>
        <!-- End Search Input -->
      </div>
    </header>
    <main>
      <CaseHighlightList :cases="filteredCases" class="w-full" />
    </main>
  </div>
</template>

<style></style>
