<script setup lang="ts">
import { getApiClient } from '@/apiclient/client';
import TextEditor from '@/views/courses/TextEditor.vue';
import { onBeforeUnmount, onMounted, ref, watch, nextTick } from 'vue';
import { debounce } from 'lodash';
import ChapterTableOfContents from '@/views/courses/ChapterTableOfContents.vue';
import Tooltip from '@/components/Tooltip.vue';
import Badge from '@/components/Badge.vue';
import { useAlertStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { computed } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import RemoveButton from '@/components/RemoveButton.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import { useI18n } from 'vue-i18n';
import MercuuBrandLogo from '@/components/MercuuBrandLogo.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { storeToRefs } from 'pinia';
import confetti from 'canvas-confetti';
import { router } from '@/router';
import { sanitizeContent } from '@/helper/stringArithmetics';
const { t } = useI18n();

const emit = defineEmits([
  'scrollTo',
  'toNextSection',
  'toPreviousSection',
  'toNextPage',
  'toPreviousPage',
  'toNextChapter',
  'onToggleIsEditing',
  'onAddPage',
  'onDeleteEmptySection',
]);
const titleEditor = ref(null);
const subtitleEditor = ref(null);
const learningObjectives = ref(null);
const courseStore = useCourseStore();
const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const leaveInvalidPageTimeout = ref(null);

const unsavedChanges = ref(false);
const isSavingChanges = ref(false);

const props = defineProps({
  allowEditing: {
    type: Boolean,
    required: false,
    default: true,
  },
  allowToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  chapter: {
    type: Object,
    required: true,
  },
  section: {
    type: Object,
    required: true,
  },
  numVisibleSections: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  numPages: {
    type: Number,
    required: true,
  },
  isInView: {
    type: Boolean,
    required: true,
  },
  isDeleted: {
    type: Boolean,
    required: true,
  },
});

const headerContainer = ref<HTMLElement | null>(null);
const fullHeight = ref(0);
const minHeight = ref(48);
const initialHeightMeasured = ref(false);
const isExpanded = ref(true);
const isFullExpanded = ref(true);
const HYSTERESIS = 10; // 10px buffer zone

const invalidPage = computed(() => {
  return props.pageIndex > props.numPages - 1 && props.numPages > 0;
});

const adjustHeight = async () => {
  await nextTick();
  if (headerContainer.value) {
    const height = headerContainer.value.offsetHeight;
    fullHeight.value = height + 16; // add 16px for the padding
    initialHeightMeasured.value = true;
  }
};

watch(
  () => [props.section?.learning_objectives, props.section?.title],
  async () => {
    await nextTick();
    adjustHeight();
  },
  { deep: true },
);

onMounted(async () => {
  await nextTick();
  setTimeout(adjustHeight, 100);
  setTimeout(adjustHeight, 500);
  window.addEventListener('resize', debounce(adjustHeight, 200));
  if (!props.section) {
    throw new Error('Section not found');
  }
  console.log('# learning objectives: ' + props.section.learning_objectives?.length);
  setLearningObjectives(props.section.learning_objectives);
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      console.log('onbeforeunload triggered saveTitleAndLearningObjectives');
      saveTitleAndLearningObjectives();
      return undefined;
    }
  };
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustHeight);
  if (unsavedChanges.value) {
    console.log('onBeforeUnmount triggered saveTitleAndLearningObjectives');
    saveTitleAndLearningObjectives();
  }
  if (props.allowEditing && !isPublished.value && !props.isDeleted) {
    alertStore.info(
      'Bitte beachten Sie, dass die Kurseinheit (Section) ' +
        (props.chapter?.index !== undefined ? props.chapter.index + 1 : '(undefined)') +
        '.' +
        (props.section?.index !== undefined ? props.section.index + 1 : '(undefined)') +
        ' noch nicht veröffentlicht ist. ' +
        'Für Ihre Schüler:innen ist sie daher noch nicht sichtbar!',
      'Kurseinheit (Section) ist verborgen',
    );
  }
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      console.log('onBeforeRouteLeave triggered saveTitleAndLearningObjectives');
      await saveTitleAndLearningObjectives();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const isPublished = computed(() => {
  return props.section?.published_at != null;
});

function setLearningObjectives(sectionLearningObjectives: { description: string; importance: number }[]) {
  console.log('setLearningObjectives', sectionLearningObjectives);
  if (!sectionLearningObjectives || !Array.isArray(sectionLearningObjectives)) {
    console.warn('Invalid learning objectives data');
    return;
  }
  if (!learningObjectives.value) {
    console.warn('null ref in learningObjectives');
    return;
  }
  if (!props.section) {
    return;
  }
  // note: slice to avoid reactivity issues or in-place changes when field deleted
  try {
    learningObjectives.value.setLearningObjectives(sectionLearningObjectives.slice(), false);
  } catch (error) {
    console.error('Failed to set learning objectives:', error);
  }
}

const publishSection = (publish: boolean) => {
  console.log('publishing: ' + publish);
  if (!props.section) {
    return;
  }
  courseStore
    .publishSection(props.section.id, publish)
    .then(() => {
      alertStore.success(
        publish
          ? 'Abschnitt ' +
              (props.chapter.index + 1) +
              '.' +
              (props.section.index + 1) +
              ' mit allen Lernbausteinen ist jetzt für die Kursteilnehmer:innen sichtbar.'
          : 'Abschnitt ' +
              (props.chapter.index + 1) +
              '.' +
              (props.section.index + 1) +
              ' mit allen Lernbausteinen ist jetzt vor den Kursteilnehmer:innen verborgen.',
      );
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Veröffentlichen/ Verbergen: ' + error);
      throw Error('Error publishing section', error);
    });
};

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.allowEditing || props.isDeleted) return;
      setTimeout(() => {
        console.log('watcher triggered saveTitleAndLearningObjectives');
        saveTitleAndLearningObjectives();
        unsavedChanges.value = false;
      }, 1500);
    }
  },
);

const saveTitleAndLearningObjectives = async () => {
  console.log('saveTitleAndLearningObjectives');
  console.log('allowEditing', props.allowEditing);
  console.log('sectionId', props.section?.id);
  console.log('isDeleted', props.isDeleted);
  console.log('unsavedChanges', unsavedChanges.value);
  console.log('isSavingChanges', isSavingChanges.value);
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;
  if (!unsavedChanges.value || !props.allowEditing || !props.section?.id || props.isDeleted) return;
  if (!titleEditor.value?.getRawTextContent) {
    console.warn('Title editor not properly initialized');
    return;
  }
  const rawTitle = titleEditor.value.getRawTextContent();
  if (rawTitle === null || rawTitle === undefined) {
    console.warn('Title content is null or undefined');
    return;
  }
  if (!learningObjectives.value?.getLearningObjectives) {
    console.warn('Learning objectives not properly initialized');
    return;
  }
  console.log('saveTitleAndLearningObjectives - deleted? ' + props.isDeleted);
  console.log('learning objectives are: ' + JSON.stringify(learningObjectives.value.getLearningObjectives()));
  console.log('title is: ' + rawTitle);
  courseStore
    .updateSection(props.section.id, {
      title: sanitizeContent(rawTitle),
      learningObjectives: learningObjectives.value.getLearningObjectives(),
      publishedAt: props.section.published_at,
    })
    .then(() => {
      unsavedChanges.value = false;
      // alertStore.success('Titel und Lernziele gespeichert', 'Gespeichert');
      titleEditor.value?.resetEmitState();
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave title', 'Error', error);
      throw new Error('Failed to autosave title');
    })
    .finally(() => {
      isSavingChanges.value = false;
    });
};

const onDeleteSectionIfEmpty = async () => {
  if (props.numPages > 0) {
    alertStore.error(
      'Löschen nicht möglich, da noch Lernbausteine vorhanden sind. Bitte löschen Sie zuerst alle Lernbausteine.',
      'Unzulässig',
    );
    return;
  }
  emit('onDeleteEmptySection', props.section.id);
};

const screenIsMdOrLarger = computed(() => {
  return window.innerWidth > 768;
});

const isPageComplete = computed(() => {
  return !!courseInteractionStore.pageCompletionStatus?.[props.section.id]?.[props.pageIndex];
});

const isSectionComplete = computed(() => {
  return isPageComplete.value && props.pageIndex === props.numPages - 1;
});

const isChapterComplete = computed(() => {
  return courseInteractionStore.chapterIsComplete(props.chapter.id);
});

const isCourseComplete = computed(() => {
  return courseInteractionStore.currentCourseIsComplete;
});

const finishCourse = async () => {
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
      x: 0.7,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 200));
  confetti({
    particleCount: 150,
    spread: 90,
    origin: {
      y: 0.6,
      x: 0.3,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 300,
    spread: 90,
    origin: {
      y: 0.8,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 50));
  confetti({
    particleCount: 400,
    spread: 100,
    origin: {
      y: 0.5,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 1500));
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
      x: 0.7,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 200));
  confetti({
    particleCount: 150,
    spread: 90,
    origin: {
      y: 0.6,
      x: 0.3,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 300,
    spread: 90,
    origin: {
      y: 0.8,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 50));
  confetti({
    particleCount: 400,
    spread: 100,
    origin: {
      y: 0.5,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 1500));
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
      x: 0.7,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 200));
  confetti({
    particleCount: 150,
    spread: 90,
    origin: {
      y: 0.6,
      x: 0.3,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 300,
    spread: 90,
    origin: {
      y: 0.8,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 50));
  confetti({
    particleCount: 400,
    spread: 100,
    origin: {
      y: 0.5,
    },
  });
  await new Promise((resolve) => setTimeout(resolve, 2000));
  router.push('/courses');
  await new Promise((resolve) => setTimeout(resolve, 500));
  router.go(0);
};

watch(invalidPage, (newValue) => {
  if (newValue) {
    leaveInvalidPageTimeout.value = setTimeout(() => {
      emit('toPreviousPage');
    }, 1000);
  } else if (leaveInvalidPageTimeout.value) {
    clearTimeout(leaveInvalidPageTimeout.value);
    leaveInvalidPageTimeout.value = null;
  }
});

defineExpose({
  titleEditor,
  subtitleEditor,
  fullHeight,
  minHeight,
});
</script>

<template>
  <div
    ref="headerContainer"
    v-if="!isDeleted"
    class="w-full relative mx-auto overflow-visible transition-all duration-200 ease-out"
    :class="{
      'pt-4': props.isInView,
      'pt-0': !props.isInView,
    }"
    :style="{
      height: props.isInView ? 'auto' : minHeight + 'px',
    }"
  >
    <div
      class="transition-[color] duration-300 flex space-y-5 md:space-y-8 h-fit dark:bg-neutral-900 dark:border-gray-700"
      :class="{
        'w-full shadow-sm fle rounded-xl border border-gray-200 bg-white pt-6 lg:pt-10 pb-4 lg:pb-10  px-4 sm:px-6 lg:px-8':
          props.isInView,
        'w-full justify-end': !props.isInView,
      }"
    >
      <div
        :class="{
          'w-full h-fit flex space-y-3 justify-center': props.isInView,
          'rounded-lg border border-gray-200 bg-white mx-4 my-2 px-4 shadow-sm ': !props.isInView,
        }"
      >
        <div class="w-full flex h-fit space-y-3 justify-center">
          <h2 class="w-full flex-col h-fit flex text-4xl text-center font-bold md:text-5xl dark:text-white">
            <div
              class="inline-flex w-full relative justify-between items-center text-base pb-6"
              v-show="props.allowToggleEditing && props.isInView"
            >
              <!-- Delete section -->
              <div>
                <button
                  v-show="props.allowEditing"
                  class="h-10 w-10 flex items-center text-center justify-center bg-red-600 hover:bg-red-700 shadow-md text-white rounded-[4px] text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium"
                  :class="{ 'opacity-50': numPages > 0 }"
                  @click.prevent="onDeleteSectionIfEmpty"
                >
                  <span translate="no" class="material-symbols-outlined notranslate text-3xl">delete</span>
                </button>
              </div>

              <!-- Publish -->
              <div v-show="props.allowEditing" class="inline-flex justify-end items-center">
                <div class="font-normal text-gray-500 dark:text-gray-200">
                  Status
                  <Tooltip
                    :placement-top="false"
                    message="Klicken zum Veröffentlichen des Kursabschnitts.
                            Veröffentlichte Abschnitte inkl. aller Lernbausteine sind für alle Nutzer:innen sichtbar, die
                            Zugriff auf den entsprechenden Kurs haben - für alle anderen natürlich nicht!"
                  />
                </div>
                <div class="group cursor-pointer" @click.prevent="publishSection(!isPublished)">
                  <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="!isPublished">
                    <Badge text="Unpublished draft" color="gray" :showDot="true" fontSize="text-sm" />
                  </div>
                  <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="!isPublished">
                    <Badge text="Publish draft" color="blue" :showDot="false" fontSize="text-sm" icon="publish" />
                  </div>
                  <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="isPublished">
                    <Badge text="Section is live" color="green" :showDot="true" :pulse="true" fontSize="text-sm" />
                  </div>
                  <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="isPublished">
                    <Badge
                      text="Hide section"
                      color="blue"
                      :showDot="false"
                      :pulse="true"
                      icon="hide_source"
                      fontSize="text-sm"
                    />
                  </div>
                </div>
              </div>

              <span v-show="props.allowToggleEditing" class="absolute font-normal end-12 text-gray-500"
                >Bearbeiten
              </span>
              <input
                :checked="props.allowEditing"
                @change="emit('onToggleIsEditing', $event?.target?.checked)"
                :invalidPage="props.disableToggleEditing"
                type="checkbox"
                id="hs-xs-switch-edit-chapter"
                class="relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 invalidPage:opacity-50 invalidPage:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-600 before:inline-block before:w-4 before:h-4 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
              />
            </div>

            <span
              v-show="props.isInView"
              class="w-full justify-between items-center inline-flex text-gray-300 font-bold text-3xl"
            >
              <span
                v-if="!!props.section && props.section.index > 0"
                @click="emit('toPreviousSection')"
                class="h-8 w-8 hover:bg-gray-50 flex items-center justify-center text-center border border-gray-300 hover:border-gray-500 rounded-lg text-gray-300 hover:text-gray-500 cursor-pointer"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_left</span
                >
              </span>
              <span v-else class="w-[36px]"></span>
              <span class="inline-flex" v-if="!!props.section && !!props.chapter">
                <span class="">{{ props.chapter?.index + 1 }}.{{ props.section?.index + 1 }}</span>
              </span>
              <span
                v-if="!!props.section && props.section.index < props.numVisibleSections - 1"
                @click="emit('toNextSection')"
                class="h-8 w-8 flex items-center justify-center text-center border rounded-lg cursor-pointer"
                :class="{
                  'bg-white border-gray-300 text-gray-300 hover:text-gray-500 hover:border-gray-500 hover:bg-gray-50':
                    !isSectionComplete || props.allowEditing,
                  'ring-pulse text-white bg-blue-600 hover:bg-blue-700': isSectionComplete && !props.allowEditing,
                }"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
              <span v-else class="w-[36px]"></span>
            </span>
            <TextEditor
              v-if="!!props.section && props.section?.title !== null"
              v-show="props.isInView"
              ref="titleEditor"
              :content="props.section.title || ''"
              :allowFormatting="false"
              @unsavedChanges="unsavedChanges = true"
              @changesCleared="unsavedChanges = false"
              :allow-edit="props.allowEditing"
              editor-classes="pb-1"
              data-testid="section-title-editor"
            />

            <p
              class="w-full pt-8 text-xs md:text-sm text-justify text-gray-800 dark:text-gray-200 font-semibold mb-3"
              v-show="props.isInView"
              data-testid="somewhere-to-click-without-consequences"
            >
              Lernziele
            </p>
            <div class="w-full space-y-3 text-gray-500 font-normal text-left" v-show="props.isInView">
              <LearningObjectives
                ref="learningObjectives"
                :maxNumber="5"
                :editable="props.allowEditing"
                @requestCompute="emit('onExtractLearningObjectives')"
                @change="unsavedChanges = true"
              />
            </div>

            <span
              v-if="isChapterComplete"
              class="w-full flex justify-center items-center inline-flex text-base text-gray-300"
            >
              <span
                v-if="props.chapter.index + 1 < courseStore?.currentCourse?.chapters?.length"
                @click="emit('toNextChapter')"
                class="h-8 mx-auto px-3 py-2 my-4 inline-flex items-center justify-center text-center border rounded-lg cursor-pointer ring-pulse text-white bg-blue-600 hover:bg-blue-700"
              >
                🏁&nbsp;&nbsp; Zum nächsten Kapitel &nbsp;🥳
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
              <span
                v-else-if="isCourseComplete && props.chapter.index + 1 === courseStore?.currentCourse?.chapters?.length"
                @click="finishCourse"
                class="h-8 mx-auto px-3 py-2 my-4 inline-flex items-center justify-center text-center border rounded-lg cursor-pointer ring-pulse text-white bg-blue-600 hover:bg-blue-700"
              >
                🏁&nbsp;&nbsp; Kurs abschließen &nbsp;🥳&nbsp;🥳&nbsp;🥳
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
            </span>

            <span
              v-if="!isChapterComplete || props.isInView"
              class="justify-between items-center inline-flex text-gray-300"
              :class="{
                'w-full pt-8 text-2xl md:text-3xl font-bold': props.isInView,
                'w-fit gap-x-2 pt-0 text-lg font-normal': !props.isInView,
              }"
            >
              <span
                v-if="props.pageIndex > 0"
                @click="emit('toPreviousPage')"
                class="h-8 w-8 hover:bg-gray-50 flex items-center justify-center text-center border border-gray-300 hover:border-gray-500 rounded-lg text-gray-300 hover:text-gray-500 cursor-pointer"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_left</span
                >
              </span>
              <span v-else class="w-[36px]"></span>

              <span
                class="inline-flex items-center"
                :class="{
                  'py-0': props.isInView,
                  'py-2': !props.isInView,
                }"
              >
                <span v-if="!!invalidPage" class="inline-flex">
                  <span
                    class="animate-spin inline-block w-8 h-8 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full mr-6"
                  />
                </span>
                <span
                  v-else-if="props.numPages > 0"
                  :class="{ 'inline-flex': props.isInView, 'flex-col flex': !props.isInView }"
                >
                  <span class="">Lernbaustein&nbsp;</span>
                  <span>
                    <span data-testid="page-number">{{ props.pageIndex + 1 }}</span>
                    <span>&nbsp;von {{ props.numPages }}</span>
                    <span class="pl-4"></span>
                  </span>
                </span>
                <span v-else class="text-xl pr-5"> Noch kein Lernbaustein vorhanden </span>
                <ProgressButton
                  v-show="props.allowEditing && props.isInView"
                  :text="t('message.addParagraph')"
                  icon="add"
                  iconSize="text-3xl"
                  :roundedFull="true"
                  @click="emit('onAddPage')"
                  data-testid="add-page-button"
                />
              </span>

              <span
                v-if="props.pageIndex < props.numPages - 1"
                data-testid="next-page-button"
                @click="emit('toNextPage')"
                class="h-8 w-8 flex items-center justify-center text-center border rounded-lg cursor-pointer"
                :class="{
                  'text-gray-300 hover:text-gray-500 hover:bg-gray-50 border-gray-300 hover:border-gray-500':
                    !isPageComplete || props.allowEditing,
                  'ring-pulse text-white bg-blue-600 hover:bg-blue-700': isPageComplete && !props.allowEditing,
                }"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
              <span v-else class="w-[36px]"></span>
            </span>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes ring-pulse {
  0% {
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0);
  }
  70% {
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.7);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(37, 99, 235, 0.2);
  }
}

.ring-pulse {
  animation: ring-pulse 2s infinite;
}

.ring-pulse:hover {
  animation: none;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 1);
}
</style>
