<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import ProfileImage from '@/components/ProfileImage.vue';
import PersonCard from './PersonCard.vue';
import { updateMinTextareaHeight } from '@/helper';
import { ErrorMessage, useField } from 'vee-validate';
import SettingInput from '@/components/SettingInput.vue';
import { InvolvementType } from '@/apiclient';

const message = defineModel({
  type: Object,
  required: true,
});

const { number, startingFromCompleteDialog, persons } = defineProps([
  'number',
  'startingFromCompleteDialog',
  'persons',
]);
const selectedPersonIds = ref([]);
const personMessagesValid = ref([]);
const personMessageRefs = ref([]);

const criterionErrors = ref([]);

const emit = defineEmits(['removeMessage', 'closeAllOtherMessageEdits', 'isValid']);
const isEditing = ref(true);
const scrollContainer = ref(null);

const messageIndexOrCreate = computed(() => {
  return message.value.index !== null ? message.value.index : 'create';
});

const isTouched = ref(false);

const { value: localTitle, errors: contentErrors } = useField('content', 'required|max:1000', {
  initialValue: message.value.content,
});

async function emitRemoveMessage(index) {
  emit('removeMessage', index);
}

function toggleEditMessage(event) {
  event.stopPropagation();

  console.log('toggleEditMessage');

  if (isEditing.value) {
    // closed for the first time => set isTouch to true
    isTouched.value = true;
  }

  isEditing.value = !isEditing.value;

  if (isEditing.value) {
    emit('closeAllOtherMessageEdits');
    return;
  }
}

function closeEdit() {
  if (!isEditing.value) {
    return;
  }
  isEditing.value = false;
}

onMounted(async () => {});

const allValid = computed(() => {
  return contentErrors.value.length === 0 && message.value.content !== null && message.value.content.trim() !== '';
});

watch(
  () => allValid.value,
  (value) => {
    console.log('DescCard watcher emitting: isValid', value);
    emit('isValid', value);
  },
);

defineExpose({
  closeEdit,
});
</script>

<template>
  <div class="flex w-[40vH]">
    <div
      @click="toggleEditMessage"
      class="group cursor-pointer p-4 min-w-full w-full max-w-full flex flex-col justify-center bg-white border border-gray-400 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
      :class="{
        'border border-red-600': !allValid && isTouched,
        'hover:bg-gray-50 dark:hover:bg-neutral-700': !isEditing,
      }"
    >
      <!-- Message header -->
      <button class="min-w-full w-full max-w-full flex flex-row justify-between">
        <div class="relative flex-grow justify-between gap-x-4">
          <div class="text-start">
            <div class="grow flex flex-col justify-start text-start">
              <div class="inline-flex items-center gap-x-2 pb-2">
                <h3 class="flex flex-grow font-medium text-gray-800 dark:text-neutral-200 pr-1">
                  <span
                    v-if="!isEditing && message.content === null"
                    class="flex max-w-full w-full min-w-full text-left"
                  >
                    Zum Bearbeiten klicken
                  </span>
                  <span
                    v-else-if="!isEditing && message.content !== null"
                    class="flex min-h-fit h-fit max-h-fit max-w-full w-full min-w-full"
                  >
                    {{ message.content }}
                  </span>
                  <span class="flex max-w-full w-full min-w-full" v-else>
                    <textarea
                      @click="
                        (event) => {
                          event.stopPropagation();
                        }
                      "
                      @keyup.space.prevent="(event) => event.stopPropagation()"
                      v-model="message.content"
                      :disabled="!isEditing"
                      placeholder="Beschreibe, was gerade in der Handlung passiert (wird vom Erzähler gelesen)"
                      class="resize-none px-0 py-0.5 my-0.5 mr-0.5"
                      @input="
                        (event) => {
                          updateMinTextareaHeight(event.target);
                          localTitle = event.target.value;
                        }
                      "
                      rows="2"
                      :class="{
                        'cursor-text border border-gray-200 rounded-lg bg-white': isEditing,
                        'border-transparent bg-transparent': !isEditing,
                        'border-red-500 focus:border-red-500 focus:ring-red-500':
                          (message.content === '' || message.content == null || contentErrors.length > 0) && isTouched,
                      }"
                      :style="{
                        width: '100%',
                        'box-sizing': 'border-box',
                      }"
                    />
                  </span>
                </h3>
              </div>
              <span v-if="isEditing" class="text-xs text-red-600 mt-1">{{ contentErrors[0] }}</span>
              <span
                v-if="
                  isEditing &&
                  (message.content === '' || message.content == null) &&
                  contentErrors.length === 0 &&
                  isTouched
                "
                class="text-xs text-red-600"
                >Dies ist ein Pflichtfeld</span
              >
            </div>
            <!-- End content -->
          </div>
          <!-- End Title + type + desc -->
        </div>
        <!-- End Col -->

        <!-- Profile images of selected persons + top-level buttons -->
        <div class="mt-0 min-w-fit w-fit max-w-fit">
          <div class="flex justify-end items-center gap-x-4">
            <div class="flex items-center gap-x-4">
              <!-- Buttons rights -->
              <div class="top-0 end-0 flex-col sticky flex justify-center">
                <!-- Delete button -->
                <button
                  class="z-10 flex items-center justify-items-center w-full font-semibold text-start text-gray-800 hover:text-gray-800 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  @click="emitRemoveMessage(message.index)"
                >
                  <span
                    translate="no"
                    class="material-symbols-outlined notranslate text-xl text-red-600 hover:text-red-800 block bg-white"
                    :class="{ 'group-hover:bg-gray-50': !isEditing }"
                  >
                    delete
                  </span>
                </button>
                <!-- End Delete button -->
              </div>
              <!-- End buttons rights -->
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End profile images of selected persons + top-level buttons -->
      </button>
      <!-- End row = message header -->
    </div>
  </div>
</template>

<style scoped>
.disabled {
  pointer-events: none;
}
</style>
