<script setup>
import { ref, nextTick, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import {
  useAlertStore,
  useAuthStore,
  useCaseStore,
  useCaseInteractionStore,
  useLanguageStore,
  usePatientInteractionStore,
} from '@/stores';
import { storeToRefs } from 'pinia';
import { HSOverlay } from 'preline';
import { v4 as uuidv4 } from 'uuid';
import confetti from 'canvas-confetti';
import TaskInfoCard from '@/components/case_interaction/TaskInfoCard.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import ChatBubbleSystem from '@/components/chat_bubbles/ChatBubbleSystem.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({});

const emit = defineEmits(['closed']);

// Main cpt refs
const modal = ref(null);

// Stores and store references
const caseStore = useCaseStore();
const { currentCase } = storeToRefs(caseStore);
const patientInteractionStore = usePatientInteractionStore();
const { descMessages, descIsStreaming } = storeToRefs(patientInteractionStore);

// Refs for component data
const canBeClosed = ref(false);
const allSubtasksCompleted = ref(false);
const taskIndex = ref(null);
const showDescMessage = ref(false);
// Computed-like functions

const task = computed(() => {
  if (taskIndex.value === null) return null;
  if (!currentCase.value) return null;
  if (!currentCase.value.tasks || currentCase.value.tasks.length === 0) return null;
  return currentCase.value.tasks[taskIndex.value];
});

const close = () => {
  HSOverlay.close('#task-transition-modal');
};

// Lifecycle hooks
onMounted(async () => {
  await HSOverlay.autoInit(); // we need to do this in order to make modals for components work which are mounted AFTER the app is initialized and AFTER the router re-initialized HSStaticMethods.autoInit()

  // Get the modal element and initialize it
  const modalElement = document.querySelector('#task-transition-modal');
  modal.value = HSOverlay.getInstance(modalElement);

  // If no instance exists, create one
  if (!modal.value) {
    modal.value = new HSOverlay(modalElement);
  }

  await nextTick();

  modal.value.addEventListener('close.hs.overlay', () => {
    console.debug('closing dialog');
    emit('closed');
  });
});

onBeforeUnmount(() => {});

// Methods
const show = async (indexOfTaskToShow) => {
  await nextTick();

  taskIndex.value = indexOfTaskToShow;

  console.log(modal.value);
  HSOverlay.open('#task-transition-modal'); // works

  console.log('opened modal');

  await new Promise((resolve) => setTimeout(resolve, 500));

  setTimeout(() => {
    showDescMessage.value = true;
  }, 1200);
};

const getRandomEmoji = (answerIsCorrect) => {
  const positiveEmojis = ['😊', '🎉', '👍', '👏', '😄', '😁', '🥳', '💪'];
  const motivationalEmojis = ['🤔', '😌', '🙂', '🌱', '🔄', '🌟', '✊'];

  if (answerIsCorrect) {
    return positiveEmojis[Math.floor(Math.random() * positiveEmojis.length)];
  } else {
    return motivationalEmojis[Math.floor(Math.random() * motivationalEmojis.length)];
  }
};

const backToOrigin = () => {
  console.log('back to origin');
};

defineExpose({
  show,
});
</script>

<template>
  <div
    id="task-transition-modal"
    class="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[100] overflow-x-hidden overflow-y-hidden pointer-events-none"
  >
    <div
      data-testid="task-transition-modal"
      class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all lg:max-w-6xl lg:w-full m-3 lg:mx-auto h-[calc(100%-3.5rem)]"
    >
      <div ref="modalWindow" class="flex w-full h-full pt-12 items-start justify-center">
        <div
          class="mx-auto flex-col flex bg-white max-w-3xl border-gray-100 rounded-xl shadow-md dark:bg-neutral-800 dark:border-neutral-700"
        >
          <TaskInfoCard
            v-if="!!task"
            :task="task"
            :delay="1200"
            :integrated="true"
            @allSubtaskInteractionsCompletedAndAnimationFinished="allSubtasksCompleted = true"
          />

          <div v-if="descMessages?.length > 0 && showDescMessage" class="border-t border-gray-200 px-4 py-2">
            <ChatBubbleSystem
              :message="descMessages[descMessages.length - 1]"
              :isStreaming="descIsStreaming"
              data-testid="initial-desc-message"
            />
          </div>

          <!-- Popover Footer -->
          <div class="py-2 px-2 gap-x-2 flex justify-end dark:bg-neutral-800 pointer-events-auto">
            <ProgressButton
              color="blue"
              :disabled="descIsStreaming"
              @click="
                () => {
                  close();
                  if (allSubtasksCompleted) {
                    backToOrigin();
                  }
                }
              "
              text="Weiter"
            />
          </div>

          <!-- end of modal contents -->

          <!-- hidden toggle button -->
          <div>
            <a
              class="hidden py-2 px-3 text-xs md:text-sm min-w-16 md:min-w-24 min-h-8 md:min-h-10 h-full text-center inline-flex justify-center items-center gap-2 rounded-lg border border-transparent font-medium bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800"
              data-hs-overlay="#task-transition-modal"
              data-testid="task-transition-modal-bottom-close-button"
              @click.stop="close"
            >
              Zurück zum Fall
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes grow-pop {
  0% {
    font-size: 24px; /* Initial text size */
    opacity: 1;
  }
  50% {
    font-size: 30px; /* Pop out to larger size */
    opacity: 1;
  }
  100% {
    font-size: 0px; /* Shrink to disappear */
    opacity: 0;
  }
}

.animate-grow-pop {
  animation: grow-pop 300ms ease-out;
}
</style>
