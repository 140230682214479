<script setup lang="ts">
import Tooltip from '@/components/Tooltip.vue';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import DiseaseInput from '@/components/new_case/DiseaseInput.vue';
import { computed, onMounted, ref } from 'vue';
import { PersonDetailsForm } from '@/components/new_case/typing';
import { watch } from 'vue';

const { showPatientDetails } = defineProps(['showPatientDetails']);
const details = defineModel({
  type: Object as () => Object,
  required: true,
});
const { errors } = useForm<PersonDetailsForm>();
const mainMedicalConditionsAreValid = ref(false);
const knownMedicalConditionsAreValid = ref(true);
const unknownMedicalConditionsAreValid = ref(true);
const mainConditionsInput = ref(null);
const knownConditionsInput = ref(null);
const unknownConditionsInput = ref(null);

const emit = defineEmits(['isValid', 'click']);

const showAbsent = ref(false);

function setMedicalConditions(value: string) {
  console.log('setMedicalConditions', value);
}

onMounted(() => {
  details.value.main_medical_conditions = details.value.main_medical_conditions || [];
  details.value.known_medical_conditions = details.value.known_medical_conditions || [];
  details.value.unknown_medical_conditions = details.value.unknown_medical_conditions || [];
});

watch(
  () => showPatientDetails,
  (value) => {
    console.log('showPatientDetails', value);
    mainConditionsInput.value.initIfEmpty();
    knownConditionsInput.value.initIfEmpty();
    unknownConditionsInput.value.initIfEmpty();
  },
);

const isValid = computed(() => {
  // console.log(
  //   'PatientDetails: isValid',
  //   mainMedicalConditionsAreValid.value,
  //   knownMedicalConditionsAreValid.value || details.value.known_medical_conditions.length === 0,
  //   unknownMedicalConditionsAreValid.value || details.value.unknown_medical_conditions.length === 0,
  //   Object.keys(errors.value).length === 0,
  // );
  // console.log('errors: ', Object.keys(errors.value));
  let isValid = mainMedicalConditionsAreValid.value;
  // console.log('PatientDetails: isValid', isValid);
  return isValid;
});

watch(
  () => isValid.value as boolean,
  (value: boolean) => {
    // console.log('PatientDetails emitting: isValid', value);
    emit('isValid', value);
  },
);

function mainConditionDetailsValidityChanged(value: boolean) {
  console.log('mainConditionDetailsValidityChanged to', value);
  mainMedicalConditionsAreValid.value = value;
}
</script>

<template>
  <div class="divide-y divide-gray-200 pt-1" v-show="!!showPatientDetails">
    <div></div>
    <div></div>
  </div>

  <div
    @click="
      (event) => {
        event.stopPropagation();
        emit('click');
      }
    "
    class="overflow-visible"
  >
    <div class="sm:col-span-11 overflow-visible inline-block text-sm mt-2.5 text-gray-500 font-medium">Krankenakte</div>

    <div class="grid grid-cols-12 overflow-visible">
      <!-- Hauptdiagnose -->
      <div class="sm:col-span-3 overflow-visible">
        <label for="main-diagnosis" class="inline-block text-sm mt-2.5 text-gray-500 dark:text-gray-200">
          Hauptdiagnose(n) *
        </label>
        <Tooltip
          :message="`Noch unbekannte Erkrankung, deretwegen bzw. wegen derer Symptome dein:e Patient:in ärztliche Hilfe aufsucht. Zum Beispiel 'Herzinfarkt' oder 'Kolorektales Karzinom' oder auch 'Akute Cholangitis auf dem Boden einer PSC'`"
        />
      </div>
      <div class="sm:col-span-9 overflow-visible">
        <DiseaseInput
          ref="mainConditionsInput"
          :model-value="details.main_medical_conditions"
          @update:model-value="(value) => (details.main_medical_conditions = value)"
          :minNumberOfDiseases="1"
          @isValid="mainMedicalConditionsAreValid = $event"
        />
      </div>
      <!-- End row -->

      <!-- Bekannte Nebendiagnosen -->
      <div class="sm:col-span-3">
        <label for="known-medical-conditions" class="inline-block text-sm text-gray-500 mt-2.5 dark:text-gray-200">
          Bekannte Nebendiagnosen
        </label>
        <Tooltip
          :message="`Nebendiagnosen, die dem Patienten bekannt sind, getrennt durch Semikolon (';'), z.B. 'Koronare Herzkrankheit; Gicht'`"
        />
      </div>
      <!-- End Col -->
      <div class="sm:col-span-9 overflow-visible">
        <DiseaseInput
          ref="knownConditionsInput"
          :model-value="details.known_medical_conditions"
          @update:model-value="(value) => (details.known_medical_conditions = value)"
          @isValid="knownMedicalConditionsAreValid = $event"
          :omit-labels="true"
        />
      </div>
      <!-- End Col -->

      <!-- Unbekannte Nebendiagnosen -->
      <div class="sm:col-span-3">
        <label for="unknown-medical-conditions" class="inline-block text-sm text-gray-500 mt-2.5 dark:text-gray-200">
          Unbekannte Nebendiagnosen
        </label>
        <Tooltip
          :message="`Nebendiagnosen, die dem Patienten NICHT bekannt sind, getrennt durch Semikolon (';'), z.B. 'primär sklerosierende Cholangitis; chronische Niereninsuffizienz'`"
        />
      </div>
      <!-- End Col -->
      <div class="sm:col-span-9 overflow-visible">
        <DiseaseInput
          ref="unknownConditionsInput"
          :model-value="details.unknown_medical_conditions"
          @update:model-value="(value) => (details.unknown_medical_conditions = value)"
          @isValid="unknownMedicalConditionsAreValid = $event"
          :omit-labels="true"
        />
      </div>
      <!-- End Col -->

      <!-- Add comorbidities -->
      <div class="sm:col-span-12 child grid sm:grid-cols-12 gap-4 items-center">
        <div class="sm:col-span-3">
          <label for="add_comorbidities" class="inline-block text-sm text-gray-500 mt-2.5 dark:text-gray-200">
            Komorbiditäten ergänzen
          </label>
          <Tooltip :message="`Sollen ggf. passende Komorbiditäten automatisch ergänzt werden?`" />
        </div>
        <!-- End Col -->
        <div class="sm:col-span-9 overflow-visible">
          <Field v-slot="{ field }" name="add_comorbidities" type="checkbox" :value="true">
            <!--                  <label>-->
            <!--                    <input type="checkbox" name="textToSpeechEnabled" v-bind="field" :value="true"/>-->
            <!--                  </label>-->
            <div class="flex">
              <input
                type="checkbox"
                name="add_comorbidities"
                v-bind="field"
                :value="true"
                class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                id="hs-default-checkbox"
              />
              <label for="hs-default-checkbox" class="text-sm text-gray-500 ms-3 dark:text-gray-400">Ja </label>
            </div>
          </Field>
        </div>
      </div>
      <!-- End row -->

      <!-- Nicht betroffen von... -->
      <div v-show="showAbsent" class="sm:col-span-3">
        <label for="no-medical-condition" class="inline-block text-sm text-gray-500 mt-2.5 dark:text-gray-200">
          Komorbiditäten ausschließen
        </label>
        <Tooltip
          :message="`Hier kannst du Erkrankungen, z.B. häufige Komorbiditäten, oder Ausprägungen ausschließen,
          z.B. 'Adipositas' oder 'Diabetes mellitus', wenn du einen Patienten mit NASH-Zirrhose erstellst.`"
        />
      </div>
      <!-- End Col -->
      <div v-show="showAbsent" class="sm:col-span-9 overflow-visible">
        <Field v-slot="{ field, errors, value }" name="absent_medical_conditions" rules="max:1000">
          <textarea
            v-bind="field"
            name="absent_medical_conditions"
            class="py-2 px-3 resize-none block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
            rows="1"
            placeholder="Relevante oder häufige Komorbiditäten u.ä., von dem dein:e Patient:in nicht betroffen sein soll (optional)"
            :class="{
              'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
            }"
          />
          <ErrorMessage class="text-sm text-red-600 mt-2" name="unknown_medical_conditions" />
        </Field>
      </div>
      <!-- End row -->
    </div>
  </div>

  <div class="divide-y divide-gray-200 pt-1" v-show="!!showPatientDetails">
    <div></div>
    <div></div>
  </div>
</template>

<style scoped></style>
