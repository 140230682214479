<script setup lang="ts">
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import PersonCard from '@/components/new_case/sections/PersonCard.vue';

interface Person {
  create_id: number;
  profile_image: Object | null;
  details: Object;
}

const { isLoading, requireAtLeastOnePatient, startingFromCompleteCase } = defineProps([
  'isLoading',
  'requireAtLeastOnePatient',
  'startingFromCompleteCase',
]);
const persons = defineModel({
  type: Array as () => Array<Person>,
  required: true,
});
const personCardsAreValid = ref([] as boolean[]);
const emit = defineEmits(['scrollToBottom', 'isValid']);

const xScrollContiner = ref(null);

onMounted(() => {
  for (let person of persons.value) {
    personCardsAreValid.value.push(startingFromCompleteCase);
  }
  console.log(
    'Persons.vue mounted with startingFromCompleteCase',
    startingFromCompleteCase,
    ' and ',
    persons ? persons.value.length : 0,
    ' persons',
  );
});

async function addPerson() {
  console.log('onAddPerson');
  persons.value.unshift({
    create_id: persons.value.length,
    profile_image: null,
    details: {
      freehand_description: '',
      age: null,
      sex: null,
      first_name: '',
      last_name: '',
      academic_title: null,
      main_medical_conditions: [],
      known_medical_conditions: [],
      unknown_medical_conditions: [],
      add_comorbidities: false,
      absent_medical_conditions: [],
      biography: '',
      style_of_speech: '',
      role: { value: null },
    },
  });
  personCardsAreValid.value.unshift(false); // no role selected in the beginning, so NOT valid
}

async function removePerson(id: number) {
  console.log('onRemovePerson', id);
  // get index of person withb person.create_id = index
  const indexToRemove = persons.value.findIndex((person) => person.create_id === id);
  persons.value.splice(indexToRemove, 1);
  personCardsAreValid.value.splice(indexToRemove, 1);
}

function centerPerson(item: any) {
  const container = xScrollContiner.value;

  if (!container) {
    return;
  }

  // Get the container's width and scroll position
  const containerWidth = container.clientWidth;
  const containerScrollLeft = container.scrollLeft;

  // Get the item's width and position relative to the container
  const itemWidth = item.clientWidth;
  const itemOffsetLeft = item.offsetLeft;

  // Calculate the scroll position to center the item
  const scrollTo = itemOffsetLeft - containerWidth / 2 + itemWidth / 2;
  console.log('scrollTo', scrollTo);

  if (scrollTo < 0) {
    return;
  }

  // Smoothly scroll the container to the calculated position
  container.scroll({
    left: scrollTo,
    behavior: 'smooth',
  });
}

onMounted(() => {
  emit('isValid', startingFromCompleteCase);
});

const noPatient = computed(() => {
  if (!requireAtLeastOnePatient) {
    return false;
  }
  if (!persons.value) {
    return true;
  }
  let noPatient = !persons.value.some((person) => person.details?.role?.value === 'PATIENT');
  console.log('noPatient', noPatient);
  return noPatient;
});

const moreThanOnePatient = computed(() => {
  if (!persons.value) {
    return false;
  }
  let moreThanOnePatient = persons.value.filter((person) => person.details?.role?.value === 'PATIENT').length > 1;
  console.log('moreThanOnePatient', moreThanOnePatient);
  return moreThanOnePatient;
});

const isValid = computed(() => {
  console.log('Checking Persons.vue validity');
  console.log('!!personCardsAreValid', !!personCardsAreValid.value);
  console.log('!noPatient', !noPatient.value);
  console.log(
    'checking all cards',
    personCardsAreValid.value.every((isValid) => isValid),
  );
  console.log(
    persons.value.length,
    !!personCardsAreValid.value,
    !noPatient.value,
    !moreThanOnePatient.value,
    personCardsAreValid.value.every((isValid) => isValid),
  );
  console.log('!moreThanOnePatient', !moreThanOnePatient.value);
  let isValid =
    persons.value.length &&
    !!personCardsAreValid.value &&
    !noPatient.value &&
    !moreThanOnePatient.value &&
    personCardsAreValid.value.every((isValid) => isValid);
  // emit('isValid', isValid);
  console.log('isValid (all persons)', isValid);
  return isValid;
});

watch(
  () => isValid.value,
  (isValid) => {
    console.log('isValid (all persons) emitting', isValid);
    emit('isValid', isValid);
  },
);
</script>

<template>
  <div class="py-8 grid grid-cols-11 gap-y-1 min-h-fit h-fit max-h-fit overflow-visible">
    <h2
      class="flex col-span-11 min-h-fit h-fit max-h-fit text-xl mt-2.5 font-bold text-gray-800 dark:text-gray-200 overflow-visible"
    >
      Füge alle weiteren beteiligten Personen hinzu
    </h2>
    <div class="flex-col flex col-span-11 min-h-fit h-fit max-h-fit w-full min-w-full overflow-visible">
      <div class="w-full text-gray-600 dark:text-gray-400 text-sm">
        Zum Beispiel Patient, pflegerischer Kollege, Oberärztin, Angehörige, Mitarbeiter des anzurufenden
        Pflegedienstes...
      </div>
      <div
        class="mt-6 mx-auto max-w-full min-h-fit h-fit max-h-fit inline-flex overflow-x-auto gap-x-2 overflow-y-visible"
        ref="xScrollContiner"
      >
        <!-- Person carousel -->
        <div class="flex-col min-h-fit h-fit max-h-fit overflow-visible">
          <PersonCard :starting-from-complete-case="true" key="create" @addPerson="addPerson" />
          <span
            v-show="requireAtLeastOnePatient && noPatient"
            class="text-blue-600 text-xs -mt-1 cursor-pointer"
            @click="addPerson"
            >Lege einen Patienten an</span
          >
          <span
            v-show="!requireAtLeastOnePatient && persons.length === 0"
            class="text-blue-600 text-xs -mt-1 cursor-pointer"
            @click="addPerson"
            >Lege eine Person an</span
          >
          <span v-show="moreThanOnePatient" class="text-red-600 text-xs -mt-1">Nur ein Patient erlaubt</span>
        </div>
        <div class="flex gap-x-2 max-w-full overflow-visible">
          <PersonCard
            v-for="(person, index) in persons"
            :key="person.create_id"
            v-model="persons[index]"
            :allowEdit="true"
            :allowRemove="true"
            :requireCompleteness="false"
            :startingFromCompleteCase="startingFromCompleteCase"
            @removePerson="removePerson"
            @centerMe="centerPerson($event.target)"
            @scrollToBottom="emit('scrollToBottom')"
            @isValid="personCardsAreValid[index] = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
