<script setup lang="ts">
const inputText = defineModel();

const props = defineProps({
  disabled: Boolean,
  placeholderMessage: String,
  unlockAudioContext: {
    type: Function,
    default: null,
  },
});

const emits = defineEmits(['onSubmit']);

const handleSubmit = async () => {
  if (props.unlockAudioContext) {
    console.log('Unlocking audio context from ChatInput...');
    await props.unlockAudioContext();
  }
  emits('onSubmit');
};
</script>

<template>
  <div
    class="inline-flex shadow-sm col-start-1 row-start-1 col-span-1 row-span-1 rounded-lg border border-gray-200"
    :class="[
      !props.disabled
        ? inputText
          ? 'focus-within:border-blue-600 focus-within:ring-blue-600'
          : 'focus-within:ring-blue-300 focus-within:border-blue-300'
        : 'focus-within:border-gray-400 focus-within:ring-gray-400',
    ]"
  >
    <!-- this div is styles as if it was a part of the textarea to give a screen size-adaptable indent -->
    <div
      class="pl-4 bg-white sm:pl-12 sm:bg-white rounded-l-lg md:pl-12 md:bg-white lg:pl-20 lg:bg-white xl:pl-16 xl:bg-white 2xl:pl-12 2xl:bg-white"
    />
    <textarea
      v-model="inputText"
      @keyup.enter="handleSubmit"
      :rows="3"
      class="flex-grow px-1 z-10 border-none resize-none rounded-r-lg text-base sm:text-xs focus:text-base py-3.5 focus:py-2 transition-[font-size] duration-300 dark:bg-neutral-900 focus:border-none focus:ring-0 dark:border-gray-700 dark:text-gray-400"
      :placeholder="placeholderMessage"
    />
    <!--              </div>-->
  </div>
  <div class="flex justify-end items-end col-start-1 row-start-1 col-span-1 row-span-1 px-1 py-1">
    <!-- Send Button -->
    <button
      @click="handleSubmit"
      type="button"
      :disabled="props.disabled"
      class="z-40 rounded-md h-7 w-7 lg:h-10 lg:w-10"
      :class="[
        !props.disabled
          ? inputText
            ? 'bg-blue-600 hover:bg-blue-700'
            : 'bg-blue-300 hover:bg-blue-300'
          : 'bg-gray-500 hover:bg-gray-400 focus:ring-2 focus:ring-blue-600 focus:z-10 focus:outline-none ',
      ]"
    >
      <span translate="no" class="material-symbols-outlined notranslate text-white text-3xl lg:text-4xl -mt-1">
        send
      </span>
    </button>
    <!-- End Send Button -->
  </div>
</template>

<style scoped></style>
