<script setup lang="ts">
import { useAuthStore } from '@/stores';
import ProfileImage from '@/components/ProfileImage.vue';
import { computed } from 'vue';

const authStore = useAuthStore();

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  timestamp: {
    type: String,
    required: false,
    default: 'just now',
  },
  author: {
    type: String,
    required: true,
    validator: (value: string) => ['user', 'companion'].includes(value),
  },
  firstOfThatAuthorInGroup: {
    type: Boolean,
    required: false,
    default: false,
  },
  lastOfThatAuthorInGroup: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const timestamp = computed(() => {
  if (props.timestamp === 'not_yet_in_database') return 'just now';
  if (props.timestamp === 'incomplete') return 'just now';
  if (!props.timestamp) return 'just now';
  try {
    const date = new Date(props.timestamp);
    if (isNaN(date.getTime())) return props.timestamp;
    return date.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  } catch (e) {
    return props.timestamp;
  }
});
</script>

<template>
  <div class="flex flex-col min-w-full">
    <p
      v-if="props.author === 'companion' && props.firstOfThatAuthorInGroup"
      class="mb-1.5 text-xs text-gray-400 dark:text-neutral-500"
    >
      Companion
    </p>

    <p
      v-if="props.author === 'user' && props.lastOfThatAuthorInGroup"
      class="mb-1.5 min-w-full text-xs text-gray-400 dark:text-neutral-500 text-end"
    >
      Du
    </p>

    <div
      class="group inline-flex min-w-full gap-x-2"
      :class="{
        'justify-start': props.author === 'companion',
        'justify-end': props.author === 'user',
      }"
      style="word-break: break-word"
    >
      <div
        v-if="props.author === 'companion'"
        class="shrink-0 mt-auto border p-1 border-blue-700/30 text-blue-700 rounded-full"
        :class="{
          'opacity-0': !props.lastOfThatAuthorInGroup,
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-stars"
          viewBox="0 0 16 16"
        >
          <path
            d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"
          />
        </svg>
      </div>

      <div v-if="props.author === 'user'">
        <ProfileImage
          :image="authStore.userProfileImageSmall"
          :initials="authStore.userInitials"
          :showIngameLevel="true"
          size="30px"
          :includeIngameProgressCardAsTooltip="false"
        />
      </div>

      <div class="order-1 bg-white shadow-sm dark:bg-neutral-800 inline-block rounded-xl pt-2 pb-1.5 px-2.5">
        <div class="text-sm text-gray-800 dark:text-neutral-200">
          {{ props.content }}
          <span>
            <span class="text-[11px] pl-2 text-gray-400 dark:text-neutral-600 italic">{{ timestamp }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
