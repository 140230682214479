<script setup>
import { storeToRefs } from 'pinia';
import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useAlertStore } from '@/stores';
import { nextTick, onMounted, reactive, ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faVial, faRadiation, faNotesMedical, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { HSTooltip, HSStaticMethods } from 'preline';

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  reset: {
    default: false,
    type: Boolean,
  },
  isLoading: {
    default: false,
    type: Boolean,
  },
  label: {
    type: String,
    default: '',
  },
  labelAbbreviation: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
  },
  style: {
    type: String,
    default: 'InteractionInput',
    validator: (value) => ['InteractionInput', 'MainView'].includes(value),
  },
  addSparkles: {
    type: Boolean,
    default: false,
  },
  targetActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onToggle']);

const caseInteractionStore = useCaseInteractionStore();

const state = reactive({
  tooltip: null,
  tooltipFixed: false,
  tooltipOpen: false,
});

const handleToggled = async () => {
  if (props.disabled) return;
  emit('onToggle');
};

onMounted(async () => {});
</script>

<template>
  <div class="group">
    <div :id="`${id}-toggle`">
      <button
        v-if="props.style === 'InteractionInput'"
        type="button"
        @mousedown="handleToggled"
        :disabled="props.disabled"
        class="flex-col transition-all duration-300 flex p-1 justify-center mt-1 gap-y-1 w-12 md:h-16 md:w-16 lg:w-20 border border-gray-200 rounded-lg inline-flex justify-center items-center -ms-px text-sm focus:z-10 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-30 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
        :class="props.targetActive ? 'text-blue-600 font-bold bg-blue-100/10' : 'text-gray-800'"
      >
        <div class="flex w-full items-center justify-center relative" v-if="props.addSparkles">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-stars"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"
              />
            </svg>
          </div>
          <div class="absolute -top-2 -right-1">
            <div v-if="!!props.icon" translate="no" class="material-symbols-outlined notranslate text-xl select-none">
              {{ icon }}
            </div>
          </div>
        </div>

        <div
          v-if="!props.addSparkles"
          translate="no"
          class="-mb-2 -mt-1 material-symbols-outlined notranslate text-3xl md:text-4xl md:-mt-2 select-none"
        >
          {{ icon }}
        </div>

        <div class="items-center hidden lg:block -mt-2 text-sm select-none">{{ label }}</div>
        <div class="items-center lg:hidden text-xs md:text-sm select-none">
          {{ !!labelAbbreviation ? labelAbbreviation : label }}
        </div>
      </button>
      <button
        v-else
        type="button"
        @mousedown="handleToggled"
        class="inline-flex px-2 gap-x-2 text-white justify-center bg-gray-500/70 hover:bg-gray-500/100 items-center border border-gray-200 rounded-full"
      >
        <div translate="no" class="material-symbols-outlined notranslate text-2xl md:text-3xl md:-mt-2 select-none">
          {{ icon }}
        </div>
        <div class="items-center text-xs whitespace-nowrap">
          {{ label }}
        </div>
      </button>
    </div>
  </div>
</template>

<style></style>
