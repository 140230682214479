/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DialogMessageInvolvementCreate } from './DialogMessageInvolvementCreate';

export type DialogMessageCreate = {
  content: string | null;
  index: number;
  person_message_involvements: Array<DialogMessageInvolvementCreate> | null;
  type: DialogMessageCreate.type;
  language?: string;
  translations?: Record<string, any> | null;
  audio_url?: string;
};

export namespace DialogMessageCreate {
  export enum type {
    PERSON_SAY = 'PERSON_SAY',
    AUDIO_EFFECT = 'AUDIO_EFFECT',
    DESC = 'DESC',
  }
}
