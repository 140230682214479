<script setup lang="ts">
import { computed, onMounted, ref, watch, nextTick } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { CategorizationItemType } from '@/helper/typing';
import { debounce } from 'lodash';
import { is } from '@vee-validate/rules';

const props = defineProps({
  item: { type: Object as () => CategorizationItemType, required: true },
  isInCategory: { type: Boolean, default: false },
  isIncorrect: { type: Boolean, default: false },
  isEditing: { type: Boolean, default: false },
  color: { type: String, default: 'gray' },
  icon: { type: String, default: null },
  onClick: { type: Function, required: false, default: null },
});

const emit = defineEmits([
  'changeItem',
  'deleteItem',
  'droppedItemAtPosition',
  'scrollBy',
  'currentDraggingPosition',
  'startDraggingItem',
]);

const draggableItem = ref(null);
const isDragging = ref(false);

// Cave: null < 170 = true, so CANNOT use null as initial value!
const currentPosition = ref({ x: undefined, y: undefined });
const initialPosition = ref({ x: undefined, y: undefined });
const offset = ref({ x: 0, y: 0 });
const touchX = ref(undefined);
const touchY = ref(undefined);
const scrollInterval = ref<ReturnType<typeof setInterval> | null>(null);

const itemClasses = computed(() => ({
  'bg-gray-100 ': !isDragging.value && !props.isIncorrect && !props.isInCategory, // Default background in waiting zone
  'bg-gray-400': isDragging.value && !props.isIncorrect && !props.isInCategory, // Default background in waiting zone
  'bg-red-50 border-red-500 text-red-500': !isDragging.value && props.isIncorrect && !props.isEditing, // Red background for incorrect drop
  'bg-teal-50 border-teal-500 text-teal-500': !isDragging.value && props.isInCategory && !props.isEditing, // Green background if correctly placed
  'bg-gray-50': !isDragging.value && props.isEditing, // Gray background if editing
}));

const handleDragStart = (event: Event) => {
  if (!draggableItem.value) return;
  if (isDragging.value) return;
  isDragging.value = true;
  draggableItem.value.style.backdropBrightness = '0.5';
  event.dataTransfer.setData('itemId', props.item.id);

  // handle the offset
  const rect = draggableItem.value.getBoundingClientRect();
  offset.value = {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
  console.log('start dragging');

  emit('startDraggingItem', props.item.id);
};

const handleDragging = (event: Event) => {
  if (!isDragging.value) handleDragStart(event);
  debounceTrackCurrentPosition(event);
  debounceAutoScroll(10);
};

const handleEndDragging = () => {
  isDragging.value = false;
  draggableItem.value.style.backdropBrightness = '1';
  currentPosition.value = { x: undefined, y: undefined };
  stopAutoScroll();
};

const handleTouchStart = (event: Event) => {
  if (!draggableItem.value) return;
  if (isDragging.value) return;
  let touch;
  try {
    touch = event.touches[0];
  } catch (e) {
    return; // invoked on desktop
  }
  isDragging.value = true;
  const rect = draggableItem.value.getBoundingClientRect();
  offset.value.x = touch.clientX - rect.left;
  offset.value.y = touch.clientY - rect.top;
};

const handleTouchEnd = (event: Event) => {
  if (!draggableItem.value) return;
  isDragging.value = false;
  stopAutoScroll();
  // this also signal end of dropping. Do not send signal drop end before sending drop position as this would reset ID
  // of dragged item in parent (not necessary at all, but in any case send position first)
  emit('droppedItemAtPosition', props.item.id, { x: touchX.value, y: touchY.value });
  draggableItem.value.style.position = 'static';
};

const handleTouchMove = (event: Event) => {
  if (!draggableItem.value) return;
  // Prevent the default scroll behavior; needs @touchmove instead of v-touch:drag for access to event
  event.preventDefault();

  // Get the current touch position
  const touch = event.touches[0];
  touchX.value = touch.clientX;
  touchY.value = touch.clientY;

  // Trigger auto-scroll if near the top or bottom of the viewport
  debounceAutoScroll();

  // Set the element's position to follow the touch point directly
  draggableItem.value.style.position = 'fixed';
  draggableItem.value.style.left = `${touchX.value - offset.value.x}px`;
  draggableItem.value.style.top = `${touchY.value - offset.value.y}px`;

  debounceSignalCurrentPosition();
};

const signalCurrentPosition = () => {
  emit('currentDraggingPosition', props.item.id, { x: touchX.value, y: touchY.value });
};

// Function to handle auto-scrolling when the user drags near screen edges
const autoScroll = (speed: number = 5) => {
  // If close to the top, scroll up
  if (touchY.value < 170 || currentPosition.value.y < 220) {
    startAutoScroll(-speed); // Scroll up by 5px
  }
  // If close to the bottom, scroll down
  else if (touchY.value > window.innerHeight - 70 || currentPosition.value.y > window.innerHeight - 150) {
    startAutoScroll(speed); // Scroll down by 5px
  } else {
    stopAutoScroll(); // Stop scrolling if in the middle area
  }
};

// Starts the auto-scroll at the specified speed (positive for down, negative for up)
const startAutoScroll = (distance: number) => {
  if (scrollInterval.value) return; // Prevent multiple intervals
  scrollInterval.value = setInterval(() => {
    emit('scrollBy', distance);
  }, 20);
};

// Stops the auto-scrolling
const stopAutoScroll = () => {
  if (scrollInterval.value) {
    clearInterval(scrollInterval.value);
    scrollInterval.value = null;
  }
};

const trackCurrentPosition = (event) => {
  // Track the current position of the cursor

  if (event.clientX === 0 && event.clientY === 0) return;
  // note: at the end of drag, 0/0 is sent for a short time. We do not want this, as this leads to short up scroll while drag is resetting.

  currentPosition.value = {
    x: event.clientX,
    y: event.clientY,
  };
};

onMounted(() => {
  // Get the initial position of the draggable item
  const rect = draggableItem.value.getBoundingClientRect();
  initialPosition.value = {
    x: rect.left,
    y: rect.top,
  };
});

const dynamicClasses = computed(() => {
  return props.onClick
    ? {
        [`text-${props.color}-600 group-hover:text-${props.color}-700`]: true,
        [`dark:text-${props.color}-400 dark:group-hover:text-${props.color}-300`]: true,
      }
    : {};
});

const debounceAutoScroll = debounce(autoScroll, 5);
const debounceSignalCurrentPosition = debounce(signalCurrentPosition, 5);
const debounceTrackCurrentPosition = debounce(trackCurrentPosition, 5);
</script>

<template>
  <div
    ref="draggableItem"
    :class="[
      'min-w-[calc(50%-20px)] md:min-w-[190px] py-2 flex items-center mb-0.5 z-10 border shadow-sm hover:shadow-md focus:shadow-md text-center rounded-lg transition-transform transform duration-1000',
      itemClasses,
      { 'animate-bounce-back': props.isIncorrect },
      props.onClick || props.isEditing ? 'cursor-pointer ' : 'cursor-move',
    ]"
    :style="{
      left: currentPosition.x - offset.x + 'px',
      top: currentPosition.y - offset.y + 'px',
      opacity: isDragging ? 0.4 : 1,
    }"
    draggable="true"
    @drag.prevent="handleDragging"
    @dragend.prevent="handleEndDragging"
    style="{ position: fixed }"
    @click.prevent="props.onClick"
    v-touch:press="handleTouchStart"
    v-touch:release="handleTouchEnd"
    @touchmove="handleTouchMove"
  >
    <span
      translate="no"
      class="material-symbols-outlined notranslate pr-2 pl-2 select-none md:mr-0"
      :class="{
        'text-gray-400 dark:text-gray-500 text-lg md:text-xl':
          !props.isInCategory && !props.isIncorrect && !props.onClick,
        'text-teal-500 dark:text-teal-500 text-lg md:text-xl': props.isInCategory && !props.isEditing && !props.onClick,
        'text-red-500 dark:text-red-500 text-lg md:text-xl': props.isIncorrect && !props.isEditing && !props.onClick,
        'text-red-600 hover:text-red-800 text-2xl': props.isEditing && !props.onClick,
        ...dynamicClasses,
      }"
      @click.prevent="
        () => {
          if (props.isEditing) emit('deleteItem', item.id);
        }
      "
    >
      {{
        !!props.icon ? props.icon : props.isInCategory ? 'task_alt' : props.isIncorrect ? 'dangerous' : 'drag_indicator'
      }}
    </span>
    <span
      v-show="!isEditing"
      class="select-none text-xs md:text-sm fallback-break"
      :class="[
        `text-${props.color}-600 dark:text-${props.color}-400`,
        props.onClick ? `group-hover:text-${props.color}-700 dark:group-hover:text-${props.color}-300` : '',
      ]"
      :style="{ whiteSpace: 'normal' }"
      >{{ props.item.content }}</span
    >
    <textarea
      ref="textareaRef"
      v-show="isEditing"
      type="text"
      class="resize-none text-xs md:text-sm p-2 border border-gray-200 rounded-md dark:bg-neutral-800 dark:border-neutral-700"
      :value="props.item.content"
      placeholder="Begriff"
      @input="
        async (event) => {
          await updateMinTextareaHeight(event.target);
        }
      "
      @change="
        async (event) => {
          await updateMinTextareaHeight(event.target);
          emit('changeItem', item.id, event.target.value);
        }
      "
      :rows="2"
    />
  </div>
</template>

<style scoped>
@keyframes bounce-back {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(20px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-bounce-back {
  animation: bounce-back 0.5s ease;
}
</style>
