<script setup lang="ts">
import {
  getCoreRowModel,
  useVueTable,
  createColumnHelper,
  ColumnOrderState,
  Column,
  ColumnResizeMode,
  ColumnVisibility,
  ColumnResizeDirection,
} from '@tanstack/vue-table';
import { computed, onMounted, ref, h, reactive, watch, nextTick } from 'vue';
import VocabListEntry from '@/components/didactics/VocabListEntry.vue';
import VocabListTableHead from '@/components/didactics/VocabListTableHead.vue';
import VocabListSearchbar from '@/components/didactics/VocabListSearchbar.vue';
import { useAlertStore, useAuthStore, useCourseStore } from '@/stores';
import { faker } from '@faker-js/faker';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
import { updateMinTextareaHeight } from '@/helper';
import AudioPlayerTooltip from '@/components/audio/AudioPlayerTooltip.vue';
import { storeToRefs } from 'pinia';
import { getApiClient } from '@/apiclient/client';

const { t } = useI18n();

const alertStore = useAlertStore();
const courseStore = useCourseStore();
const authStore = useAuthStore();

const props = defineProps({
  showSearchbar: {
    type: Boolean,
    default: true,
  },
  addedHeaderHeights: {
    type: Number,
    default: 0,
  },
  fullWidth: {
    type: Number,
    default: 300,
  },
  vocabList: {
    type: Array,
    default: [],
  },
  viewFullscreen: {
    type: Boolean,
    default: true,
  },
  showColumnSelected: {
    type: Boolean,
    default: false,
  },
  showColumnTerm: {
    type: Boolean,
    default: true,
  },
  showColumnUsageExamples: {
    type: Boolean,
    default: true,
  },
  showColumnExplanation: {
    type: Boolean,
    default: true,
  },
  showColumnContextLinks: {
    type: Boolean,
    default: true,
  },
  showColumnTranslations: {
    type: Boolean,
    default: true,
  },
  showColumnRemarks: {
    type: Boolean,
    default: true,
  },
  showColumnTags: {
    type: Boolean,
    default: true,
  },
  showColumnResultsAtTests: {
    type: Boolean,
    default: true,
  },
  showColumnIsFavourite: {
    type: Boolean,
    default: true,
  },
  allowEditing: {
    type: Boolean,
    default: true,
  },
  allowEditingTerm: {
    type: Boolean,
    default: false,
  },
  hideRandomButOneColumn: {
    type: Array,
    default: [],
  }, // give the column name of which all but one shall be starred. All others are hidden.
  disableScroll: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'isDragging',
  'updatedCell',
  'currentDraggingPosition',
  'droppedAtPosition',
  'scrollBy',
  'deleteItem',
]);

const data = ref([]);

const { favoriteVocabList } = storeToRefs(authStore);

const scrollContainerVocabList = ref<HTMLElement | null>(null);
const currentYScroll = ref(0);
const outerContainer = ref<HTMLElement | null>(null);

// reactive states for cell rendering
const editModeState = reactive(new Map());
const isMaximizedState = reactive(new Map());
const visibleColumnsMap = reactive(new Map());
const usageExamplesContentRefs = reactive(new Map<string, HTMLElement | null>());
const usageExamplesAreOverflowingState = reactive(new Map<string, boolean>());
const isFavorite = ref([]);
const addingToFavorite = ref([]);

const playAudioAtUrl = ref(null as string | null);
const audioPlayerPosition = ref({ x: 0, y: 0 });

onMounted(() => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  if (scrollContainerVocabList.value) {
    scrollContainerVocabList.value.addEventListener(
      'scroll',
      () => {
        currentYScroll.value = scrollContainerVocabList.value.scrollTop;
      },
      { passive: true },
    );
  }

  // Initialize edit mode state for each cell
  // props.vocabList.forEach((row, rowIndex) => {
  //   Object.keys(row).forEach((columnId) => {
  //     editModeState.set(`${rowIndex}-${columnId}`, false);
  //   });
  // });
  //
  // data.value = props.vocabList;

  initializeTable();
});

const initializeTable = () => {
  data.value = [...props.vocabList];
  // Note: this INTENTIONALLY breaks Vue's reacivity system which seems bullshit but forces
  // tanstack to re-render the table, cf. https://www.reddit.com/r/vuejs/comments/16wn76t/comment/k2zwgft/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button
  // ** leave this comment **

  isFavorite.value.length = 0;
  props.vocabList.forEach((vocab) => {
    isFavorite.value.push(authStore.isFavorite(vocab));
    addingToFavorite.value.push(false);
  });

  initializeRandomVisibleColumns();
};

watch(
  () => favoriteVocabList.value.length,
  () => {
    isFavorite.value.length = 0;
    props.vocabList.forEach((vocab) => {
      isFavorite.value.push(authStore.isFavorite(vocab));
      addingToFavorite.value.push(false);
    });
  },
  { immediate: true },
);

watch(
  () => props.vocabList,
  () => {
    initializeTable();
  },
  { deep: true },
);

const computedStyleMain = computed(() => {
  if (props.viewFullscreen) {
    return {
      height: `calc(var(--fallback-viewport-height, 100vh) - ${props.addedHeaderHeights}px)`,
      width: props.fullWidth + 'px',
    };
  }
});

const computedStyleTable = computed(() => {
  if (props.viewFullscreen) {
    return {
      height: `calc(var(--fallback-viewport-height, 100vh) - ${props.addedHeaderHeights}px - 120px)`,
      width: outerContainer.value ? outerContainer.value.offsetWidth + 'px' : window.innerWidth - 400 + 'px',
    };
  }
});

type Vocab = {
  index: number;
  isSelected: boolean;
  term: string;
  audio_url: string;
  explanation: string;
  usageExamples: string[];
  exampleAudioUrls: string[];
  contextLinks: string[];
  translations: string[];
  image_url: string[];
  tags: string[];
  isFavourite: boolean;
  vocabListIds: string[];
  lastTestedAt: string;
  resultsAtTests: boolean[];
  remarks: string;
};

// Initialize random visible column for each row based on hideRandomButOneColumn
function initializeRandomVisibleColumns() {
  if (!props.hideRandomButOneColumn || props.hideRandomButOneColumn.length === 0) {
    visibleColumnsMap.clear(); // Clear any existing random selection if feature is disabled
    return;
  }

  // Pick a random column to show for each row (others are starred)
  data.value.forEach((row, rowIndex) => {
    const randomColumn = faker.helpers.arrayElement(props.hideRandomButOneColumn);
    visibleColumnsMap.set(rowIndex, new Set([randomColumn])); // Store as a set of visible columns
  });

  // Hide all columns which are not part of this game
  table.getAllLeafColumns().forEach((column) => {
    if (!props.hideRandomButOneColumn.includes(column.id)) {
      columnVisibility.value = {
        ...columnVisibility.value,
        [column.id]: false,
      };
    }
  });
}

function isEditMode(rowIndex: string, columnId: string) {
  return editModeState.get(`${rowIndex}-${columnId}`) || false;
}

function toggleEditMode(rowIndex: string, columnId: string) {
  if (!props.allowEditing) {
    return;
  }
  const key = `${rowIndex}-${columnId}`;
  editModeState.set(key, !isEditMode(rowIndex, columnId));
}

function isMaximized(rowIndex: string, columnId: string) {
  return isMaximizedState.get(`${rowIndex}-${columnId}`) || false;
}

function setMaximizedState(rowIndex: string, columnId: string, value: boolean) {
  const key = `${rowIndex}-${columnId}`;
  isMaximizedState.set(key, value);
}

// Set a reference for a specific cell
const setContentRef = (rowIndex: string, columnId: string, el: HTMLElement | null) => {
  const key = `${rowIndex}-${columnId}`;
  usageExamplesContentRefs.set(key, el);

  // Calculate overflow after the DOM is updated
  if (el) {
    usageExamplesAreOverflowingState.set(key, el.scrollHeight > el.clientHeight);
  }
};

// Check if a specific cell is overflowing
const isOverflowing = (rowIndex: number, columnId: string) => {
  const key = `${rowIndex}-${columnId}`;
  return usageExamplesAreOverflowingState.get(key) || false;
};

const removeContentRef = (rowIndex: string, columnId: string) => {
  const key = `${rowIndex}-${columnId}`;
  usageExamplesContentRefs.delete(key);
  usageExamplesAreOverflowingState.delete(key);
};

const recalculateOverflow = (rowIndex: string, columnId: string) => {
  const key = `${rowIndex}-${columnId}`;
  nextTick(() => {
    const el = usageExamplesContentRefs.get(key);
    if (el) {
      usageExamplesAreOverflowingState.set(key, el.scrollHeight > el.clientHeight);
    }
  });
};

const addFavorite = async (index: number) => {
  await (
    await getApiClient()
  ).vocabLists
    .copyVocabToPersonalVocabList(favoriteVocabList.value.id, props.vocabList[index].id)
    .then(async (response) => {
      alertStore.success('Vocabulary added to your Wortschatzkiste');
      // play the add animation, so:
      addingToFavorite.value[index] = true;
      // wait 300 ms
      await new Promise((resolve) => setTimeout(resolve, 300));
      addingToFavorite.value[index] = false;
      isFavorite.value[index] = true;
    })
    .catch((error) => {
      alertStore.error('Failed to add vocab item to favorites', 'Error', error);
    });
};

const buildEditableTextCell = (
  toggleEditMode: any,
  audioUrlPropertyName: string | null = null,
  imageUrlPropertyName: string | null = null,
  allowEditing: boolean = true,
  showAddFavoriteShortcutIcon: boolean = false,
) => {
  let editableTextCell = ({ row, column, getValue }: { row: any; column: any; getValue: any }) => {
    // Define a reactive object to toggle edit mode for each row
    const isRandomColumnVisible =
      !props.hideRandomButOneColumn ||
      props.hideRandomButOneColumn.length === 0 ||
      (visibleColumnsMap.get(row.index) && visibleColumnsMap.get(row.index).has(column.id));

    return h(
      'div',
      {
        class: 'inline-flex overflow-visible whitespace-normal fallback-break',
        style: "{ width: column.getSize() + 'px' }",
      },
      [
        isRandomColumnVisible
          ? isEditMode(row.index, column.id)
            ? h('textarea', {
                value: row.original[column.id],
                onInput: (e: Event) => {
                  const target = e.target as HTMLInputElement;
                  // target.style.width = target.value.length + 1 + 'ch';
                  updateMinTextareaHeight(target);
                },
                onBlur: async (e: Event) => {
                  const target = e.target as HTMLInputElement;
                  row.original[column.id] = target.value;
                  toggleEditMode(row.index, column.id);
                  emit('updatedCell', row.original.id, column.id, target.value);
                },
                class:
                  'border-gray-200 p-0 rows-1 bg-transparent resize-none rounded-lg text-xs md:text-sm flex w-[' +
                  column.getSize() +
                  'px]',
              })
            : h(
                'span',
                {
                  class: 'overflow-hidden whitespace-normal fallback-break',
                  style: '{ width: `min(${row.original[column.id].length + 1}ch, ${column.getSize()}px)` }',
                  onClick: () => {
                    toggleEditMode(row.index, column.id);
                  },
                },
                row.original[column.id],
              )
          : h(
              'button',
              {
                onClick: () => {
                  // Reveal clicked column for this row
                  if (!visibleColumnsMap.get(row.index)) {
                    visibleColumnsMap.set(row.index, new Set());
                  }
                  visibleColumnsMap.get(row.index).add(column.id);
                },
                class: 'text-gray-400 hover:text-gray-800',
              },
              '***',
            ),
        !!audioUrlPropertyName
          ? !!row.original[audioUrlPropertyName] &&
            h(
              'button',
              {
                onClick: async (event) => {
                  if (row.original[audioUrlPropertyName]) {
                    playAudioAtUrl.value = null;
                    await nextTick();
                    const { clientX, clientY } = event; // Get click position
                    console.log('Play audio: ', row.original[audioUrlPropertyName], 'x', clientX, 'y', clientY);
                    audioPlayerPosition.value = { x: clientX, y: clientY };
                    playAudioAtUrl.value = row.original[audioUrlPropertyName];
                  }
                },
                class: 'pl-1 text-gray-400 hover:text-gray-800 flex items-center',
              },
              [
                h(
                  'span',
                  {
                    translate: 'no',
                    class: 'material-symbols-outlined text-[24px] notranslate -mb-2 text-lg',
                  },
                  'volume_up',
                ),
              ],
            )
          : null,
        h(
          'button',
          {
            onClick: () => addFavorite(row.index),
            class: {
              'text-gray-400 hover:text-gray-800 flex items-center': true,
              hidden: isFavorite.value[row.index] || !showAddFavoriteShortcutIcon,
            },
          },
          [
            h(
              'span',
              {
                style: {
                  fontVariationSettings: addingToFavorite.value[row.index] ? "'FILL' 1" : "'FILL' 0",
                  fontFamily: 'Material Symbols Outlined',
                },
                class: {
                  'animate-grow-pop text-[27px]': addingToFavorite.value[row.index],
                  'flex items-center -mb-2 text-center material-symbols-outlined notranslate cursor-pointer text-gray-400 hover:text-gray-500':
                    true,
                },
                translate: 'no',
              },
              'star',
            ),
          ],
        ),
        !!imageUrlPropertyName
          ? h(
              'button',
              {
                onClick: () => {
                  alertStore.info(
                    'Noch nicht implementiert - will follow soon',
                    'Show image: ' + row.original[imageUrlPropertyName],
                  );
                },
                class: 'hidden pl-0.5 text-gray-400 hover:text-gray-800 flex items-center',
              },
              [
                h(
                  'span',
                  {
                    class: 'material-symbols-outlined text-[24px] notranslate -mb-2 text-lg',
                    translate: 'no',
                  },
                  'image',
                ),
              ],
            )
          : null,
        !!props.allowEditing && allowEditing
          ? h(
              'button',
              {
                onClick: () => {
                  toggleEditMode(row.index, column.id);
                },
                class:
                  'pl-0.5 text-gray-400 hover:text-gray-800 flex items-center overflow-visible' +
                  (!props.hideRandomButOneColumn ? '' : ' hidden'),
              },
              [
                h(
                  'span',
                  {
                    class: 'material-symbols-outlined notranslate text-[24px] -mb-2 text-lg overflow-visible',
                    translate: 'no',
                  },
                  isEditMode(row.index, column.id) ? 'done' : 'edit',
                ),
              ],
            )
          : null,
      ],
    );
  };
  return editableTextCell;
};

const buildEditableArrayOfTextsCell = (toggleEditMode: any) => {
  const editableArrayOfTextsCell = ({ row, column }: { row: any; column: any }) => {
    const addNewEntry = () => {
      if (!Array.isArray(row.original[column.id])) {
        row.original[column.id] = [];
      }
      row.original[column.id].push(''); // Add an empty string to the array
    };

    const removeEntry = (index: number) => {
      if (Array.isArray(row.original[column.id])) {
        row.original[column.id].splice(index, 1); // Remove the entry at the specified index
        removeContentRef(row.index, column.id); // Remove the reference to the content element
        recalculateOverflow(row.index, column.id); // Update overflow after content changes
        emit('updatedCell', row.original.id, column.id, row.original[column.id]);
      }
    };

    return h(
      'div',
      {
        class: 'relative w-full flex',
      },
      [
        h(
          'div',
          {
            ref: (el: HTMLElement | null) => {
              setContentRef(row.index, column.id, el);
            },
            class: {
              'w-full inline-flex flex-col space-y-0.5 py-1.5 h-fit': true,
              // 'h-8 overflow-hidden ': !isMaximized(row.index, column.id) && !isEditMode(row.index, column.id) && row.original[column.id].length >= 2,
              // 'min-h-8 h-fit': isMaximized(row.index, column.id) || isEditMode(row.index, column.id) || row.original[column.id].length <= 1,
            },
            style: `{ width: column.getSize() + 'px' }`,
            onMouseenter: () => {
              setMaximizedState(row.index, column.id, true);
            },
            onMouseleave: () => {
              setMaximizedState(row.index, column.id, false);
              recalculateOverflow(row.index, column.id); // Update overflow after content changes
            },
            onClick: () => {
              setMaximizedState(row.index, column.id, !isMaximized(row.index, column.id));
              if (!isMaximized(row.index, column.id)) {
                recalculateOverflow(row.index, column.id); // Update overflow after content changes
              }
            }, // Handles mobile tap
            onFocusout: (e: Event) => {
              const target = e.relatedTarget as HTMLTextAreaElement;
              if (!usageExamplesContentRefs.get(`${row.index}-${column.id}`)) {
                return; // no content ref
              }
              if (usageExamplesContentRefs.get(`${row.index}-${column.id}`).contains(target)) {
                return; // focus stil on a child
              }
              toggleEditMode(row.index, column.id);
              recalculateOverflow(row.index, column.id); // Update overflow after content changes
            },
          },
          [
            (isEditMode(row.index, column.id) ||
              !Array.isArray(row.original[column.id]) ||
              row.original[column.id].length === 0) &&
            props.allowEditing
              ? h(
                  'div',
                  {
                    class: 'flex flex-col h-full overflow-hidden space-y-0.5',
                  },
                  [
                    ...(row.original[column.id] || []).map((text: string, idx: number) =>
                      h('div', { class: 'flex items-center space-x-2' }, [
                        h('textarea', {
                          value: text,
                          onInput: (e: Event) => {
                            const target = e.target as HTMLInputElement;
                            // target.style.width = target.value.length + 1 + 'ch';
                            updateMinTextareaHeight(target);
                          },
                          onBlur: async (e: Event) => {
                            const target = e.target as HTMLTextAreaElement;
                            if (target.value.trim() === '') {
                              removeEntry(idx); // Remove the entry if it's empty
                              return;
                            }
                            row.original[column.id][idx] = target.value; // Update the value in the array
                            emit('updatedCell', row.original.id, column.id, row.original[column.id]);
                          },
                          rows: 2,
                          class: 'border-gray-200 bg-transparent resize-none rounded-lg text-xs md:text-sm flex-1',
                        }),
                        h(
                          'button',
                          {
                            onClick: () => removeEntry(idx),
                            class: 'text-red-500 hover:text-red-700',
                          },
                          [
                            h(
                              'span',
                              { class: 'material-symbols-outlined text-lg' },
                              'delete', // Material Design "delete" icon
                            ),
                          ],
                        ),
                      ]),
                    ),
                    // Always render the "Add Entry" button in edit mode
                    h(
                      'button',
                      {
                        onClick: addNewEntry,
                        class: {
                          'text-gray-400 hover:text-gray-800 flex items-center space-x-1': true,
                          '-mt-1': !Array.isArray(row.original[column.id]) || row.original[column.id].length === 0, // move up if no entry
                        },
                      },
                      [
                        h(
                          'span',
                          {
                            class: {
                              'material-symbols-outlined text-lg flex items-center ': true,
                            },
                          },
                          'add', // Material Design "add" icon
                        ),
                        h('span', { class: 'text-sm ' }, 'Add Entry'),
                      ],
                    ),
                  ],
                )
              : h(
                  'ul',
                  {
                    class: 'list-disc pl-0 space-y-1 pr-12',
                    onClick: () => {
                      toggleEditMode(row.index, column.id);
                    },
                  },
                  isMaximized(row.index, column.id)
                    ? (row.original[column.id] || []).map((text: string) =>
                        h('li', { class: 'text-xs md:text-sm' }, text),
                      )
                    : row.original[column.id][0] || '',
                ),
            h(
              'button',
              {
                onClick: () => {
                  toggleEditMode(row.index, column.id);
                  console.log(`Edit mode for ${row.index}-${column.id}:`, isEditMode(row.index, column.id));
                },
                class: {
                  hidden: !props.allowEditing,
                  'absolute right-0 pl-1 text-gray-400 hover:text-gray-800 flex items-center': true,
                  'top-[1.5px]': !isEditMode(row.index, column.id) && !isOverflowing(row.index, column.id),
                  '-top-3': !isEditMode(row.index, column.id) && isOverflowing(row.index, column.id),
                  'bottom-0': isEditMode(row.index, column.id),
                },
              },
              [
                h(
                  'span',
                  { class: 'material-symbols-outlined text-lg' },
                  isEditMode(row.index, column.id) ? 'done' : 'edit',
                ),
              ],
            ),

            // Show More button if content overflows
            !isEditMode(row.index, column.id) &&
              !isMaximized(row.index, column.id) &&
              (isOverflowing(row.index, column.id) || (row.original[column.id] || []).length > 1) &&
              h(
                'button',
                {
                  onClick: () => console.log('Expand content'),
                  class: 'absolute bottom-0 right-0 text-xs text-gray-600',
                },
                'Mehr',
              ),
          ],
        ),
      ],
    );
  };

  return editableArrayOfTextsCell;
};

// TODO: for usage exmaples, same but with array of strings
// => one shown, rest on dropdown
// => add button to add new usage example
// => edit on dropdown also (all span => input then)

// tanstack table declarations
const columnHelper = createColumnHelper<Vocab>();

const columns = [
  {
    accessorKey: 'isSelected',
    header: h('input', {
      type: 'checkbox',
      class:
        'border-gray-300 rounded text-blue-600 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800',
      onChange: (e: Event) => {
        const target = e.target as HTMLInputElement;
        data.value.forEach((row: any) => {
          row.isSelected = target.checked;
        });
      },
    }),
    cell: ({ row }: { row: any }) =>
      h('input', {
        type: 'checkbox',
        class:
          'border-gray-300 rounded text-blue-600 focus:ring-blue-500 dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800',
        checked: row.original.isSelected,
        onChange: (e: Event) => {
          const target = e.target as HTMLInputElement;
          row.original.isSelected = target.checked;
        },
      }),
    enableResizing: false,
    minSize: 10,
    size: 20,
  },
  {
    accessorKey: 'term',
    header: t('message.columnTerm'),
    cell: buildEditableTextCell(toggleEditMode, 'audio_url', 'image_url', props.allowEditingTerm, true),
    enableResizing: true,
  },
  {
    accessorKey: 'usage_examples',
    header: t('message.columnUsageExamples'),
    cell: buildEditableArrayOfTextsCell(toggleEditMode),
    enableResizing: true,
  },
  {
    accessorKey: 'explanation',
    header: t('message.columnExplanation'),
    cell: buildEditableTextCell(toggleEditMode),
  },
  {
    accessorKey: 'contextLinks',
    header: t('message.columnContextLinks'),
    cell: ({ row }: { row: any }) =>
      h(
        'div',
        {
          class: 'flex items-center space-x-2', // Adjust spacing between buttons as needed
        },
        row.original.contextLinks.map((url: string, index: number) =>
          h(
            'button',
            {
              key: index, // Unique key for each button
              class: 'flex items-center', // Adjust button styling
              onClick: () => {
                alertStore.info('Jump to context link: ' + url); // Specific URL in the alert
              },
            },
            [
              h(
                'span',
                {
                  translate: 'no',
                  class: 'material-symbols-outlined notranslate text-2xl mx-auto -my-2 hover:text-gray-500',
                  title: 'Jump to linked context',
                },
                'jump_to_element',
              ),
            ],
          ),
        ),
      ),
  },
  {
    accessorKey: 'translations',
    header: t('message.columnTranslations'),
    cell: buildEditableTextCell(toggleEditMode),
  },
  {
    accessorKey: 'remarks',
    header: t('message.columnRemarks'),
    cell: buildEditableTextCell(toggleEditMode),
  },
  {
    accessorKey: 'tags',
    header: t('message.columnTags'),
  },
  {
    accessorKey: 'resultsAtTests',
    header: t('message.columnResultsAtTests'),
    // cell should be average from last 10 tests
    cell: ({ row }: { row: any }) => {
      const results = row.original.resultsAtTests;
      const average = (results.reduce((acc: number, curr: boolean) => acc + (curr ? 1 : 0), 0) / results.length) * 100;
      return h('span', { class: 'whitespace-nowrap' }, `${average.toFixed(1)} %`);
    },
  },
  {
    accessorKey: 'isFavourite',
    header: t('message.columnIsFavorite'),
    cell: ({ row }: { row: any }) =>
      h(
        'button',
        {
          class: 'w-full flex items-center',
          onClick: () => {
            row.original.isFavourite = !row.original.isFavourite;
          },
        },
        [
          h(
            'span',
            {
              translate: 'no',
              class: 'material-symbols-outlined notranslate mx-auto text-2xl -my-2 hover:text-gray-500',
              style: `font-variation-settings: 'FILL' ${row.original.isFavourite ? 1 : 0};`,
            },
            'star',
          ),
        ],
      ),
    enableResizing: false,
  },
  {
    accessorKey: 'deleteItem',
    header: '',
    cell: ({ row }: { row: any }) =>
      h(
        'button',
        {
          class: 'w-full flex items-center',
          onClick: () => {
            emit('deleteItem', row.original.id);
          },
        },
        [
          h(
            'span',
            {
              translate: 'no',
              class: 'material-symbols-outlined notranslate mx-auto text-2xl -my-2 text-red-600 hover:text-red-700',
            },
            'delete',
          ),
        ],
      ),
    enableResizing: false,
  },
];

const randomizeColumns = () => {
  table.setColumnOrder(faker.helpers.shuffle(table.getAllLeafColumns().map((d) => d.id)));
};

function toggleColumnVisibility(column: Column<any, any>) {
  columnVisibility.value = {
    ...columnVisibility.value,
    [column.id]: !column.getIsVisible(),
  };
}

function toggleAllColumnsVisibility() {
  table.getAllLeafColumns().forEach((column) => {
    toggleColumnVisibility(column);
  });
}

// TODO add usage examples!
// TODO add context links
// TODO shall we use tags?
// TODO add "results at test" functionality
const columnVisibility = ref({
  isSelected: props.showColumnSelected,
  term: props.showColumnTerm,
  explanation: props.showColumnExplanation,
  usage_examples: props.showColumnUsageExamples,
  contextLinks: false,
  translations: false,
  remarks: props.showColumnRemarks,
  tags: false,
  resultsAtTests: false,
  isFavourite: props.showColumnIsFavourite,
  deleteItem: props.allowEditing,
});
const columnOrder = ref<ColumnOrderState>([
  'isSelected',
  'term',
  'usage_examples',
  'explanation',
  'contextLinks',
  'translations',
  'remarks',
  'tags',
  'resultsAtTests',
  'isFavourite',
  'deleteItem',
]);
const columnSizing = ref({
  isSelected: 30,
  term: 250,
  usage_examples: 400,
  explanation: 400,
  contextLinks: 200,
  translations: 200,
  remarks: 200,
  tags: 200,
  resultsAtTests: 200,
  isFavourite: 30,
  deleteItem: 30,
});

const table = useVueTable({
  get data() {
    return data.value;
  },
  columns,
  state: reactive({
    get columnVisibility() {
      return columnVisibility.value;
    },
    get columnOrder() {
      return columnOrder.value;
    },
    get columnSizing() {
      return columnSizing.value;
    },
  }),
  onColumnOrderChange: (order) => {
    columnOrder.value = order instanceof Function ? order(columnOrder.value) : order;
  },
  getCoreRowModel: getCoreRowModel(),
  enableColumnResizing: true,
  columnResizeMode: 'onChange',
  debugTable: false,
  debugHeaders: false,
  debugColumns: false,
});

const scrollToBottom = () => {
  if (scrollContainerVocabList.value) {
    scrollContainerVocabList.value.scrollTop = scrollContainerVocabList.value.scrollHeight;
  }
};

const setAllEditsOff = () => {
  data.value.forEach((row, rowIndex) => {
    Object.keys(row).forEach((columnId) => {
      editModeState.set(`${rowIndex}-${columnId}`, false);
    });
  });
};

defineExpose({
  currentYScroll,
  scrollToBottom,
  getFullScrollHeight: () => outerContainer.value?.scrollHeight,
});
</script>

<template>
  <div ref="outerContainer" class="flex flex-col overflow-hidden relative" :style="computedStyleMain">
    <VocabListSearchbar :show="showSearchbar" />

    <table
      ref="scrollContainerVocabList"
      :style="computedStyleTable"
      :class="{
        'overflow-y-auto overflow-x-auto': !disableScroll,
        'overflow-y-hidden overflow-x-hidden': disableScroll,
      }"
      class="inline-block align-middle [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar]:h-2"
    >
      <thead class="sticky bg-white top-0 z-10 border-b border-gray-200 dark:border-neutral-700 overflow-visible">
        <VocabListTableHead :table="table" :columnSizing="columnSizing" />
      </thead>

      <tbody class="divide-y divide-gray-200 dark:divide-neutral-700 overflow-visible">
        <VocabListEntry
          @isDragging="
            (isDragging, vocabItemId) => {
              emit('isDragging', isDragging, vocabItemId);
            }
          "
          @currentDraggingPosition="(position) => emit('currentDraggingPosition', position)"
          @droppedAtPosition="(position) => emit('droppedAtPosition', position)"
          @scrollBy="(distance) => emit('scrollBy', distance)"
          v-for="row in table.getRowModel().rows"
          :key="row.id"
          :row="row"
        />
      </tbody>
    </table>
    <div v-if="!!playAudioAtUrl">
      <AudioPlayerTooltip
        :audio-url="playAudioAtUrl"
        :position="audioPlayerPosition"
        @close="playAudioAtUrl = null"
        :autoplay="true"
      />
    </div>
  </div>
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}

@keyframes grow-pop {
  0% {
    font-size: 24px; /* Initial text size */
    opacity: 1;
  }
  50% {
    font-size: 30px; /* Pop out to larger size */
    opacity: 1;
  }
  100% {
    font-size: 0px; /* Shrink to disappear */
    opacity: 0;
  }
}

.animate-grow-pop {
  animation: grow-pop 300ms ease-out;
}
</style>
