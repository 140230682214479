<script lang="ts"></script>

<script setup lang="ts">
import Tooltip from '@/components/Tooltip.vue';
import SpecialtyInput from '@/components/SpecialtyInput.vue';
import { ErrorMessage, Field, Form, useForm } from 'vee-validate';
import TagInput from '@/components/TagInput.vue';
import { ref, watch } from 'vue';
import { OtherCaseDetails } from '@/components/new_case/typing';

const externallySelectedSpecialties = ref([]);
const mountSpecialtyInput = ref(true);
const externallySelectedTags = ref([]);
const mountTagInput = ref(true);

const otherCaseDetails = defineModel({
  type: Object as () => OtherCaseDetails,
  required: true,
});

const { errors, validate } = useForm<OtherCaseDetails>();
const emit = defineEmits(['isValid']);

async function sendExternalSpecialties(specialties) {
  console.log('Sending external specialties: ' + JSON.stringify(specialties));
  externallySelectedSpecialties.value = specialties;
  mountSpecialtyInput.value = false;
  setTimeout(() => {
    mountSpecialtyInput.value = true;
  }, 10);
}

async function sendExternalTags(tags) {
  console.log('Sending external tags: ' + JSON.stringify(tags));
  externallySelectedTags.value = tags;
  mountTagInput.value = false;
  setTimeout(() => {
    mountTagInput.value = true;
  }, 10);
}

watch(
  () => otherCaseDetails.value.heading,
  async (value) => {
    let result = await validate();
    let valid = result.valid;
    // console.log('valid', valid);
    emit('isValid', valid);
  },
);
</script>

<template>
  <div class="py-8 grid grid-cols-11 gap-y-1">
    <h2 class="col-span-11 inline-block text-xl mt-2.5 font-bold text-gray-800 dark:text-gray-200">
      Allgemeine Angaben
    </h2>
    <div class="col-span-11">
      <div class="block text-sm mb-2 text-gray-600 dark:text-gray-400"></div>
    </div>

    <div class="col-span-11 grid grid-cols-11 grid-rows-3 gap-4 items-center">
      <!-- Title -->
      <div class="col-span-3 row-start-1 items-center">
        <label for="headline" class="inline-block text-sm py-2 text-gray-600 dark:text-gray-400"> Titel </label>
      </div>
      <div class="col-span-8 row-start-1 items-center">
        <Field v-slot="{ field, errors, value }" name="heading" rules="max:80">
          <textarea
            v-bind="field"
            v-model="otherCaseDetails.heading"
            class="py-2 px-3 block w-full resize-none border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
            rows="1"
            placeholder="Kurztitel, unter dem andere deinen Fall finden"
            :class="{
              'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
            }"
          />
          <ErrorMessage class="text-xs text-red-600" name="heading" />
        </Field>
      </div>
      <!-- End row -->

      <!-- Scene description -->
      <div class="col-span-3 row-start-2 items-center">
        <label for="description" class="inline-block text-sm py-2 text-gray-600 dark:text-gray-400">
          Beschreibung
        </label>
      </div>
      <div class="col-span-8 row-start-2 items-center">
        <Field v-slot="{ field, errors, value }" name="description" rules="max:1000">
          <textarea
            v-bind="field"
            v-model="otherCaseDetails.description"
            class="py-2 px-3 block w-full resize-none border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
            rows="1"
            placeholder="Beschreibung der Szene und ihrer Ausgangssituation"
            :class="{
              'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
            }"
          />
          <ErrorMessage class="text-xs text-red-600" name="description" />
        </Field>
      </div>
      <!-- End row -->
    </div>
  </div>
</template>

<style scoped></style>
