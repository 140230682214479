<!--TODO
TODO v1: Gather examination reports under the requested label by order of the requests. Heading requested method + date, body report.
TODO v2: Allow examinations at different dates, e.g. if therapy (also: "wait for xxxx") is established. Same as v1 but ordered by date.
TODO v3: How/ to which extent can we get/ generate actual images, ECGs etc?

TODO: Tooltip, dass Bezeichnung der Untersuchung angegeben werden soll
-->

<script setup>
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { useAuthStore, usePatientInteractionStore } from '@/stores';

import SystemMessageFooter from '@/components/case_interaction/SystemMessageFooter.vue';

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const { user } = storeToRefs(authStore);
const { patientName, patientDob, examinationIsStreaming } = storeToRefs(patientInteractionStore);

const isLoading = ref(true);

// import shared functions
import { useChatBubbleFunctions } from '@/components/chat_bubbles/chatBubbleFunctions';
import { getSentenceForCharacter } from '@/helper';
import DOMPurify from 'dompurify';
import { HSAccordion, HSStaticMethods } from 'preline';

const { calculateCols, calculateRows, getSelectedCharacterPositions } = useChatBubbleFunctions();

const selectedSentenceIndcs = ref([]);
const selectableDiv = ref(null);
const exmTitle = ref('');

const { exmReport, id } = defineProps(['exmReport', 'id']);

const accordion = ref(null);
const showFull = ref(false);

const bgColor = ref('bg-white');

onMounted(async () => {
  await nextTick();
  // HSStaticMethods.autoInit();
  await nextTick();

  // wait 220 ms
  await new Promise((resolve) => setTimeout(resolve, 220));

  accordion.value = new HSAccordion(document.getElementById(`${id}-hs-basic-with-arrow-heading-one`));
  // accordion.value = new HSAccordion(document.getElementById(`${id}-hs-basic-with-arrow-collapse-one`));

  // accordion.value = new HSAccordion(document.querySelector('#accordion'));
  // const { element } = HSAccordion.getInstance('#accordion', true);
  // console.log('element: ', element);

  // does not work:
  // accordion.value.on('show', () => {
  //   console.log('show');
  //   showFull.value = true;
  // });
  // accordion.value.on('hide', () => {
  //   console.log('hide');
  //   showFull.value = false;
  // });

  if (exmReport.created_at === 'incomplete') {
    showFull.value = true;
    await accordion.value.show();
    return;
  }

  showFull.value = true;
  await accordion.value.show();

  document.addEventListener('click', handleClickOutside);
  exmTitle.value = 'Befundbericht zu ' + exmReport.name + ' von ' + patientName.value + ' (*' + patientDob.value + '):';
});

const formattedReport = computed(() => {
  // TODO: replace this by <p> and <ul> tags and test/ adapt getSelectedCharacterPositions accordingly

  // let organPattern = /[\.|:]\s+(\w+):/g;
  // let organPattern = /\s([a-zA-ZäöüÄÖÜß]+):/g;
  let organPattern = /####\s+([\sa-zA-ZäöüÄÖÜß]+):/g; // capture '#### ORGAN:'
  let organPattern2 = /###\s+([\sa-zA-ZäöüÄÖÜß]+):/g; // capture '### ORGAN:'
  let organPattern3 = /\.([\sa-zA-ZäöüÄÖÜß]+):/g; // capture '. ORGAN:'

  let bulletPointPattern = /\s+-\s+/g;

  // const newQuestionPattern = /\*\*(\d+)\*\*/g;
  // const newOptionPattern = /\*([A-E])\*\./g;

  let message = exmReport.report;

  let modifiedString = message.replace(organPattern, (match, word) => {
    return `.<br><u>${word}:</u>`;
  });

  modifiedString = modifiedString.replace(organPattern2, (match, word) => {
    return `.<br><u>${word}:</u>`;
  });

  modifiedString = modifiedString.replace(organPattern3, (match, word) => {
    return `.<br><u>${word}:</u>`;
  });

  modifiedString = modifiedString.replace(bulletPointPattern, (match) => {
    return `<br>- `;
  });

  if (modifiedString.startsWith('<br>')) {
    modifiedString = modifiedString.substring(4);
  }

  return DOMPurify.sanitize(modifiedString);
});

function handleClickOutside(event) {
  // Check if the clicked element is outside the selectableDiv
  console.debug('Clicked outside of selectable div? ' + selectableDiv.value);

  if (!selectableDiv.value) {
    console.warn('selectableDiv not set');
    return;
  }

  if (!selectableDiv.value.contains(event.target)) {
    // console.debug('Clicked outside selectable div');
    selectedSentenceIndcs.value = [];
  }
}

function onSelectedText(report) {
  // console.log('ref', selectableDiv.value);
  // console.log('type of ref', typeof selectableDiv.value);
  let characterPositions = getSelectedCharacterPositions(selectableDiv.value);
  selectedSentenceIndcs.value = [];
  let offset = exmTitle.value.length;
  characterPositions = characterPositions.map((pos) => pos - offset);
  console.log('characterPositions', characterPositions);
  for (let i = 0; i < characterPositions.length; i++) {
    let sentenceIdx = getSentenceForCharacter(report, characterPositions[i]);
    if (!selectedSentenceIndcs.value.includes(sentenceIdx)) {
      selectedSentenceIndcs.value.push(sentenceIdx);
    }
  }
}

// Cleanup the event listener when the component is unmounted
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

async function collapseAccordion() {
  if (!showFull.value) {
    return;
  }
  showFull.value = false;
  await accordion.value.hide();
}

async function handleToggled() {
  console.log('showFull before toggle:' + showFull.value);
  showFull.value = !showFull.value;
  if (showFull.value) {
    await accordion.value.show();
  } else {
    await accordion.value.hide();
  }
}

watch(showFull, (newValue) => {
  if (newValue) {
    bgColor.value = 'bg-white';
    return;
  }
  bgColor.value = 'bg-white';
});
</script>

<template>
  <!--  streaming: {{ examinationIsStreaming }}-->
  <!--  showFull: {{ showFull }}-->
  <!--  exmReport.id: {{ exmReport.id }}-->
  <!--  exmReport.created_at: {{ exmReport.created_at }}-->

  <div
    class="mb-1.5 text-sm border border-gray-200 rounded-lg p-4 dark:bg-neutral-900 dark:border-gray-700"
    :class="bgColor"
  >
    <div class="hs-accordion-group">
      <div class="hs-accordion active" :id="`${id}-hs-basic-with-arrow-heading-one`">
        <button
          class="hs-accordion-toggle py-3 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-600 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
          @click="handleToggled"
        >
          <span translate="no" class="material-symbols-outlined notranslate text-xl block hs-accordion-active:hidden">
            expand_more
          </span>
          <span translate="no" class="material-symbols-outlined notranslate text-xl hidden hs-accordion-active:block">
            expand_less
          </span>
          <div>{{ exmTitle }}</div>
        </button>
        <div
          :id="`${id}-hs-basic-with-arrow-collapse-one`"
          class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
        >
          <div class="text-gray-800 dark:text-neutral-200 overflow-x-auto">
            <div v-html="formattedReport" ref="selectableDiv" @mouseup="onSelectedText(exmReport.report)" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Button Group -->
  <SystemMessageFooter
    :message="exmReport"
    messageType="EXAMINATION"
    conversation="PATIENT"
    :hideFooter="examinationIsStreaming || !showFull"
    :markedSentenceIndcs="selectedSentenceIndcs"
    :showCollapse="true"
    @onCollapse="collapseAccordion"
  />
  <!-- End Button Group -->
</template>

<style scoped></style>
