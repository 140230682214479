<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  reset: {
    default: false,
    type: Boolean,
  },
  isLoading: {
    default: false,
    type: Boolean,
  },
  placeholderMessage: {
    type: String,
  },
  label: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    required: true,
  },
  showIcon: {
    type: String,
    default: 'chevronRight',
  },
  beforeIcon: {
    type: String,
    default: '',
  },
  autoShowTarget: {
    type: Boolean,
    default: false,
  },
  autoCloseTarget: {
    type: Boolean,
    default: false,
  },
  style: {
    type: String,
    default: 'InteractionInput',
    validator: (value) => ['InteractionInput', 'MainView'].includes(value),
  },
});

const emit = defineEmits(['showTarget']);
const targetIsOpen = ref(false);

const toggleTarget = async () => {
  if (props.disabled) {
    return;
  }
  targetIsOpen.value = !targetIsOpen.value;
  emit('showTarget', targetIsOpen.value);
};

const forceShowTarget = async () => {
  targetIsOpen.value = true;
  emit('showTarget', targetIsOpen.value);
};

const forceCloseTarget = async () => {
  targetIsOpen.value = false;
  emit('showTarget', targetIsOpen.value);
};

// Watch for auto show/close changes
watch(
  () => props.autoShowTarget,
  (value) => {
    forceShowTarget();
  },
  { immediate: true },
);

watch(
  () => props.autoCloseTarget,
  (value) => {
    forceCloseTarget();
  },
  { immediate: true },
);

defineExpose({
  toggleTarget,
  forceShowTarget,
  forceCloseTarget,
});
</script>

<template>
  <div class="group">
    <!-- Popover -->
    <div :id="`${id}-tooltip`" class="hs-tooltip inline-block [--trigger:none]">
      <button
        v-if="props.style === 'InteractionInput'"
        type="button"
        @click.prevent="toggleTarget"
        class="hs-tooltip-toggle flex-shrink-0 justify-center items-center h-12 w-12 md:h-16 md:w-16 lg:h-16 lg:w-20 inline-flex justify-center z-50 items-center mt-1 pt-1 -ms-px rounded-s-lg md:rounded-none md:grpup-first:rounded-s-lg grpup-first:ms-0 group-last:rounded-e-lg text-sm focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
      >
        <div class="items-center hover:scale-100">
          <div class="inline-flex items-center">
            <div
              v-if="!!beforeIcon"
              translate="no"
              class="material-symbols-outlined notranslate ml-1 -mr-3 text-3xl md:-mt-2 transition-all transform duration-300 ease-in-out"
            >
              {{ beforeIcon }}
            </div>
            <div
              translate="no"
              class="material-symbols-outlined notranslate text-4xl md:-mt-2 transition-all transform duration-300 ease-in-out"
              :class="{
                '-rotate-180': targetIsOpen && showIcon === 'chevron_left',
                'rotate-180': targetIsOpen && showIcon !== 'chevron_left',
                'rotate-0': !targetIsOpen,
              }"
            >
              {{ showIcon }}
            </div>
          </div>
          <div class="items-center -mt-2 hidden md:block text-sm">{{ label }}</div>
        </div>
      </button>
      <button
        v-else
        type="button"
        @click.prevent="toggleTarget"
        class="inline-flex px-2 gap-x-1 text-white justify-center bg-gray-500/70 hover:bg-gray-500/100 items-center border border-gray-200 rounded-full"
      >
        <div
          v-if="!!beforeIcon"
          translate="no"
          class="material-symbols-outlined notranslate text-2xl md:text-3xl md:-mt-2 transition-all transform duration-300 ease-in-out"
        >
          {{ beforeIcon }}
        </div>
        <div class="items-center text-xs whitespace-nowrap">
          {{ label }}
        </div>
        <div
          translate="no"
          class="material-symbols-outlined notranslate -ml-1 text-2xl md:text-3xl md:-mt-2 transition-all transform duration-300 ease-in-out"
          :class="{
            '-rotate-180': targetIsOpen && showIcon === 'chevron_left',
            'rotate-180': targetIsOpen && showIcon !== 'chevron_left',
            'rotate-0': !targetIsOpen,
          }"
        >
          {{ showIcon }}
        </div>
      </button>
    </div>
    <!-- End Popover -->
  </div>
</template>

<style></style>
