<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['clicked']);
</script>

<template>
  <div>
    <button
      type="button"
      @click="emit('clicked')"
      :disabled="disabled"
      class="py-1.5 px-3 inline-flex items-center gap-x-2 text-[13px] rounded-full border border-blue-600 text-blue-600 hover:bg-blue-50 hover:text-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-blue-500 dark:text-blue-500 dark:hover:bg-blue-500/10 dark:hover:text-blue-400"
    >
      {{ text }}
    </button>
  </div>
</template>

<style scoped></style>
