<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import AudioRecorderSTT from '@/components/inputs/AudioRecorderSTT.vue';
import ButtonChevronShowHide from '@/components/inputs/ButtonChevronShowHide.vue';
import { useAlertStore, useAuthStore, useCaseInteractionStore, usePatientInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';
import ChatInput from '@/components/case_interaction/ChatInput.vue';
import InteractionInputToggleGroup from '@/components/case_interaction/InteractionInputToggleGroup.vue';

const patientInteractionStore = usePatientInteractionStore();
const { descIsStreaming } = storeToRefs(patientInteractionStore);

const caseInteractionStore = useCaseInteractionStore();
const { audioIsStreaming, somthingIsStreaming, someMessageToUndo, someMessageToRedo } =
  storeToRefs(caseInteractionStore);

const authStore = useAuthStore();
const { currentChatEnabled: chatEnabled } = storeToRefs(authStore);

const alertStore = useAlertStore();

const props = defineProps({
  placeholderMessage: {
    type: String,
  },
  disabled: {
    default: false,
  },
  allowAudioInput: {
    default: false,
  },
  isShowingCompanionChat: {
    default: false,
  },
  unlockAudioContext: {
    type: Function,
    default: null,
  },
});

const emit = defineEmits([
  'onSubmit',
  'onShowHistory',
  'onShowActionColumn',
  'onToggleSubtitles',
  'onToggleMockDesktop',
  'onShowCompanionChat',
]);
const inputText = ref('');
const lastSubmittedTextForDebug = ref('');
const lastTextSentAt = ref('');
const resetAudioInput = ref(false);
const showHistoryButton = ref(null);

const mockProgress = ref(0);

onMounted(() => {});

async function handleSubmit() {
  if (props.disabled) {
    return;
  }
  if (inputText.value.trim() === '') {
    return;
  }

  emit('onSubmit', inputText.value);
  lastSubmittedTextForDebug.value = inputText.value;
  lastTextSentAt.value = new Date().toISOString();
  inputText.value = '';
}

function showHistory(show: boolean) {
  emit('onShowHistory', show);
}

function showActionColumn(show: boolean) {
  emit('onShowActionColumn', show);
}

// const isLargeScreen = ref(true);
//
// window.onresize = async function () {
//   console.log(window.document.body.clientWidth)
//   const newVal = window.document.body.clientWidth >= 1024
//   console.log(isLargeScreen.value)
//   if (newVal !== isLargeScreen.value) {
//     isLargeScreen.value = newVal;
//     await new Promise((resolve) => setTimeout(resolve, 100));
//   }
//   isLargeScreen.value = newVal;
//
//   await new Promise((resolve) => setTimeout(resolve, 100));
// };
//
// onMounted(async () => {
// });

const isLargeScreen = computed(() => {
  // Check if the screen size is large (greater than or equal to lg breakpoint)
  console.log('width: ' + window.document.body.clientWidth);
  const screenWidth = window.document.body.clientWidth;
  return screenWidth >= 1024;
});

// const isLargeScreen = computed(() => {
//   // Check if the screen size is large (greater than or equal to lg breakpoint)
//   console.log('window.innerWidth: ' + window.innerWidth);
//   const screenWidth = window.innerWidth;
//   return screenWidth >= 1024;
// });

watch(
  () => inputText.value,
  (newVal, oldVal) => {
    if (oldVal !== '' && newVal === '') {
      // input reset to '', so either submitted or deleted by user => reset audio recorder to await new input
      resetAudioInput.value = true;
    }
    if (newVal !== '' && !chatEnabled.value) {
      // chat is disabled and non-trivial input received from AudioRecorder => SUBMIT and reset!
      console.log('Chat disabled, non-trivial input received from AudioRecorder => SUBMIT and reset!');
      console.log('Direct submission from audio is: ' + inputText.value);
      handleSubmit();
    }
  },
);

function resetAudioInputCompleted() {
  resetAudioInput.value = false;
}

function updateInputTextByTranscription(transcription: string) {
  inputText.value = transcription;
}

function openMockDesktop() {
  emit('onToggleMockDesktop');
}

async function undoInteraction() {
  if (!!showHistoryButton.value) {
    await showHistoryButton.value.forceShowTarget(); // show the history so the user sees what happens / what is undone
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
  caseInteractionStore
    .undoSay()
    .then((someMessageHasBeenUndone: boolean) => {
      if (someMessageHasBeenUndone) {
        alertStore.success('Interaction undone');
      } else {
        alertStore.info('No interaction to undo');
      }
    })
    .catch((error: Error) => {
      alertStore.error('Error undoing interaction', 'Error', error);
      throw error;
    });
}

async function redoInteraction() {
  console.log('redoInteraction');
  if (!!showHistoryButton.value) {
    await showHistoryButton.value.forceShowTarget(); // show the history so the user sees what happens / what is undone
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
  caseInteractionStore
    .redoSay()
    .then((someMessageHasBeenRedone: boolean) => {
      if (someMessageHasBeenRedone) {
        alertStore.success('Interaction redone');
      } else {
        alertStore.info('No interaction to redo');
      }
    })
    .catch((error: Error) => {
      alertStore.error('Error redoing interaction', 'Error', error);
      throw error;
    });
}

function aiCompanion() {
  console.log('aiCompanion - ', !props.isShowingCompanionChat);
  emit('onShowCompanionChat', !props.isShowingCompanionChat);
}

const windowLastTranscriptionTest = computed(() => {
  if (typeof window === 'undefined') return 'no window';
  return window.lastTranscriptionText || 'no text';
});
</script>

<template>
  <div class="flex min-w-full select-none w-full h-fit z-[90]">
    <div class="items-center select-none min-w-full w-full z-[90] grid grid-rows-1 grid-cols-10">
      <!-- Left toggle buttons -->
      <div
        v-if="chatEnabled"
        class="pl-1 pr-0 sm:pl-0 select-none sm:hidden overflow-visible row-start-1 col-start-1 w-fit inline-flex items-end gap-x-1"
      >
        <ButtonToggle
          id="UndoInteractionButtonWithChatEnabled"
          label="Undo"
          labelAbbreviation="Undo"
          icon="undo"
          :style="'InteractionInput'"
          :disabled="somthingIsStreaming || !someMessageToUndo"
          @onToggle="undoInteraction"
        />
        <div class="scale-75 origin-bottom-left">
          <ButtonToggle
            v-show="someMessageToRedo"
            id="RedoInteractionButtonWithChatEnabled"
            label="Redo"
            labelAbbreviation="Redo"
            icon="redo"
            :style="'InteractionInput'"
            :disabled="somthingIsStreaming"
            @onToggle="redoInteraction"
          />
        </div>
      </div>
      <div class="pl-1 -mt-[152px] select-none sm:hidden row-start-1 col-start-1 inline-flex col-span-2">
        <ButtonToggle
          id="InteractionInputMockDesktopButton"
          label="KIS-PC"
          labelAbbreviation="KIS"
          icon="desktop_windows"
          :style="'MainView'"
          @onToggle="openMockDesktop"
        />
      </div>

      <!-- Audio & chat input -->
      <div
        class="z-[80] row-start-1"
        :class="[
          chatEnabled
            ? ' ml-4 md:ml-0 col-start-3 col-span-5 sm:col-start-2 sm:col-span-8 grid grid-rows-1 grid-cols-10 '
            : 'col-start-5 col-span-2 xl:col-start-4 flex justify-center',
        ]"
      >
        <div v-if="!chatEnabled" class="pr-1 flex-col flex">
          <ButtonToggle
            id="UndoInteractionButtonWithChatDisabled"
            label="Undo"
            labelAbbreviation="Undo"
            icon="undo"
            :style="'InteractionInput'"
            :disabled="somthingIsStreaming || !someMessageToUndo"
            @onToggle="undoInteraction"
          />
          <div class="scale-75 -my-2 z-[80]">
            <ButtonToggle
              v-show="someMessageToRedo"
              id="RedoInteractionButtonWithChatDisabled"
              label="Redo"
              labelAbbreviation="Redo"
              icon="redo"
              :style="'InteractionInput'"
              :disabled="somthingIsStreaming"
              @onToggle="redoInteraction"
            />
          </div>
        </div>

        <!-- Audio input -->
        <div
          class="flex z-[90] pointer-events-none max-h-full h-full transition-all duration-300 select-none"
          :class="[
            chatEnabled
              ? 'row-start-1 col-start-1 pl-8 -mx-8 sm:col-start-2 md:col-start-1 justify-start'
              : 'items-center justify-center -mx-6 -mt-6',
          ]"
        >
          <AudioRecorderSTT
            :size="chatEnabled && !isLargeScreen ? 'small' : 'normal'"
            :disabled="props.disabled"
            @newTranscription="updateInputTextByTranscription"
            :reset="resetAudioInput"
            @resetComplete="resetAudioInputCompleted"
            testIdSuffix="main"
            :unlockAudioContext="props.unlockAudioContext"
          />
        </div>

        <!-- Text input -->
        <div
          v-if="chatEnabled"
          class="row-start-1 mt-1 z-[80] select-none col-start-2 col-span-9 sm:col-span-8 2xl:col-span-7 grid grid-cols-1 grid-rows-1 justify-start transition-all duration-300 overflow-hidden"
          :style="{
            width: chatEnabled ? '100%' : '0%',
            opacity: chatEnabled ? '1' : '0',
          }"
        >
          <ChatInput
            v-model="inputText"
            :placeholderMessage="props.placeholderMessage"
            :disabled="props.disabled"
            :unlockAudioContext="props.unlockAudioContext"
            @onSubmit="handleSubmit"
          />
        </div>

        <div v-if="!chatEnabled" class="pl-1">
          <ButtonToggle
            id="AiCompanionButtonWithChatDisabled"
            label="Tipp"
            labelAbbreviation="Tipp"
            :style="'InteractionInput'"
            :addSparkles="true"
            :disabled="somthingIsStreaming"
            :targetActive="props.isShowingCompanionChat"
            @onToggle="aiCompanion"
          />
        </div>
      </div>

      <!-- Right toggle buttons -->
      <div
        class="pr-1 z-[80] -mt-[152px] select-none row-start-1 col-start-9 col-span-2 sm:col-start-10 sm:col-span-3 flex items-center justify-end sm:px-2"
      >
        <ButtonChevronShowHide
          ref="showHistoryButton"
          id="showHistory"
          @showTarget="showHistory"
          label="Verlauf"
          beforeIcon="history"
          :style="'MainView'"
          showIcon="chevron_left"
        />
      </div>
      <div
        class="pr-1 z-[80] gap-x-2 select-none row-start-1 col-start-9 col-span-2 sm:col-start-10 sm:col-span-3 flex items-center justify-end sm:px-2"
      >
        <div v-if="chatEnabled">
          <ButtonToggle
            id="AiCompanionButtonWithChatEnabled"
            label="Tipp"
            labelAbbreviation="Tipp"
            :style="'InteractionInput'"
            :addSparkles="true"
            :disabled="somthingIsStreaming"
            :targetActive="props.isShowingCompanionChat"
            @onToggle="aiCompanion"
          />
        </div>
        <InteractionInputToggleGroup :showMinimalVersionWhenSmallScreen="true" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
