/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_speech_to_text_stt__post } from '../models/Body_speech_to_text_stt__post';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SttService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Speech To Text
   * @param formData
   * @param audioFormat
   * @param caseLanguage
   * @returns any Successful Response
   * @throws ApiError
   */
  public speechToText(
    formData: Body_speech_to_text_stt__post,
    audioFormat: string = 'webm',
    caseLanguage?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/stt/',
      query: {
        audio_format: audioFormat,
        case_language: caseLanguage,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
