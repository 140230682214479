<script setup lang="ts">
import TextFinishedButton from '@/components/didactics/text/TextFinishedButton.vue';
import { onMounted, ref, watch } from 'vue';
import { useAlertStore, useCourseStore } from '@/stores';
import { onBeforeRouteLeave } from 'vue-router';
import ProgressButton from '@/components/ProgressButton.vue';
import SetPhrase from './SetPhrase.vue';
const props = defineProps({
  contentItem: {
    type: Object,
    required: true,
  },
  boxReducedWidth: {
    type: Number,
    required: true,
  },
  germanContainerWidth: {
    type: Number,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
});

const courseStore = useCourseStore();
const alertStore = useAlertStore();

const unsavedChanges = ref(false);
const contentEditor = ref(null);
const isSavingChanges = ref(false);

const localContent = ref(props.contentItem.set_phrase_list);
const isSubmitting = ref(false);

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.isEditing) return;
      setTimeout(() => {
        saveSetPhraseList();
        unsavedChanges.value = false;
      }, 1500);
    }
  },
);

onMounted(async () => {
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      saveSetPhraseList();
      return undefined;
    }
  };
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveSetPhraseList();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const saveSetPhraseList = async () => {
  if (!props.isEditing) return;
  if (!props.contentItem) return;
  if (isSavingChanges.value) return;
  isSavingChanges.value = true;

  try {
    await courseStore.updateSetPhraseList(props.sectionId, props.contentItem.id, {
      title: localContent.value.title,
      description: localContent.value.description,
    });
    console.log('saved set phrase list');
    unsavedChanges.value = false;
  } catch (error) {
    console.error(error);
    alertStore.error('Failed to autosave set phrase list', 'Error', error);
    throw new Error('Failed to autosave set phrase list');
  } finally {
    isSavingChanges.value = false;
  }
};

const addSetPhrase = async () => {
  console.log('add set phrase');
  try {
    // First save all existing set phrases
    for (const setPhrase of localContent.value.set_phrases) {
      await courseStore.updateSetPhraseInContentItemWithSetPhraseList(
        props.sectionId,
        props.contentItem.id,
        setPhrase.id,
        {
          phrase: setPhrase.phrase,
          explanation: setPhrase.explanation,
        },
      );
    }

    // Then add the new set phrase
    await courseStore.addSetPhraseToContentItemWithSetPhraseList(props.sectionId, props.contentItem.id, {
      phrase: '',
      explanation: '',
    });
  } catch (error) {
    console.error(error);
    alertStore.error('Failed to add set phrase', 'Error', error);
    throw new Error('Failed to add set phrase');
  }
};

const deleteSetPhrase = async (setPhraseId: string) => {
  console.log('delete set phrase with id', setPhraseId);
  try {
    await courseStore.deleteSetPhraseFromContentItemWithSetPhraseList(
      props.sectionId,
      props.contentItem.id,
      setPhraseId,
    );
  } catch (error) {
    console.error(error);
    alertStore.error('Failed to delete set phrase', 'Error', error);
    throw new Error('Failed to delete set phrase');
  }
};
</script>

<template>
  <div class="relative w-full bg-lime-200 rounded-lg border border-lime-500 px-2 pb-1 text-sm flex-col flex">
    <span class="text-sm pt-1 pb-2 text-lime-800 inline-flex text-center items-center">
      <span class="text-center uppercase flex items-center"> 🛠️ Redemittel </span>
      <span class="px-2 text-xl mb-1 flex items-center" v-if="props.isEditing || localContent.title !== ''"> | </span>
      <textarea
        v-if="props.isEditing"
        v-model="localContent.title"
        class="px-2 py-0 flex-grow bg-lime-100 resize-none border-lime-500 rounded-lg text-lime-800 my-auto"
        placeholder="Titel (optional)"
        rows="1"
        @change="unsavedChanges = true"
      />
      <span v-else class="text-lime-800 dark:text-neutral-200 font-semibold flex items-center">
        {{ localContent.title }}
      </span>
    </span>

    <span v-if="!props.isEditing && localContent.description !== ''" class="text-xs md:text-sm pt-2 pb-4">
      {{ localContent.description }}
    </span>
    <textarea
      v-if="props.isEditing"
      v-model="localContent.description"
      class="w-full bg-lime-100 text-xs md:text-sm px-2 py-0 mb-2 resize-none text-gray-800 border-lime-500 rounded-lg"
      placeholder="Erläuterungen (zur Gesamtliste; optional)"
      rows="1"
      @change="unsavedChanges = true"
    />

    <ul class="">
      <li v-for="setPhrase in localContent.set_phrases" :key="setPhrase.id" class="pb-4">
        <div class="flex-col flex w-full -ml-2">
          <SetPhrase
            :key="setPhrase.id"
            :setPhrase="setPhrase"
            :isEditing="props.isEditing"
            :sectionId="props.sectionId"
            :contentItemId="props.contentItem.id"
            @deleteSetPhrase="deleteSetPhrase"
          />
        </div>
      </li>
    </ul>

    <div class="pt-1 mx-auto justify-center flex">
      <ProgressButton
        v-if="props.isEditing"
        :disabled="isSubmitting"
        @click="
          () => {
            unsavedChanges = true;
            addSetPhrase();
          }
        "
        icon="add"
        iconSize="text-2xl"
        :text="'Redemittel hinzufügen'"
      />
    </div>

    <div class="absolute bottom-0.5 end-3">
      <TextFinishedButton
        v-if="!props.isEditing"
        :contentItemId="contentItem.id"
        :sectionIndex="<number>props.sectionIndex"
        :pageIndex="<number>props.pageIndex"
      />
    </div>
  </div>
</template>

<style scoped></style>
