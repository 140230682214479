/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ResourceType {
  READING = 'READING',
  LISTENING = 'LISTENING',
  NO_RESOURCE = 'NO_RESOURCE',
}
