<script setup>
import { ref, onMounted, computed, onBeforeMount, nextTick, watch, onBeforeUnmount, defineProps } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { router } from '@/router';
import { onBeforeRouteLeave } from 'vue-router';
import { useAlertStore, useAuthStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import BreadcrumbElement from '@/components/breadcrumbs/BreadcrumbElement.vue';
import { useCourseInteractionStore } from '@/stores/courseInteraction.store';
import TextEditor from '@/views/courses/TextEditor.vue';
import { HSSelect, HSStaticMethods } from 'preline';
import { useI18n } from 'vue-i18n';
import { debounce } from 'lodash';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import { toRoman } from '../../helper';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import PublishStatusCard from '@/views/courses/PublishStatusCard.vue';
import CourseDetailsMainLargeScreen from '@/views/courses/CourseDetailsMainLargeScreen.vue';
import { is } from '@vee-validate/rules';
import CourseDetailsMainSmallScreen from '@/views/courses/CourseDetailsMainSmallScreen.vue';

const { t } = useI18n();

const courseStore = useCourseStore();
const {
  currentCourse,
  currentCourseTitle,
  currentCourseDescription,
  currentCourseChapters,
  numberOfChapters,
  numberOfCases,
  lastRemountEnforced,
} = storeToRefs(courseStore);
const courseInteractionStore = useCourseInteractionStore();
const {
  isEnrolled,
  numChaptersCompleted,
  currentCourseAccomplishments,
  numSectionsPerChapter,
  numSectionsStartedPerChapter,
  numSectionsCompletedPerChapter,
  fetchingProgress,
} = storeToRefs(courseInteractionStore);
const alertStore = useAlertStore();
const authStore = useAuthStore();
const { userId, isAdmin: userIsAdmin } = storeToRefs(authStore);
const isLoading = ref(false);
const isEditing = ref(false);
const initialFetchCompleted = ref(false);
const renderEditor = ref(true); // toggle to re-rerender
const savingChangedOrder = ref(false);
const isDraggingEnabled = ref(false);

const courseScrollContainer = ref();
const callbackQueue = ref([]);
const courseTitleEditor = ref(null);
const courseDescriptionEditor = ref(null);
const confirmDeleteChapterModal = ref(null);
const aboutToDeleteChapterWithIndex = ref(null);

const newCourseTitle = ref('');
const newCourseDescription = ref('');

const sortedChapters = ref([]);

const unsavedChangesCounter = ref(0);

const innerHeader = ref(null);
const innerHeaderHeight = ref(0);

const mainSmallScreen = ref(null);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const organizations = ref(['SKK', 'Test KH', 'Text KH 2']);
const liveAt = ref([true, true, false]);

const isLive = computed(() => {
  return liveAt.value.some((live) => live);
});

const adjustHeight = () => {
  if (innerHeader.value) {
    innerHeaderHeight.value = innerHeader.value.offsetHeight;
  }
};

const percentageFetched = computed(() => {
  return Math.round(fetchingProgress.value * 100);
});

const debouncedAdjustHeight = debounce(adjustHeight, 200);

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
});

onBeforeMount(async () => {
  await router.isReady();

  window.addEventListener('resize', debouncedAdjustHeight);

  let courseId = router.currentRoute.value.params.courseId;
  console.log('Premount for: #' + courseId + '#');
  if (courseId === null || courseId === 'null') {
    console.error('courseId cannot be null');

    // // Crete a new course
    // console.log('Create new course');
    // await courseStore.appendEmptyCourse();
    // await router.push('/course/' + courseStore.currentCourse.id);
    // alertStore.success('Neuer Kurs erstellt.');
    // courseId = courseStore.currentCourse.id;
  }
  console.log('Continuing premount for: #' + courseId + '#');
  await courseStore.setCurrentCourse(courseId);
  try {
    const savedState = localStorage.getItem(`course-editing-${courseId}`);
    if (savedState && isOwnerOrEditorOrAdmin.value) {
      try {
        isEditing.value = JSON.parse(savedState);
      } catch (e) {
        console.warn('Invalid saved state in localStorage, resetting...', e);
        localStorage.removeItem(`course-editing-${courseId}`);
        isEditing.value = false;
      }
    }
  } catch (e) {
    console.error('Error accessing localStorage:', e);
    isEditing.value = false;
  }
  console.log('Title: ', currentCourseTitle.value);
  newCourseTitle.value = currentCourseTitle.value;
  newCourseDescription.value = currentCourseDescription.value;
  console.log('#chapters: ' + numberOfChapters.value);
  if (!numberOfChapters.value && isOwnerOrEditorOrAdmin.value) {
    console.log('Empty course - setting edit on');
    isEditing.value = true;
  }

  console.log('Checking if enrolled = there exists an interaction for this course');
  await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();

  initialFetchCompleted.value = true;
});

onMounted(async () => {
  console.log('CourseDetails mounted');

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  if (currentCourseChapters.value.length > 0) {
    sortedChapters.value = getOrderedChapters();
  }

  window.onbeforeunload = (e) => {
    if (unsavedChangesCounter.value > 0) {
      saveChanges();
      return undefined;
    }
  };

  // watcher: if changes to chapter length completed, trigger scroll
  watch(
    () => currentCourseChapters.value.length,
    async (newLength, oldLength) => {
      sortedChapters.value = getOrderedChapters();
      console.debug('#chapters changed from ' + oldLength + ' to ' + newLength);

      await nextTick();
      for (const func of callbackQueue.value) {
        console.log('executing callback');
        await func();
      }
      callbackQueue.value = [];
    },
  );
  // Watcher to update sortedChapters whenever chapters change
  watch(
    () => currentCourseChapters.value,
    async (newChapters) => {
      sortedChapters.value = getOrderedChapters();

      // Use nextTick to wait for DOM updates before enabling dragging
      await nextTick();

      // Enable dragging after ensuring data and DOM are in sync
      isDraggingEnabled.value = true;
    },
    { immediate: true },
  );
  watch(
    () => isEditing.value,
    async (newVal, oldVal) => {
      await rerenderEditor();
      localStorage.setItem(`course-editing-${courseStore.currentCourse.id}`, JSON.stringify(isEditing.value));
    },
  );
  setTimeout(() => {
    console.log('HSS init');
    // HSStaticMethods.autoInit();
  }, 100);
  isDraggingEnabled.value = true;
  adjustHeight();
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChangesCounter.value > 0) {
    try {
      await saveChanges();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const getOrderedChapters = () => {
  // order currentCourseChapters.value by index
  const chapters = currentCourseChapters.value;
  if (!chapters) {
    return [];
  }
  chapters.sort((a, b) => a.index - b.index);
  return chapters;
};

const enrollButtonText = computed(() => {
  return isEnrolled.value ? t('message.continueCourse') : t('message.enrollCourse');
});

async function onStartCourseClick() {
  console.debug('Starting course ' + currentCourse.value.id);

  if (isEnrolled.value) {
    let lastViewed = await courseInteractionStore.getLastViewedForCurrentCourse();
    if (!lastViewed) {
      console.warn('No last viewed found');
      alertStore.info('Sorry, we could not find your last viewed section.', 'Something went wrong...');
      return;
    }
    console.debug('Resuming : ' + lastViewed.sectionId + '@ page=' + lastViewed.pageIndex);
    await router.push({
      name: 'course-section',
      params: {
        sectionId: lastViewed.sectionId,
      },
      query: {
        page: lastViewed.pageIndex.toString(),
      },
    });
    return;
  }

  console.debug('Enrolling');
  isLoading.value = true;
  await courseInteractionStore.enrollForCurrentCourse(t);
  isLoading.value = false;
}

async function onStartChapter(id) {
  if (savingChangedOrder.value) {
    console.log('saving order change, ignore onStartChapter');
    return;
  }
  console.debug('Starting chapter ' + id);

  if (isEditing.value) {
    console.debug('Editing mode active, not starting chapter.');
    await onEditChapter(id);
    return;
  }

  if (!isEnrolled.value) {
    console.debug('User is not enrolled in course yet.');
    alertStore.error('status.error.enrollFirstInCourse');
    // wait for 2 seconds
    await new Promise((r) => setTimeout(r, 2000));
    return;
  }

  isLoading.value = true;
  await courseStore.setCurrentChapter(id);
  await courseInteractionStore.startCurrentChapter();

  console.log('Navigating to chapter ' + courseStore.currentChapterId);
  // wait 100 ms
  await new Promise((r) => setTimeout(r, 100));
  isLoading.value = false;

  await router.push('/chapter/' + courseStore.currentChapterId);
  await router.go(0);
}

async function scrollToBottom() {
  console.log('scrolls: ' + document.body.scrollHeight);
  await nextTick();
  setTimeout(() => {
    courseScrollContainer.value.scrollTo({
      top: courseScrollContainer.value.scrollHeight,
      behavior: 'smooth',
    });
  }, 200);
}

async function onEditChapter(id) {
  try {
    console.debug('Editing chapter ' + id);
    isLoading.value = true;
    await courseStore.setCurrentChapter(id);
    await router.isReady();
    await router.push('/chapter/' + courseStore.currentChapterId);
    console.log('Navigating to edit chapter ' + courseStore.currentChapterId);
    await router.go(0); // TODO it is nasty that we need this
    // despite a key on <component> inside router-view. We cannot directly put a
    // key=route.fullPath on the router-view, because it breaks treansition animations
    // (lifecycle hooks of route-view)
    isLoading.value = false;
  } catch (error) {
    console.error('Error navigating to chapter:', error);
  } finally {
    isLoading.value = false;
  }
}

const onMoveChapter = async (event) => {
  if (!isDraggingEnabled.value) return;
  savingChangedOrder.value = true;
  // The event contains useful information such as the new index of the dragged item
  const { oldIndex, newIndex } = event;
  console.log(`Item moved from ${oldIndex} to ${newIndex}`);

  await courseStore
    .moveChapter(oldIndex, newIndex)
    .then(async () => {
      sortedChapters.value = getOrderedChapters();
      // TODO: do changes by hand to avoid re-fetching all chapters
      await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();
    })
    .finally(() => {
      savingChangedOrder.value = false;
    });
};

async function onDeleteChapter(event, idx) {
  await event.stopPropagation();

  aboutToDeleteChapterWithIndex.value = idx;
  console.debug('Deleting chapter ' + idx);

  await confirmDeleteChapterModal.value.promptUserConformation();
}

async function deleteChapter() {
  savingChangedOrder.value = true;

  await courseStore.deleteChapter(aboutToDeleteChapterWithIndex.value);
  sortedChapters.value = getOrderedChapters();

  // TODO: do changes by hand to avoid re-fetching all chapters
  await courseInteractionStore
    .setCourseInteractionForCurrentCourseIfEnrolled()
    .then(() => {
      alertStore.success('status.success.chapterDeleted');
    })
    .catch((error) => {
      console.error('Failed to delete chapter:', error);
      alertStore.error('status.error.chapterDeleteFailed', 'Error', error);
    })
    .finally(() => {
      savingChangedOrder.value = false;
      aboutToDeleteChapterWithIndex.value = null;
    });
}

async function onNewChapter() {
  console.debug('Creating new chapter');
  isLoading.value = true;

  callbackQueue.value.push(scrollToBottom);
  await courseStore.appendEmptyChapter();
  isLoading.value = false;
}

async function onCopyLink() {
  console.debug('Copying course link to clipboard');
  // TODO: this seems to be http currently => fix, should be https
  const currentUrl = window.location.href;

  navigator.clipboard
    .writeText(currentUrl)
    .then(() => {
      console.log('URL copied to clipboard:', currentUrl);
      alertStore.success('status.success.copiedLinkToClipboard');
    })
    .catch((error) => {
      console.error('Failed to copy URL to clipboard:', error);
      alertStore.error('status.error.copiedLinkToClipboardError');
    });
}

async function onExportPdf() {
  console.debug('Exporting course progress to PDF');
  alertStore.error('status.error.notSupported');
}

async function onExportSignedPdf() {
  console.debug('Exporting course progress to signed PDF');
  alertStore.error('status.error.notSupported');
}

async function onPreviousCourse() {
  console.debug('Navigating to previous course');
  alertStore.error('status.error.notSupported');
}

async function onNextCourse() {
  console.debug('Navigating to next course');
  alertStore.error('status.error.notSupported');
}

const communityDiscussionUrl = computed(() => {
  if (
    !!currentCourse.value &&
    !!currentCourse.value.community_urls &&
    !!currentCourse.value.community_urls.discussion_url
  ) {
    return currentCourse.value.community_urls.discussion_url;
  }
  return 'https://community.casuu.care';
});

const communityFeedbackUrl = computed(() => {
  if (
    !!currentCourse.value &&
    !!currentCourse.value.community_urls &&
    !!currentCourse.value.community_urls.feedback_url
  ) {
    return currentCourse.value.community_urls.feedback_url;
  }
  return 'https://community.casuu.care';
});

const chapterCompletedProgress = (idx) => {
  if (!isEnrolled.value) {
    return 0;
  }
  if (!numSectionsCompletedPerChapter.value || !numSectionsPerChapter.value) {
    return 0;
  }
  if (numSectionsPerChapter.value[idx] === 0 || !numSectionsPerChapter.value[idx]) {
    return 0;
  }
  return (numSectionsCompletedPerChapter.value[idx] / numSectionsPerChapter.value[idx]) * 100;
};

const chapterIsCompleted = computed(() => {
  let isCompleted = [];
  for (let i = 0; i < numberOfChapters.value; i++) {
    if (!numSectionsCompletedPerChapter.value[i] || !numSectionsPerChapter.value[i]) {
      isCompleted.push(false);
      continue;
    }
    isCompleted.push(numSectionsCompletedPerChapter.value[i] === numSectionsPerChapter.value[i]);
  }
  return isCompleted;
});

const chapterStartedProgress = (idx) => {
  if (!isEnrolled.value) {
    return 0;
  }
  if (!numSectionsStartedPerChapter.value || !numSectionsPerChapter.value) {
    return 0;
  }
  if (numSectionsPerChapter.value[idx] === 0 || !numSectionsPerChapter.value[idx]) {
    return 0;
  }
  return (numSectionsStartedPerChapter.value[idx] / numSectionsPerChapter.value[idx]) * 100;
};

async function saveChanges() {
  console.debug('Saving changes');
  isLoading.value = true;
  console.log('Editor: ', courseTitleEditor);
  console.log('Editor.value: ', courseTitleEditor.value);
  await courseStore.updateCourseTitleAndDescription(
    currentCourse.value.id,
    courseTitleEditor.value.getRawTextContent(),
    courseDescriptionEditor.value.getRawTextContent(),
  );
  unsavedChangesCounter.value = 0;
  newCourseTitle.value = currentCourseTitle.value;
  newCourseDescription.value = currentCourseDescription.value;
  await rerenderEditor();
  alertStore.success('status.success.changesSaved');
  isLoading.value = false;
}

async function discardChanges() {
  console.debug('Discarding changes');
  isLoading.value = true;
  newCourseTitle.value = currentCourseTitle.value;
  newCourseDescription.value = currentCourseDescription.value;
  await rerenderEditor();
  unsavedChangesCounter.value = 0;
  alertStore.success('status.success.changesDiscarded');
  isLoading.value = false;
}

async function rerenderEditor() {
  renderEditor.value = false;
  await nextTick();
  renderEditor.value = true;
}

const isOwnerOrEditorOrAdmin = computed(() => {
  return (
    currentCourse.value &&
    (courseStore.isOwnerOfCurrentCourse(userId.value) ||
      courseStore.isEditorOfCurrentCourse(userId.value) ||
      userIsAdmin.value)
  );
});

const titlePerson = (case_) => {
  if (!case_) {
    return null;
  }
  let titlePerson = null;
  if (!!case_.patient) {
    titlePerson = case_.patient;
  } else if (!!case_.third_persons && case_.third_persons.length > 0) {
    titlePerson = case_.third_persons[0];
  } else {
    return null;
  }
  return titlePerson;
};

const titlePersonProfileImage = (case_) => {
  if (!case_) {
    return null;
  }
  let titlePerson = null;
  if (!!case_.patient) {
    titlePerson = case_.patient;
  } else if (!!case_.third_persons && case_.third_persons.length > 0) {
    titlePerson = case_.third_persons[0];
  } else {
    return null;
  }
  if (!!titlePerson && !!titlePerson.profile_image && !!titlePerson.profile_image.image_urls) {
    return titlePerson.profile_image.image_urls.small;
  }
  return null;
};

const titlePersonFirstName = (case_) => {
  if (!case_) {
    return null;
  }
  let titlePerson = null;
  if (!!case_.patient) {
    titlePerson = case_.patient;
  } else if (!!case_.third_persons && case_.third_persons.length > 0) {
    titlePerson = case_.third_persons[0];
  } else {
    return null;
  }
  if (!!titlePerson && !!titlePerson.first_name) {
    return titlePerson.first_name;
  }
  return null;
};

const titlePersonLastName = (case_) => {
  if (!case_) {
    return null;
  }
  let titlePerson = null;
  if (!!case_.patient) {
    titlePerson = case_.patient;
  } else if (!!case_.third_persons && case_.third_persons.length > 0) {
    titlePerson = case_.third_persons[0];
  } else {
    return null;
  }
  if (!!titlePerson && !!titlePerson.last_name) {
    return titlePerson.last_name;
  }
  return null;
};

const computedStyleMain = computed(() => {
  if (!props.outerHeaderHeight) {
    return { height: '90vH' };
  }
  if (!innerHeaderHeight.value) {
    return { height: '90vH' };
  }
  const calculatedHeight = `calc(var(--fallback-viewport-height, 90vh) - ${props.outerHeaderHeight}px - ${innerHeaderHeight.value}px)`;

  return { height: calculatedHeight };
});

watch(
  () => courseStore.lastRemountEnforced,
  () => {
    console.debug('Course store lastRemountEnforced changed');
    sortedChapters.value = getOrderedChapters();
  },
);
</script>

<template>
  <div class="w-full h-screen relative">
    <header
      ref="innerHeader"
      class="sticky top-0 flex flex-wrap md:justify-start md:flex-nowrap z-10 w-full bg-white dark:border-none border-b border-gray-200 pb-1.5 dark:bg-neutral-900"
    >
      <div class="max-w-[85rem] w-full mx-auto px-3 sm:px-3 md:px-4 lg:px-6 xl:px-8 flex justify-between">
        <ol class="flex items-center whitespace-nowrap w-full">
          <!-- level 1 breadcrump: all courses -->
          <BreadcrumbElement label="Kurse" to="/courses" />
          <!-- level 2 breadcrump: current course -->
          <BreadcrumbElement
            :label="currentCourse ? currentCourse.title : 'Kurs'"
            :to="'/course/' + (currentCourse ? currentCourse.id : '')"
            :showSeparator="false"
          />
        </ol>
      </div>
    </header>

    <!-- Table Section -->
    <main ref="courseScrollContainer" class="flex-grow w-full overflow-y-auto" :style="computedStyleMain">
      <div v-show="!initialFetchCompleted">
        <div class="flex justify-center items-center pt-64 min-h-full relative">
          <LoadingSpinnerLarge />
          <div class="absolute start-50 top-[309px] text-sm md:text-base text-gray-600 md:text-gray-800 md:top-50">
            {{ percentageFetched }} %
          </div>
        </div>
      </div>
      <div
        v-show="initialFetchCompleted"
        class="flex-col mb-12 flex max-w-[85rem] px-2 py-2 sm:px-6 lg:px-8 lg:py-14 mx-auto h-fit min-h-fit overflow-hidden"
      >
        <!-- Card -->
        <div class="-m-1.5 overflow-x-auto overflow-y-visible">
          <div class="p-1.5 min-w-full inline-block align-middle overflow-y-visible">
            <div
              class="mb-2 border border-gray-200 bg-white rounded-xl shadow-sm overflow-visible dark:bg-neutral-900 dark:border-gray-700"
            >
              <!-- Header -->
              <div
                class="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center dark:border-gray-700"
                :class="{
                  'border-b border-gray-200': isEditing,
                  'border-none lg:border-b border-gray-200': !isEditing,
                }"
              >
                <div class="flex-col">
                  <!-- discard/ save buttons -->
                  <div class="flex justify-right gap-x-2 pb-2">
                    <button
                      v-show="unsavedChangesCounter"
                      type="button"
                      class="py-2 px-3 flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-400 text-white hover:bg-red-500 disabled:opacity-50 disabled:pointer-events-none"
                      @click="discardChanges"
                    >
                      <div
                        v-if="isLoading"
                        class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                        role="status"
                        aria-label="loading"
                      >
                        <span class="sr-only">Loading</span>
                      </div>
                      <div class="flex gap-x-1 text-xs" v-else>Verwerfen</div>
                    </button>
                    <button
                      v-show="unsavedChangesCounter"
                      type="button"
                      class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      @click="saveChanges"
                    >
                      <div
                        v-if="isLoading"
                        class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                        role="status"
                        aria-label="loading"
                      >
                        <span class="sr-only">{{ $t('message.loading') }}</span>
                      </div>
                      <div class="flex gap-x-1 text-xs" v-else>{{ $t('message.save') }}</div>
                    </button>
                  </div>

                  <div>
                    <h2 class="md:text-xl font-semibold text-gray-800 dark:text-gray-200">
                      <TextEditor
                        v-if="initialFetchCompleted && renderEditor"
                        ref="courseTitleEditor"
                        :allowEdit="isEditing"
                        :allowFormatting="false"
                        :content="newCourseTitle"
                        :placeholderMessage="t('message.courseTitle')"
                        @unsavedChanges="unsavedChangesCounter++"
                        @changesCleared="unsavedChangesCounter--"
                      />
                    </h2>
                    <div class="inline-flex items-center text-sm text-gray-600 dark:text-gray-400">
                      <TextEditor
                        v-if="initialFetchCompleted && renderEditor"
                        ref="courseDescriptionEditor"
                        :allowEdit="isEditing"
                        :allowFormatting="false"
                        :content="newCourseDescription"
                        :placeholderMessage="t('message.courseDescription')"
                        @unsavedChanges="unsavedChangesCounter++"
                        @changesCleared="unsavedChangesCounter--"
                      />
                      <p
                        :class="{
                          'hidden lg:block': !isEditing,
                          block: isEditing,
                        }"
                      >
                        {{ newCourseDescription ? '&nbsp;' : '' }}{{ $t('message.chapter', numberOfChapters) }},
                        {{ $t('message.case', numberOfCases) }} .
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="-ml-1 text-xs uppercase text-gray-500"
                  :class="{
                    'block lg:hidden': !isEditing,
                    hidden: isEditing,
                  }"
                >
                  {{ newCourseDescription ? '&nbsp;' : '' }}{{ $t('message.chapter', numberOfChapters) }},
                  {{ $t('message.case', numberOfCases) }} .
                </div>

                <!-- buttons -->
                <div>
                  <div
                    class="inline-flex"
                    :class="{
                      'gap-x-4': isEditing,
                      'gap-x-2 -ml-3 lg:ml-0': !isEditing,
                    }"
                  >
                    <!-- Bearbeiten an/ aus-->
                    <div class="flex-col justify-start flex items-center" v-show="isOwnerOrEditorOrAdmin">
                      <p class="flex flex-col items-center">
                        <input
                          v-model="isEditing"
                          :disabled="unsavedChangesCounter || savingChangedOrder"
                          type="checkbox"
                          id="hs-xs-switch"
                          data-testid="course-edit-toggle-button"
                          class="relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-600 before:inline-block before:w-4 before:h-4 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
                        />
                      </p>
                      <p class="inline-flex items-center">
                        <span
                          v-show="savingChangedOrder"
                          class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                          role="status"
                          aria-label="loading"
                        >
                        </span>
                        <label
                          for="hs-xs-switch"
                          class="items-end font-medium text-sm text-gray-500 dark:text-gray-400 -mb-1 pt-1 px-4"
                          >{{ $t('message.edit') }}
                        </label>
                      </p>
                    </div>

                    <!-- Freunde einladen -->
                    <div v-show="isEnrolled" class="hs-dropdown relative inline-block [--placement:bottom-right]">
                      <div id="hs-invite-friends-to-course-dropdown" type="button">
                        <ProgressButton
                          :text="$t('message.shareAndDiscuss')"
                          color="white"
                          icon="share"
                          iconSize="2xl"
                        />
                      </div>
                      <div
                        class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 border-gray-50 border hidden divide-y divide-gray-200 min-w-48 z-20 bg-white shadow-md rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-neutral-800 dark:border dark:border-gray-700"
                        aria-labelledby="hs-invite-friends-to-course-dropdown"
                      >
                        <div class="py-2 first:pt-0 last:pb-2">
                          <span class="block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-gray-600">
                            {{ $t('message.options') }}
                          </span>
                          <a
                            class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            @click.prevent="onCopyLink"
                          >
                            <span translate="no" class="material-symbols-outlined notranslate"> share </span>
                            <div class="flex-col">
                              <div>
                                {{ $t('message.copyLink') }}
                              </div>
                              <div class="text-xs text-gray-400">
                                {{ $t('message.copyLinkDescription') }}
                              </div>
                            </div>
                          </a>
                          <a
                            class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            :href="communityDiscussionUrl"
                            target="_blank"
                          >
                            <span translate="no" class="material-symbols-outlined notranslate"> add_comment </span>
                            <div class="flex-col">
                              <div>
                                {{ $t('message.communityDiscussion') }}
                              </div>
                              <div class="text-xs text-gray-400">
                                {{ $t('message.communityDiscussionDescription') }}
                              </div>
                            </div>
                          </a>
                          <a
                            class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            :href="communityFeedbackUrl"
                            target="_blank"
                          >
                            <span translate="no" class="material-symbols-outlined notranslate"> comment </span>
                            <div class="flex-col">
                              <div>
                                {{ $t('message.communityFeedback') }}
                              </div>
                              <div class="text-xs text-gray-400">
                                {{ $t('message.communityFeedbackDescription') }}
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <!-- Fortschritt exportieren -->
                    <div
                      v-if="false"
                      v-show="isEnrolled"
                      class="hs-dropdown relative inline-block [--placement:bottom-right]"
                    >
                      <div id="hs-export-course-progress-dropdown" type="button" class="">
                        <ProgressButton
                          :text="$t('message.exportProgress')"
                          color="white"
                          icon="download"
                          iconSize="2xl"
                        />
                      </div>
                      <div
                        class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 border-gray-50 border hidden divide-y divide-gray-200 min-w-48 z-20 bg-white shadow-md rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-neutral-800 dark:border dark:border-gray-700"
                        aria-labelledby="hs-export-course-progress-dropdown"
                      >
                        <div class="py-2 first:pt-0 last:pb-2">
                          <span class="block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-gray-600">
                            {{ $t('message.downloadOptions') }}
                          </span>
                          <a
                            class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            @click.prevent="onExportPdf"
                          >
                            <svg
                              class="size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
                              />
                              <path
                                d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"
                              />
                            </svg>
                            .PDF
                          </a>
                          <a
                            class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            @click.prevent="onExportSignedPdf"
                          >
                            <svg
                              class="size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
                              />
                              <path
                                d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"
                              />
                            </svg>
                            {{ $t('message.signedPDFWithQRCode') }}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div>
                      <!-- <button
                        v-show="isLoading"
                        class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      >
                        <a
                          class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        >
                          <span class="sr-only">{{ $t('message.loading') }}</span>
                        </a>
                      </button> -->
                      <!-- enroll / continue -->
                      <ProgressButton
                        v-show="!isEditing"
                        :showProgress="isLoading"
                        :disabled="isLoading"
                        :text="enrollButtonText"
                        icon="school"
                        iconSize="2xl"
                        @click="onStartCourseClick"
                      />
                      <!-- publish -->
                      <div
                        v-show="!isLoading && isEditing"
                        class="hs-dropdown relative inline-block [--placement:bottom-right]"
                      >
                        <div id="hs-publish-course-dropdown" type="button" class="">
                          <!--                          <ProgressButton text="Publish" color="blue" icon="publish" iconSize="2xl">-->
                          <!--                            <PublishStatusCard :live-at="liveAt" :organizations="organizations" />-->
                          <!--                          </ProgressButton>-->
                        </div>
                        <div
                          class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 border-gray-50 border hidden divide-y divide-gray-200 min-w-48 z-20 bg-white shadow-md rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-neutral-800 dark:border dark:border-gray-700"
                          aria-labelledby="hs-publish-course-dropdown"
                        >
                          <div class="py-2 first:pt-0 last:pb-2">
                            <span
                              class="block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-gray-600"
                            >
                              Grant or revoke access by institution
                            </span>

                            <a
                              v-for="(org, index) in organizations"
                              class="flex-col text-sm flex cursor-pointer items-center justify-start gap-x-4 gap-y-1"
                            >
                              <span
                                class="pl-4 flex group items-center w-full justify-start gap-x-1.5 mt-0.5"
                                :class="{
                                  'text-green-800 hover:text-red-600': liveAt[index],
                                  'text-red-600 hover:text-green-800': !liveAt[index],
                                }"
                                @click="
                                  (event) => {
                                    event.stopPropagation();
                                    liveAt[index] = !liveAt[index];
                                  }
                                "
                              >
                                <span
                                  class="size-1.5 shrink-0 mr-2 inline-block rounded-full"
                                  :class="{
                                    'bg-green-800 animate-pulse group-hover:bg-red-600 group-hover:animate-none':
                                      liveAt[index],
                                    'bg-red-600 group-hover:bg-green-800': !liveAt[index],
                                  }"
                                ></span>
                                <span class="block group-hover:hidden">{{ org }}</span>
                                <span v-show="liveAt[index]" class="hidden group-hover:block">Revoke access</span>
                                <span v-show="!liveAt[index]" class="hidden group-hover:block">Grant access</span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Header -->

              <!-- We show the chapters as a table if large screen or editing -->
              <div
                :class="{
                  'hidden lg:block': !isEditing,
                  block: isEditing,
                }"
                :key="lastRemountEnforced"
              >
                <CourseDetailsMainLargeScreen
                  :sortedChapters="sortedChapters"
                  :isLoading="isLoading"
                  :isEditing="isEditing"
                  :isDraggingEnabled="isDraggingEnabled"
                  :initialFetchCompleted="initialFetchCompleted"
                  :isOwnerOrEditorOrAdmin="isOwnerOrEditorOrAdmin"
                  :aboutToDeleteChapterWithIndex="aboutToDeleteChapterWithIndex"
                  :chapterCompletedProgress="chapterCompletedProgress"
                  :chapterStartedProgress="chapterStartedProgress"
                  :savingChangedOrder="savingChangedOrder"
                  :titlePerson="titlePerson"
                  :titlePersonProfileImage="titlePersonProfileImage"
                  :titlePersonFirstName="titlePersonFirstName"
                  :titlePersonLastName="titlePersonLastName"
                  @onEditChapter="onEditChapter"
                  @onMoveChapter="onMoveChapter"
                  @onDeleteChapter="onDeleteChapter"
                  @onNewChapter="onNewChapter"
                  @onStartChapter="onStartChapter"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- We show the chapters as cards if small screen and not editing -->
        <div
          :class="{
            'block lg:hidden': !isEditing,
            hidden: isEditing,
          }"
          :key="lastRemountEnforced"
        >
          <CourseDetailsMainSmallScreen
            ref="mainSmallScreen"
            :sortedChapters="sortedChapters"
            :numChaptersCompleted="numChaptersCompleted"
            :numberOfChapters="numberOfChapters"
            :chapterIsCompleted="chapterIsCompleted"
            :isLoading="isLoading"
            :initialFetchCompleted="initialFetchCompleted"
            :chapterCompletedProgress="chapterCompletedProgress"
            :chapterStartedProgress="chapterStartedProgress"
            :titlePerson="titlePerson"
            :titlePersonProfileImage="titlePersonProfileImage"
            :titlePersonFirstName="titlePersonFirstName"
            :titlePersonLastName="titlePersonLastName"
            @onStartChapter="onStartChapter"
          />
        </div>
      </div>
      <!-- End Card -->
    </main>

    <div v-show="isLoading" class="absolute flex items-center justify-center top-0 left-0 w-full h-screen bg-white/70">
      <LoadingSpinnerLarge />
    </div>

    <UserConfirmationModal
      ref="confirmDeleteChapterModal"
      prompt_message=""
      :approve_message="$t('message.delete')"
      :discard_message="$t('message.back')"
      overlayId="confirmDeleteChapterModal"
      approve_color="bg-red-500 hover:bg-red-600"
      @approved="deleteChapter"
      @declined="aboutToDeleteChapterWithIndex = null"
    >
      Möchten Sie das Kapitel wirklich löschen? Dies kann nicht rückgängig gemacht werden!
      <!--    TODO i18n  {{ $t('message.confirmDeleteChapter') }}-->
    </UserConfirmationModal>
  </div>
  <!-- End Table Section -->
</template>

<style></style>
