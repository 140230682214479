<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import PersonEdit from './PersonEdit.vue';
import { mapTitle, updateMinTextareaHeight } from '@/helper';
import { ErrorMessage, Field, useField, useForm } from 'vee-validate';
import { InvolvementType } from '@/apiclient';

const localPersonDialogInvolvementType = ref(InvolvementType.IN_THE_ROOM);

const person = defineModel({
  type: Object,
  required: false,
  default: () => ({
    create_id: null,
    profile_image: null,
    voice: null,
    details: {
      freehand_description: '',
      age: null,
    },
  }),
});

const props = defineProps({
  isSelected: {
    type: Boolean,
    default: false,
  },
  allowEdit: {
    type: Boolean,
    default: true,
  },
  allowRemove: {
    type: Boolean,
    default: true,
  },
  allowSelect: {
    type: Boolean,
    default: false,
  },
  requireCompleteness: {
    type: Boolean,
    default: false,
  },
  startingFromCompleteDialog: {
    type: Boolean,
    default: false,
  },
  alwaysShowDetails: {
    type: Boolean,
    default: false,
  },
  alwaysShowPatientDetails: {
    type: Boolean,
    default: false,
  },
  validateEdits: {
    type: Boolean,
    default: true,
  },
  initialPersonDialogConcern: {
    type: Object,
    default: null,
  },
  initialPersonDialogInvolvement: {
    type: Object,
    default: {
      create_id: null,
      type: InvolvementType.IN_THE_ROOM,
    },
  },
});

const isEditing = ref(false);
const editTouched = ref(false);
const selectTouched = ref(false);
const generateFinished = ref(false);
const emit = defineEmits([
  'addPerson',
  'removePerson',
  'toggleSelect',
  'editedPersonDialogInvolvement',
  'click',
  'centerMe',
  'scrollToBottom',
  'isValid',
]);

// compose male/female string based on patient sex
const personSummary = computed(() => {
  let sexSymbol = '';
  let personSummary = '';
  if (!person.value.details) {
    return '';
  }
  switch (person.value.details.sex) {
    case 'FEMALE':
      sexSymbol = '♀';
      break;
    case 'MALE':
      sexSymbol = '♂';
      break;
    case 'DIVERSE':
      sexSymbol = '⚧';
      break;
  }

  const sepStringAdd = (str1, str2) => {
    str1 = !!str1 ? str1.trim() : null;
    str2 = !!str2 ? str2.trim() : null;
    return !!str1 && !!str2 ? str1 + ' | ' + str2 : str1 || str2;
  };

  personSummary = sepStringAdd(personSummary, `${sexSymbol}`);
  personSummary = sepStringAdd(personSummary, !!person.value.details.age ? `${person.value.details.age} Jahre` : '');
  personSummary = sepStringAdd(
    personSummary,
    !!person.value.details.role ? person.value.details.role.display_name : '',
  );

  return personSummary;
});

async function emitRemovePerson(id) {
  emit('removePerson', id);
}

function editPersonDetails() {
  if (person.value.create_id === null) {
    return;
  }
  if (isEditing.value) {
    // i.e. closed editing for the first time
    editTouched.value = true;
  }
  isEditing.value = !isEditing.value;
  person.value.placeholderName = 'Person #' + (person.value.create_id + 1);
}

function emitToggleSelect(id) {
  selectTouched.value = false;
  emit('toggleSelect', id, localPersonDialogInvolvementType.value);
}

function emitAddPerson() {
  emit('addPerson');
}

function emitEditedPersonDialogInvolvement() {
  // console.log('editedPersonDialogInvolvement');
  emit('editedPersonDialogInvolvement', localPersonDialogInvolvementType.value);
}

const isDummy = computed(() => {
  return person.value.create_id === null;
});

function emitCenterMeIfOpened(event) {
  if (!isEditing.value) {
    emit('centerMe', event);
  }
}

function emitCenterMeIfSelected(event) {
  if (!props.isSelected) {
    console.log('centerMe');
    emit('centerMe', event);
  }
}

onMounted(async () => {
  await nextTick(() => {
    // HSStaticMethods.autoInit();
    // const el = HSDropdown.getInstance('#' + dropdownId.value);
  });
  if (!!props.initialPersonDialogInvolvement) {
    localPersonDialogInvolvementType.value = props.initialPersonDialogInvolvement.type;
  }
  emitEditedPersonDialogInvolvement();
});

onBeforeUnmount(() => {});

const isPatient = computed(() => {
  let check = !!person.value.details?.role && person.value.details.role.value === 'PATIENT';
  return check;
});

const showingDetails = toRef(props.alwaysShowDetails);

function personEditValidityChanged(value) {
  personEditIsValid.value = value;
}
</script>

<template>
  <!-- highlight errors only if touched -->
  <div
    class="group/person cursor-pointer transition-all min-h-fit h-fit max-h-fit duration-75 flex flex-col border rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-visible"
    :class="{
      'min-w-[200px] w-[200px]': !isEditing,
      'min-w-[482px] w-[482px] hover:bg-white': isEditing && !isPatient,
      'min-w-[484px] w-[484px] hover:bg-white': isEditing && isPatient && !generateFinished,
      'min-w-[884px] w-[884px] hover:bg-white': isEditing && isPatient && generateFinished,
      'bg-white border-gray-200': !isDummy,
      'hover:bg-gray-50': !isDummy && !isEditing,
      'bg-blue-50 hover:bg-blue-100 border-blue-200': isDummy,
      'border-red-600': !allValid && !isDummy && ((validateEdits && editTouched) || selectTouched),
      'border-blue-600': !allValid && !isDummy && ((validateEdits && !editTouched) || !selectTouched),
    }"
    @click.prevent="
      async (event) => {
        event.stopPropagation();
        isDummy
          ? emitAddPerson()
          : allowSelect
          ? emit('click', event) || emitCenterMeIfSelected(event) || emitToggleSelect(person.create_id)
          : emitCenterMeIfOpened(event) || editPersonDetails();
      }
    "
  >
    <!-- Header -->
    <div class="p-3 md:pt-5 md:px-5 grid grid-cols-3 gap-x-2 overflow-visible">
      <div class="col-start-2 flex-shrink-0 relative h-16 w-16 md:w-[62px] md:h-[62px] mx-auto overflow-visible">
        <img
          v-if="!isDummy && !!person?.profile_image"
          class="flex-shrink-0 h-16 w-16 md:w-[62px] md:h-[62px] rounded-full"
          :class="{ 'group-hover/person:scale-105': allowEdit || allowSelect }"
          :src="person.profile_image.image_urls.small"
        />
        <div
          v-else-if="!isDummy && !person?.profile_image"
          class="flex-shrink-0 h-16 w-16 md:w-[62px] md:h-[62px] rounded-full flex items-center justify-center text-sm font-medium text-white bg-gray-600"
          :class="{ 'group-hover/person:scale-105': allowEdit || allowSelect }"
        >
          <!-- dummy icon if no image (will be isSelected later) -->
          <span translate="no" class="material-symbols-outlined notranslate text-5xl"> person </span>
        </div>
        <div
          v-else
          class="flex-shrink-0 h-16 w-16 md:w-[62px] md:h-[62px] rounded-full flex items-center justify-center text-sm font-medium text-white bg-blue-600 group-hover/person:bg-blue-700 group-hover/person:scale-105"
        >
          <span translate="no" class="material-symbols-outlined notranslate text-5xl"> person_add </span>
        </div>
      </div>

      <div class="ms-auto overflow-visible" v-if="!isDummy">
        <div
          @click.prevent="
            async (event) => {
              event.stopPropagation();
              emitToggleSelect(person.create_id);
            }
          "
          v-if="allowSelect"
          class="h-7 w-7 rounded-lg border border-gray-200 flex items-center justify-center"
        >
          <span
            v-show="isSelected"
            translate="no"
            class="material-symbols-outlined notranslate text-blue-600 group-hover/person:text-blue-700"
            :style="{ 'font-weight': '400' }"
          >
            check
          </span>
        </div>

        <div class="top-0 end-0 flex-col sticky flex justify-center overflow-visible -mt-3 -mr-2">
          <!-- Delete button -->
          <button
            v-if="allowRemove"
            @click.prevent="emitRemovePerson(person.create_id)"
            class="z-10 flex items-center justify-items-center w-full font-semibold text-start text-gray-500 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-600 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
          >
            <span
              translate="no"
              class="material-symbols-outlined notranslate text-xl text-red-600 hover:text-red-800 block"
            >
              delete
            </span>
          </button>
        </div>
      </div>
    </div>
    <!-- End Header -->

    <!-- Body -->
    <div class="p-3 pt-0 md:px-5 md:pb-5 text-center overflow-visible">
      <h3 class="md:text-lg font-medium text-gray-800 dark:text-neutral-200">
        {{ person.details ? mapTitle(person.details.academic_title) : '' }}
        <span v-if="person.create_id === null" class="text-blue-600 group-hover/person:text-blue-700"
          >Person hinzufügen</span
        >
        <span v-else-if="!person?.details?.first_name && !person?.details?.last_name">
          {{ person.placeholderName || 'Zum Bearbeiten klicken' }}
        </span>
        <span v-else>
          {{ person.details ? person.details.first_name : '' }}
          {{ person.details ? person.details.last_name : '' }}
        </span>
      </h3>

      <p class="text-sm text-gray-500 dark:text-neutral-500 overflow-visible">
        {{ personSummary }}
      </p>
      <div v-if="!!person?.role" class="inline-flex justify-center items-center gap-x-2">
        <svg
          class="flex-shrink-0 h-4 w-4 text-gray-500 dark:text-neutral-500"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z" />
          <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2" />
          <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2" />
          <path d="M10 6h4" />
          <path d="M10 10h4" />
          <path d="M10 14h4" />
          <path d="M10 18h4" />
        </svg>
        <p class="text-sm text-gray-500 dark:text-neutral-500">Arzt</p>
      </div>
    </div>
    <!-- End Body -->

    <!-- Footer 1: messsage authorship & scene involvement -->
    <div
      @click="
        (event) => {
          event.stopPropagation();
        }
      "
      class="transition-height overflow-hidden ease-in-out duration-75 flex flex-grow flex-col text-start border-gray-200 dark:border-neutral-700"
      :class="{ 'max-h-0': !isSelected, 'border-t max-h-[500px] px-1 py-1': isSelected }"
    >
      <div class="flex text-xs text-gray-500 px-0.5 pb-1 pt-2">Beteiligung *</div>
      <div class="flex-grow">
        <select
          v-model="localPersonDialogInvolvementType"
          @click="
            (event) => {
              event.stopPropagation();
              emit('click');
            }
          "
          class="py-2 px-0.5 block w-full border-gray-200 text-gray-500 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
          @change="emitEditedPersonDialogInvolvement"
        >
          <option selected="" :value="InvolvementType.IN_THE_ROOM">Vor Ort im selben Raum</option>
          <option :value="InvolvementType.ON_THE_PHONE">Am Telefon</option>
        </select>
      </div>
    </div>
    <!--     End Footer 1 -->

    <!-- Footer 2: edit person/ patient details -->
    <div
      @click="
        (event) => {
          event.stopPropagation();
          emit('click');
        }
      "
      class="transition-height overflow-hidden ease-in-out duration-75 flex flex-grow flex-col text-start border-gray-200 dark:border-neutral-700"
      :class="{
        'max-h-0': !isEditing,
        'border-t max-h-[1000px] px-1 py-1': isEditing && !isPatient,
        'border-t max-h-[2000px] px-1 py-1': isEditing && isPatient,
      }"
    >
      <!-- This should ideally have v-model="person.details", however, cf.: https://github.com/eta-io/casuu/issues/69. TODO adapt once fixed in Vue -->
      <PersonEdit
        :model-value="person.details"
        @update:model-value="(value) => (person.details = value)"
        v-if="!!person && person.details !== undefined"
        :requireFullValidity="props.requireCompleteness"
        :startingFromCompleteDialog="props.startingFromCompleteDialog"
        :alwaysShowDetails="props.alwaysShowDetails"
        :alwaysShowPatientDetails="props.alwaysShowPatientDetails && isPatient"
        :editTouched="editTouched"
        :selectTouched="selectTouched"
        :generate="!person.profile_image || !person.voice"
        @scrollToBottom="emit('scrollToBottom')"
        @detailsToggled="(value) => (showingDetails = value)"
        @generateFinished="(value) => (generateFinished = true)"
        @isValid="personEditValidityChanged"
        @setProfileImage="person.profile_image = $event"
        @setVoice="person.voice = $event"
      />
    </div>
    <!--     End Footer 2 -->
  </div>
</template>

<style scoped>
.disabled {
  pointer-events: none;
}
</style>
