<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import { PCMPlayer } from '@/audio';
import { useCaseInteractionStore, useAlertStore } from '@/stores';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { useAudioStore } from '@/stores/audio.store';

const emit = defineEmits(['playbackFinished', 'playingAudio', 'wordChange']);

// Props
const props = defineProps({
  message: String,
  messageIsComplete: {
    type: Boolean,
    default: true,
  },
  /**
   * @property {String} messageId - The unique identifier for the message.
   * Once this is a valid UUID, the backend will store the audio stream.
   * The messageId is sent to the backend with each chunk of the message.
   * @default 'not_yet_in_database'
   */
  messageId: {
    type: String,
    default: 'not_yet_in_database',
  },
  bufferFirstNChunks: {
    type: Number,
    default: 0,
  },
  voice: {
    type: Object, // Assuming Voice is an object type
    required: false,
  },
});

// State
const websocket = ref(null);
const prevMessage = ref('');
const pcmPlayer = ref(null);
const fileReader = ref(null);
const audioDataBuffer = ref([]);
const audioDataQueue = ref([]);
const totalReceivedDuration = ref(0);
const fileReaderBusy = ref(false);
const numberOfChunksReceived = ref(0);
const streamingStarted = ref(false);
const playbackStarted = ref(false);
const waitingForPlayback = ref(false);
const audioPlaying = ref(false);
const playbackFinished = ref(false);
const allAudioBytesReceived = ref(false);
const uuid = ref('');
const currentWordStartTime = ref(0);
const wordTimings = ref([]);
const alertStore = useAlertStore();
const debugInterval = ref(null);

const caseInteractionStore = useCaseInteractionStore();
const { audioAutoplayEnabled: autoplay } = storeToRefs(caseInteractionStore);

// Add to script setup
const audioElement = ref(null);

// In script setup
const audioStore = useAudioStore();

// Replace pcmPlayer ref with computed
// const pcmPlayer = computed(() =>
//   audioStore.getPCMPlayer({
//     encoding: '16bitInt',
//     channels: 1,
//     sampleRate: 16000,
//     flushingTime: 200,
//     playbackFinishedCallback: onPlaybackFinished,
//   }),
// );

// Watchers
watch(
  () => props.message,
  (newMessage) => {
    if (props.messageIsComplete) {
      console.debug('Message is complete. Do not send new message part.');
      return;
    }

    const newPart = newMessage.slice(prevMessage.value.length);
    sendStringChunk(newPart);

    prevMessage.value = newMessage;
  },
);

// watch(
//     () => playbackFinished.value,
//     (newStatus) => {
//       if (newStatus) {
//         console.debug('Playback finished. Emitting event.');
//         emit('playbackFinished');
//       }
//     },
//     {immediate: true},
// )

// TODO: this does not fire for some reason if leaving case interation:
// watch(
//     () => caseInteractionStore.currentCaseInteraction,
//     (newValue) => {
//       if (!newValue) {
//         console.warn('currentCaseInteraction changed to null. Stopping audio playback.');
//         onStopAudio();
//       }
//     },
//     {immediate: true},
// )

// Methods
const startStreaming = async () => {
  console.log(uuid.value + ': startStreaming');
  caseInteractionStore.setAudioIsStreaming(true);
  streamingStarted.value = true;
  console.log(
    'Creating websocket with voice provider: ' +
      props.voice.voice_provider +
      ', voice model: ' +
      props.voice.voice_model +
      ', voice id: ' +
      props.voice.voice_id +
      '.',
  );
  websocket.value = (await getStreamingClient()).createWebSocket(
    'tts/',
    props.voice.voice_provider,
    props.voice.voice_model,
    props.voice.voice_id,
  );

  websocket.value.onmessage = (event) => {
    if (!caseInteractionStore.currentCaseInteraction) {
      console.warn('currentCaseInteraction changed to null. Refusing reception of audio chunk.');
      return;
    }
    let data = JSON.parse(event.data);
    let readingChars = data['aligned_chars'];
    let readingDurations = data['char_durations_ms'];

    // Calculate word timings for this chunk
    const chunkTimings = calculateWordTimings(readingChars, readingDurations);

    // Add offset based on total duration of previous chunks
    const totalDurationSoFar = caseInteractionStore.subtitleWords.reduce((acc, word) => Math.max(acc, word.endTime), 0);

    const adjustedTimings = chunkTimings.map((timing) => ({
      ...timing,
      startTime: timing.startTime + totalDurationSoFar,
      endTime: timing.endTime + totalDurationSoFar,
    }));

    wordTimings.value = [...wordTimings.value, ...adjustedTimings];

    // Process audio data
    const binaryData = atob(data['audio_b64']);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      view[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'audio/wav' });

    audioDataQueue.value.push(blob);
    caseInteractionStore.addChunkToReadingQueue({
      readingChars,
      readingDurations,
      wordTimings: adjustedTimings,
    });

    caseInteractionStore.setFirstAudioChunkReceived(true);
    numberOfChunksReceived.value += 1;

    if (numberOfChunksReceived.value <= props.bufferFirstNChunks || !autoplay.value) {
      console.debug('Received chunk #' + numberOfChunksReceived.value + '. Adding to buffer.');
      return;
    }

    processNextAudioChunk();

    console.log('Calculated word timings:', chunkTimings);
    console.log('Adjusted timings:', adjustedTimings);
  };

  websocket.value.onclose = () => {
    console.log(uuid.value + ': WebSocket closed externally');
    if (props.messageIsComplete) {
      console.log(
        uuid.value + ': Text streaming completed and websocket closed externally, so all audio bytes received.',
      );
      console.log(uuid.value + ': Decreasing PCMPlayer timeout interval');
      pcmPlayer.value.setTimeoutInterval(500);
      console.log(uuid.value + ': Playback started: ' + playbackStarted.value);
      console.log(uuid.value + ': Audio data queue length: ' + audioDataQueue.value.length);
      allAudioBytesReceived.value = true;
    }
    if (!playbackStarted.value && autoplay.value) {
      console.log(uuid.value + ': No playback started yet. Initiating immediate playback.');
      processNextAudioChunk();
    }
  };

  websocket.value.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  sendStringChunk(props.message);
};

const processNextAudioChunk = () => {
  console.log(uuid.value + ': Processing next audio chunk');
  if (!caseInteractionStore.currentCaseInteraction) {
    console.warn('currentCaseInteraction changed to null. Breaking audio playback.');
    return;
  }
  if (fileReaderBusy.value) {
    console.warn('FileReader is busy.');
    return;
  }
  if (audioDataQueue.value.length === 0) {
    console.log(uuid.value + ': No audio chunks in queue.');
    return;
  }
  const blob = audioDataQueue.value.shift();
  waitingForPlayback.value = false;
  playbackStarted.value = true;
  audioPlaying.value = true;
  fileReaderBusy.value = true;
  caseInteractionStore.setIsReplaying(true); // Set flag when playback starts

  fileReader.value.onload = () => {
    const arrayBuffer = fileReader.value.result;
    const audioData = new Int16Array(arrayBuffer);

    audioDataBuffer.value.push(audioData);
    totalReceivedDuration.value += audioData.length / 16000;

    playAudio(audioData);

    if (audioDataQueue.value.length === 0) {
      console.log(uuid.value + ': Queue emptied.');
      if (allAudioBytesReceived.value) {
        console.debug('All audio bytes received and played.');
        audioPlaying.value = false;
        playbackFinished.value = true;
      }
    }
    console.log(uuid.value + ': After play audio in process next audio chunk');

    processNextAudioChunk();
    fileReaderBusy.value = false;
  };

  fileReader.value.onerror = (error) => {
    console.error('Error reading Blob:', error);
    fileReaderBusy.value = false;
    processNextAudioChunk();
  };

  fileReader.value.readAsArrayBuffer(blob);
  console.log(uuid.value + ': End of process next audio chunk');
};

const onPlaybackFinished = () => {
  console.log(uuid.value + ': Playback finished');
  console.log(uuid.value + ': Audio data queue length: ' + audioDataQueue.value.length);
  console.log(uuid.value + ': All audio bytes received: ' + allAudioBytesReceived.value);
  if (audioDataQueue.value.length > 0) {
    console.debug('But there are more audio chunks to play in the queue, so resuming.');
    processNextAudioChunk();
  } else {
    console.debug('All audio bytes received and nothing more to play.');
    audioPlaying.value = false;
    playbackFinished.value = true;
    caseInteractionStore.setAudioIsStreaming(false);
    caseInteractionStore.setIsReplaying(false); // Reset flag when playback ends
    emit('playbackFinished');
  }
};

const playAudio = (audioData) => {
  pcmPlayer.value.feed(audioData);
};

// const onReplayAudio = () => {
//   if (audioPlaying.value) {
//     onStopAudio();
//   }
//   if (audioDataBuffer.value.length === 0) {
//     startStreaming();
//     console.log(props.message);
//     return;
//   }
//   console.log(uuid.value + ': Replaying audio');
//   audioPlaying.value = true;
//   playbackFinished.value = false;
//   for (const audioDataChunk of audioDataBuffer.value) {
//     playAudio(audioDataChunk);
//   }
// };

const onStopAudio = () => {
  pcmPlayer.value.destroy();
  pcmPlayer.value = new PCMPlayer({
    encoding: '16bitInt',
    channels: 1,
    sampleRate: 16000,
    flushingTime: 200,
  });
  audioPlaying.value = false;
  playbackFinished.value = true;
};

const sendFinished = () => {
  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    console.debug('Sending finished message');
    websocket.value.send(
      JSON.stringify({
        text: '',
        is_finished: true,
        message_id: props.messageId,
      }),
    );
  } else {
    console.error('WebSocket not open when trying to send finished message.');
  }
};

const sendStringChunk = (chunk) => {
  if (!caseInteractionStore.currentCaseInteraction) {
    console.warn('currentCaseInteraction changed to null. Aborting string send to TTS.');
    return;
  }
  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    waitingForPlayback.value = true;
    playbackFinished.value = false;
    console.debug('Sending message: ' + chunk);
    websocket.value.send(
      JSON.stringify({
        text: chunk,
        fetched_complete_message_from_db: false,
        is_finished: false,
        message_id: props.messageId,
      }),
    );
  } else {
    console.log(uuid.value + ': WebSocket not open yet. Waiting for connection...');
    setTimeout(() => {
      sendStringChunk(chunk);
    }, 500);
  }
};

watch(
  () => props.messageIsComplete,
  (newStatus) => {
    console.log(uuid.value + ': messageIsComplete changed to: ' + newStatus + '.');
    if (newStatus && streamingStarted.value) {
      console.debug('messageIsComplete changed to: ' + newStatus + '. Send finished message.');
      sendFinished();
    }
    if (!newStatus && !streamingStarted.value) {
      console.debug('messageIsComplete changed to: ' + newStatus + '. Start streaming.');
      startStreaming();
    }
  },
  { immediate: true },
);

watch(
  fileReaderBusy,
  (newStatus) => {
    if (!newStatus) {
      console.debug('fileReaderBusy changed to: ' + newStatus + '. Resume playback.');
      processNextAudioChunk();
    }
  },
  { immediate: true },
);

watch(
  autoplay,
  (newVal) => {
    console.log(uuid.value + ': autoplay is now ' + newVal);
    console.log(autoplay.value);
    if (newVal) {
      console.debug('autoplay enabled. Starting from queue of length ' + audioDataQueue.value.length);
      processNextAudioChunk();
    }
  },
  { immediate: true },
);

// Create stable references to the callbacks
const handleStart = () => {
  caseInteractionStore.setCurrentWordIndex(-1);
};

const handleTimeUpdate = (currentTime) => {
  const store = useCaseInteractionStore();

  // Find the current word based on timing
  const currentWordIndex = store.subtitleWords.findIndex((timing) => {
    return currentTime >= timing.startTime && currentTime <= timing.endTime;
  });

  if (currentWordIndex !== -1 && currentWordIndex !== store.currentWordIndex) {
    store.setCurrentWordIndex(currentWordIndex);
  } else if (currentWordIndex === -1 && store.currentWordIndex !== -1) {
    store.setCurrentWordIndex(-1);
  }
};

// Mounted
onMounted(() => {
  uuid.value = uuidv4();
  console.log(uuid.value + ': AudioPlayerTTS mounted');
  playbackFinished.value = true;

  pcmPlayer.value = new PCMPlayer({
    encoding: '16bitInt',
    channels: 1,
    sampleRate: 16000,
    flushingTime: 200,
    playbackFinishedCallback: onPlaybackFinished,
  });

  // debugInterval.value = setInterval(() => {
  //   if (!pcmPlayer.value.audioCtx) return;
  //   const state = pcmPlayer.value.audioCtx.state;
  //   const timestamp = new Date().toISOString();
  //   console.log(`[${timestamp}] Audio context state: ${state}`);
  //   console.log('Is autoplay enabled:', autoplay.value);
  //   console.log('Queue length:', audioDataQueue.value.length);
  //   console.log('Is playing:', audioPlaying.value);
  //   alertStore.info(
  //     `Audio context: ${state} | Autoplay: ${autoplay.value} | Queue: ${audioDataQueue.value.length} | Playing: ${audioPlaying.value}`,
  //   );
  // }, 5000);

  fileReader.value = new FileReader();
  caseInteractionStore.resetCurrentlyReadingChatMessageText();
  caseInteractionStore.setFirstAudioChunkReceived(false);
  pcmPlayer.value.on('timeupdate', handleTimeUpdate);
  caseInteractionStore.resetSubtitles();

  // Reset word index when playback starts
  pcmPlayer.value.on('start', () => {
    caseInteractionStore.setCurrentWordIndex(-1);
  });

  if (!!import.meta.env.DEV) {
    console.log('DEV environment detected: Setting window.audioPlayer to pcmPlayer.value');
    window.audioPlayer = pcmPlayer.value;
  }

  // Add event listeners through the store
  audioStore.addEventListener('timeupdate', handleTimeUpdate);
  audioStore.addEventListener('start', handleStart);
});

const calculateWordTimings = (readingChars, readingDurations) => {
  let currentTime = 0;
  let currentWord = '';
  let wordStartTime = 0;
  let timings = [];

  for (let i = 0; i < readingChars.length; i++) {
    const char = readingChars[i];
    const duration = readingDurations[i];

    if (currentWord === '') {
      wordStartTime = currentTime;
    }

    currentWord += char;
    currentTime += duration;

    if (char === ' ' || i === readingChars.length - 1) {
      const trimmedWord = currentWord.trim();
      if (trimmedWord) {
        // Add a small buffer to the end time to ensure smooth transitions
        timings.push({
          word: trimmedWord,
          startTime: wordStartTime,
          endTime: currentTime + 50, // Add 50ms buffer
        });
      }
      currentWord = '';
    }
  }

  console.log('Word timings:', timings);
  return timings;
};

// Add cleanup in onUnmounted
onUnmounted(() => {
  // Remove event listeners
  audioStore.removeEventListener('timeupdate', handleTimeUpdate);
  audioStore.removeEventListener('start', handleStart);
  clearInterval(debugInterval.value);

  pcmPlayer.value.destroy();
});

// Expose the unlock method to parent components
defineExpose({
  unlockAudioContext: async () => {
    let unlocked = false;

    // Create and play a silent audio element
    const silentAudio = new Audio(
      'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA',
    );
    silentAudio
      .play()
      .then(() => {
        silentAudio.pause();
        silentAudio.currentTime = 0;
      })
      .catch((e) => {
        console.warn('Failed to play silent audio:', e);
      });

    if (pcmPlayer.value) {
      unlocked = await pcmPlayer.value.unlockAudioContext();
    }

    if (!unlocked) alertStore.info('Failed to unlock audio context. Audio may be muted.');
    return unlocked;
  },
});
</script>

<template>
  <div>
    <!-- Hidden audio element (to unlock audio context on iOS) -->
    <audio
      ref="audioElement"
      src="data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA"
      preload="auto"
      style="display: none"
    ></audio>
  </div>
</template>
