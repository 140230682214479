/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ThirdPersonInteractionMessage } from '../models/ThirdPersonInteractionMessage';
import type { UserEditedMessage } from '../models/UserEditedMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ThirdPersonMessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Third Person Interaction Message
   * :param session:
   * :param user:
   * :param third_person_interaction_message_id:
   * :return:
   * @param thirdPersonInteractionMessageId
   * @returns ThirdPersonInteractionMessage Successful Response
   * @throws ApiError
   */
  public getThirdPersonInteractionMessage(
    thirdPersonInteractionMessageId: string,
  ): CancelablePromise<ThirdPersonInteractionMessage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/third-person-messages/third-person-message/{third_person_interaction_message_id}',
      path: {
        third_person_interaction_message_id: thirdPersonInteractionMessageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Store User Edit For Third Person Interaction Message
   * @param thirdPersonInteractionMessageId
   * @param requestBody
   * @returns ThirdPersonInteractionMessage Successful Response
   * @throws ApiError
   */
  public storeUserEditForThirdPersonInteractionMessage(
    thirdPersonInteractionMessageId: string,
    requestBody: UserEditedMessage,
  ): CancelablePromise<ThirdPersonInteractionMessage> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/third-person-messages/{third_person_interaction_message_id}',
      path: {
        third_person_interaction_message_id: thirdPersonInteractionMessageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Request Third Person Message Translation
   * :param session:
   * :param user:
   * :param third_person_interaction_message_id:
   * :param target_language:
   * :param translation_option:
   * :return:
   * @param thirdPersonInteractionMessageId
   * @param targetLanguage
   * @param translationOption
   * @returns any Successful Response
   * @throws ApiError
   */
  public requestThirdPersonMessageTranslation(
    thirdPersonInteractionMessageId: string,
    targetLanguage: string,
    translationOption: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/third-person-messages/{third_person_interaction_message_id}/translation',
      path: {
        third_person_interaction_message_id: thirdPersonInteractionMessageId,
      },
      query: {
        target_language: targetLanguage,
        translation_option: translationOption,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Undo Third Person Interaction Message
   * Sets a third_person interaction message as undone or reverts the undone
   * state.
   *
   * Args:
   * database_client: Injected database client
   * user: Current authenticated user
   * third_person_interaction_message_id: ID of the message to undo
   *
   * Returns:
   * The updated third_person interaction message
   * @param thirdPersonInteractionMessageId
   * @param revert
   * @returns ThirdPersonInteractionMessage Successful Response
   * @throws ApiError
   */
  public undoThirdPersonInteractionMessage(
    thirdPersonInteractionMessageId: string,
    revert: boolean = false,
  ): CancelablePromise<ThirdPersonInteractionMessage> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/third-person-messages/{third_person_interaction_message_id}/undo',
      path: {
        third_person_interaction_message_id: thirdPersonInteractionMessageId,
      },
      query: {
        revert: revert,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
