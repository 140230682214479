<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
console.log('alertStore instance in SignUp:', useAlertStore);
import { router } from '@/router';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useForm } from 'vee-validate';
import ProgressButton from '@/components/ProgressButton.vue';

const initialValues = {
  email: '',
  password: '',
  passwordConfirm: '',
  otpConfirm: '',
};

const authStore = useAuthStore();
const alertStore = useAlertStore();

const signedUp = ref(false);
const email = ref('');
const password = ref('');
const signUpConfirmed = ref(false);
const isSubmitting = ref(false);
const directlyProceedingToConfirm = ref(false);

async function handleSignUp(values, { setErrors, controlledValues }) {
  if (isSubmitting.value) {
    return; // Prevent multiple submissions
  }

  console.log('signing up');

  isSubmitting.value = true;

  const client = await getApiClient();

  client.authentication
    .signUp({
      email: values.email,
      password: values.password,
    })
    .then(() => {
      alertStore.success('status.success.registrationSuccessful');
      isSubmitting.value = false;
      signedUp.value = true;
      // safe for later use
      email.value = controlledValues.email;
      password.value = controlledValues.password;
    })
    .catch((error) => {
      alertStore.error('status.error.registrationFailed');
      isSubmitting.value = false;
      signedUp.value = false;
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}

async function handleSignUpConfirm(values, { setErrors, controlledValues }) {
  if (isSubmitting.value) {
    return; // Prevent multiple submissions
  } else if (!signedUp.value) {
    return; // user has not signed up yet
  }

  if (signUpConfirmed.value) {
    console.debug('sign up already confirmed. pushing to sign in.');
    router.push('/account/sign-in'); // Note: does not work here if awaited! (TODO: why?)
    return; // triggers background confirm-sign-up otherwise (which fails)
  }

  isSubmitting.value = true;

  if (directlyProceedingToConfirm.value) {
    // we do not remember the email address in this case, so the user had to enter it again
    email.value = controlledValues.email;
  }

  const client = await getApiClient();

  client.authentication
    .confirmSignUp({
      token: controlledValues.otpConfirm,
      token_hash: null,
      email: email.value,
    })
    .then(() => {
      alertStore.success('status.success.registrationSuccessful');
      signUpConfirmed.value = true;
      isSubmitting.value = false;
      signedUp.value = true;
    })
    .catch((error) => {
      signUpConfirmed.value = false;
      alertStore.error('status.error.registrationFailed');
      isSubmitting.value = false;
      setErrors({
        otpConfirm: 'status.error.invalidCode',
      });
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}

function proceedToConfirm() {
  console.debug('directly proceeding to confirm');
  signedUp.value = true;
  directlyProceedingToConfirm.value = true;
}
</script>

<template>
  <main class="w-full h-screen max-w-md mx-auto py-2 px-6">
    <div
      v-if="!signedUp"
      class="mt-4 md:mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-800 dark:border-gray-700"
    >
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">{{ $t('message.signUp') }}</h1>

          <p class="mt-2 text-sm text-red-600 dark:text-gray-400">
            {{ $t('message.registrationDisabled') }}
          </p>
          <a href="mailto:hello@casuu.health" class="text-red-600 text-sm decoration-2 hover:underline font-medium">
            hello@casuu.health
          </a>

          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {{ $t('message.alreadyHaveAccount') }}
            <router-link to="/account/sign-in" class="text-blue-600 decoration-2 hover:underline font-medium">
              {{ $t('message.signInNow') }}
            </router-link>
          </p>

          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {{ $t('message.alreadyRegistered') }}
            <a type="button" class="text-blue-600 decoration-2 hover:underline font-medium" @click="proceedToConfirm">
              {{ $t('message.confirmNow') }}
            </a>
          </p>
        </div>

        <div class="mt-5">
          <!-- Form -->
          <Form @submit="handleSignUp" v-slot="{ values, errors }" :initial-values="initialValues" ref="signUpForm">
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div>
                <label for="email" class="block text-sm mb-2 dark:text-white">{{ $t('message.email') }}</label>
                <div class="relative">
                  <Field
                    :disabled="isSubmitting"
                    name="email"
                    type="email"
                    rules="email|required"
                    :class="{
                      'border-red-500': errors.email,
                    }"
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="email" />
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div>
                <div class="flex justify-between items-center">
                  <label for="password" class="block text-sm mb-2 dark:text-white">{{ $t('message.password') }}</label>
                </div>
                <div class="relative">
                  <Field
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                    name="password"
                    type="password"
                    ref="password"
                    rules="required|password"
                    :class="{
                      'border-red-500': errors.password,
                    }"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="password" />
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div>
                <div class="flex justify-between items-center">
                  <label for="passwordConfirm" class="block text-sm mb-2 dark:text-white">{{
                    $t('message.confirmPassword')
                  }}</label>
                </div>
                <div class="relative">
                  <Field
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                    name="passwordConfirm"
                    type="password"
                    :rules="`required|confirmed:${values.password}`"
                    :class="{
                      'border-red-500': errors.passwordConfirm,
                    }"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="passwordConfirm" />
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Checkbox -->
              <div>
                <div class="flex items-center">
                  <div class="flex">
                    <Field
                      id="acceptTermsOfUse"
                      name="acceptTermsOfUse"
                      type="checkbox"
                      rules="required"
                      :value="true"
                      :class="{
                        'border-red-500': errors.acceptTermsOfUse,
                      }"
                      class="border-gray-200 rounded text-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                    />
                  </div>
                  <div class="ml-3">
                    <label for="acceptTermsOfUse" class="text-sm dark:text-white"
                      >{{ $t('message.acceptTerms') }}
                      <a class="text-blue-600 decoration-2 hover:underline font-medium" href="#">{{
                        $t('message.termsOfUse')
                      }}</a>
                      und
                      <a class="text-blue-600 decoration-2 hover:underline font-medium" href="/privacy-and-terms">{{
                        $t('message.privacyPolicy')
                      }}</a></label
                    >
                  </div>
                </div>
                <ErrorMessage class="text-sm text-red-600 mt-2" name="acceptTermsOfUse" />
              </div>

              <!-- End Checkbox -->

              <ProgressButton :text="$t('message.signUp')" :showProgress="isSubmitting" type="submit" />
            </div>
          </Form>
          <!-- End Form -->
        </div>
      </div>
    </div>

    <div
      v-else
      class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-800 dark:border-gray-700"
    >
      <div class="p-4 sm:p-7">
        <div class="text-center">
          <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">{{ $t('message.signUp') }}</h1>

          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {{ $t('message.emailSent') }}
          </p>
        </div>

        <div class="mt-5">
          <!-- Form -->
          <Form
            @submit="handleSignUpConfirm"
            v-slot="{ values, errors }"
            :initial-values="initialValues"
            ref="signUpConfirmForm"
          >
            <div class="grid gap-y-4">
              <!-- Form Group -->
              <div v-if="email === null || email === ''" class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                <label for="email" class="block text-sm mb-2 dark:text-white">{{ $t('message.email') }}</label>
                <div class="relative">
                  <Field
                    :disabled="isSubmitting"
                    name="email"
                    type="email"
                    rules="email|required"
                    :class="{
                      'border-red-500': errors.email,
                    }"
                    class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="email" />
                </div>
              </div>
              <div>
                <div class="relative text-sm text-gray-600 dark:text-gray-400">
                  <label for="otpConfirm" class="block text-sm mb-2 dark:text-white">{{
                    $t('message.confirmationCode')
                  }}</label>
                  <Field
                    class="font-mono text-center py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-400"
                    name="otpConfirm"
                    type="text"
                    ref="otpConfirm"
                    rules="required"
                    :class="{
                      'border-red-500': errors.otpConfirm,
                    }"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="otpConfirm" />
                </div>
              </div>
              <!-- End Form Group -->

              <button
                type="submit"
                class="py-3 px-4 justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
              >
                <div
                  v-if="isSubmitting"
                  class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <p v-else-if="signedUp && !signUpConfirmed">{{ $t('message.confirm') }}</p>
                <p v-else>{{ $t('message.continueToLogin') }}</p>
              </button>
            </div>
          </Form>
          <!-- End Form -->
        </div>
      </div>
    </div>
  </main>
</template>
