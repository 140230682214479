<!--TODO
TODO v1: table w/ all lab results (and normal range). If new parameter requested, append.
TODO v2: Allow lab test at different dates, e.g. if therapy (also: "wait for xxxx") is established. Same as v1 but ordered by date.

TODO: Standard mit Blutbild, Kreatinin, CrP sollte bei Starten des Falls mit erscheinen
TODO: Tooltip, dass man Parameternamen mit Kommata getrennt angeben soll
-->

<script setup>
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useAuthStore, usePatientInteractionStore, useCaseInteractionStore } from '@/stores';

import ChatBubbleSystem from '@/components/chat_bubbles/ChatBubbleSystem.vue';
import LabSheet from '@/components/chat_bubbles/LabSheet.vue';
import ExmReport from '@/components/chat_bubbles/ExmReport.vue';
import TaskInfoCard from '@/components/case_interaction/TaskInfoCard.vue';

const { currentTask } = storeToRefs(useCaseInteractionStore());

const emit = defineEmits(['startReport', 'trySolve']);

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  showLab: {
    type: Boolean,
    default: true,
  },
  showExaminations: {
    type: Boolean,
    default: true,
  },
  showDesc: {
    type: Boolean,
    default: true,
  },
});

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const { user } = storeToRefs(authStore);
const { labSheets, examinationReports, descMessages, descIsStreaming } = storeToRefs(patientInteractionStore);

const isLoading = ref(true);
const latestDesc = ref(0);

const labAndExaminationReportsAndDescriptionMessages = computed(() => {
  let allMessages = [...examinationReports.value, ...descMessages.value];
  allMessages = allMessages.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  allMessages = allMessages.sort((a, b) => ((a.created_at ? a.created_at : null) === 'incomplete' ? 1 : -1));

  // insert labSheets as one single element at position 1
  allMessages.splice(1, 0, ...labSheets.value);

  // revert order
  allMessages = allMessages.reverse();

  // update index of latest description message
  latestDesc.value = allMessages.findLastIndex((message) => message.type === 'DESCRIPTION');

  return allMessages;
});
</script>

<template>
  <!--  <div class="overflow-y-scroll bg-red-100 flex-col-reverse ml-2 max-h-full min-h-full h-full">-->
  <!--    &lt;!&ndash; this fixes scroll to bottom; source: https://stackoverflow.com/a/72644230 &ndash;&gt;-->
  <!--    <div class="translate-z-0 overflow-y-visible min-h-full h-full max-h-full pt-1">-->
  <!-- part of the scroll stick -->

  <div class="flex-col flex content-between min-h-full h-full">
    <div class="mx-2 mt-2 flex-grow">
      <TaskInfoCard :task="currentTask" :integrated="false" />
    </div>

    <div class="overflow-y-auto flex-shrink items-end flex-col-reverse max-w-[100%] md:max-w-2xl w-full space-y-3">
      <div class="space-y-2" v-for="(message, index) in labAndExaminationReportsAndDescriptionMessages">
        <div
          class=""
          v-if="props.showDesc && !!message ? (message.type ? message.type : null) === 'DESCRIPTION' : false"
        >
          <ChatBubbleSystem :message="message" :isStreaming="descIsStreaming && index === latestDesc" />
        </div>
        <div
          v-else-if="
            props.showExaminations && !!message ? (message.type ? message.type : null) === 'EXAMINATION' : false
          "
        >
          <ExmReport :exmReport="message" :id="id + index" />
        </div>
        <div
          v-else-if="
            props.showLab &&
            !!message &&
            (message.type ? message.type : null) !== 'EXAMINATION' &&
            (message.type ? message.type : null) !== 'DESCRIPTION'
          "
        >
          <LabSheet :labSheetData="message" :id="id + index" />
        </div>
      </div>
      <!--          <div class="mt-5 space-y-2" v-for="labSheet in labSheets">-->
      <!--            <LabSheet :labSheetData="labSheet"/>-->
      <!--          </div>-->
      <!--        </div>-->
    </div>
  </div>
</template>

<style scoped></style>
