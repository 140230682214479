<script setup lang="ts">
import { ErrorMessage, Field, Form, useField, useForm } from 'vee-validate';
import { StudentDetails } from '@/apiclient';
import { OtherCaseDetails } from '@/components/new_case/typing';
import { computed, nextTick, onMounted, watch } from 'vue';

const student = defineModel({
  type: Object as () => StudentDetails,
  required: true,
});

const {
  value: localRole,
  errors,
  validate,
} = useField('role', 'required|max:35', { initialValue: student.value.role });

const emit = defineEmits(['isValid']);

watch(
  () => student.value.role,
  async (value) => {
    if (localRole.value !== student.value.role) {
      localRole.value = student.value.role;
    }
    let result = await validate();
    let valid = result.valid;
    emit('isValid', valid);
  },
  { immediate: true, deep: true },
);

onMounted(async () => {
  await nextTick();
});
</script>

<template>
  <div class="py-8 grid grid-cols-11 gap-y-1">
    <h2 class="col-span-11 inline-block text-xl mt-2.5 font-bold text-gray-800 dark:text-gray-200">
      Beschreibe mit einem Begriff die Rolle, die der:die Student:in in Deinem Fall übernehmen soll.
    </h2>
    <div class="col-span-11">
      <p class="block text-sm text-gray-600 dark:text-gray-400">
        Er oder sie könnte zum Beispiel "Arzt" sein oder "Pflegekraft" oder auch "Pfleger im Spätdienst"
      </p>
      <p class="block text-sm text-gray-600 dark:text-gray-400">
        Die Aufgabe(n) für deine Student:innen folgen später.
      </p>
      <div class="md:pt-5">
        <!-- TODO: TagEditor mit einigen Defaults und sonst Freitext-Möglichkeit -->
        <textarea
          v-model="student.role"
          class="py-4 my-4 px-3 mx-auto resize-none block w-[20rem] border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
          rows="1"
          placeholder="z.B. Pfleger im Spätdienst, Ärztin, ..."
          :class="{
            'border-red-500 focus:border-red-500 focus:ring-red-500': errors.length > 0,
          }"
          @input="localRole = $event.target.value"
        />
        <ErrorMessage class="text-sm text-red-600 mt-2" name="role" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
